import { Menu } from "ant-design-vue";//菜单递归
import AppMenuItem from "./app-menu-item.vue";

//菜单递归方法
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
        <p slot="title">
          <app-menu-item :item="menuInfo" :isOpen="isOpen" :itemLevel="itemLevel"/>
        </p>
        <template v-for="res in menuInfo.children">
          <a-menu-item v-if="!res.children && !res.menuGroup" :key="res.key" @click="$baseMenu.changeMenu(res)" >
            <app-menu-item :item="res" :isOpen="isOpen" :itemLevel="2"/>
          </a-menu-item>
          <a-menu-item-group v-else-if="res.menuGroup" :key="res.key">
            <template #title>{{ res.title }}</template>
          </a-menu-item-group>
          <sub-menu v-else :key="res.key" :menu-info="res" :isOpen="isOpen" :itemLevel="2"/>
        </template>
      </a-sub-menu>
    `,
  name: 'SubMenu',
  isSubMenu: true,// must add isSubMenu: true
  props : {
    ...(Menu.SubMenu as any).props,// Cannot overlap with properties within Menu.SubMenu.props,这里是父级给自己得数据，函数模板在ant2.0以下需要
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
    // 是否收缩菜单--左侧菜单得展开与收缩在App.vue文件中
    collapsed: {
      type: Boolean,
      default: false,
    },
    // 是否开启我的菜单
    isOpen: {
      type: Boolean,
      default: false,
    },

    //层级，用来开启我的菜单时防止递归将二级菜单也给icon
    itemLevel: {
      type: Number,
      default: 0,
    }

  },

  components: {
    AppMenuItem, //引入组件
  },
  
};

export default SubMenu;