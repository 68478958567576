

















































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import BaseHomeChartsContentOrg from "./base-home-charts-content-org.vue";

@Component({
  components: {
    BaseHomeChartsContentOrg,
  },
})
export default class BaseHomeChartsContent extends Vue {
  mounted() {
    this.initChart();
  }

  /**
   * 月份选项
   * 仅提供1-当前月 月份
   */
  get monthOptions() {
    let curMonth = new Date().getMonth() + 1;
    let options = [];
    for (let i = 1; i <= curMonth; i++) {
      options.push({
        label: `${i}${this.$t("baseHome.month")}`,
        value: i,
      });
    }
    return options;
  }

  /**
   * 类型选项
   */
  typeOption = [
    {
      label: this.$t("baseHome.state"),
      value: "STATE",
    },
    {
      label: this.$t("baseHome.region"),
      value: "REGION",
    },
    {
      label: this.$t("baseHome.company"),
      value: "COMPANY",
    },
    {
      label: this.$t("baseHome.thousand"),
      value: "THOUSAND",
    },
  ];

  /**
   * 初始化 图表
   */

  declareChart: any;
  taxesChart: any;
  completeChart: any;

  initChart() {
    this.$nextTick(() => {
      this.initDeclareChart();
      this.initTaxesChart();
      this.initCompleteChart();
      window.addEventListener("resize", () => {
        this.resizeChart();
      });
    });
  }

  /**
   * 重绘图标
   */
  resizeChart() {
    this.declareChart && this.declareChart.resize();
    this.taxesChart && this.taxesChart.resize();
    this.completeChart && this.completeChart.resize();
  }

  /**
   * ------ 申报进度图表
   */

  declareCompany: string | null = null;
  declareDate: null | number = null;

  initDeclareChart() {
    const dom: any = this.$refs.declare;
    this.declareChart = window.echarts.init(dom);
    this.getDeclareData();
  }

  async getDeclareData() {
    console.log("params:", {
      company: this.declareCompany,
      period: this.declareDate
        ? `${new Date().getFullYear()}-${
            this.declareDate < 10 ? "0" + this.declareDate : this.declareDate
          }`
        : undefined,
    });
    let progress = { done: 16, total: 30 };
    this.declareChart.setOption(
      this.generateDeclareOption(progress.done, progress.total)
    );
  }

  generateDeclareOption(done: any, total: any) {
    done = parseFloat(done);
    total = parseFloat(total);
    const undone = total - done;
    const option = {
      tooltip: {},
      color: ["#409eff", "#eee"],
      // title: {
      //     text: '66%\n(4/6)',
      //     left: 'center',
      //     top: '50%',
      //     textStyle: {
      //         color: '#555',
      //         fontSize: 18,
      //         align: 'center',
      //     },
      // },
      graphic: {
        type: "text",
        left: "center",
        top: "40%",
        style: {
          text: `${this.$t("baseHome.reportProgress")}\n${
            total === 0 ? 0 : parseFloat((done / total).toFixed(2))
          }%\n(${done}/${undone})`,
          textAlign: "center",
          fill: "#555",
          fontSize: 15,
        },
      },
      series: [
        {
          name: this.$t("baseHome.reportProgress"),
          type: "pie",
          radius: ["65%", "80%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#eef",
            borderWidth: 1,
          },
          label: {
            show: false,
            position: "center",
            label: "test",
          },
          // emphasis: {
          //   label: {
          //     show: true,
          //     fontSize: '40',
          //     fontWeight: 'bold'
          //   }
          // },
          labelLine: {
            show: true,
          },
          data: [
            { value: done, name: this.$t("baseHome.finished") },
            { value: undone, name: this.$t("baseHome.unfinished") },
          ],
        },
      ],
    };
    return option;
  }

  /**
   * ------ 实缴税金图表
   */

  taxesType = this.typeOption[0].value;
  taxesDate: null | string[] = null;
  taxesCompany = null;

  @Watch("taxesType")
  taxesTypeWatch(newVal: string, oldVal: string) {
    if (newVal === "COMPANY" || oldVal === "COMPANY") {
      this.$nextTick(() => {
        this.taxesChart && this.taxesChart.resize();
      });
    }
  }

  initTaxesChart() {
    const dom: any = this.$refs.taxes;
    this.taxesChart = window.echarts.init(dom);
    this.getTaxesData();
  }

  async getTaxesData() {
    let params: any = { type: this.taxesType };
    if (this.taxesDate?.length) {
      try {
        params.startTime = this.taxesDate[0];
        params.endTime = this.taxesDate[1];
      } catch (e) {
        console.log(e);
      }
    }
    if (this.taxesType === "COMPANY") {
      params.company = this.taxesCompany;
    }
    console.log("params:", params);
    let payment = [
      {
        name: "中国",
        value: 5259,
      },
      {
        name: "印尼",
        value: 559,
      },
      {
        name: "印度",
        value: 3259,
      },
    ];
    this.taxesChart.setOption(this.generateTaxesOption(payment));
  }

  generateTaxesOption(data: any = []) {
    let option = {
      grid: {
        left: 15,
        top: 15,
        right: 15,
        bottom: 5,
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
      },
      dataset: [
        {
          dimensions: ["name", "value", "sort"],
          source: data.map((item: any) => [
            item.name,
            item.value,
            item.name === "中国" || item.name === "china"
              ? 999999999999
              : item.value,
          ]),
        },
        {
          transform: {
            type: "sort",
            config: {
              dimension: "sort",
              order: "desc",
            },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          rotate: 0,
        },
        axisLine: {
          lineStyle: {
            color: "#acacac",
          },
        },
        axisTick: {
          textStyle: {
            color: "#acacac",
          },
        },
      },
      yAxis: {
        axisLine: {
          show: true,
          lineStyle: {
            color: "#acacac", //坐标轴的颜色
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: true,
          textStyle: {
            color: "#acacac", //坐标的字体颜色
          },
        },
      },
      series: {
        type: "bar",
        encode: {
          x: "name",
          y: "value",
        },
        datasetIndex: 1,
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#d5e8ff", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#3081f2", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
      },
    };
    return option;
  }

  /**
   * ------ 达成率图表
   */

  completeType = this.typeOption[0].value;
  completeDate: null | string[] = null;
  completeCompany = null;

  @Watch("completeType")
  completeTypeWatch(newVal: string, oldVal: string) {
    if (newVal === "COMPANY" || oldVal === "COMPANY") {
      this.$nextTick(() => {
        this.completeChart && this.completeChart.resize();
      });
    }
  }

  initCompleteChart() {
    const dom: any = this.$refs.complete;
    this.completeChart = window.echarts.init(dom);
    this.getCompleteData();
  }

  async getCompleteData() {
    let params: any = { type: this.completeType };
    if (this.completeDate?.length) {
      try {
        params.startTime = this.completeDate[0];
        params.endTime = this.completeDate[1];
      } catch (e) {
        console.log(e);
      }
    }
    if (this.completeType === "COMPANY") {
      params.company = this.completeCompany;
    }
    console.log("params:", params);
    let rate = [
      {
        name: "中国",
        value: 0.0556,
      },
      {
        name: "印尼",
        value: 0.14,
      },
      {
        name: "印度",
        value: 0.03,
      },
    ];
    this.completeChart.setOption(this.generateCompleteOption(rate));
  }

  generateCompleteOption(data: any = []) {
    let names: string[] = [];
    let values: number[] = [];
    data
      .sort(function (a: any, b: any) {
        return a.value - b.value;
      })
      .forEach((item: any) => {
        names.push(item.name);
        values.push(item.value);
      });
    let option = {
      title: {
        // text: '财力贡献达成率',
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
        formatter: function (params: any) {
          var str = "";
          for (let item of params) {
            str += item.name + "<br>";
            str +=
              item.marker +
              " " +
              parseFloat((item.value * 100).toFixed(2)) +
              "%<br>";
          }
          return str;
        },
      },
      grid: {
        left: 15,
        top: 10,
        right: 15,
        bottom: 10,
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        show: false,
      },
      yAxis: {
        type: "category",
        data: names,
        axisLine: { show: false },
        axisTick: { show: false },
      },
      series: [
        {
          // name: '2011',
          type: "bar",
          data: values,
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#3081f2", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#1df5ff", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
            barBorderRadius: [0, 10, 10, 0],
          },
          barWidth: 8,
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
            borderRadius: [0, 10, 10, 0],
          },
        },
      ],
    };
    return option;
  }
}
