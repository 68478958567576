
/**
*  AES key iv
*/
export class AESKeyIVGenerator {
  private static instance: AESKeyIVGenerator;
  private key: string;
  private iv: string;

  private constructor() {
      this.key = '9745c05d3be2a7ec132a469f25954435'
      this.iv = '22063fc9e24613ef'
  }

  public static getInstance(): AESKeyIVGenerator {
      if (!AESKeyIVGenerator.instance) {
          AESKeyIVGenerator.instance = new AESKeyIVGenerator();
      }
      return AESKeyIVGenerator.instance;
  }

  public getKey(): string {
      return this.key;
  }

  public getIV(): string {
      return this.iv;
  }
}