import axios from "axios";
import { LogUtil } from "./log-Util";
import { Util } from "./util";

interface IActionLog {
  /**
   * 行为日志目标
   *
   * @type {('MENU' | 'BUTTON' | 'LINK' | string)}
   * @memberof IActionLog
   */
  target: 'MENU' | 'BUTTON' | 'LINK' | string;

  /**
   * 行为日志内容
   *
   * @type {IParams}
   * @memberof IActionLog
   */
  log: IParams;

  /**
   * 行为日志描述
   *
   * @type {string}
   * @memberof IActionLog
   */
  description?: string;

  /**
   * 操作时间
   *
   * @type {string}
   * @memberof IActionLog
   */
  time: any;

  /**
   * 当前操作用户
   *
   * @type {string}
   * @memberof IActionLog
   */
  user: string;

  /**
   * 请求类型(TODO 待规范标识)
   *
   * @type {string}
   * @memberof IActionLog
   */
  requesType?: string;

  /**
   * 请求地址 (TODO 待规范标识)
   *
   * @type {string}
   * @memberof IActionLog
   */
  requestUrlUrl?: string;

  /**
   * 请求参数
   *
   * @type {string}
   * @memberof IActionLog
   */
  queryString?: string;
}
interface IParams {
  [key: string]: any;
}

/**
 * 行为日志服务
 *
 * @export
 * @class ActionLogService
 */
export class ActionLogService {
  /**
   * 行为日志服务唯一实例
   *
   * @static
   * @type {ActionLogService}
   * @memberof ActionLogService
   */
  static instance: ActionLogService;

  /**
   * 日志集合
   *
   * @private
   * @type {IActionLog[]}
   * @memberof ActionLogService
   */
  private actionLogs: IActionLog[] = [];

  /**
   * 保存阈值
   *
   * @private
   * @type {number}
   * @memberof ActionLogService
   */
  private threshold: number = 10;

  /**
   * 当前操作用户名称
   *
   * @private
   * @type {string}
   * @memberof ActionLogService
   */
  private user: string = '';

  /**
  * 当前系统名称
  *
  * @private
  * @type {string}
  * @memberof ActionLogService
  */
  private System: string = '';

  /**
  * 当前菜单路径
  *
  * @private
  * @type {string}
  * @memberof ActionLogService
  */
  private menuPath: string = '';


  /**
   * 获取行为日志服务
   *
   * @static
   * @return {*}  {ActionLogService}
   * @memberof ActionLogService
   */
  static getInstance(): ActionLogService {
    if (!this.instance) {
      this.instance = new ActionLogService();
    }
    return this.instance;
  }

  /**
   * Creates an instance of ActionLogService.
   * 私有化行为日志服务，只能通过getInstance获取单例
   * 
   * @memberof ActionLogService
   */
  private constructor() {
    this.initEnvironment();
    this.initLocal();
  }

  /**
   * 初始化环境配置
   *
   * @private
   * @memberof ActionLogService
   */
  private initEnvironment() {
    const user = localStorage.getItem('ibzuaa-user') || ''
    const username = JSON.parse(user).personname
    if (username) {
      this.user = username
    }
    const environment = (window as any).Environment
    if (environment) {
      this.threshold = environment.logSizeThreshold || 10
    }
  }

  /**
   * 初始化本地操作日志（上次未保存）
   *
   * @private
   * @memberof ActionLogService
   */
  private initLocal() {
    if (localStorage.getItem('actionLogs')) {
      this.actionLogs = JSON.parse(localStorage.getItem('actionLogs') as string);
    }
  }

  /**
   * 添加操作行为日志
   *
   * @param {({ target: 'MENU' | 'BUTTON' | 'LINK' | string, log: IParams, description?: string })} { target, log, description }
   * @memberof ActionLogService
   */
  addActionLog({ target, log, description, requestType, requestUrl, queryString }: { target: 'MENU' | 'BUTTON' | 'LINK' | string, log: IParams, description?: string, requestType?: string, requestUrl?: string, queryString?: string }) {
    if (this.threshold <= 0) {
      return;
    }
    const time = new Date().getTime();
    const actionLog = { target, log, description, time, user: this.user, requestUrlUrl: requestUrl, requesType: requestType, queryString }
    console.log(actionLog)
    this.actionLogs.push(actionLog);
    //  本地存储
    if (localStorage.getItem('actionLogs')) {
      let tempActionLogs: any[] = [];
      try {
        tempActionLogs = [...JSON.parse(localStorage.getItem('actionLogs') as string)]
      } catch (error) {
        tempActionLogs = []
      }
      tempActionLogs.push(actionLog);
      localStorage.setItem('actionLogs', JSON.stringify(tempActionLogs));
    } else {
      localStorage.setItem('actionLogs', JSON.stringify([actionLog]));
    }
    if (this.actionLogs.length % this.threshold === 0) {
      localStorage.removeItem('actionLogs');
      this.saveActionLog();
    }
  }

  /**
   * 菜单行为
   *
   * @param {{ menu: IParams, description?: string }} { menu, description }
   * @memberof ActionLogService
   */
  menuAction({ menu, description }: { menu: IParams, description?: string }) {
    const log: any = { caption: menu.title, name: menu.key };
    const requestUrl = this.getRequestUrl();
    const requestType = this.handRequestType('MENU',menu.menuUrl)
    this.addActionLog({ target: 'MENU', log, description, requestUrl,requestType });
    // 提供给子应用审计日志使用
    localStorage.setItem('mainMenuUrl',JSON.stringify(menu.menuUrl))
  }

  /**
   * 按钮行为
   *
   * @param {IParams} button 按钮配置
   * @param {*} actionContext 按钮触发源（视图）
   * @memberof ActionLogService
   */
  buttonAction(button: IParams, actionContext: any) {
    //  查询参数
    let queryString: string = '';
    try {
      let xData: any = null;
      if (actionContext && actionContext.appDeCodeName && actionContext.getDatas instanceof Function) {
        xData = actionContext;
      } else if (actionContext && actionContext.viewInstance && actionContext.$refs) {
        xData = actionContext.$refs[actionContext.viewInstance.xDataControlName]?.ctrl;
      }
      if (xData && xData.appDeCodeName && xData.getDatas instanceof Function) {
        const data = xData.getDatas()?.[0];
        queryString = data ? `${xData.appDeCodeName.toLowerCase()}=${data[xData.appDeCodeName.toLowerCase()] || data.srfkey}` : '';
      }
    } catch (error) {
      LogUtil.warn('获取按钮行为查询参数错误');
    }
    const requestUrl = this.getRequestUrl();
    const requestType = this.handRequestType('BUTTON',button.caption)
    this.addActionLog({ target: 'BUTTON', log: button, requestUrl, requestType, queryString });
  }

  /**
   * 按钮行为2
   *
   * @param {IParams} button
   * @param {IParams} [data={}]
   * @memberof ActionLogService
   */
  buttonAction2(button: IParams, data: IParams = {}) {
    let queryString: string = '';
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key: string, index: number) => {
        if (Util.isExistAndNotEmpty(data[key])) {
          queryString += `${key}=${data[key]}${index !== Object.keys(data).length - 1 ? ';' : ''}`;
        }
      });
    }
    const requestUrl = this.getRequestUrl();
    const requestType = this.handRequestType('BUTTON',button.caption)
    this.addActionLog({ target: 'BUTTON', log: button, requestUrl, requestType, queryString });
  }

  /**
   * 链接行为
   *
   * @param {{ log: IParams, description?: string }} { log, description }
   * @memberof ActionLogService
   */
  linkAction(type: string, data: IParams = {}) {
    const requestUrl = this.getRequestUrl();
    let queryString: string = '';
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key: string, index: number) => {
        queryString += `${key}=${data[key]}${index !== Object.keys(data).length - 1 ? ';' : ''}`;
      });
    }
    const requestType = this.handRequestType('LINK',type)
    this.addActionLog({ target: 'LINK', log: data, requestUrl, requestType, queryString });
  }

  /**
   * 处理日志请求类型
   * @param {'MENU' | 'BUTTON' | 'LINK' | string} type 
   * @param { string } requestType 
   * @memberof ActionLogService
   * @return { string } 请求类型规则:模块名 -> 菜单 -> 视图名 -> 按钮名
   */
  handRequestType(type:'MENU' | 'BUTTON' | 'LINK' | string,requestType:any):string{
    let _type:string = ``
    if(type === 'MENU'){
      return requestType
    }else{
      // TODO 基座暂无BUTTON及LINK
      return ''
    }

  }

  /**
   * 获取请求地址
   *
   * @private
   * @return {*}  {string}
   * @memberof ActionLogService
   */
  private getRequestUrl(): string {
    if (window.location && window.location.href) {
      return window.location.href;
    }
    if (document.location) {
      return document.location.toString();
    }
    return '';
  }

  /**
   * 保存操作日志
   *
   * @private
   * @memberof ActionLogService
   */
  private saveActionLog() {
    const newActionLogs = this.actionLogs.slice(this.actionLogs.length - this.threshold);
    console.log(newActionLogs)
    if (newActionLogs.length === 0) {
      return;
    }
    axios({
      method: 'POST',
      url: '/uaa/log',
      data: newActionLogs
    }).then((response: any) => {
      if (response && response.status === 200) {
        LogUtil.log('保存审计日志成功');
        return;
      }
      LogUtil.warn('保存审计日志失败');
    }).catch((error: any) => {
      LogUtil.warn('保存审计日志失败');
    });
  }
}