






































































































import { Component, Vue } from "vue-property-decorator";
import { ApiService } from "@/api";
import {Util} from '@/api/util'
import { AppConfig } from "@/config/config";
// eslint-disable-next-line no-unused-vars
import { MicroAppConfig } from "@/interface";
import {AESKeyIVGenerator} from '@/utils/encryption'



@Component({})
export default class AppVersion extends Vue {

  /**
  * 当前激活面板集合 
  */
  public activeNames:any = []

  /**
  * 是否手风琴模式 
  */
  public accordion:boolean = true

  /**
  *  打包时间 
  */
  public buildTime:any = ''

  /**
  * 基座版本 
  */
  public portalVersion:any = ''

  /**
  *  基座服务版本 
  */
  public portalServeVersion:any = {}

  /**
   *  微应用版本集合
   */
  public appVersion:any = []

  /**
  * 德勤基座分支
  */
  public dttBranch:any = 'master'

  /**
  *  VUE声明周期 
  */
  mounted(){
    this.initAppVersion()
  }

  /**
  *  初始化appverision 
  */
  public initAppVersion(){
    AppConfig.apps.map(item => {
      this.appVersion.push({
        name: this.handleMicroAppConfigName(item.name),
        mircoAppName:item.name
      })
    })
  }

  /**
  * 折叠面板展开事件
  * @param val 
  */
  public handleChange(val:any){
    if(this.accordion){
      this.getVersion(val)
    }else{
      val.map((item:any) => {
        this.getVersion(item)
      })
    }
  }

  /**
  * 获取版本 
  */
  public async getVersion(name:string){
    if(name === 'portal'){
      this.getPortalVersion()
    }else{
      const microName = this.appVersion.find((item:any) => {
        return item.name === name
      }).mircoAppName
      const microApp:MicroAppConfig | undefined = AppConfig.apps.find((item:MicroAppConfig) => {
        return item.name === microName
      })
      if(microApp){
        this.getMicroAppVersion(microApp)
      }
    }
  }

  /**
  * 获取基座信息 
  */
  public getPortalVersion(){
    ApiService.getInstance().getServeCore('/base/').then((res:any) => {
        this.portalServeVersion = res.data
      })
    ApiService.getInstance().getVersion().then((res:any) => {
      if(res && res.data){
        this.buildTime = res.data.build_time
      }  
    })
    ApiService.getInstance().getAppVersion().then((res:any) => {
      if(res && res.data){
        let _data:any = {}
        if(res.data.encrypt){
          const generator = AESKeyIVGenerator.getInstance()
          const dttApikey = {
              key:generator.getKey(),
              iv:generator.getIV()
          }
          const data = Util.decryptAES256(res.data.version, dttApikey.key, dttApikey.iv)
          _data = JSON.parse(data)
        }else{
          _data = res.data
        }
        this.portalVersion = _data.version.portalVersion
      }  
    })
  }

  /**
  * 获取微应用版本 
  * @param appName 
  */
  public getMicroAppVersion(microApp:MicroAppConfig){
    this.getServeCore(microApp.name)
    ApiService.getInstance().getVersion(microApp.entry).then((res:any) => {
        if(res && res.data instanceof Object){
          const index  = this.appVersion.findIndex((item:any) => {
            return item.mircoAppName === microApp.name
          })
          this.$set(this.appVersion[index],'buildTime', res.data.build_time)
        }
    })
    ApiService.getInstance().getAppVersion(microApp.entry).then((res:any) => {
      if(res && res.data instanceof Object){
        let _data:any = {}
        if(res.data.encrypt){
          const generator = AESKeyIVGenerator.getInstance()
          const dttApikey = {
              key:generator.getKey(),
              iv:generator.getIV()
          }
          const data = Util.decryptAES256(res.data.version, dttApikey.key, dttApikey.iv)
          _data = JSON.parse(data)
        }else{
          _data = res.data
        }
        const appVersion = _data
        const index = this.appVersion.findIndex((item:any) => {
          return item.mircoAppName === appVersion.appName
        })
        this.$set(this.appVersion[index],'portalVersion', appVersion.portalVersion)
        this.$set(this.appVersion[index],'dttModel', appVersion.dttModel)
      }
    })
  }

  /**
  * 获取服务端版本信息 
  * @param appName 
  */
  public async getServeCore(appName:any){
    const _name = this.handleMicroAppConfigName(appName)
    await ApiService.getInstance().getServeCore(`/${_name}/`).then((res:any) => {
        const serverVersion = res.data
          const index = this.appVersion.findIndex((item:any) => {
            return item.name === serverVersion?.serviceCategory.toLowerCase()
          })
        this.$set(this.appVersion[index],'coreVersion', serverVersion.coreVersion)
        this.$set(this.appVersion[index],'ibizVersion', serverVersion.ibizVersion)
    })
  }

  /**
  * 微应用和nginx二级目录名称适配器
  * @param config 
  */
  public handleMicroAppConfigName(name:string){
    // 适配微应用和nginx二级目录名称不一致的情况
    let adapters = [
      {
        adapter:'randd',
        data:'base'
      },
      {
        adapter:'neweit',
        data:'eit'
      },
      {
        adapter:'vat',
        data:'vatfp'
      }
    ]
    let data:any = ''
    const find = adapters.find(item => {
      return item.adapter === name
    })
    if(find){
      data = find.data
    }else{
      data = name
    }
    return data
  }
}

