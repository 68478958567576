































import { Component, Vue } from "vue-property-decorator";
import CryptoJS from 'crypto-js';
import { ApiService } from "@/api";
import { MicroActionService } from "@/service/micro-action-service";
import { AppService } from "@/service/app-service";
import { Message } from "element-ui";

@Component({})
export default class AppUpdatePassword extends Vue {
    /**
  *  登录按钮loading 
  */
  buttonLoading:any = false

 /**
     * 原密码
     * 
     * @public
     * @memberof AppUpdatePassword
     */
    public oldPwd: string = "";

    /**
     * 新密码
     * 
     * @public
     * @memberof AppUpdatePassword
     */
    public newPwd: string = "";

    /**
     * 确认密码
     * 
     * @public
     * @memberof AppUpdatePassword
     */
    public confirmPwd: string = "";

    /**
     * 是否能禁用确认修改
     * 
     * @public
     * @memberof AppUpdatePassword
     */
    public disUpdate:boolean = true;

    /**
    *  新密码校验错误文本
    */
    public newPwdErrorTest:any = ''

    /**
    *  确认密码校验文本 
    */
    public confirmPwdErrorTest:any = ''

    /**
    *  旧密码校验文本 
    */
    public oldPwdErrorTest:any = ''

    /**
     * 校验输入的原密码是否为空
     * 
     * @public
     * @memberof AppUpdatePassword
     */
    public oldPwdVaild(){
        if(!this.oldPwd){
            this.oldPwdErrorTest = this.$t('components.appupdatepassword.oldpwderr')
        }else{
          this.oldPwdErrorTest = ''
        }
    }
    /**
     * 校验输入的新密码是否为空
     * 
     * @public
     * @memberof AppUpdatePassword
     */
    public newPwdVaild(){
        if(!this.newPwd){
          this.newPwdErrorTest = this.$t('components.appupdatepassword.newpwderr')
          this.confirmPwd = ''
          this.confirmPwdErrorTest = ''
          return
        }
        if(this.newPwd === this.oldPwd){
          this.newPwdErrorTest = this.$t('components.appupdatepassword.newpwderr3')
          return
        }
        // eslint-disable-next-line no-useless-escape
        let reg = /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/
        if(!reg.test(this.newPwd)){
          this.newPwdErrorTest = this.$t('components.appupdatepassword.newpwderr2')
          return
        }
        this.confirmVaild()
        this.newPwdErrorTest = ''
    }

    /**
     * 校验确认密码与新密码是否一致
     * 
     * @public
     * @memberof AppUpdatePassword
     */
    public confirmVaild() {
        if (this.newPwd && this.confirmPwd) {
            if (this.confirmPwd !== this.newPwd) {
              this.confirmPwdErrorTest = this.$t('components.appupdatepassword.confirmewderr')
              this.disUpdate=true
            }else{
                this.confirmPwdErrorTest = ''
            }
            if(!this.confirmPwdErrorTest && !this.newPwdErrorTest){
              this.disUpdate=false;
            }
        }
    }
    private map = new Map()

    /**
    *  防抖函数 
    */
    public debounce(fun: any, params: any, context: any, wait: number = 300) {
      if (typeof fun === 'function') {
          if (this.map.has(fun)) {
              const num = this.map.get(fun);
              clearTimeout(num);
          }
          this.map.set(
              fun,
              setTimeout(() => {
                  this.map.delete(fun);
                  fun.apply(context, params);
              }, wait),
          );
      }
    }
    /**
     * 修改密码
     *
     * @public
     * @memberof AppUpdatePassword
     */
    public updatePwd(){
      this.buttonLoading = true
      const user = localStorage.getItem('ibzuaa-user') || ''
      const loginname = JSON.parse(user)?.loginname || ''
      const oldPwd_md5 = CryptoJS.MD5(loginname.toLowerCase() + '||' + this.oldPwd).toString()
      const newPwd_md5 = CryptoJS.MD5(loginname.toLowerCase() + '||' + this.newPwd).toString()
      ApiService.getInstance().changPassword(null,{oldPwd:oldPwd_md5,newPwd:newPwd_md5}).then((response:any) =>{
            if (response && response.status === 200) {
                this.$emit("close");
                Message({
                  message:this.$t('components.appupdatepassword.success') as string,
                  type:'success'
                });
                this.buttonLoading = false
                this.logout(false)
            }
        }).catch((error: any) =>{
          this.buttonLoading = false;
            Message({
              message:error.data.message,
              type:'error'
            });
        })
    }

  /**
   * 退出登录
   *
   * @memberof AppUser
   */
   public async logout(isMessage:boolean = true) {
    try {
        const res:any = await ApiService.getInstance().Logout();
        if (res ) {
          AppService.getInstance().noticeAllMicroApp('CLEAR_CODE_LIST');
          if (localStorage.getItem('loginToUser')) {
            console.log('我走login退出')
            localStorage.removeItem('loginToUser');
            MicroActionService.getInstance().handleLogout();
          } else {
            console.log('我走SSO退出')
            localStorage.removeItem('loginToUser');
            ApiService.getInstance().ssoLogout().then((data:any) => {
              MicroActionService.getInstance().handleLogoutSSO();
              window.location.href = data.data.redirectUrl
            })
          }
          (document as any).querySelector(':root').removeAttribute('style')
        }
    } catch (error) {
          MicroActionService.getInstance().handleLogout(isMessage);
          AppService.getInstance().noticeAllMicroApp('CLEAR_CODE_LIST');
    }
  }

  /**
  * 清空 
  */
  public clear(){
    this.oldPwd = ''
    this.newPwd = ''
    this.confirmPwd = ''
    this.oldPwdErrorTest = ''
    this.newPwdErrorTest = ''
    this.confirmPwdErrorTest = ''
  }

}
