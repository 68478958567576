export interface IwhiteList{
  /**
  * 接口地址 
  */
  url:string,
  /**
  * 请求方法 
  */
  method:'get'|'post'
}

/**
* 可重复提交白名单 
*/
export const whiteList:Array<IwhiteList> = [
  {
    url:'/tda/tdagriddefs/fetchquerygriddata',
    method:'post'
  }
]