



















import { Component, Prop, Vue } from "vue-property-decorator";
import { AppConfig } from "@/config/config";


@Component
export default class MenuItem extends Vue {

  @Prop({ type: Object, default: {} })item!: any;

  /**
   * 是否收缩菜单
   */
  @Prop({default:false}) collapsed!:boolean;

  /**
   * 子应用配置文件
   */
  public AppConfig = AppConfig

  /**
  * 层级
  */
  @Prop({default:false}) itemLevel!:number
  
  /**
   * 获取图标路径
   * @param icon 
   */
  public getIcon(icon:any){
    if (icon === '--') {
      icon = ''
    }
    let img = require(`/public/img/micro-main/${icon}.png`)
    return img
  }

  /**
   * 开启我的菜单时,字体图标样式
   */
  get getFontIconStyle(){
    return this.collapsed ? 'line-height:40px' : ''
  }

}
