import CryptoJS  from 'crypto-js';
/**
 * 平台工具类
 *
 * @export
 * @class Util
 */
export class Util {

    /**
    * 判断对象是否为空，避免发生数值0误判
    *
    * @param obj
    */
    public static isExist(obj: any): boolean {
        return obj !== undefined && obj !== null;
    }

    /**
    * 字符串不为空并且对象存在
    *
    * @param str
    */
    public static isExistAndNotEmpty(str: string | undefined | null): boolean {
        return this.isExist(str) && !this.isEmpty(str);
    }

    /**
    * 是否为空
    *
    * @static
    * @param {*} value
    * @returns {boolean}
    * @memberof Util
    */
    public static isEmpty(value: any): boolean {
        return this.isUndefined(value) || Object.is(value, '') || value === null || value !== value;
    }

    /**
    * 是否未定义
    *
    * @static
    * @param {*} value
    * @returns {boolean}
    * @memberof Util
    */
    public static isUndefined(value: any): boolean {
        return typeof value === 'undefined';
    }
    /**
     * AES解密
     * @param ciphertext 
     * @param key 
     * @param iv 
     * @returns 
     */
    public static decryptAES256(ciphertext: string, key: string, iv: string): string {
        const decrypted = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }   

    /**
    * AES加密
    * @param data 
    * @param key 
    * @param iv 
    * @returns 
    */
    public static encryptAES256(data: string, key: string, iv: string): string {
        const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    /**
    * 字符串长度拼接函数
    * @param input 
    * @param length 
    * @returns 
    */
    public static concatenateString(input: string,length:number = 16): string {
        let result = '';
        while (result.length < length) {
            result += input;
        }
        return result.slice(0, 16);
    }

    /**
     * 获取安全随机数 代替Math.random
     * @param min 最小值
     * @param max 最大值
     * @returns min 和 max之间创建一个随机数
     */
    public static getRandomIntInclusive(min:number = 0, max:number = 10000){
        const randomBuffer = new Uint32Array(1);
        window.crypto.getRandomValues(randomBuffer);
        let randomNumber = randomBuffer[0] / (0xffffffff + 1);
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(randomNumber * (max - min + 1)) + min;
    }

    /**
    * 创建 UUID
    *
    * @static
    * @returns {string}
    * @memberof Util
    */
    public static createUUID(): string {
        function s4() {
            return Util.getRandomIntInclusive()
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

}
