import { AppConfig } from "@/config/config";
import AppState from "./app-state";

/**
 * 计算部署二级路由
 * @param path 
 * @returns 
 */
const computeMicroAppFolder = (path: string) => {
  // nginx环境
  if (path.startsWith('/') && path.endsWith('/')) {
    return path.substring(1, path.length - 1);
  } else {
    return path;
  }
}

/**
 * 计算所有应用
 * @param path 
 * @returns 
 */
const computeApps = () => {
  const confApps = AppConfig.apps ? AppConfig.apps : [];
  if (confApps && confApps.length > 0) {
    confApps.forEach((app: any) => {
      Object.assign(app, {
        props: {
          AppState: AppState,
          activeRule: app.activeRule,
          microAppFolder: computeMicroAppFolder(app.entry)
        }
      })
    })
  }
  return confApps;
}

const apps = computeApps();
export default apps;
