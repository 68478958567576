<template>
  <div style="height: 100%;width: 100%;" :class="showFig == 1 ? 'wheatClass' : 'blackCalss'">
    <el-row style="height: 65%; width: 100%;">
      <el-col :span="8" style="height: 100%;">
        <div class="leftOne">
          <div style="height: 20px;width: 100%;">
            <span class="leftOne1">{{ $t('components.tisHome.RiskScanResults') }}</span>
            <el-dropdown class="textRight" @command="handleCommand">
              <span class="el-dropdown-link leftOne1">
                {{ this.commandShow }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">{{ $t('components.tisHome.LastMonth') }}</el-dropdown-item>
                <el-dropdown-item command="b">{{ $t('components.tisHome.ThisQuarter') }}</el-dropdown-item>
                <el-dropdown-item command="c">{{ $t('components.tisHome.ThisYear') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div style="height: calc(100% - 20px);width: 100%;display: flex;">
            <div class="leftOne-imgDiv">
              <img src="../../assets/img/leftOne3-img.png" class="leftOne-img">
              <div class="leftOne-imgSpanDiv">
                <span class="leftOne-imgSpanTop">{{ this.lowRisk }}</span>
                <span class="leftOne-imgSpanBottom">{{ $t('components.tisHome.LowRisk') }}</span>
              </div>
            </div>
            <div class="leftOne-imgDiv">
              <img src="../../assets/img/leftOne4-img.png" class="leftOne-img">
              <div class="leftOne-imgSpanDiv">
                <span class="leftOne-imgSpanTop">{{ this.highRisk }}</span>
                <span class="leftOne-imgSpanBottom">{{ $t('components.tisHome.HighRisk') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="leftTwo">
          <div class="leftTwo1">
            <span class="leftTwo1-span1">{{ $t('components.tisHome.Announcement') }}</span>
            <span class="leftTwo1-span2" @click="jumpDetails('公告')">{{ $t('components.tisHome.ViewAll') }} ></span>
          </div>
          <div class="leftTwo2">
            <el-row class="leftTwo2-div" v-for="(item, key) in noticeList" :key="key">
              <el-col :span="3" style="font-weight: 700;">{{ item.time }}</el-col>
              <el-col :span="21" class="leftTwo2-divCol21">{{ item.context }}</el-col>
            </el-row>
          </div>
        </div>
      </el-col>
      <el-col :span="8" style="height: 100%;">
        <div class="middelOne">
          <div style="height: 20px;width: 100%;margin-bottom: 10px;">
            <span class="middelOne1">{{ $t('components.tisHome.DataUploaded') }}</span>
            <el-dropdown class="textRight" @command="handleCommand2">
              <span class="el-dropdown-link middelOne1">
                {{ this.commandShow2 }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">{{ $t('components.tisHome.LastMonth') }}</el-dropdown-item>
                <el-dropdown-item command="b">{{ $t('components.tisHome.ThisQuarter') }}</el-dropdown-item>
                <el-dropdown-item command="c">{{ $t('components.tisHome.ThisYear') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="middelOne-echartsDiv">
            <div class="middelOne-echarts" v-for="(item, key) in 4" :key="key">
              <echartsShow :id="key" :titleNow="titleList[key]" :showColor="showFig == 1 ? false : true"></echartsShow>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8" style="height: 100%;">
        <div class="rightOne">
          <span class="rightOne-span1">{{ $t('components.tisHome.IndividualReport') }}</span>
          <span class="rightOne-span2" @click="individualReport('单体')">{{ $t('components.tisHome.ClickToView') }}</span>
          <img class="rightOne-img" src="../../assets/img/rightOne-img.png">
        </div>
        <div class="rightTwo">
          <span class="rightTwo-span1">{{ $t('components.tisHome.GroupReport') }}</span>
          <span class="rightTwo-span2" @click="individualReport('集团')">{{ $t('components.tisHome.ClickToView') }}</span>
          <img class="rightTwo-img" src="../../assets/img/rightTwo-img.png">
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 35%; width: 100%;">
      <el-col :span="8" style="height: 100%;">
        <div class="bottomOne">
          <div class="bottomOne1">
            <span class="bottomOne1-span1">{{ $t('components.tisHome.ConsultationAnswers') }}</span>
            <span class="bottomOne1-span2" @click="jumpDetails('咨询与解答')">{{ $t('components.tisHome.ViewAll') }} ></span>
          </div>
          <div class="bottomOne2">
            <div class="bottomOne2-buttonDiv">
              <el-button @click="noticeShow = 1" :class="buttonClass(1)" plain>{{ $t('components.tisHome.FAQ') }}</el-button>
              <el-button @click="noticeShow = 2" :class="buttonClass(2)" plain>{{ $t('components.tisHome.PolicyInterpretation') }}</el-button>
              <el-button @click="noticeShow = 3" :class="buttonClass(3)" plain>{{ $t('components.tisHome.TaxConsultation') }}</el-button>
            </div>
            <div class="bottomOne2-div">
              <el-row class="bottomOne2-row" v-for="(item, key) in problemList" :key="key" style="overflow-y: auto;">
                <span class="bottomOne2-divCol21">{{ item.context }}</span>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="16" style="height: 100%;">
        <div class="bottomTwo">
          <div class="bottomTwo1">
            <span class="bottomTwo1-span1">{{ $t('components.tisHome.AdvisorOnline') }}</span>
            <span class="bottomTwo1-span2" @click="jumpDetails('顾问在线')">{{ $t('components.tisHome.Homepage') }} ></span>
          </div>
          <div class="bottomTwo2">
            <div v-for="(item, key) in chatList" :key="key">
              <div v-for="(res, index) in item.responsesummary" :key="index"  class="bottomTwo2-List">
                <div v-if="res.showNow === 0" class="bottomTwo2-div">
                  <div class="bottomTwo2-nameTime0">
                    <span style="font-weight: 700;">{{ res.name }}</span>
                    <span style="font-weight: 400;"> {{ res.time }}</span>
                  </div>
                  <el-row class="bottomTwo2-context0">
                    <el-col :span="4" style="display: flex;align-items: center; ">
                      <span class="bottomTwo2-userImgSpan"></span>
                      <img style="height: 45px;width: 45px;" src="../../assets/img/userImg.png">
                    </el-col>
                    <el-col :span="20">
                      <span style="margin-left: 20px;">{{ res.msg }}</span>
                    </el-col>
                  </el-row>
                </div>
                <div v-else class="bottomTwo2-div2">
                  <div :class="showFig == 1 ? 'bottomTwo2-nameTime1 colorWhite2' : 'bottomTwo2-nameTime1'">{{ res.name +
                    res.time }}</div>
                  <div :class="showFig == 1 ? 'bottomTwo2-context1 colorWhite' : 'bottomTwo2-context1'">
                    <p>{{ res.orgname }}  {{ res.riskscenename }}  {{ res.period }}</p>
                    <p>{{ res.msg }}</p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="floatBoxSmall" @click="toDrawer()">
      <i class="el-icon-setting"></i>
    </div>
    <el-drawer :title="$t('components.tisHome.Homepage')" :visible.sync="drawer" direction="rtl" :before-close="handleClose">
      <div style="padding: 20px;">
        <span style="color: #000000;"
          v-show="(commonTabs === 'common' || commonTabs === 'mymenu') && loginName !== 'ibzadmin'">{{ $t('components.appuser.myMenus') }}:</span>
        <el-button style="margin-left: 20px;" size="small" @click="commonMore" :disabled="!isSuperUser"
          v-show="(commonTabs === 'common' || commonTabs === 'mymenu') && loginName !== 'ibzadmin'">{{ $t('components.appuser.myMenus') }}</el-button>
        <div>{{ $t('components.tisHome.BackgroundColor') }} :</div>
        <div @click="toShowFig(1)" :class="this.showFig == 0 ? 'background1' : 'background1 clickNow'"></div>
        <div @click="toShowFig(0)" :class="this.showFig == 0 ? 'background2 clickNow' : 'background2'"></div>
      </div>
    </el-drawer>
    <my-common-dialog v-if="commonTabs === 'common'" ref="myCommonDialog" @selected-watch="commonListChange" />
  </div>
</template>
<script>
import echartsShow from "./echartsShow.vue";
import myCommonDialog from "./components/my-common-dialog.vue";
import { ApiService } from "@/api";
import { OpenMenu } from "./mixin/open-menu";
import { eventBus } from "@/service/micro-action-service";

// 创建OpenMenu实例
const openMenu  = new OpenMenu();

export default {
  components: {
    echartsShow,
    myCommonDialog,
  },
  data() {
    return {
      noticeShow: 1,
      commandShow: this.$t('components.tisHome.LastMonth'),
      commandShow2: this.$t('components.tisHome.LastMonth'),
      loginName: '',
      historyList: [],
      myMenuGroup: [],
      allChildrenMenu: [],
      commonTabs: 'mymenu',
      drawer: false,
      isClose: false,
      isSuperUser: false,
      showFig: 0,
      highRisk: 0,
      lowRisk: 0,
      titleList: [
        {
          title: '财报数据',
          color: ['rgba(0, 203, 255, 1)', 'rgba(0, 144, 255, 1)'],
          value: 7,
          valueAll: 10
        }, {
          title: '核算数据',
          color: ['rgba(248, 93, 65, 1)', 'rgba(229, 139, 105, 1)'],
          value: 10,
          valueAll: 20
        }, {
          title: '申报数据',
          color: ['rgba(187, 245, 86, 1)', 'rgba(67, 148, 34, 1)'],
          value: 30,
          valueAll: 30
        }, {
          title: '发票数据',
          color: ['rgba(109, 98, 242, 1)', 'rgba(206, 70, 254, 1)'],
          value: 12,
          valueAll: 20
        }
      ],
      chatList: [
       
      ],
      // {
      //     context: '请问，增值税简易计税项目进项转出测算值与实际值间存在较大差异的场景如何处理？',
      //     name: 'DTT ADMIN',
      //     time: '2023-01-01',
      //     showNow: 1
      //   }, {
      //     context: '根据您提出的问题，解答如下：开营业税改征增值税试点的通知》（财税【2016】36号）附件一《营业税改征增值税试点实施办法》请点击查看。',
      //     name: '德勤资深税务顾问',
      //     time: '2023-01-01',
      //     showNow: 0
      //   }
      noticeList: [
        {
          context: '营业收入与增值税销售额差异风险指标调整',
          time: '6-20'
        }, {
          context: '企业所得税汇算清缴申报表上传模板更新',
          time: '6-19'
        }, {
          context: '功能上新：税务数据分析报表自助配置',
          time: '6-18'
        }, {
          context: '系统更新维护通知',
          time: '6-17'
        }, {
          context: '税务数据服务平台上线了',
          time: '6-16'
        }
      ],
      problemList: [
        {
          context: '如何使用德勤顾问在线功能'
        }, {
          context: '税局下载的申报表加密该如何上传'
        }, {
          context: '金四销项发票上传失败常见原因'
        }
      ]
    }
  },
  watch: {
    showFig: {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        if (newVal == 1) {
          this.wheatContext()
        } else {
          this.blackContext()
        }
      }
    }
  },
  created() {
  },
  mounted() {
    this.getData('tisRiskLevel');
    this.getData('tisAdviseOnline');
    const loadContainer = document.getElementById(
      "child-container-loading"
    );
    if (loadContainer) {
      loadContainer.style.cssText = "display: none;";
    }
    if (localStorage.getItem("colorShow") == 1) {
      this.wheatContext()
      this.showFig = 1
    } else {
      this.$nextTick(()=>{
        this.blackContext()
      })
    }
    // tis租户监听当前路由地址是不是首页
    eventBus.$on('tabPaneChange', (flag) => {
      // flag:是首页，并且是tis租户
      // this.showFig != 1，当前选择不是表示白色背景
      if (flag && this.showFig !== 1) {
        this.blackContext()
      } else {
        this.wheatContext()
      }
    });
    eventBus.$on('isSuperUser', (flag) => {
      if (flag) {
          this.isSuperUser = true
      } else {
        this.isSuperUser = false
      }
    });
  },

  methods: {
    // 初始化数据
    getData(type, date) {
      let param = {}
      if (type === 'tisRiskLevel') {
        param = {
          changeTime: date,
          methodName: type,
        }
      }else if (type === 'tisAdviseOnline') {
        param = {
          methodName: type
        }
      }
      ApiService.getInstance().getTisHomePage(param).then((res) => {
        if (type === 'tisRiskLevel') {
          this.highRisk = res.data[0].high
          this.lowRisk = res.data[0].low
        } else if (type === 'tisAdviseOnline') {
          res.data.map((menu) => {
            let list = {
              responsesummary : []
            }
            if (menu.responsesummary) {
              list.responsesummary = JSON.parse(menu.responsesummary)
              list.responsesummary.map((res,index) => {
                if (index === 0) {
                  res.showNow = 1
                  res.orgname = menu.orgname
                  res.period = menu.period
                  res.riskscenename = menu.riskscenename
                } else {
                  res.showNow = 0
                }
              })
              this.chatList.push(list)
            }
          })

        }
      }) 
    },
    buttonClass(x) {
      if (this.noticeShow == x) {
        if (this.showFig == 1) {
          return 'bottomOne2-button1 buttonClick1'
        } else {
          return 'bottomOne2-button buttonClick'
        }
      } else {
        if (this.showFig == 1) {
          return 'bottomOne2-button1'
        } else {
          return 'bottomOne2-button'
        }
      }
    },
    blackContext() {
      var updBackground = document.querySelectorAll('.cns-frame-shell')
      var updBackground2 = document.querySelectorAll('.app-page-exp')
      var updBackground3 = document.querySelectorAll('.cns-tabs-card')
      var updBackground4 = document.querySelectorAll('.cns-page-exp')
      var updBackground5 = document.querySelectorAll('.cns-tabs-tab')
      var updBackground6 = document.querySelectorAll('.more-btn')
      updBackground[0]?.style.setProperty('background-color', '#000000', 'important');
      updBackground2[0]?.style.setProperty('background-color', '#000000', 'important');
      updBackground3[0]?.style.setProperty('background-color', '#000000', 'important');
      updBackground3[0]?.style.setProperty('overflow-x', 'hidden', 'important');
      updBackground4[0]?.style.setProperty('background-color', '#000000', 'important');
      updBackground5.forEach(element => {
        element.style.setProperty('border', 'solid 1px #D9D9D9', 'important');
        element.style.setProperty('background-color', '#000000', 'important');
        element.style.setProperty('color', '#ffffff', 'important');
      });
      updBackground6.forEach(element => {
        element.style.setProperty('border', 'solid 1px #D9D9D9', 'important');
        element.style.setProperty('background-color', '#000000', 'important');
        element.style.setProperty('color', '#ffffff', 'important');
      });
      var leftTwo = document.querySelectorAll('.leftTwo')
      leftTwo[0]?.style.setProperty('background', 'rgba(25, 25, 34, 1)', 'important');
      leftTwo[0]?.style.setProperty('color', '#FFFFFF', 'important');
      leftTwo[0]?.style.setProperty('border', '0px', 'important');
      var bottomOne = document.querySelectorAll('.bottomOne')
      bottomOne[0]?.style.setProperty('background', 'rgba(25, 25, 34, 1)', 'important');
      bottomOne[0]?.style.setProperty('color', '#FFFFFF', 'important');
      bottomOne[0]?.style.setProperty('border', '0px', 'important');
      var middelOne = document.querySelectorAll('.middelOne')
      middelOne[0]?.style.setProperty('background', 'rgba(25, 25, 34, 1)', 'important');
      middelOne[0]?.style.setProperty('color', '#FFFFFF', 'important');
      middelOne[0]?.style.setProperty('border', '0px', 'important');
      var bottomTwo = document.querySelectorAll('.bottomTwo')
      bottomTwo[0]?.style.setProperty('background', 'rgba(25, 25, 34, 1)', 'important');
      bottomTwo[0]?.style.setProperty('color', '#FFFFFF', 'important');
      bottomTwo[0]?.style.setProperty('border', '0px', 'important');
    },
    wheatContext() {
      var updBackground = document.querySelectorAll('.cns-frame-shell')
      var updBackground2 = document.querySelectorAll('.app-page-exp')
      var updBackground3 = document.querySelectorAll('.cns-tabs-card')
      var updBackground4 = document.querySelectorAll('.cns-page-exp')
      var updBackground5 = document.querySelectorAll('.cns-tabs-tab')
      var updBackground6 = document.querySelectorAll('.more-btn')
      updBackground[0]?.style.setProperty('background-color', '#f0f2f5', 'important');
      updBackground2[0]?.style.setProperty('background-color', '#f0f2f5', 'important');
      updBackground3[0]?.style.setProperty('background-color', '#f0f2f5', 'important');
      updBackground3[0]?.style.setProperty('overflow-x', 'hidden', 'important');
      updBackground4[0]?.style.setProperty('background-color', '#FFFFFF', 'important');
      updBackground5.forEach(element => {
        element.style.setProperty('border', '1px solid #FFFFFF', 'important');
        element.style.setProperty('background-color', '#FFFFFF', 'important');
        element.style.setProperty('color', '#616161', 'important');
      });
      updBackground6.forEach(element => {
        element.style.setProperty('border', '1px solid #FFFFFF', 'important');
        element.style.setProperty('background-color', '#FFFFFF', 'important');
        element.style.setProperty('color', '#616161', 'important');
      });
      var leftTwo = document.querySelectorAll('.leftTwo')
      leftTwo[0]?.style.setProperty('background', '#FFFFFF', 'important');
      leftTwo[0]?.style.setProperty('color', '#000000', 'important');
      leftTwo[0]?.style.setProperty('box-shadow', '0 1px 1px rgba(0,0,0,.05), 0 2px 6px 0 rgba(0,0,0,.045);');
      var bottomOne = document.querySelectorAll('.bottomOne')
      bottomOne[0]?.style.setProperty('background', '#FFFFFF', 'important');
      bottomOne[0]?.style.setProperty('color', '#000000', 'important');
      bottomOne[0]?.style.setProperty('box-shadow', '0 1px 1px rgba(0,0,0,.05), 0 2px 6px 0 rgba(0,0,0,.045);');
      var middelOne = document.querySelectorAll('.middelOne')
      middelOne[0]?.style.setProperty('background', '#FFFFFF', 'important');
      middelOne[0]?.style.setProperty('color', '#000000', 'important');
      middelOne[0]?.style.setProperty('box-shadow', '0 1px 1px rgba(0,0,0,.05), 0 2px 6px 0 rgba(0,0,0,.045);');
      var bottomTwo = document.querySelectorAll('.bottomTwo')
      bottomTwo[0]?.style.setProperty('background', '#FFFFFF', 'important');
      bottomTwo[0]?.style.setProperty('color', '#000000', 'important');
      bottomTwo[0]?.style.setProperty('box-shadow', '0 1px 1px rgba(0,0,0,.05), 0 2px 6px 0 rgba(0,0,0,.045);');

    },
    handleCommand(con) {
      if (con == 'a') {
        this.commandShow = this.$t('components.tisHome.LastMonth')
        this.getData('tisRiskLevel', 'M')
      } else if (con == 'b') {
        this.commandShow = this.$t('components.tisHome.ThisQuarter')
        this.getData('tisRiskLevel', 'Q')
      } else {
        this.commandShow = this.$t('components.tisHome.ThisYear')
        this.getData('tisRiskLevel', 'Y')
      }
    },
    handleCommand2(con) {
      if (con == 'a') {
        this.commandShow2 = this.$t('components.tisHome.LastMonth')
        this.getData('tisRiskLevel', 'M')
      } else if (con == 'b') {
        this.commandShow2 = this.$t('components.tisHome.ThisQuarter')
        this.getData('tisRiskLevel', 'Q')
      } else {
        this.commandShow2 = this.$t('components.tisHome.ThisYear')
        this.getData('tisRiskLevel', 'Y')
      }
    },

    allChildrenMenuObj() {
      let obj = {};
      this.allChildrenMenu.forEach((item) => {
        if (item.resourceId) obj[item.resourceId] = { ...item };
      });
      return obj;
    },
    getHistory() {
      this.historyList = [];
      ApiService.getInstance()
        .getHistory()
        .then(res => {
          this.historyList = res.data.result;
        });
      this.historyList
        .map((item) => {
          if (item.resourceId in this.allChildrenMenuObj) {
            return openMenu.resChildrenMenuItem(
              this.allChildrenMenuObj[item.resourceId]
            );
          }
        })
        .filter((item) => item);
    },
    getAllmenus() {
      this.allChildrenMenu = [];
      return ApiService.getInstance()
        .getMenus()
        .then((res) => {
          let { status, data } = res;
          if (status == 200 && data) {
            (data || []).forEach((module) => {
              if (module.menuItems?.length) {
                module.menuItems.forEach((first) => {
                  if (first.hidden || !first.children?.length) {
                    return;
                  }
                  first.children.forEach((second) => {
                    second.apptag = module.app;
                    second.path = module.system;
                    if (second.children?.length) {
                      second.children.forEach((third) => {
                        third.apptag = module.app;
                        third.path = module.system;
                        if (third.children?.length) {
                          third.children.forEach((fourth) => {
                            fourth.apptag = module.app;
                            fourth.path = module.system;
                          });
                        }
                      });
                    }
                  });
                  this.allChildrenMenu.push(
                    ...openMenu.formattingGetPSAppMenuItems(first.children)
                  );
                });
              }
            });
          }
        });
    },
    getMyMenuGroup() {
      ApiService.getInstance()
        .getMyMenuGroup()
        .then((res) => {
          this.myMenuGroup = res.data.result.groups;
        });
    },
    commonListChange(value) {
      this.commonList = value;
    },
    commonMore() {
      this.isClose = true;//给弹窗参数，用于弹窗取消之后在不刷新页面的时候重新打开重新获取数据
      if (this.commonTabs === "common") {
        const myCommonDialog = this.$refs.myCommonDialog;
        myCommonDialog.setDialogVisible(true);
      }
      if (this.commonTabs === "mymenu") {
        // 点击tis页面设置中得“我得菜单”，通知app-user打开弹窗
        eventBus.$emit('opemMenus', true);
        this.drawer = false;
      }
    },
    toShowFig(show) {
      localStorage.setItem("colorShow", show);
      this.showFig = show
    },
    toDrawer() {
      this.drawer = true
    },
    handleClose(done) {
      done();
      // this.$confirm('请确定主题已保存再关闭', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   done();
      // }).catch(() => {
      // });
    },

    /**
     * 单体集团跳转
     */
    individualReport(type) {
      const currDate = new Date();
      const year = currDate.getFullYear();
      let period = year + '-01-01'
      const context = localStorage.getItem('microAppData')
      if(context){
        const code = JSON.parse(context).context.isuserorgvirtual
        if(!code){
          if (type === '单体') {
            this.$router.push('tra#/index2/tramonsubdefs/views/rptgridview?srfmenuname%3Dtra-menuitem3%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DTRA_RISK_ANLS');
          } 
        } else {
          if (type === '单体') {
            // 添加管理报告页
            this.$router.push('tra#/index2/tramonsubdefs/views/customviewrpt?reportCode=TAX_RISK_ANALYSIS_REPORT');
          }
        }
        if (type === '集团') {
          const currDate = new Date();
          const year = currDate.getFullYear();
          period = year + '-01-01'
          // 添加管理报告页
          this.$router.push('tra#/index2/tramonsubdefs/views/customviewrpt?reportCode=GROUP_TAX_RISK_OPERATION_REPORT&period=' + period);
        }
      }
    },

    /**
     * 公告，咨询与解答，顾问在线 跳转详情
     */
    jumpDetails(type) {
        if (type === '顾问在线') {
          this.$router.push('tra#/index2/tramoprmoriscres/views/riskresponsetabexpview?srfmenuname%3Dtra-menuitem800%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DTRA_RISK_SCE_RES');
        } else if (type === '咨询与解答') {
          this.$router.push('retom#/index/tomtaxcases/views/searchgridview?srfmenuname%3Dtom-menuitem19%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DTOM_LEGAL_SEARCH')
        } else if (type === '公告') {
          this.$router.push('retom#/index/tomtaxcases/views/searchgridview?srfmenuname%3Dtom-menuitem19%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DTOM_LEGAL_SEARCH')
        }
    }
  },

  beforeDestroy() {
    // 取消订阅事件
    // eventBus.$off('tabPaneChange');
    eventBus.$off('isSuperUser');
  }
}
</script>
<style lang='less' scoped>
@import "tis-home.less";
</style>