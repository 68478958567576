



























import { Component, Vue } from "vue-property-decorator";
import { ApiService } from "../../api";
import { Message } from "element-ui";
import { AppService } from "@/service/app-service";
import store from "@/shared/store";
import shared from "@/shared";
import apps from "@/micro/apps";
import { eventBus } from '@/service/micro-action-service';

@Component({})
export default class AppLang extends Vue {
  public flag = true;
  /**
   * 选中组织部门名称
   *
   * @type {string}
   * @memberof AppOrgSector
   */
  public selectedOrgName: string = "";
  /**
   * 选中组织部门id
   *
   * @type {string}
   * @memberof AppOrgSector
   */
  public selectedOrgId: string = "";  
  /**
   * 组织部门名称数组
   *
   * @type {Array<any>}
   * @memberof AppOrgSector
   */
  public selectedOrgArray: Array<any> = [];

  /**
   * 树配置项
   */
  public treeProps: any = {
    label: (data: any) => {
      return `${data.orgcode}  ${data.orgname}`;
    },
    children: "childorgset"
  };
  public isloading: any = false;


 /**
   * 刷新页面
   */
   public visiblechange(tag:any){
      console.log(tag?"展开":"收起")
      if(!tag){
        this.selectedOrgArray = [];
      }else{
        this.isloading = true;
        this.getOrgData();
      }
   }
  /**
   * 刷新页面
   */
  public reloadPage(){
    const pages:any = store.getState().pages;
    //获取面包屑数据，刷新页面的时候取缓存数据
    
    setTimeout(() => {
       const activeCrumbList = shared.getActiveCrumbList();
       let activeKey:any = "";
       if(activeCrumbList&&activeCrumbList.length > 0){
          activeKey =  activeCrumbList[activeCrumbList.length - 1].breadpath
          const activePage = pages.find((page: any) => {
            return page.microAppName + "__" + page.fullPath === activeKey;
          });
          // 未激活应用
          const activeApp = apps.find((app: any) => {
            return app.name === activePage.microAppName;
          });
          if (activeApp) {
            // 这个需要更改模板，加一个RELOAD行为，然后再基座中触发就会刷新子应用
            AppService.getInstance().noticeMicroApp({
              tag: activePage.microAppName,
              action: "RELOAD",
              data: activePage
            });
          } 
      }else{
          const pages:any = store.getState().pages;
          let path = this.$route.fullPath;
          activeKey = path.split("#")[1];
          const activePage = pages.find((page: any) => {
            return page.fullPath === path.split("#")[1];
          });
          if(activePage && activePage.microAppName){
            // 这个需要更改模板，加一个RELOAD行为，然后再基座中触发就会刷新子应用
            AppService.getInstance().noticeMicroApp({
              tag: activePage.microAppName,
              action: "RELOAD",
              data: activePage
            });
          }
      }
      
      let activeOrgData: any = localStorage.getItem('activeOrgData') ? JSON.parse(localStorage.getItem('activeOrgData') as string) : {};
      let orgid = activeOrgData?.orgid;
      for(let count = 0;count < pages.length;count++){
        //当前tab
          if(pages[count].microAppName + "__" + pages[count].fullPath === activeKey){
            pages[count].orgid = orgid;
          }
      }
    },200);
    
    
  }
  /**
     * 组织返回值属性名小写转换
     */
  public ObjectToLowerCase(obj:any){
        if(Array.isArray(obj)){
            obj.map((item:any) => {
                item = this.ObjectToLowerCase(item)
                if(item.childorgset.length > 0){
                    this.ObjectToLowerCase(item.childorgset)
                }
            })
        }else{
            for (var key in obj){
                obj[key.toLowerCase()] = obj[key];
                if (/[A-Z]/.test(key)) {
                  delete(obj[key]);
                }
            }
        }
        return obj;

  }

  public handleNodeClick(data: any) {
    const ref = (this.$refs.orgSelect as any);
    let orgid = data.orgId?data.orgId:data.orgid;
    let orgcode = data.orgCode?data.orgCode:data.orgcode;
    let orgname = data.orgName?data.orgName:data.orgname;
    let accessflag = data.accessFlag?data.accessFlag:data.accessflag;
    if(accessflag && this.selectedOrgId != orgid){
      let copydata = data;
      ApiService.getInstance()
      .updateSwitchOrg(orgid)
      .then(res => {
        if(res.data.result){
          this.selectedOrgName = orgcode +  orgname;
          this.selectedOrgId = orgid;
          let activeOrg = this.ObjectToLowerCase(JSON.parse(JSON.stringify(copydata)));
          activeOrg.systemid = "base";
          localStorage.setItem('orgsData', JSON.stringify(activeOrg));
          localStorage.setItem('activeOrgData', JSON.stringify(activeOrg));
          Message({ message: res.data.result, type: "success" });
          AppService.getInstance().noticeAllMicroApp('UPDATE_ORG',data);
          eventBus.$emit('resetMenu', data);
          this.reloadPage();
        }else{
          Message({ message: res.data.errorInfo, type: "error" });
        }
      })
      .catch( res => {
        console.log(res);
      } );
      ref.blur();
      this.filtermethod("");
    }
  }
  expandNodes(node:any) {
      for (let i = 0; i < node.childNodes.length; i++) {
        node.childNodes[i].expanded = false;
        if (node.childNodes[i].childNodes.length < 0) {
          this.expandNodes(node.childNodes[i]);
        }
      }
  }
  public focusReset() {
      (this.$refs.orgTree as any).filter("");
  }
  public filtermethod(val: any) {
    (this.$refs.orgTree as any).filter(val);
  }
  /**
   * 节点过滤
   */
  public filterNode(value: any, data: any) {
    const _data = data.orgName?(data.orgName + data.orgCode):(data.orgname + data.orgcode);
    if (!value) return true;
    return _data.indexOf(value) !== -1;
  }
  created() {
    this.getOrgData();
  }


  /**
   * 获取组织下拉数据
   *
   * @param {*} $event
   * @memberof AppLang
   */
   public getOrgData() {
      ApiService.getInstance()
        .getOrganizationDrop()
        .then(res => {
          this.$nextTick(() => {
            if(res.data.extResult){
              this.selectedOrgName = res.data.extResult.orgCode + "  " +res.data.extResult.orgName;
              this.selectedOrgId = res.data.extResult.orgId;
              const _activeOrgData=this.ObjectToLowerCase(res.data.extResult)
              _activeOrgData.systemid = "base";
              localStorage.setItem('activeOrgData',JSON.stringify(_activeOrgData))
            }
            if(res.data.result){
              const _orgData=this.ObjectToLowerCase(res.data.result)
              _orgData.systemid = "base";
              localStorage.setItem('orgsData',JSON.stringify(_orgData))
            }
            const data = res.data.result;
            this.selectedOrgArray = data;
            this.isloading = false;
          })
        });
   }

  /**
   * 操作指南打开新视图
   *
   * @memberof AppDefaultIndexViewLayout
   */
  public operationGuide() {
    this.$router.push("/base#/frontpage/baseuserguides/views/customview");
  }

  //   处理语言
  /**
   * 本地语言资源
   *
   * @type {*}
   * @memberof AppLang
   */
   public localList: any[] = [
    {
        type: 'zh-CN',
        name: this.$t('language.CN'),
    },
    {
        type: 'en-US',
        name: this.$t('language.EN'),
    },
  ];

  /**
   * 标题
   *
   * @type {(string | null)}
   * @memberof AppLang
   */
  public caption: string | null = null;

  /**
   * vue 生命周期
   *
   * @memberof AppLang
   */
  public mounted() {
    let lang: string = this.$i18n.locale;
    const local: any = this.localList.find((_local: any) =>
      Object.is(_local.type, lang)
    );
    this.caption = local.name;
  }

  /**
   * 选择语言
   *
   * @param {*} $event
   * @memberof AppLang
   */
  public selectLang($event: any): void {
    const loacl = this.handleLocaleMap($event.key)
    ApiService.getInstance().switchlang(loacl).then((res:any) => {
      this.$i18n.locale = $event.key;
      const local: any = this.localList.find((_local: any) =>
        Object.is(_local.type, $event.key)
      );
      this.caption = local.name;
      localStorage.setItem("local", $event.key);
      window.location.reload();
    }).catch((error:any)=>{
      Message({ message: '切换语言失败！', type: "error" });
    })
  }

  /**
  * 菜单显示状态改变 
  */
  public onVisibleChange(visible:boolean){
    const icon = this.$refs.eldropdownicon
    if(!icon){
      return
    }
    if(visible){
      (icon as Element).classList.add('is-reverse')
    }else{
      (icon as Element).classList.remove('is-reverse')
    }
  }

  /**
 * 处理语言路径映射
 *
 */
 public handleLocaleMap(key: string){
    switch (key) {
        case 'zh-CN':
            return 'ZH_CN';
        case 'en-US':
            return 'EN';
        default:
            return 'ZH_CN';
    }
 }
}
