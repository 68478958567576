import { AppConfig } from "@/config/config";
import { micro } from "@/micro";
import router from "@/routes";
import { createStore } from "redux";
import { Vue } from "vue-property-decorator";
import { eventBus } from "@/service/micro-action-service";
import qs from "qs";

export type State = {
  pages?: Array<any>;
  selectTheme?: string;//动态主题颜色
  historyPathList?: Array<string>;
  menus?:Array<string>; // 菜单数据
  activeCrumbList?: Array<any>; //面包屑--展示在面包屑上的数据
  activeCrumbPathAll?: Array<any>; //面包屑--所有打开过的菜单地址备份,
  baseConfig?: object;//base系统配置,
  isAppMenu?:boolean//是否原生菜单
};

type Action = {
  type: string;
  payload?: any;
};

const updateHistoryPath = (action: string, state: State, path: string) => {
  if (action === "ADD_PAGE") {
    if (!state.historyPathList) {
      state.historyPathList = [];
      state.historyPathList.push(path);
    } else {
      const index = state.historyPathList.findIndex((item: string) => { return item === path });
      if (index !== -1) {
        state.historyPathList.splice(index, 1);
        state.historyPathList.push(path);
      } else {
        state.historyPathList.push(path);
      }
    }
    const env = (window as any).Environment;
    if (path.startsWith(env.baseDeployUrl)) {
      path = path.substring(env.baseDeployUrl.length);
    }
    if (router.currentRoute.fullPath !== path) {
      router.push(path);
    }
  } else if (action === "REMOVE_PAGE") {
    if (state.historyPathList) {
      const index = state.historyPathList.findIndex((item: string) => { return item === path });
      if (index !== -1) {
        state.historyPathList.splice(index, 1);
      }
    }
  } else if (action === "REMOVE_ALL") {
    if (state.historyPathList) {
      state.historyPathList = [];
    }
  } else if (action === "REMOVE_OTHER") {
    if (state.historyPathList) {
      state.historyPathList = [];
      state.historyPathList.push(path);
    }
  }
}

const reducer = (state: State = {}, action: Action): State => {
  switch (action.type) {
   
    // 添加页面
    case "ADD_PAGE":
      {
        if (action.payload.microAppName == 'otp'
         && action.payload.fullPath.indexOf('?') == '-1'
         && action.payload.fullPath != '/') {
          action.payload.fullPath += '?'
        }
        if (!state.pages) {
          state.pages = [];
        }
        const { microAppName, fullPath } = action.payload;
        const currentConfig = AppConfig.apps.find((item: any) => {
          return item.name === microAppName;
        })
        if (currentConfig) {
          if (micro.isOpenMoreView(state) === false && micro.isActivePath(currentConfig.name, fullPath, state) === false) {
            micro.noticeLimitLoading();
            return state;
          }
        }
        let activeOrgData: any = localStorage.getItem('activeOrgData') ? JSON.parse(localStorage.getItem('activeOrgData') as string) : {};
        Vue.set(action.payload,"orgid",activeOrgData?.orgid);
        const { payload } = action;
        const n = state.pages.findIndex(page => page.fullPath === `${fullPath}` );
        if (n === -1){
          let url = payload.fullPath.substring(0, decodeURIComponent(payload.fullPath).indexOf("?"))
          const i = state.pages.findIndex(page => page.microAppName === payload.microAppName && 
                    (page.fullPath.substring(0, decodeURIComponent(page.fullPath.indexOf("?"))) === url ));
          if (i === -1) {
            state.pages.push(action.payload);
          } else if (i > -1){
            /* 
            孟鹏飞 2023/08/23 
              TDA报表内容的前缀都是相同的  例如 tda/#/indextda/tramonsubdefs/views/templatereport
              这里如果按照前缀判断,会导致基座中的TDA报表页面的页签会互相替换
              添加一个判断让TDA报表内容不会替换
            */
            if (payload.microAppName == 'tda' && url.indexOf('templatereport') != -1) {
              state.pages.push(action.payload);
            } else {
              Vue.set(state.pages, i, action.payload);
            }
          }
        }
        if (currentConfig) {
          updateHistoryPath('ADD_PAGE', state, `${currentConfig.activeRule}#${fullPath}`);
        }
        return state;
      }
    case "UPDATE_PAGE":
      {
        if (state.pages) {
          let activeOrgData: any = localStorage.getItem('activeOrgData') ? JSON.parse(localStorage.getItem('activeOrgData') as string) : {};
          const { index, data } = action.payload;
          state.pages[index] = data;
          // 合入自定义菜单的中英文
          if(sessionStorage.getItem('activeMenuItem')){
            const activeMenuItem = JSON.parse(sessionStorage.getItem('activeMenuItem')|| '{}')
            state.pages[index].titleZHCN = activeMenuItem?.titleZHCN
            state.pages[index].titleEN = activeMenuItem?.titleEN
            sessionStorage.removeItem('activeMenuItem')
          }
          if(!state.pages[index].orgid){
            state.pages[index].orgid = activeOrgData?.orgid;
          }
          const { microAppName, fullPath } = data;
          const currentConfig = AppConfig.apps.find((item: any) => {
            return item.name === microAppName;
          })
          if (currentConfig) {
            updateHistoryPath('ADD_PAGE', state, `${currentConfig.activeRule}#${fullPath}`);
          }
        }
        return state;
      }
    case "REMOVE_PAGE":
      {
        if (state.pages) {
          const { microAppName, fullPath } = state.pages[action.payload];
          const currentConfig = AppConfig.apps.find((item: any) => {
            return item.name === microAppName;
          })
          if (currentConfig) {
            updateHistoryPath('REMOVE_PAGE', state, `${currentConfig.activeRule}#${fullPath}`);
          }
          state.pages.splice(action.payload, 1);
        }
        return state;
      }
    case "REMOVE_ALL":
      {
        if (state.pages) {
          state.pages.length = 0;
          updateHistoryPath('REMOVE_ALL', state, '');
        }
        return state;
      }
    case "REMOVE_OTHER":
      {
        if (state.pages) {
          state.pages.length = 0;
          state.pages[0] = action.payload;
          const { microAppName, fullPath } = action.payload;
          const currentConfig = AppConfig.apps.find((item: any) => {
            return item.name === microAppName;
          })
          if (currentConfig) {
            updateHistoryPath('REMOVE_OTHER', state, `${currentConfig.activeRule}#${fullPath}`);
          }
        }
        return state;
      }
    case "ADD_HISTORY_PATHS":
      {
        updateHistoryPath('ADD_PAGE', state, action.payload);
        return state;
      }
    case "ADD_MENU_MAP":
      {
        state.menus = action.payload
        return state
      }
    case "ADD_ACTIVEMENUPATHCOPY": //面包屑--所有点击过的菜单数据
      {
        sessionStorage.setItem('breadcrumbListCopy', JSON.stringify(action.payload));
        state.activeCrumbPathAll = action.payload
        return state
      }
    case "ADD_ACTIVEMENUPATH": //面包屑--当前展示在面包屑上的数据
      {
        sessionStorage.setItem('breadcrumbList', JSON.stringify(action.payload));
        state.activeCrumbList = action.payload;
        return state
      }
    case "UPDATE_ACTIVEMENUPATH": //面包屑--更新数据
      {
        const { menuPath, viewPath, info, microAppName } = action.payload;
        if (viewPath) {
          if (viewPath.includes("/frontpage/baseuserguides/views/customview")) {
            return state
          }
          //取路由中srfmenuname数据给下钻页面的key值，也是菜单的key值
          const params: any = {};
          const paramArray: Array<string> = decodeURIComponent(viewPath).split(";");
          if (paramArray.length > 0) {
            paramArray.forEach((item: any) => {
                Object.assign(params, qs.parse(item));
            });
          }
          const bread: any = sessionStorage.getItem('breadcrumbList') === 'undefined' ? '""' :sessionStorage.getItem('breadcrumbList') ;
          const data: any = state.activeCrumbList ?  JSON.parse(JSON.stringify(state.activeCrumbList))  : JSON.parse(bread);
          let string: any = info ? ("-" + info ): '';
          let paramsid: any = '';
          if (paramArray.length > 0) {
            paramsid = paramArray[0].split('=')[1].split('&')[0]
          }
          // 点新建编辑等需要新增一个对象并push
          let ObjectNew:any = Vue.observable({
            menuInfo: viewPath.includes('iframes') ? paramsid : viewPath,
            title: menuPath.caption + string,//eg: "对外付汇供应商管理-新建" 
            codeName: menuPath.codeName,//刷新子应用得时候用到，给到子应用
            key: '',//将每一级得key给到面包屑
            menuUrl: '',//eg："非贸付汇申报管理 -> 基础信息 -> 非贸付汇供应商管理"，这里没有数据
            breadpath: microAppName + "__" + viewPath,//路径
          });    
          //将路由中srfmenuname数据给下钻页面的key值，也是菜单的key值
          if ( Object.prototype.hasOwnProperty.call(params, "srfmenuname") &&
            params.srfmenuname ) {
              ObjectNew.key = params.srfmenuname + paramsid
          }
          // 取所有面包屑数据
          let arrayCopy = sessionStorage.getItem('breadcrumbListCopy')
          let arrayParseCopy = arrayCopy ? JSON.parse(arrayCopy) : {}
          let list: any = state.activeCrumbPathAll ? state.activeCrumbPathAll : arrayParseCopy
            
          // 判断当前路由是否已经存在，从而判断是新增还是切换
          if (list && list.length > 0) {
            const currentConfig:any = list?.findIndex((item: any) => {
              return ((item.breadpath).includes(viewPath)) 
            })
            //已经存在
            if (currentConfig > -1) {
              //如果存在，则覆盖，如果不存在，则push。目前数据都是返回两次
              if (data && JSON.stringify(data).includes(viewPath)) {
                // 如果当前面包屑已经有当前路由，那就看是否所有得面包屑breadpath和viewPath都相等
                // 相等说明是点击菜单进来的，不相等说明是新建等非菜单点进来的，那就set最后一个面包屑数据，比如新建得时候会返回info=’新建‘，如果不覆盖就无法同步
                data.map((item:any) =>{
                  if (!item.breadpath.includes(viewPath)) {
                    Vue.set(data, data?.length - 1, ObjectNew);
                  }
                })
              } if (data && !JSON.stringify(data).includes(viewPath)) {
                // 当前面包屑没有当前路由，直接push进去就行
                Vue.set(data, data?.length, ObjectNew);
              }
              // 存储当前展示面包屑数据
              state.activeCrumbList = data;
              sessionStorage.setItem('breadcrumbList', JSON.stringify(state.activeCrumbList));
              // 存储所有面包屑数据
              list[currentConfig].fullPathChildren = data;
              state.activeCrumbPathAll = list;
              sessionStorage.setItem('breadcrumbListCopy', JSON.stringify(state.activeCrumbPathAll));
            } else if (currentConfig === -1 ) {
              //如果不存在，则push
              if (data && !JSON.stringify(data).includes(viewPath)) {
                Vue.set(data, data?.length, ObjectNew);
                state.activeCrumbList = data;
              }
              let Obj:any = Vue.observable({
                  title: microAppName,
                  key: viewPath,//将每一级得key给到面包屑
                  menuUrl: '',
                  breadpath: viewPath,
                  fullPathChildren: data
                })

              // 存储所有面包屑数据
              list.push(Obj);
              state.activeCrumbPathAll = JSON.parse(JSON.stringify(list)) ;
              sessionStorage.setItem('breadcrumbListCopy', JSON.stringify(list));
            }
          }
          
          // 如果找不到就说明不是菜单，而是新建、编辑、iframes，如果找到就继续修改所有页签数据中的当前数据
          // let allDta:any = state.activeCrumbPathAll;
          // let pages:any = state.pages;
          // if (allDta?.length > 0) {
          //   const j: any = allDta.findIndex((res:any) => { return decodeURIComponent(res.breadpath) === decodeURIComponent(viewPath) });
          //   if (j > -1){
          //     const n: any = pages.findIndex((item:any) => { return decodeURIComponent(item.fullPath) === decodeURIComponent(viewPath) });
          //     if (n > -1){
          //       setTimeout(() => {
          //         console.log(allDta[j].fullPathChildren[allDta[j].fullPathChildren.length -1].title)
          //         Vue.set(pages[n], 'myCaptionLocale', allDta[j].fullPathChildren[allDta[j].fullPathChildren.length -1].title);
          //         state.pages = JSON.parse(JSON.stringify(pages));
          //         sessionStorage.setItem('sessionPage',JSON.stringify(state.pages))
          //       }, 100);
          //     }
          //   }
          // }
        }
        return state
      } 
    case 'SESSION_PAGE':
      {
        state.pages = action.payload
        return state
      }
    case 'CLEAR_CACHE':
      {
        state.pages = []
        state.activeCrumbList = []
        state.activeCrumbPathAll = []
        return state
      }
    case 'ADD_BASE_CONFIG':
      {
        state.baseConfig = action.payload;
        return state
      }
    case 'SET_APP_MENU':
      {
        state.isAppMenu = action.payload;
        sessionStorage.setItem('isAppMenu',action.payload)
        return state
      }
    default:
      return state;
  }
};

const store = createStore<State, Action, unknown, unknown>(reducer);

export default store;
