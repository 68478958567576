// 一个进度条插件
import "nprogress/nprogress.css";
import {
  addGlobalUncaughtErrorHandler,
  start,
} from "qiankun";

/**
 * 添加全局的未捕获异常处理器
 */
addGlobalUncaughtErrorHandler((event: Event | string) => {
  console.error(event);
  const { message: msg } = event as any;
  // 加载失败时提示
  if (msg && msg.includes("died in status LOADING_SOURCE_CODE")) {
    console.error("子应用加载失败，请检查应用是否可运行");
  }
});

/**
 * 启动乾坤
 *
 * @author chitanda
 * @date 2023-02-22 16:02:57
 * @export
 */
export function run(): void {
  start();
}

// 导出 qiankun 的启动函数
export default run;
