import enLocale from 'element-ui/lib/locale/lang/en'
import enBaseHome from './en-base-home'

function getAppLocale() {
    const data = {
        micro: {
            tablimittooltip: 'Only {count} tabs can be opened at most. Please close the existing tab before opening a new tab'
        },  
        common: {
            microapp: 'MicroApp Base Application',
            more: 'More'
        },
        language: {
            EN: 'English',
            CN: 'Chinese'
        },
        components: {
            apppageexp: {
                closeall: 'Close All',
                closeother: 'Close Other'
            },
            appuser: {
                info: 'User Info',
                logout: 'Logout',
                clearCache:'Clear Cache',
                myMenus:'My Menu',
                sureclearCache:'Are you sure clear cache?',
                updatePassWord:'Update Password',
                versiondetail:'Version Detail',
                versionBuildTime:'Version Build Time',
                currentVersion:'Current Version',
                serveCoreVersion:'Core Version',
                serveIBIZVersion:'Ibiz Version',
                model:'Model'
            },
            login: {
                loginbutton: 'Login',
                loginerror: 'Login Failed!',
                placeholder: {
                    user: 'Please Enter User Name',
                    password: 'Please Enter Password'
                }
            },
            appupdatepassword: {
                oldpwd: 'Original Password',
                newpwd: 'New Password',
                confirmpwd: 'Confirm Password',
                sure: 'Sure',
                oldpwderr: 'The Original Password Cannot Be Empty!',
                newpwderr: 'New Password Cannot Be Empty!',
                newpwderr2:'New password cannot be less than 8 digits and must contain uppercase and lowercase numbers and special characters',
                newpwderr3:'The New Password Cannot Be The Same As The Old Password!',
                confirmewderr: 'The Two Input Passwords Are Inconsistent!',
                success:'Successfully Modified Password!'
            },
            tisHome: {
                RiskScanResults: 'Risk Scan Results',
                LastMonth: 'Last Month',
                ThisQuarter: 'This Quarter',
                ThisYear: 'This Year',
                LowRisk: 'Low Risk',
                HighRisk: 'High Risk',
                Announcement: 'Announcement',
                ViewAll: 'View All',
                DataUploaded: 'Data To Be Uploaded',
                IndividualReport: 'Individual Report',
                GroupReport: 'Group Report',
                ClickToView: 'Click To View',
                ConsultationAnswers: 'Consultation And Answers',
                FAQ: 'FAQ',
                PolicyInterpretation: 'Policy Interpretation',
                TaxConsultation: 'Tax Consultation',
                AdvisorOnline: 'Advisor Online',
                Homepage: 'Homepage Configuration',
                BackgroundColor: 'Background Color',
                NoMore: 'No More',
                Sanduserpermissions: 'This displays all menu applications with unified resource codes and user permissions',
                UserRequired: 'The Username Cannot Be Empty',
                PwdRequired: 'Password Cannot Be Empty',
                VerificationCode: 'Verification Code',
                Validate: 'Validate',
                Enterverificationcode: 'Please Enter The Email Verification Code',
                EmailVerificationCode: 'Email Verification Code',
                EnterSMSverificationcode: 'Please Enter The SMS Verification Code',
                SMSVerificationCode: 'SMS Verification Code',
                SSO: 'SSO',
                Maildomainname: 'Please Enter The Email Domain Name',
                EmitRequired: 'The Email Domain Name Cannot Be Empty',
                GoTo: 'Go To',
                ICPPNo: 'Shanghai ICP Preparation No. 19022806-2',
                PreparationNo: 'Shanghai Public Security Network Preparation No. 31010102006864',
                TaxManagement: 'Tax Management System Platform Portal',
            },
            operationGuide: {
                operationGuideTitle: "Operation Guide",
                userOperationGuide: "User Operation Guide",
            },
        },
        el: {
            colorpicker: {
                clear: 'Clear',
                confirm: 'Confirm'
            }
        },
        // ↑ el与elemen提供的同名，会被覆盖
        ...enLocale,
        ...enBaseHome,
    }
    return data;
}
export default getAppLocale;