







import { Component, Vue } from "vue-property-decorator";
import BaseHome from "@/components/base-home/base-home.vue";
import tisHome from "@/components/base-home/tis-home.vue";
@Component({
  components: {
    BaseHome,
    tisHome
  },
})
export default class Home extends Vue {
  isTis:any = false
  created() {
    const context:any = localStorage.getItem('microAppData')
    if(context){
      const code = JSON.parse(context).context.tenantcode
      if(code === 'TIS'){
        this.isTis = true
      }
    }
  }
       
}
