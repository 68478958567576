































import { Vue,Component,Prop, Watch } from "vue-property-decorator";

@Component({})
export default class AppMenuSearch extends  Vue {
  /**
  * 搜索数据
  */
  @Prop({default:[]})searchList!: any

  public loading = true;
  /**
  *  检索关键字内容
  */
  public searchValue:string = ''

  /**
  *  返回内容 
  */
  public dropdownList:any = []

  /**
   * 如果是第一次搜索需要获取group，之后就不需要重复获取了
   */
   public isFirst: Boolean = true

  /**
  * 下拉菜单样式处理,与搜索栏保持一致 
  */
  public dropDownStyle(){
    const el = document.getElementsByClassName('app-menu-search')[0] as HTMLDivElement;
    const dropdown = document.getElementsByClassName('menu-search')[0] as HTMLDivElement;
    if(dropdown){
      dropdown.style.width = el.offsetWidth - 10 + 'px'
      dropdown.style.left = '0px'
    }
  }

  /**
  * 检索栏过滤方法
  */
  public querySearch<T extends boolean|KeyboardEvent>(visible:T) {
    if(!visible){
      this.dropdownList = []
      return
    }
    if((visible as KeyboardEvent)?.code === 'Enter'){
      const dropdown = this.$refs.searchMenuDropdown;
      (dropdown as any).visible = true;
    }
    if (this.isFirst) {
      this.$emit('search-click', this.searchValue);
      this.isFirst = false;
    }
    this.dropdownList = this.searchValue ? this.searchList.filter((restaurant: any) =>
    restaurant.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0) : this.searchList;
    this.loading = false
    this.$forceUpdate()
  }

  /**
   * 收起菜单时，把展开菜单都去掉
   */
  @Watch("searchList")
  onSearchListChange(newVal: any) {
    console.log(newVal)
    this.dropdownList = this.searchValue ? this.searchList.filter((restaurant: any) =>
    restaurant.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0) : this.searchList;
  }

  /**
  * 检索栏下拉选中方法
  */
  public handleSelect(item: any) {
    this.$emit('search-item-select', item)
  }

  /**
  * 关键字高亮
  */
  public highlighting(value: string) {
    if (this.searchValue) {
      return value.replace(
        new RegExp(this.searchValue, "g"),
        `<span class="tag">${this.searchValue}</span>`
      );
    } else {
      return value;
    }
  }

  mounted(){
    this.$nextTick(() => {
      this.dropDownStyle()
    })
  }
}
