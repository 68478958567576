import Vue from "vue";
import { ConfigProvider, Button, Menu, Dropdown, Avatar, Tabs, Icon, Input } from 'ant-design-vue';
import VueRouter from "vue-router";
import '@/elementui'
import ViewUI from 'view-design';
import moment from 'moment'
import App from "./App.vue";
import router from "./routes";
import i18n from '@/locale';
// import 'ant-design-vue/dist/antd.css';
// import "./assets/styles/locale.antd.min.css";
// import "./assets/css/font-awesome.min.css";
import "./assets/styles/common.less";
import "@/style/default.less";
import { AppService } from "./service/app-service";
import { getBaseConfig  } from "./baseConfig";

Vue.use(ConfigProvider);
Vue.use(Button);
Vue.use(Menu);
Vue.use(Dropdown);
Vue.use(Avatar);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Input);
Vue.use(VueRouter);
Vue.use(ViewUI)
Vue.config.productionTip = false;
AppService.getInstance().initApp(router);
Vue.filter('dateFormat', (str:any, partten = "YYYY-MM-DD") => {
  return moment(str).format(partten);
})


const init = () =>{
  new Vue({
    i18n,
    router,
    render: (h) => h(App),
  }).$mount("#main-app");
}

if((window as any).Environment?.enableConfiguration){
  getBaseConfig().finally(() => init())
}else{
  init()
}

