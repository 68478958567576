import Vue from "vue";
import i18n from "@/locale";
import ElementLocale from "element-ui/lib/locale";

import {
  Tabs,
  TabPane,
  Calendar,
  Select,
  Option,
  OptionGroup,
  Radio,
  RadioGroup,
  RadioButton,
  Button,
  ButtonGroup,
  DatePicker,
  TimeSelect,
  TimePicker,
  Tooltip,
  Autocomplete,
  Dialog,
  Tree,
  Switch,
  Input,
  MenuItem,
  MenuItemGroup,
  Menu,
  Badge,
  Col,
  Row,
  Progress,
  Popover,
  Submenu,
  Breadcrumb,
  BreadcrumbItem,
  MessageBox,
  Message,
  Collapse,
  CollapseItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Drawer
} from "element-ui";

// import 'element-ui/lib/theme-chalk/index.css'; // html中引入

Vue.use(Tabs)
  .use(TabPane)
  .use(Calendar)
  .use(Select)
  .use(Option)
  .use(OptionGroup)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Button)
  .use(ButtonGroup)
  .use(DatePicker)
  .use(TimeSelect)
  .use(TimePicker)
  .use(Tooltip)
  .use(Autocomplete)
  .use(Dialog)
  .use(Tree)
  .use(Switch)
  .use(Input)
  .use(MenuItemGroup)
  .use(MenuItem)
  .use(Menu)
  .use(Badge)
  .use(Popover)
  .use(Row)
  .use(Col)
  .use(Progress)
  .use(Submenu)
  .use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Collapse)
  .use(CollapseItem)
  .use(Dropdown)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Drawer)
  ;
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$success = (message:any) => {
    Message.success(message);
  }
  Vue.prototype.$throw = (message:any) => {
    Message.error(message);
  }


ElementLocale.i18n((key: any, value: any) => i18n.t(key, value));
