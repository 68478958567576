

















import { Vue,Component,Prop,Watch } from "vue-property-decorator";
import AppMenuBase from './app-menu-base'
import { micro } from "@/micro";
import { AppService } from "@/service/app-service";
import { ActionLogService } from "@/api";
import shared from "@/shared";
import SubMenu from "./app-sub-menu";
import AppMenuItem from "./app-menu-item.vue";
import AppMenuSearch from "./app-menu-search.vue";
import { IrootMenus,IMenuItem,IfuzzrySerach } from '@/interface'
import qs from "qs";
import { Result } from "ant-design-vue";
import { eventBus } from '@/service/micro-action-service';
import { lastValueFrom } from "rxjs";

@Component({
  components: {
    'sub-menu': SubMenu,
    AppMenuItem,
    AppMenuSearch
  }
})
export default class AppMenu extends AppMenuBase {

  /**
  * 菜单数据 
  */
  public menus:IrootMenus[] = []

  /**
  * 当前选中的菜单项 key 数组
  */
  selectKey: string = "";

  /**
  * 当前展开的 SubMenu 菜单项 key 数组
  */
  openKeys: string[] = [];

  /**
   * 存储选中得item
   */
   public activeMenuItem: any = {};

  /**
  * 是否收缩菜单--菜单递归
  */
  @Prop({ default: false }) collapsed!: boolean;

  /**
  * 打开菜单回调 
  * @param openKeys 
  */
  public openChange(openKeys:string[]){
    const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
    const index  = this.menus.findIndex((item:any) => item.system === latestOpenKey)
    // 一级菜单手风琴
    if(index !== -1){
      this.service.menusLoad(this.menus[index]).then((res:any) => {
        this.menus[index].children = res
      })
      this.$nextTick(() => {
        this.openKeys = [this.menus[index].key]
      })
    }else{
      // 模块内树形菜单手风琴,openKeys[0]始终为打开的一级菜单
      const _index = this.menus.findIndex((item:any) => item.system === openKeys[0])
      if(_index !== - 1){
        // 关闭菜单项得时候找到关闭得层级level，展开时latestOpenKey是最后一个新打开得页签，
        // 关闭时latestOpenKey字段是空得，关闭时需要截取关闭之前得key值，
        var indexOld:any = 0
        let newdata = this.openKeys;
        let newList = openKeys;
        if(latestOpenKey === undefined) {
          indexOld = newdata.findIndex((key,index) => {
            if (openKeys.indexOf(key) === -1)
            return index + 1
          });
        } else {
          indexOld = 0
        }
        const flat = this.flattenTree(this.menus[_index])
        // let _openKeys:any = [openKeys[0]]
        //latestOpenKey === undefined表示关闭，关闭时截取关闭之前得数据，新增得时候取第一级得key就好
        let _openKeys:any = latestOpenKey === undefined ? newList.slice(0, indexOld) : [openKeys[0]];
       
        const findMenu = (key:any,menu:any[] = []) => {
          let temp = flat.find((menu:any) => menu.key === key)
          if(temp){
            menu.push(temp)
            if(temp.parent){
              findMenu(temp.parent,menu)
            }
          }
          return menu
        }
        const _menu = findMenu(latestOpenKey)
        if(_menu && _menu.length) {
          _menu.forEach((menu:any) => {
            if (!_openKeys.includes(menu.key)) {
              _openKeys.splice(1, 0, menu.key);
            }
          })
        }
        this.$nextTick(() => {
          this.openKeys  = _openKeys
        })
      }
    }
  }

  /**
  * 树形结构平铺并标明层级返回
  * @param node 
  * @param level 
  * @param flatArray 
  */
  public flattenTree(node: any, level = 0, flatArray: any[] = [],parent:any = null): any[] {
    flatArray.push({ ...node, level,parent });
      if (node.children) {
        node.children.forEach((childNode:any) => {
          this.flattenTree(childNode, level + 1, flatArray,node.key);
        });
      }
    return flatArray;
  } 

  /**
  * 计算选中的菜单
  */
  public computeMenukeys(path: string) {
    this.computeRootOpenKeys(path)
  }

  /**
  * 计算一级菜单打开项 
  */
  public computeRootOpenKeys(path:string){
    let root = path.split("#")[0]
    const index  = this.menus.findIndex((item:any) => item.path === root)
    if(path === '/'){
      this.selectKey = 'index'
    }
    if(index !== -1){
      this.addOpenKeys(this.menus[index].key)
      this.service.menusLoad(this.menus[index]).then((res:any) => {
        if (this.menus[index].path !== "/" && this.menus[index].app !== "index") {
          this.menus[index].children = AppService.getInstance().initMenuUrl(res,this.menus[index].title)
        }
        if(this.menus[index].children){
          this.computeAppOpenKeys(this.menus[index].children,path)
          shared.setmenus(this.menus)
          const _activeMenuItem = this.isContains(this.selectKey,res)
          if(_activeMenuItem && _activeMenuItem.isContains){
            this.activeMenuItem = _activeMenuItem.item
            this.breadInit(this.$route.hash)
          }
        }
      })
    }
  }

  /**
  * 计算子系统菜单打开项 
  * @param menu 
  */
  public computeAppOpenKeys(menu:IMenuItem[]|undefined,path:string){
    if(!menu){
      return
    }
    const params: any = {};
    const _params: any = path.slice(path.indexOf("?") + 1);
    const paramArray: Array<string> = decodeURIComponent(_params).split(";");
    if (paramArray.length > 0) {
      paramArray.forEach((item: any) => {
        Object.assign(params, qs.parse(item));
      });
    }
    if (
      Object.prototype.hasOwnProperty.call(params, "srfmenuname") &&
      params.srfmenuname &&
      this.selectKey !== params.srfmenuname
    ) {
      this.selectKey = params.srfmenuname;
    }
    const parentMenus = this.getParentMenus(menu);
    if (parentMenus?.length && !this.collapsed) {
      // 不是收缩状态再去改
      parentMenus.forEach((item:string) => {
        this.addOpenKeys(item)
      });
    }
  }

  /**
  * 添加打开项
  * @param key 
  */
  public addOpenKeys(key:string){
    if(!this.openKeys.includes(key)){
      this.openKeys.push(key)
    }
  }

  /**
  * 获取父菜单项 所有父节点
  *
  * @memberof Menu
  */
  public getParentMenus(menu:IMenuItem[]) {
    let temp: string[] = [];
    let forFn = (arr: any, key: string, parent: any = null) => {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        if (item.key === key) {
          if (parent?.key) {
            temp.push(parent.key);
            forFn(menu, parent.key);
          }
        } else {
          if (item.children) {
            forFn(item.children, key, item);
          }
        }
      }
    };
    forFn(menu, this.selectKey);
    return temp;
  }

  /**
  * 监听路由变化
  */
  @Watch("$route")
  onPathChange(newVal: any, oldVal: any) {
    if (newVal.meta.caption === '首页' || newVal.meta.caption === 'Home') {
      this.openKeys = [];
      this.selectKey = 'Home';
      // 存储面包屑
      shared.setActiveCrumbPath([])
    }
    if (newVal.hash.includes("?")) {
      if (newVal.hash.includes("isOutsideLinks")) {
        // 在主应用中发布事件
        eventBus.$emit('remove', newVal.fullPath);
      } else {
        this.breadInit(newVal.hash);
        this.computeMenukeys(newVal.fullPath);
      }
    }
  }

  /**
  * 展开收起变化
  * @param newVal 
  * @param oldVal 
  */
  @Watch("collapsed")
  onCollapsed(newVal:any,oldVal:any){
    this.service.setCollapsed(newVal)
    this.$forceUpdate()
  }

  /**
 * 点击面包屑得时候改变openKeys得值达到触发左侧菜单得效果
 *
 * @memberof Menu
 */
 public changeSelectKey(value: any) {
    if (value === 'Home') {
      this.selectKey = 'Home';
    } else {
      this.openKeys = value;
    }
 }

  /**
  * 切换菜单
  */
  changeMenu(item: IMenuItem) {
    if (micro.limitLoading) {
      micro.noticeLimitLoading();
      return;
    }
    ActionLogService.getInstance().menuAction({ menu: item })
    const { key } = item;
    this.selectKey = key;
    if (item && item.path) {
      if (
        location.pathname.slice(
          (window as any).Environment.baseDeployUrl.length
        ) !== item.path
      ) {
        const loadContainer = document.getElementById(
          "child-container-loading"
        );
        if (loadContainer) {
          loadContainer.style.cssText = "display: block;";
        }
        setTimeout(() => {
          this.$router.push(item.path);
        }, 0);
      }
      // 微应用
      if (item.apptag && item.funtag) {
        AppService.getInstance().noticeMicroApp({
          tag: item.apptag,
          action: "MENUCLICK",
          data: item
        });
      } else {
        //基层应用
        setTimeout(() => {
          const tempRoute = {};
          Object.assign(tempRoute, {
            fullPath: this.$route.fullPath,
            menuUrl: item.menuUrl,
            ...this.$route.meta
          });
          shared.addPage(tempRoute);

        }, 0);
      }
      // 将点击数据存在字段里初始化面包屑数据得时候用
      this.activeMenuItem = JSON.parse(JSON.stringify(item));
    }
  }

  /**
  * 模糊搜索点击 
  * @param item 
  */
  public searchItemSelect(item: IfuzzrySerach) {
    const index  = this.menus.findIndex((menu:any) => menu.system === item.system)
    this.service.menusLoad(this.menus[index]).then((res:any) => {
        const isContains = this.isContains(item.key,res)
        if(isContains.isContains){
          this.changeMenu(isContains.item)
        }
    })
  }

  /**
  * 检查模糊搜索传入数据是否包含当前子系统内,如果包含返回该对象 
  */
  public isContains(
    key:string,
    arr:Array<any>,
    contains:{isContains:boolean,item:any} = {isContains:false,item:[]}
  ){
    arr.forEach((item:any) => {
      if(item.key === key){
        contains.isContains = true
        contains.item = item
      }
      if(item.children){
        contains = this.isContains(key,item.children,contains)
      }
    })
    return contains
  }

  /**
  * 面包屑初始化
  */
  public breadInit(route: any) {
    // 路由只有有数据的时候才往下进行，路由数据会延迟，需要确认是完整数据eg: #/frontpage/baseusers/views/gridview?srfmenuname%3Drandd-menuitem2%3Bsrfmenubaropen%3Dtrue
    // 取出路径方便重组面包屑数据
    let menuUrl = (this.activeMenuItem?.menuUrl || '').split('->');
    // 获取存储得点击所有数据
    let data: any = shared.getActiveCrumbPathAll() ? shared.getActiveCrumbPathAll() : JSON.parse(sessionStorage.getItem('breadcrumbListCopy') || '');

    // 定义新对象，将选中得数据给新对象
    let breadcrumbObj = this.activeMenuItem;
    // 定义新数组，存放选中得面包屑数据
    let activeMenuPath: any = [];
    // 当前选中得数据是新数据才能push

    // 重组面包屑数据
    (menuUrl || []).map((items: any) => {
      let Object = {
        title: items,
        key: this.uuid(),//将每一级得key给到面包屑
        menuUrl: this.activeMenuItem.menuUrl,
        breadpath: this.activeMenuItem.apptag + "__" + route.substr(1)
      }
      activeMenuPath.push(Object)
    })
    // 重组之后得数据是一个对象里加数组
    breadcrumbObj.fullPathChildren = activeMenuPath;
    //这里要和点击页签得路经一致
    breadcrumbObj.breadpath = route.substr(1)
    //判断是否是新数据，是新数据
    if (!JSON.stringify(data).includes(this.activeMenuItem.menuUrl || '')) {
      // 新数据直接存储
      data.push(breadcrumbObj)
      // 存储面包屑所有点击的数据
      shared.setActiveCrumbPathAll(data)
      // 存储面包屑
      shared.setActiveCrumbPath(activeMenuPath)
    } else if (JSON.stringify(data).includes(this.activeMenuItem.menuUrl || '')) {
      // 切换页签，不是新数据，只替换展示得面包屑，不存储本地
      if (this.activeMenuItem.menuUrl) {
        // 存储面包屑
        shared.setActiveCrumbPath(activeMenuPath)
      }

    }

    // 清空选中项，否则影响页签得切换功能
    this.activeMenuItem = {}
  }

  public uuid() {
    return Math.random().toString(36).substring(3, 15)
  }
  
  created() {
    Vue.prototype.$baseMenu = this;
    eventBus.$on('customMenuMessage', this.myEventHandler);
    eventBus.$on('resetMenu', this.resetMenu);
    this.service.rootMenusLoad().then((res:any) => {
      this.menus = AppService.getInstance().initMenuUrl(res,'基座')
      this.loadingService.closeLoading()
      // 菜单数据异步原因,获取菜单数据后执行一次激活项处理
      this.computeMenukeys(this.$route.fullPath)
    })
  }

  /**
  * 重置菜单数据 
  */
  resetMenu(){
    this.service.clearMenuCache()
    this.service.rootMenusLoad().then((res:any) => {
      this.menus = AppService.getInstance().initMenuUrl(res,'基座')
      this.loadingService.closeLoading()
      // 菜单数据异步原因,获取菜单数据后执行一次激活项处理
      this.computeMenukeys(this.$route.fullPath)
    })
  }

  // 自定义菜单触发基座，基座触发eventBus，在这里执行切换菜单方法
  myEventHandler(data:any) {
    // 执行相应的函数
    this.changeMenu(data)
  }

  setI18nLocale(locale:any){
    this.$i18n.locale = locale
  }

}
