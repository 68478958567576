



































































































































































































import { Vue, Component, Mixins, Watch, Prop } from "vue-property-decorator";
import draggable from "vuedraggable";
import { OpenMenu } from "../mixin/open-menu";
import { ApiService } from "@/api";
// import Sortable from "sortablejs"; //插件引入
import { Message, MessageBox } from "element-ui";
import { AppConfig } from "@/config/config";
import { eventBus } from "@/service/micro-action-service";
interface MenuItem {
  caption: string;
  resourceId: string;
  [key: string]: string;
}

//自定义菜单递归方法
const customMenu = {
template: `
    <div class="view"> 
      <draggable v-model="menuInfo.childrenGroups" :group="{name:menuInfo.id, pull:false, put:false}" @start="$myCommonDialog.drag = true" @end="$myCommonDialog.drag = false" class="buttondraggable"
        @input="$myCommonDialog.handleListChange($event, 'group', menuInfo)">
          <div class="wrapper" style="margin-right:10px"
            :class="{ 'active': myChooseTabsId === item.groupMenuId , 
            'lostactive': item.chooseChildId === item.groupMenuId}"
            v-for="item in menuInfo.childrenGroups" :key="item.id"
            @click="$myCommonDialog.showTabsInfo(item, menuInfo)">
            <div :class="{'houseTitle': true,'grey': item.grey}"><i :class="item.iconClass"></i> {{ $myCommonDialog.getLocaleCaption(item, 'group') }}</div>
          </div>
      </draggable>

      <div class="box checked" v-if="menuInfo.menus && ( menuInfo.level === childListlength )" >
        <draggable class="draggable" chosenClass="chosen" v-model="menuInfo.menus" :group="{name:'people', pull:false, put:true}" @start="$myCommonDialog.drag = true" @end="$myCommonDialog.drag = false"
        @input="$myCommonDialog.handleListChange($event, 'child', menuInfo)">
            <div class="checked-item" v-for="res in menuInfo.menus" :key="res.menuIdNew" @click="$myCommonDialog.editSelected(res)"
              :class="{ 'activeChild': res.menuIdNew === applicationSelectID }">
              <el-tooltip effect="dark" :content="$t('baseHome.common.remove2')" placement="top"
                @click.native.stop="$myCommonDialog.removeSelected(res.group ? res.id : res.menuIdNew, res.group ? 'g' : 'm')">
                <i class="el-icon-remove"></i>
              </el-tooltip>
              <i v-if="res.iconClass" :class="res.iconClass" :style="{ color: res.color }"></i>
              <img v-else-if="res.group" :class="{ 'grey': res.grey }" src="/portal/img/icon-group.svg" alt />
              <img v-else :class="{ 'grey': res.unauthFlag === '1' }" :src="res.src || '/portal/img/icon-import.svg'" alt />
              <p v-if="res.group" 
                :class="{ 'grey': res.grey }" 
                :title="$myCommonDialog.getLocaleCaption(res, 'group')">
                {{ $myCommonDialog.getLocaleCaption(res, 'group') }}
              </p>
              <p v-else 
                :class="{ 'grey': res.unauthFlag === '1' && !res.menuId.includes('OtherCustom') }" 
                :title="$myCommonDialog.getLocaleCaption(res, 'menus')">
                {{ $myCommonDialog.getLocaleCaption(res, 'menus') }}
              </p>
            </div>
        </draggable>
      </div>
    </div>
  `,
name: 'customMenu',
props : {
  menuInfo: {//整个item
    type: Object,
    default: () => ({}),
  },
  myChooseTabsId: {//当前选中group菜单
    type: String,
    default: () => (''),
  },
  applicationSelectID: {
    type: String,
    default: () => (''),
  },
  isOnintTable: {
    type: String,
    default: () => (''),
  },
  myChooseTabschildId: {//当前选中得子应用菜单
    type: String,
    default: () => (''),
  },
  childListlength: {//展示层级得数组长度，用来判断只展示最后一级得子应用
    type: Number,
    default: () => (1),
  },
},

components: {
  draggable, //引入拖拽组件
}

};



@Component({
components: {
  draggable,
  'custom-menu': customMenu
}
})

export default class AppCustomTheme extends Mixins(OpenMenu) {
  /**
   * 是否显示弹窗
   *
   * @type {boolean}
   * @memberof AppCustomTheme
   */
  public dialogVisible: boolean = false;
  public myFavorite: any = [];
  public isUseMymenu: boolean = false;
  /*是否开启首页*/
  public isOpenIndex: boolean = false;
  public myChooseTabsId: any = "";
  public editableTabs: any = [];
  public isOnintTable: any = "";
  public childList: any = [];//用于递归菜单展
  public myChooseTabschildId: any = '';
  public formData: any = {//编辑弹窗
      newTitle: "",//编辑弹窗得值
      newTitleEn: "",//编辑弹窗得值(英文)
      externalLink:"",//编辑弹窗外链地址
      caption: "",//默认值
      caption2: "",//默认值
      iconClass: "",//一级菜单编辑弹窗得icon
  };
  public isSubapplication: any = false; //是否是子应用，用于显示按钮
  public isShowChild: any = false; //二级菜单不显示icon输入框
  public applicationMenu:any = [];//保存我的菜单左侧数据
  public searchAllValue = "";//检索栏过滤
  public buckUpMenus: any = [];//备份菜单
  public selectId: any = '';//用于递归菜单-标记当前选中得group或者menus得id值
  public childListlength: Number = 1;//选中1级数据，清空
  public groupA:any = {
      // put: true, //可以拖入
      // pull: false
  };
  public  groupB:any = {
      name: "itxst",
      pull: "clone", //B组拖拽时克隆到A组
      put: false,
  };

  /*菜单保存按钮*/
  public primaryLoading:any = false;

  public exportValue:any = ''; //导出菜单
  public exportOptions:any = [//导出菜单分为导出原生菜单和导出我的菜单
      {
      value: this.$t("baseHome.exportMenu"),
      label: this.$t("baseHome.exportMenu")
      }, {
      value: this.$t("baseHome.exportMyMenu"),
      label: this.$t("baseHome.exportMyMenu")
      }
  ]
  /*拖拽start和end的drag参数*/
  public drag:Boolean = false;
  /*menus下的组点击的时候不能编辑*/
  public noEdit:Boolean = true;
  /*基座可点击，独立应用不可点击*/
  public buttonDisabled = true;
  /*点击查询*/
  public centerDialogVisible = false;
  public mountedSystems = [
      "vat",
      "vatrpt",
      "eit",
      "mis",
      "atf",
      "tda",
      "tra",
      "retom",
      "rnd",
      "evr",
      "ntpf",
      "efiling",
      "base",
      "dm",
      "ovatfp",
      "otp"
  ];
  public isExternalLink:any = false;

  created() {
    // 实现面包屑点击功能，这里将整个vue给实例化
    Vue.prototype.$myCommonDialog = this;
  }

  /**
   * 关闭弹窗重新打开需要重新获取数据
   */
  @Prop({ default: false }) public isClose!: boolean;
  @Watch("isClose", { immediate: true })
  isCloseWatch() {
      if (this.isClose) {
      Promise.all([this.getMyMenusGroup()]).then(
          (arr: any) => {
          (arr[0] || []).forEach((item: any) => {
              if (item.resourceId in this.allChildrenMenuObj) {
              this.addSelected(this.allChildrenMenuObj[item.resourceId], false);
              }
          });
          }
      );
      }
  }
  
  // 监控弹窗，每次打开弹窗都需要重新获取allgroup接口以更新最新数据
  @Watch("dialogVisible", { immediate: true })
  isDialogVisible() {
      if (this.dialogVisible) {
            this.getMyMenusGroup()
            /**
             * 是否开启我的菜单
             */
            ApiService.getInstance().getisOpenMymenus().then((res: any) => {
                if (res.data.result == 1) {
                this.isUseMymenu = true;
                return;
                }
                if (res.data.result == 0) {
                this.isUseMymenu = false;
                return;
                }
            })
            this.getAllmenus()
            this.getOpenhomepagelink()
      }
  }

  /**
   * 获取group菜单
   */
  async getMyMenusGroup() {
    ApiService.getInstance()
      .getMyMenuAllGroup().then((res: any) => {
            // 将一级菜单项合进一个json中并排序，方便渲染
            if (res.data.result.menus && res.data.result.menus.length > 0) {
                res.data.result.menus.forEach((item: any) => {
                    item.orderValue = item.pos
                });
                res.data.result.groups = res.data.result.groups.concat(res.data.result.menus)
                res.data.result.groups.sort((a:any, b:any) => a.orderValue - b.orderValue);
                this.editableTabs = res.data.result.groups
            } else {
                this.editableTabs = res.data.result.groups
                /**
                 * 无一级菜单项并且关闭默认菜单
                 */
                this.saveDisabledStatus(false)
            }
          this.isOnintTable = this.editableTabs && this.editableTabs.length > 0 ? this.editableTabs[0].groupMenuId : '';
          this.myChooseTabsId = this.editableTabs && this.editableTabs.length > 0 ? this.editableTabs[0].groupMenuId : '';
          (this.editableTabs || []).forEach((resnew: any) => {
            resnew.menus?.forEach((item: any) => {
                const mySelect: any = {};
                mySelect.resourceId = item.menuId + this.uuid();
                item.menuIdNew = item.menuId + this.uuid();//用于菜单唯一值
                this.myFavorite.push(mySelect);
            });

            // 新增level和id
            resnew.level = 1;
            resnew.id = this.uuid();
            // 当前级下如果有一个有权限的菜单项，那么当前组就不用置灰
            let string = JSON.stringify(resnew);

            //|| resnew.menuId这里临时加一级菜单得菜单项权限，后面接口返回数据中返回unauthFlag，可以用unauthFlag判断
            if (string.indexOf('"unauthFlag":"0"') > -1 || resnew.menuId) { 
                resnew.grey = false;
            } else {
                resnew.grey = true;
            }
            if (!resnew.menuId) {this.cyclicLoop(resnew, 1);}
          });
          //默认将第一组数据展示在页面上
          if (this.editableTabs && this.editableTabs.length > 0) {
          this.childList.push(this.editableTabs[0]);
          // 下面是默认数据，解决第一次打开我的菜单弹窗并点击<编辑>提示未选择数据
          this.showTabsInfo(this.editableTabs[0], {});
          }
      });
      return Promise.resolve(this.myFavorite);
  }

  /**
   * 递归---给我的菜单添加层级和id，作为唯一标识
   * level，id
   */
  cyclicLoop(res:any, number:any) {
      // 当前级下如果有一个有权限的菜单项，那么当前组就不用置灰
      let string = JSON.stringify(res);
      if (string.indexOf('"unauthFlag":"0"') > -1) {
        res.grey = false;
      } else {
        res.grey = true;
      }
      if (res.childrenGroups) {//自定义菜单
        res.childrenGroups.map((item: any) => {
            item.level = number + 1;
            item.id = this.uuid();
            item.group = true;//是否是group，只要用于区分组和项，后面需要将组过滤出来
            let stringChild = JSON.stringify(item);
            if (stringChild.indexOf('"unauthFlag":"0"') > -1) {
            item.grey = false;
            } else {
            item.grey = true;
            }
            this.cyclicLoop(item, number + 1);
        })
      }
      if (res.menus) {//子应用菜单
        res.menus.map((item: any) => {
            item.level = number + 1;
            item.id = this.uuid();
            item.orderValue = Number(item.pos);
            item.menuIdNew = item.menuId + this.uuid();//用于菜单唯一值
            this.cyclicLoop(item, number + 1);
            if (item.unauthFlag === '0') {
                res.grey = false;
            }

        })
        //将组数组在菜单中显示，排序完再赋值给组
        if (res.menus?.length > 0 || res.childrenGroups?.length > 0) {
            res.menus = res.childrenGroups ? res.menus.concat(res.childrenGroups) : res.menus;
        }
        //group自定义菜单需要根据导入excel中排序值进行排序
        // 按照排序字段对JSON数组进行排序
        res.menus.sort((a:any, b:any) => a.orderValue - b.orderValue);

      }
  }

  /**
   * js生成随即字符串，用于赋值给id
   */
  public uuid() {
      return Math.random().toString(36).substring(3, 15)
  }

  /**
   * 设置我的菜单
   */
  async setMunesGroup() {
      /*菜单保存按钮*/
      this.primaryLoading = true;
      let allValue:any = {
        menus: [],
        groups: []
      };
      (this.editableTabs || []).forEach((res: any, index:any) => {
        this.$set(res, 'orderValue', index)
        delete res.grey;
        delete res.pos;
        if (res.new) {
          delete res.new;  
          delete res.baseMenuFavoriteId;  
        }
        this.saveLoop(res);
      })
      setTimeout(() => {
            this.editableTabs.forEach((res: any,index: any) => {
                if (res.apptag) {
                    res.pos = index
                    allValue.menus.push(res)
                } else {
                    allValue.groups.push(res)
                }
            })
            console.log(allValue,'最终数据');
      ApiService.getInstance()
      .postMymenuGroup(allValue ).then((res: any) => {
          Message({
          message: `${res.data.messageInfo}保存我的菜单`,
          type: "success"
          });
        //   清空菜单缓存，更新最新数据
          sessionStorage.removeItem('isFirst')
          sessionStorage.removeItem('customMenu')
          /*菜单保存按钮*/
          this.primaryLoading = false;
          this.close();
          //点击确认菜单数据更新，页面暂未更新，增加刷新页面解决
          this.$forceUpdate();
          this.$router.go(0);
      }).catch((err: any) => {
          /*菜单保存按钮*/
          this.primaryLoading = false;
          Message.error((err as any)?.response?.data?.message);
      });
    }, 1000);

  }

  /**
   * 递归---保存前需要遍历数据并排序
   * 
   */
  
  saveLoop(res:any) {
      //菜单项
      if (res.menus) {
        res.menus.forEach((item: any, index: any) => {
            item.pos = index;
            item.iconclass = item.iconClass;
            delete item.grey;
            delete item.orderValue;
            // this.saveLoop(item);
        });
      }
      //菜单组
      if (res.childrenGroups) {
      // 之前是直接赋值，这里更改为从菜单项中过滤出组然后赋值给组，将混排中得组给组
      if (res.menus.length > 0 && res.childrenGroups.length > 0) {
          let filteredData = res.menus.filter((item:any) => item.group === true);
          Vue.set(res, 'childrenGroups', filteredData);
          res.childrenGroups.map((item: any) => {
            item.orderValue = item.pos;
            delete item.grey;
            delete item.pos;
          })
          res.childrenGroups.map((item: any) => {
            this.saveLoop(item);
          })
      } else {
          res.childrenGroups.map((item: any,index:any) => {
            item.orderValue = index;
            delete item.grey;
            this.saveLoop(item);
          })
      }
      res.menus = res.menus.filter((item:any) => !item.group);
      }
  }


  /**
   * 获取菜单
   */
  async getAllmenus() {
      this.allChildrenMenu = [];
      this.menuTabsList = [];
      this.menuTabsObject = {};
      return ApiService.getInstance().getMenus().then((res: any) => {
          let { status, data } = res;
          if (status == 200 && data) {
          // 获取整体菜单数据
          let newarr = data.filter((module: any) =>
              this.mountedSystems.includes(module.system)
          )
          let params = {
                system: "Other",
                app: "Other",
                appName: "其他",
                captionLocale: "其他",
                caption: "其他",
                tag: "OtherView",
                unauthFlag:"0",
                menuItems: [
                    {
                        caption: "其他",
                        caption2: "Other",
                        caption3: "其他",
                        captionLocale: "其他",
                        hidden: false,
                        tag: "OtherCustom",
                        funcTag: "OtherCustom",
                        resourceId: "OtherCustomGroup",
                        unauthFlag:"0",
                        children: [{
                            apptag: "other",
                            caption: "外链",
                            caption2: "External Links",
                            caption3: "外链",
                            captionLocale: "外链",
                            menuId: "OtherCustom",
                            myCaption: "外链",
                            myCaptionEn: "External Links",
                            myCaptionLocale: "外链",
                            hidden: false,
                            tag: "OtherCustom",
                            funcTag: "OtherCustom",
                            resourceId: "OtherCustom",
                            iconClass: "fa fa-external-link",
                            level: 1,
                            unauthFlag:"0",
                        }]
                    }
                ],
                
            }
          this.applicationMenu = newarr;
          this.applicationMenu.push(params);
          (data || [])
              .filter((module: any) =>
              this.mountedSystems.includes(module.system)
              )
              .forEach((module: any) => {
                  Object.assign(module, {
                      menuUrl: `${module.captionLocale}`,
                  });

              if (module.menuItems?.length) {
                  module.menuItems.forEach((first: any) => {
                  if (first.hidden || !first.children?.length) {
                      // 隐藏的菜单不计算
                      if (first.hidden) {
                      return;
                      }
                      // 没页面的一级菜单不计算
                      // 有页面的一级菜单左左侧tab名与右侧菜单名使用同一个名字
                      if (!first.children?.length) {
                      if (first.resourceId) {
                          let data = {
                            apptag: module.app,
                            path: module.system,
                            type: module.captionLocale,
                            menuUrl: `${module.captionLocale} -> ${first.captionLocale}`,
                          };
                          const children = [
                          {
                              ...first,
                              ...data
                          }
                          ];
                          this.menuTabsList.push({
                            caption: first.captionLocale,
                            resourceId: first.resourceId
                          });
                          this.menuTabsObject[first.resourceId] = {
                            ...first,
                            ...data,
                            children
                          };
                          this.allChildrenMenu.push(
                            ...this.formattingGetPSAppMenuItems(children)
                          );
                      }
                      }
                      return;
                  }
                  this.menuTabsList.push({
                      caption: first.captionLocale,
                      resourceId: first.resourceId,
                  });
                  this.menuTabsObject[first.resourceId] = first;
                  Object.assign(first, {
                      menuUrl: `${module.captionLocale} -> ${first.captionLocale}`,
                  });
                  first.children.forEach((second: any) => {
                      second.apptag = module.app;
                      second.path = module.system;
                      second.type = module.captionLocale;
                      Object.assign(second, {
                          menuUrl: `${first.menuUrl} -> ${second.captionLocale}`,
                      });
                      if (second.children?.length) {
                      second.children.forEach((third: any) => {
                          third.apptag = module.app;
                          third.path = module.system;
                          third.type = module.captionLocale;
                          third.menuUrl = `${second.menuUrl} -> ${third.captionLocale}`;
                          if (third.children?.length) {
                          third.children.forEach((fourth: any) => {
                              fourth.apptag = module.app;
                              fourth.path = module.system;
                              fourth.type = module.captionLocale;
                              fourth.menuUrl = `${third.menuUrl} -> ${fourth.captionLocale}`;
                          });
                          }
                      });
                      }
                  });
                  this.allChildrenMenu.push(
                      ...this.formattingGetPSAppMenuItems(first.children)
                  );
                  });
              }
              });

          try {
              this.menuTabsChange(this.menuTabsList[0]);
          } catch (e) {
              this.menuTabsAct = "";
              this.menuList = [];
          }
          return data.result;
          }
      });
  }

  /**
   * 所有子菜单
   */
  allChildrenMenu: any[] = [];
  /**
   * 获取group数据的所有子菜单
   */
  get allChildrenMenuObj() {
      let obj: any = {};
      this.allChildrenMenu.forEach((item: any) => {
      if (item.resourceId) obj[item.resourceId] = { ...item };
      });
      return obj;
  }

  /**
   * 选中菜单
   */
  public showTabsInfo(editable: any, menuInfo:any) {
      this.noEdit = true;
      if (editable.level === 1) {
        this.isShowChild = true;//一级菜单显示icon
      } else {
        this.isShowChild = false;//其他级菜单不显示icon
      }
      if (editable.menuId && editable.menuId.includes("OtherCustom")) {
        this.isExternalLink = true;//只有外链得菜单项展示对应input
      } else {
        this.isExternalLink = false;//只有外链得菜单项展示对应input
      }
      this.isSubapplication = false;//显示编辑子应用按钮
      this.selectId = editable.id;//选中得id
      this.formData.newTitle = editable.groupMenuName? editable.groupMenuName : editable.myCaption ? editable.myCaption :editable.caption;//修改title时得数据
      this.formData.newTitleEn = editable.groupMenuNameEn? editable.groupMenuNameEn : editable.myCaptionEn ? editable.myCaptionEn : editable.caption2;//修改title时得数据
      this.formData.externalLink = editable.externalLink;//修改外链地址得数据
      this.formData.iconClass = editable.iconClass;
      this.isOnintTable = editable.groupMenuId ? editable.groupMenuId : editable.baseMenuFavoriteId;
      this.level = editable.level;//层级
      this.myChooseTabsId = editable.groupMenuId ? editable.groupMenuId : editable.baseMenuFavoriteId;//选中得数据id，用于标记路径
      if (editable.level === 2) {//选中2级数据id，用于一级菜单标记路径
        this.myChooseTabschildId = menuInfo.groupMenuId;
      }
      if (editable.level === 1) {//选中1级数据，清空
        this.myChooseTabschildId = '';
      }
      // 更新菜单数据和active、lostactive选中状态
      this.updataChildData(editable, menuInfo);
  }
  
  /**
   * 更新childList菜单数据和active、lostactive选中状态
   * 功能描述，只需要保留最后一级得子应用数据，这里如果点击得是3级数据，那么一级和二级得menus数据不需要展示
   */
  updataChildData(item:any, menuInfo:any ) {
      // 将选中之后得数据删除，重新push数据
      this.childList = this.childList.slice(0, item.level - 1 );
      
      if (item.childrenGroups || item.menus) {
      // 清空选中数据得下级chooseChildId标记，防止下级应用在没有点击得时候加上lostactive样式
      item.chooseChildId = ''

      //新增或者点击其他页签得时候需要从this.editableTabs取数据，因为所有的数据更改都会处理this.editableTabs，而this.childList仅用于页面展示
      this.createChildLoop(this.editableTabs );
      }
      // 主要实现将所有层级得路径用lostactive标记功能，需要去上一级得childrenGroups找到对应得数据并赋值，更新json
      // 超过两层用这个方法，第一级得数据是固定得，所以需要额外处理
      if (item.level > 2 && menuInfo) {
      let length = this.childList.length;
      this.childList[length -3].childrenGroups.forEach((res:any) =>{
          // 点击得数据在上一级里找到并标记，用于标记路径
          if (res.groupMenuId === menuInfo.groupMenuId) {
            Vue.set(res, 'chooseChildId', menuInfo.groupMenuId);
          }
      })
      }

      // 点击当前级别，避免当前级别得active和lostactive同时出现
      if (item.level > 1 && menuInfo) {
      (this.childList[item.level -2].childrenGroups || []).forEach((res:any) =>{
          res.chooseChildId = ''
      })
      }
      //拷贝，防止数据无法实时更新
      this.childList = JSON.parse(JSON.stringify(this.childList));

      // 记录最新数据得长度，用于控制显示最后一个层级得menus应用
      this.childListlength = this.childList.length;
  }
  
  // 递归this.editableTabs，新建多级菜单未保存时，切换页签从editableTabs取数据
  createChildLoop(arr:any) {
      (arr || []).find((item: any) => {
      if (item.id === this.selectId ){
          this.childList.push(item);
          return
      } else {
          this.createChildLoop(item.childrenGroups)
      }
      })
  }

  /**
   * 《增加子菜单》 ---- 按钮
   */
  public addChildTab(): void {
      this.addChildLoop(this.editableTabs );
  }
  /**
   * 《增加子菜单》---递归editableTabs并赋值
   */
  addChildLoop(arr:any) {
      (arr || []).find((item: any) => {
      if (item.id === this.selectId ){
          let childId: any = Math.random() * 100000;
            childId = Math.ceil(childId);
          if (!Array.isArray(item.childrenGroups)) {
            item.childrenGroups = []
          }
          const newGroupTable = {
            id: this.uuid(),
            groupMenuId: String(childId),
            childGroupMenuId: childId,
            groupMenuName: "新建组",
            groupMenuNameEn: "newGroupTable",
            menus: [],
            level: item.level + 1,//判断层级
            group: true
          }
          // Array.isArray(item.childrenGroups) ? item.menus : 
          item.childrenGroups.push(newGroupTable)
          item.menus.push(newGroupTable)
          // 更新childList菜单数据
          this.updataChildData(item, {});
          this.showTabsInfo(newGroupTable, item)
          return
      } else if (item.childrenGroups){
          this.addChildLoop(item.childrenGroups )
      }
      })
  }

  /**
   * 新增一级菜单
   */
  public addTab(): void {
      let number: any = Math.random() * 100000;
      number = Math.ceil(number);
      if (!this.editableTabs) {
        this.editableTabs = [];
      }
      this.formData.newTitle = "新建组";
      this.formData.newTitleEn = "newGroup";
      const newEditableTabs = {
        id: this.uuid(),
        groupMenuId: String(number),
        groupMenuName: "新建组",
        groupMenuNameEn:"newGroup",
        menus: [],
        level: 1,//判断层级
        group: true
      };
      this.editableTabs.push(newEditableTabs);
      this.showTabsInfo(newEditableTabs, {})
  }

  /**
   * 编辑自定义菜单
   */
  public editTab(): void {
      if (this.myChooseTabsId === "") {
        Message({ message: "请选择一个标签栏", type: "warning" });
        return;
      }
      // 默认值得时候清空让用户重命名
      if (this.formData.newTitle === "newGroup" || this.formData.newTitle === "newGroupTable") {
        this.formData.newTitle = ""
      }
      if (this.formData.newTitleEn === "newGroup" || this.formData.newTitleEn === "newGroupTable") {
        this.formData.newTitleEn = ""
      }
      this.editDialogVisible = true;
  }

  /**
   * 我的菜单开关
   */
  public isUseMymenuChange(): void {
      let openmymenuflag: number;
      if (this.isUseMymenu) {
        openmymenuflag = 1;
      } else {
        openmymenuflag = 0;
      }
      ApiService.getInstance().isOpenMyMenus(openmymenuflag).then((res: any) => {
            Message({ message: res.data.messageInfo, type: "success" });
            if (this.isUseMymenu) {
                // 开启我的菜单同时会开启默认首页
                this.isOpenIndex = true
                this.isOpenIndexChange()
            } else {
                window.location.reload();
            }
      });
  }

  /**
   * 首页开关
   */
   public isOpenIndexChange(): void {
      let isOpenIndex: number;
      if (this.isOpenIndex) {
        isOpenIndex = 1;
      } else {
        isOpenIndex = 0;
      }
      ApiService.getInstance().saveHomepagelink(isOpenIndex).then((res: any) => {
          Message({ message: res.data.messageInfo, type: "success" });
          window.location.reload();
      });
  }

 /**
   * 获取首页开关值
   */
   public getOpenhomepagelink(): void {
      ApiService.getInstance().getOpenhomepagelink().then((res: any) => {
          this.isOpenIndex = res.data.result === 1 ? true : false;
      });
   }

    /**
   * 根据条件disabled 提交按钮
   */
    public saveDisabledStatus(value:any) {
        // 当前关闭默认首页
        if (!this.isOpenIndex) {
            // 没有配置一级菜单项
            if (!value) {
                this.primaryLoading = true
            } else {
                this.primaryLoading = false
            }
        } else {
            this.primaryLoading = false
        }
        
        
    }
  

  // 判断是一级应用还是二级应用
  public level: any = 0;
  /**
   * 提交编辑页栏
   */
  public confirmNewTitle() {
      if (this.myChooseTabsId || this.isSubapplication ) {
      (this.editableTabs || []).forEach((res: any) => {
          if (res.id === this.selectId || res.groupMenuId === this.applicationSelectID || res.childGroupMenuId === this.applicationSelectID) { 
            // 编辑得是第一级得菜单项
            if (res.level === 1 && res.baseMenuFavoriteId) {
                Vue.set(res, 'myCaption', this.formData.newTitle ? this.formData.newTitle : this.formData.caption);
                Vue.set(res, 'myCaptionEn', this.formData.newTitleEn ? this.formData.newTitleEn : this.formData.caption2);
            }  else {
                Vue.set(res, 'groupMenuName', this.formData.newTitle);
                Vue.set(res, 'groupMenuNameEn', this.formData.newTitleEn);
            }
            Vue.set(res, 'externalLink', this.formData.externalLink);
            Vue.set(res, 'iconClass', this.formData.iconClass);
            Vue.set(res, 'iconclass', this.formData.iconClass);
            this.editDialogVisible = false;
          } else {
          this.editTitleLoop(res);
          }
      })
      // 更新菜单页面展示部分
      this.updataeditTitle();
      }
  }

  /**
   * 《编辑》-> 《确定》递归数据并赋值，更新editableTabs
   */
  editTitleLoop(res:any){
      if (res.childrenGroups) {//自定义菜单
      res.childrenGroups.map((item: any) => {
          if (item.id === this.selectId) {
          Vue.set(item, 'groupMenuName', this.formData.newTitle);
          Vue.set(item, 'groupMenuNameEn', this.formData.newTitleEn);
          Vue.set(item, 'externalLink', this.formData.externalLink); 
          this.editDialogVisible = false;
          return;
          } else {
          this.editTitleLoop(item);
          }
      })
      }
      if (res.menus) {//子应用菜单
      res.menus.map((item: any) => {
          if (item.menuIdNew === this.selectId) {
              Vue.set(item, 'myCaption', this.formData.newTitle ? this.formData.newTitle : this.formData.caption);
              Vue.set(item, 'myCaptionEn', this.formData.newTitleEn ? this.formData.newTitleEn : this.formData.caption2);
              Vue.set(item, 'externalLink', this.formData.externalLink); 
              this.editDialogVisible = false;
          } else {
              this.editTitleLoop(item);
          }
      })
      }
  }

  /**
   * 《编辑》-> 《确定》递归数据更新childList数据
   */
  updataeditTitle(){
      (this.childList || []).forEach((item:any) => {
          if (item.childrenGroups) {
          item.childrenGroups.map((res:any) =>{
              if (res.id === this.selectId ) {
              Vue.set(res, 'groupMenuName', this.formData.newTitle);
              Vue.set(res, 'groupMenuNameEn', this.formData.newTitleEn);
              Vue.set(res, 'externalLink', this.formData.externalLink); 
              if (res.level === 1) {
                  Vue.set(res, 'iconClass', this.formData.iconClass);
                  Vue.set(res, 'iconclass', this.formData.iconClass);
              }
              return
              }
          })
          }
          if (item.menus) {
          item.menus.map((res:any) =>{
              if (res.menuIdNew === this.selectId ) {
              Vue.set(res, 'myCaption', this.formData.newTitle ? this.formData.newTitle : this.formData.caption);
              Vue.set(res, 'myCaptionEn', this.formData.newTitleEn ? this.formData.newTitleEn : this.formData.caption2);
              Vue.set(res, 'externalLink', this.formData.externalLink); 
              return
              }
          })
          }
      });
  }


  public deleteAfterGetMenus() {
    ApiService.getInstance().getMyMenuAllGroup()
  }
  
  /**
   * 删除页签
   */
  public deleteMenusInfo() {
      const parmars = {
        ids: this.myChooseTabsId
      };
      ApiService.getInstance().deleteMyMenus(parmars).then((res: any) => {
          console.log(res)
          this.$router.go(0);
      });
  }
  
  /**
   * 拖拽排序，三种情况
   * 1、拖拽一级自定义菜单---'father'
   * 2、拖拽二级及以后得自定义菜单和应用菜单---'child'
   * 3、从全部应用里拖拽到我的菜单里---'drag'
   */
  public handleListChange(e: any, type: any, menuInfo:any) {
      if (this.myChooseTabsId) {
      if (type === 'father') {
          let string = JSON.stringify(this.editableTabs);
          if (string.indexOf(this.myChooseTabsId) > -1) {
            this.editableTabs = JSON.parse(JSON.stringify(e));
          }
      } else if (type === 'child' || type === 'group') {
          this.childLoop(this.editableTabs, menuInfo, type);
          this.childLoop(this.childList, menuInfo, type);
      } else if (type === 'drag') {
          (this.editableTabs || []).forEach((res: any) => {
            this.dragLoop(res, JSON.parse(JSON.stringify(e)) );

          //过滤
          // return res.menus = this.removeArr(res.menus);
          })
      }
      }
  }

  /**
   * 拖拽二级及以后得自定义菜单和应用菜单--'child'---之后更新editableTabs、childList，递归,双向互动
   */
  childLoop(data:any, menuInfo:any, type:any){
      (data || []).find((res: any) => {
      if (res.groupMenuId === menuInfo.groupMenuId) {
          if (type === 'child') {
            Vue.set(res, 'menus', menuInfo.menus);
            // 之前是直接赋值，这里更改为从菜单项中过滤出组然后赋值给组，将混排中得组给组
            let filteredData = menuInfo.menus.filter((item:any) => item.group === true);
            Vue.set(res, 'childrenGroups', filteredData);
          } else if (type === 'group') {
            //当拖拽组的时候需要更新menus中组的排序
            Vue.set(res, 'childrenGroups', menuInfo.childrenGroups);
            let number = 0;
            let data = JSON.parse(JSON.stringify(res.menus)) 
            data.map((items:any) => {
                if (items.group) {
                Object.assign(items, menuInfo.childrenGroups[number]);
                number ++;
                }
            });
            Vue.set(res, 'menus', data);
          }
      } else {
          this.childLoop(res.childrenGroups, menuInfo, type);
      }
      });
  }

  /**
   * 从全部应用里拖拽到我的菜单里---'drag'---拖拽之后更新editableTabs，递归
   */
  dragLoop(res:any, e:any){
      if (res.groupMenuId === this.myChooseTabsId ) {
        (this.childList || []).forEach((item:any) =>{
            if (item.groupMenuId === this.myChooseTabsId) {
            let data:any = JSON.parse(JSON.stringify(item))
            data.menus.map((child:any) =>{
                if (!child.menuIdNew) {
                    child.menuIdNew = child.menuId + this.uuid()
                }
            })
                Vue.set(item, 'menus', data.menus);             
                Vue.set(res, 'menus', data.menus);
            }
        })
      } else {
        if (res.menuId === "OtherCustom") {
            res.menuId = res.menuId + this.uuid();
            res.baseMenuFavoriteId = res.menuId + this.uuid();
        } 
        if (res.menuId && !res.baseMenuFavoriteId) {
            res.baseMenuFavoriteId = res.menuId + this.uuid();
            res.new = true
        } 
        // 如果当前拖拽得是给得一级菜单项
        if (res.baseMenuFavoriteId) {
           this.saveDisabledStatus(true)
        } 
        (res.childrenGroups || [] ).map((data:any) =>{
            this.dragLoop(data, e);
        })

      }
  }
      
  /**
   * 渲染拖拽菜单数据去重
   */
  public removeArr(arr: any) {
      const res = new Map();
      return arr.filter((arr: any) => !res.has(arr.group) && !res.has(arr.groupMenuId) && res.set(arr.groupMenuId, 1))
  }

  /**
   * 菜单文字较多时看不清楚，点住拉伸的效果解决此问题
   */
  stretchBack(e:any){
      let init = e.clientX;
      let parent:any = document.getElementById("silderLeft");
      let initWidth = parent.offsetWidth;
      document.onmousemove = function(e) {
          let end = e.clientX;
          let newWidth = end - init + initWidth;
          parent.style.width = newWidth + "px";
      };
      document.onmouseup = function() {
      document.onmousemove = document.onmouseup = null;
      };
  }
  /**
   * ---------- tabs操作
   */

  public menuTabsAct = "";
  public menuTabsList: MenuItem[] = [];
  public menuTabsObject: { [key: string]: any } = {};
  /**
   * 全部应用中点击二级展示子应用在右侧视图中
   */
  menuTabsChange(item: MenuItem) {
      this.menuTabsAct = item.resourceId;
      const children = item.resourceId === "OtherCustomGroup" ? item.children :JSON.parse(JSON.stringify(this.menuTabsObject[item.resourceId].children || []));
      this.menuList = item.resourceId === "OtherCustomGroup" ? item.children :this.formattingGetPSAppMenuItems(children);
      let obj: any = {};
      this.menuList.map(item => {
          return {
            label: item.captionLocale,
            color: item.color,
            resourceId: item.resourceId,
            icon: item.iconClass
          };
      }).forEach(item => {
          obj[item.resourceId] = {
            label: item.label,
            color: item.color,
            icon: item.icon
          };
      });
  }

  /**
   * ---------- 菜单操作
   */

  // tabs所选的右侧菜单
  public menuList: any[] = [];

  /**
   * 计算右侧菜单的选中状态
   */
  get menuListComputed() {
      return this.menuList.map(item => {
      return {
          ...item,
          myCaption: item.caption,
          myCaptionEn: item.caption2,
          menuId: item.resourceId,
          groupMenuId:'',
      };
      });
  }

  /**
   * 计算右侧菜单的选中状态 必须要有set  否则报错
   */
  set menuListComputed(res:any) {  
  }

  /**
   * 计算所有子菜单的选中状态
   */
  get allChildrenMenuComputed() {
      return this.allChildrenMenu.map(item => {
      return {
          ...item,
          isChecked: !!this.selectedObj[item.nemenuId]
      };
      });
  }

  public selected: any = [];
  /**
   * 选中菜单的对象形式
   */
  get selectedObj() {
      let obj: any = {};
      this.selected.forEach(
      (item: any, index: number) =>
          (obj[item.resourceId] = { ...item, dttIndex: index })
      );
      return obj;
  }

  /**
   * 添加选中
   */
  addSelected(item: any, type: any) {
      //如果是初始化就不循环，只有手动选中的时候才会循环
      let menuIdNew:any = null;
      let param:any =  JSON.parse(JSON.stringify(item));
      if (this.myChooseTabsId && type && this.myChooseTabschildId == '') {
      (this.editableTabs || []).forEach((res: any) => {
          if (this.myChooseTabsId === res.groupMenuId) {
            menuIdNew = this.uuid();
            param.menuIdNew = param.resourceId + menuIdNew;
            param.menuId = param.resourceId;
            param.myCaption = param.caption;
            param.myCaptionEn = param.caption2;
            res.menus.push(param);
            //更新菜单项数组，使及时更新面板上数据
            this.childList = [res];
          }
      });
      } else if (this.myChooseTabsId && type && this.myChooseTabschildId) {
      (this.childList || []).forEach((res:any) => {
          if (res.id === this.selectId ){
            menuIdNew = this.uuid();
            param.menuId = param.resourceId;
            param.menuIdNew = param.resourceId + menuIdNew;
            param.myCaption = param.caption;
            param.myCaptionEn = param.caption2;
            res.menus.push(param);
            this.addmenusLoop(this.editableTabs, param);
            return
          } 
      });
      }
      if (param.menuIdNew in this.selectedObj) {
      return;
      }
      this.selected.push(param);
  }

      /**
   * 《增加子应用》---递归editableTabs并赋值
   */
  addmenusLoop(arr:any, res:any) {
      (arr || []).find((item: any) => {
      if (item.id === this.selectId ){
          if (!JSON.stringify(item).includes(res.menuIdNew)) {
            item.menus.push(res)
          }
          return
      } else if (item.childrenGroups && item.childrenGroups.length > 0){
          this.addmenusLoop(item.childrenGroups, res )
      }
      })
  }

  /**
   * 删除选中
   */
  removeSelected(resourceId: any, type: any) {
      if (this.myChooseTabsId && this.level == 1) {
      (this.editableTabs || []).forEach((res: any) => {
          if (this.myChooseTabsId === res.groupMenuId) {
            const menuindex = res.menus.findIndex((item: any) => (type === 'm' ? item.menuIdNew : item.id ) === resourceId);
            const groupIndex = res.childrenGroups ? res.childrenGroups.findIndex((item: any) => (item.id ) === resourceId) : -1;
            if (menuindex > -1) res.menus.splice(menuindex, 1);
            if (groupIndex > -1) res.childrenGroups.splice(groupIndex, 1);
          }
      });
      }
      if (this.myChooseTabsId && this.level > 1) {
      (this.editableTabs || []).forEach((res: any) => {
          if (this.myChooseTabsId === res.groupMenuId) {
              const menuindex = res.menus.findIndex((item: any) => (type === 'm' ? item.menuIdNew : item.id ) === resourceId);
              const groupIndex = res.childrenGroups ? res.childrenGroups.findIndex((item: any) => (item.id ) === resourceId) : -1;
              if (menuindex > -1) res.menus.splice(menuindex, 1);
              if (groupIndex > -1) res.childrenGroups.splice(groupIndex, 1);
          } else {
              this.deleteLoop(res, resourceId, type)
          }
      })
      }
      //删除子应用同时需要更新childList数据
      (this.childList || []).forEach((item: any) => {
          if (this.myChooseTabsId === item.groupMenuId) {
            const menuindex = item.menus.findIndex((item: any) => (type === 'm' ? item.menuIdNew : item.id ) === resourceId);
            const groupIndex = item.childrenGroups ? item.childrenGroups.findIndex((data: any) => (data.id ) === resourceId) : -1;
            if (menuindex > -1) item.menus.splice(menuindex, 1);
            if (groupIndex > -1) item.childrenGroups.splice(groupIndex, 1);
          }
      });
      if (resourceId in this.selectedObj) {
      this.selected.splice(this.selectedObj[resourceId].dttIndex, 1);
      }
  }
  
  /**
   * 删除更新editableTabs，递归
   */
  deleteLoop(res:any, resourceId:any, type: any){
      res.childrenGroups?.forEach((data: any) => {
      if (this.myChooseTabsId === data.groupMenuId) {
          const menuindex = data.menus.findIndex((item: any) => (type === 'm' ? item.menuIdNew : item.id  ) === resourceId);
          if (menuindex > -1) data.menus.splice(menuindex, 1);
          if (type === 'g') {
            data.childrenGroups.map((child:any, index: any) =>{
                if (child.id  === resourceId) {
                   data.childrenGroups.splice(index, 1);
                }
            }) 
          }
      } else {
          this.deleteLoop(data, resourceId, type)
      }
      })
  }

  /**
   * 编辑选中
   */
  public applicationSelectID: any = '';//当前选中得id值
  public ChooseApplicationId: any = '';
  editSelected(res: any) {
      if (res.menuId && res.menuId.includes("OtherCustom")) {
        this.isExternalLink = true;//只有外链得菜单项展示对应input
      } else {
        this.isExternalLink = false;//只有外链得菜单项展示对应input
      }
      this.noEdit = res.group ? false : true;
      this.isSubapplication = true;//显示编辑子应用按钮
      this.formData.newTitle = res.myCaption ? res.myCaption : res.caption;//编辑弹窗得值
      this.formData.newTitleEn = res.myCaptionEn? res.myCaptionEn : res.caption2;//编辑弹窗得值(英文)
      this.formData.externalLink = res.externalLink;//编辑弹窗得外链地址
      this.formData.caption = res.caption;//默认值
      this.formData.caption2 = res.caption2;//默认值
      this.selectId = res.menuIdNew; //将选中得数据id
      if (this.isSubapplication) {
        this.ChooseApplicationId = res.menuIdNew;
        this.applicationSelectID = res.menuIdNew;
      } else {
        this.myChooseTabsId = res.menuIdNew;
        this.isOnintTable = "";
        this.myChooseTabschildId = '';
        (this.editableTabs || []).forEach((item: any) => {
            if (item.groupMenuId === this.myChooseTabsId) {
               this.childList = item.childrenGroups          
            }
        })
      }
  }


  /**
   * 点击菜单跳转,通知基座触发
   */
  openMenu(item: MenuItem) {
      let _this: any = this;
      MessageBox.confirm('请先保存已经编辑的菜单', '提示', {
          dangerouslyUseHTMLString:true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          showClose: false,
          center: true,
          customClass: 'penmission-configuration-messagebox'
      }).then(() => {
          //关闭当前弹窗
          this.dialogVisible = false;
          // 全部应用跳转得时候告诉tis首页，背景颜色更改
          eventBus.$emit('tabPaneChange', false);
          // 打开对应子应用
          // 开启我得菜单走自定义菜单逻辑
          if (this.isUseMymenu) { 
            _this.$baseMenu.selfChangeMenu(item)
          } else {
            // 关闭我得菜单走原生菜单逻辑
            this.openMenuMixin({
                apptag: item.apptag,
                funtag: item.funcTag,
                key: `${item.apptag}-${item.tag}`,
                path: "/" + item.path,
                title: item.myCaptionLocale,
                resourceId: item.resourceId,
                iconClass: item?.iconClass ?? 'las la-plus-square',
            });
          }
      }).catch(() => {
          // this.centerDialogVisible = true
      })
  }

  /**
   * ---------- 检索操作
   */
  get restaurants(): any {
      return this.allChildrenMenuComputed.map(item => {
      return {
          ...item,
          type: item.type,
      };
      });
  }

  /**
   * 检索栏过滤方法
   */
  public querySearch(queryString: any, cb: any) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(
      (restaurant: any) =>
          restaurant.captionLocale.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
      )
      : restaurants;
      cb(results);
  }

  /**
   * 关键字检索选中确认
   */
  public handleSelect(item: any) {
      this.addSelected(item, true);
  }

  /**
   * 过滤条件加上标识样式
   */
  public searchValueAddTag(value: string, search: string) {
      if (search) {
      return value.replace(
          new RegExp(search, "g"),
          `<span class="tag">${search}</span>`
      );
      } else {
      return value;
      }
  }


  /**
   * ---------- 编辑弹框弹框操作
   */

  public editDialogVisible = false;
  public handleEidtClose(done: any) {
      console.log(done)
      this.editDialogVisible = false;
  }
  /**
   * 设置弹窗状态
   */
  public setDialogVisible(value: boolean) {
      this.dialogVisible = value;
  }

  /**
   * 获取弹窗状态
   */
  public getDialogVisible() {
      return this.dialogVisible;
  }

  /**
   * 弹窗关闭按钮
   */
  public close() {
      this.dialogVisible = false;
      //关闭弹窗标记为false，下次进来的时候标记为true，用于重新走一遍group接口
      this.$emit("close-watch", false);
      this.editableTabs = this.buckUpMenus;
      this.childList = [];
      this.isClose = false;
  }

  /**
   * 弹窗确认按钮
   */
  async confirm() {
      await this.setMunesGroup();
  }

  /**
   * 导入菜单
   */
  public importMenu() {
      const inputUpLoad = (this.$refs.inputUpLoad as any);
        if (inputUpLoad.value) {
        inputUpLoad.value = ''
      }
      inputUpLoad.click()
  }

  /**
   * 导出菜单
   */
  public exportMenu(value:any) {
      this.exportValue = value;
      // 导出原生菜单
      if (this.exportValue === this.$t("baseHome.exportMenu")) {
        ApiService.getInstance().getExportMenu().then((response: any) => {    
          if (response.status == 200) {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let elink = document.createElement('a');
            elink.download = '我的菜单';
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          }
        })
      } else if (this.exportValue === this.$t("baseHome.exportMyMenu")) {
      // 导出自定义菜单
      ApiService.getInstance().getExportMyMenu().then((response: any) => {
          if (response.status == 200) {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let elink = document.createElement('a');
            elink.download = '我的菜单';
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          }
        })
      }
  }


  /**
   * 选中文件后上传
   * @param $event 
   */
  public fileChange($event: any) {
      let obj = $event.target || $event.srcElement;
      if (obj.files) {
      const file = obj.files[0];
      if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          const data = new FormData()
          data.append('file', file);
          ApiService.getInstance().importMenu(data).then((response: any) => {
              if (response.status === 200) {
                const { errMsgMap, groups, menus } = response.data.result;
                this.buckUpMenus = this.editableTabs;
                if (errMsgMap && Object.keys(errMsgMap).length > 0) {
                    this.ImportMenuErrorMessageBox(errMsgMap, groups)
                } else {
                    this.editableTabs = groups;
                    // 将一级菜单项合进一个json中并排序，方便渲染
                    if (menus) {
                        menus.forEach((item: any) => {
                            console.log(item,'itemitemitemitem')
                            if (item.menuId.includes("OtherCustom") || item.externalLink) {
                                item.apptag = "other"
                                item.caption = "外链"
                                item.caption2 = "External Links"
                                item.caption3 = "外链"
                                item.funcTag = "OtherCustom"
                                item.tag = "OtherCustom"
                            }
                            item.orderValue = Number(item.pos) 
                        });
                        this.editableTabs = this.editableTabs.concat(menus)
                        this.editableTabs.sort((a:any, b:any) => a.orderValue - b.orderValue);
                        // 保存接口可点击
                        this.primaryLoading = false
                    }

                    (this.editableTabs || []).forEach((res: any) => {
                        // 新增level和id
                        res.level = 1;
                        res.id = this.uuid();
                        this.cyclicLoop(res, 1);
                        // 当前级下如果有一个有权限的菜单项，那么当前组就不用置灰
                        let string = JSON.stringify(res);
                        if (string.indexOf('"unauthFlag":"0"') > -1) {
                            res.grey = false;
                        } else {
                            res.grey = true;
                        }
                    });
                    this.childList = [];
                }
              }
          })
          .catch((err: any) => {
              Message({
                message: err.data.message,
                type: "error"
              });
          })
      }
      }
  }

  /**
   * 导入菜单错误信息提示框
   * @param 错误集合
   */
  public ImportMenuErrorMessageBox(errMsgMap: any, groups: any) {
      const html = this.handleErrMsgMap(errMsgMap)
      MessageBox.confirm(`<div class="import-menu-error-message">${html}</div>`, '导入文件错误,是否继续导入?', {
        confirmButtonText: '继续导入',
        cancelButtonText: '取消导入',
        type: 'error',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.editableTabs = groups
      }).catch(() => {
      (this.$refs.inputUpLoad as any).value = '';
        this.editableTabs = this.buckUpMenus
      });
  }

  /**
   * 
   * @param errMsgMap 错误信息集合
   */
  public handleErrMsgMap(errMsgMap: any): string {
      let html: string = ''
      let ErrArr = Object.keys(errMsgMap)
      ErrArr.map((item: any) => {
      if (errMsgMap[item] && Array.isArray(errMsgMap[item])) {
          errMsgMap[item].map((err: any) => {
          html += `
              <p> ${err} </p>
          `
          })
      }
      })
      return html
  }

      /**
   * 获取国际化标题
   */
  public getLocaleCaption(menu: any, type:any) {
      if (type === 'menus') {
          switch (this.$i18n.locale) {
              case "zh-CN":
              return menu.myCaption ? menu.myCaption : menu.caption;
              case "en-US":
              return menu.myCaptionEn ? menu.myCaptionEn : menu.caption2;
              case "zh-TW":
              case "zh-HK":
              return menu.caption3 ? menu.caption3 : menu.caption;
              default:
              return menu.caption;
          }
      } else if (type === 'group') {
          switch (this.$i18n.locale) {
              case "zh-CN":
              return menu.groupMenuName ? menu.groupMenuName : menu.groupMenuNameEn ? menu.groupMenuNameEn : menu.myCaption;
              case "en-US":
              return menu.groupMenuNameEn ? menu.groupMenuNameEn : menu.groupMenuName ? menu.groupMenuName : menu.myCaptionEn;
              case "zh-TW":
              case "zh-HK":
              return menu.caption3 ? menu.caption3 : menu.caption;
              default:
              return menu.caption;
          }
      }
      
  }

}
