






































































































import { Component, Mixins } from "vue-property-decorator";
import draggable from "vuedraggable";
import { OpenMenu } from "../mixin/open-menu";
import { ApiService } from "@/api";
import { Message } from "element-ui";
import { AppConfig } from "@/config/config";

interface MenuItem {
  caption: string;
  resourceId: string;
  [key: string]: string;
}
@Component({
  components: {
    draggable
  }
})

export default class myCommonDialog extends Mixins(OpenMenu) {
  public myFavorite: any = [];
  public applicationMenu:any = [];//保存我的菜单左侧数据
  public isOpen: boolean = false;//是否开启我的菜单

  public created() {
    
    /**
     * 是否开启我的菜单
     */
    ApiService.getInstance()
      .getisOpenMymenus()
      .then((res: any) => {
        if (res.data.result == 1) {
          this.isOpen = true
          Promise.all([this.getFavorite(), this.getGroup()]).then((arr) => {
            (arr[0] || []).forEach((item: any) => {
              if (JSON.stringify(this.allChildrenMenuObj).includes(item.resourceId)) {
                let name: any = "";
                if (this.$i18n.locale === "zh-CN") {
                  name = item.remarkName;
                } else if (this.$i18n.locale === "en-US") {
                  name = item.remarkNameEn;
                }
                if (item.remarkName) this.addSelected(this.allChildrenMenuObj[item.resourceId + name]);
              }
            });
            this.selectedWatch();
          })
          return;
        } else {
          this.isOpen = false
          Promise.all([this.getFavorite(), this.getAllmenus()]).then((arr: any) => {
            (arr[0] || []).forEach((item: any) => {
              if (JSON.stringify(this.allChildrenMenuObj).includes(item.resourceId)) {
                let name: any = "";
                if (this.$i18n.locale === "zh-CN") {
                  name = item.remarkName;
                } else if (this.$i18n.locale === "en-US") {
                  name = item.remarkNameEn;
                }
                if (item.remarkName) this.addSelected(this.allChildrenMenuObj[item.resourceId + name]);
              }
            });
            this.selectedWatch();
          });
          return;
        }
      });
  }

  /**
   * 获取我的常用
   */
  async getFavorite() {
    await ApiService.getInstance()
      .getMenuCollection()
      .then((res: any) => {
        res.data.result.forEach((item: any) => {
          const obj: any = {};
          obj.resourceId = item.menuid;
          if (this.isOpen) {
            obj.remarkName = item.remarkname?.split("-")[2];
            obj.remarkNameEn = item.remarkname?.split("-")[3];
          } else {
            obj.remarkName = item.remarkname?.split("-")[0];
            obj.remarkNameEn = item.remarkname?.split("-")[1];
          }
          this.myFavorite.push(obj);
        });
      });
    return Promise.resolve(this.myFavorite);
  }
  /**
   * 设置我的常用
   */
  async setFavorite() {
    // Message({ message: "暂无接口", type: "warning" });
    // return Promise.reject();
    const parmars: any = [];
    this.selected.forEach((res: any, index: number) => {
      let allFavorite: any = {};
      allFavorite.menuid = res.resourceId;
      allFavorite.pos = index;
      allFavorite.remarkName = res.caption + '-' + res.caption2 + '-' + res.myCaption + '-' + res.myCaptionEn;
      parmars.push(allFavorite);
    });
    ApiService.getInstance()
      .postMenuCollection(parmars)
      .then((res: any) => {
        Message({ message: res.data.result, type: "success" });
        this.selectedWatch();
      });
  }

  /**
   * 获取菜单
   */
   async getAllmenus() {
    this.allChildrenMenu = [];
    this.menuTabsList = [];
    this.menuTabsObject = {};
    await ApiService.getInstance()
      .getMenus()
      .then((res: any) => {
        let { status, data } = res;
        if (status == 200 && data) {
           // 获取整体菜单数据
           let newarr = data.filter((module: any) =>
              AppConfig.mountedSystems.includes(module.system)
          )
          this.applicationMenu = newarr;
          (data || [])
            .filter((module: any) =>
              AppConfig.mountedSystems.includes(module.system)
            )
            .forEach((module: any) => {
              if (module.menuItems?.length) {
                module.menuItems.forEach((first: any) => {
                  if (first.hidden || !first.children?.length) {
                    // 隐藏的菜单不计算
                    if (first.hidden) {
                      return;
                    }
                    // 没页面的一级菜单不计算
                    // 有页面的一级菜单左左侧tab名与右侧菜单名使用同一个名字
                    if (!first.children?.length) {
                      if (first.resourceId) {
                        const data = {
                          apptag: module.app,
                          path: module.system
                        };
                        const children = [
                          {
                            ...first,
                            ...data
                          }
                        ];
                        this.menuTabsList.push({
                          caption: first.captionLocale,
                          resourceId: first.resourceId
                        });
                        this.menuTabsObject[first.resourceId] = {
                          ...first,
                          ...data,
                          children
                        };
                        this.allChildrenMenu.push(
                          ...this.formattingGetPSAppMenuItems(children)
                        );
                      }
                    }
                    return;
                  }
                  this.menuTabsList.push({
                    caption: first.captionLocale,
                    resourceId: first.resourceId,
                  });
                  this.menuTabsObject[first.resourceId] = first;
                  first.children.forEach((second: any) => {
                    second.apptag = module.app;
                    second.path = module.system;
                    if (second.children?.length) {
                      second.children.forEach((third: any) => {
                        third.apptag = module.app;
                        third.path = module.system;
                        if (third.children?.length) {
                          third.children.forEach((fourth: any) => {
                            fourth.apptag = module.app;
                            fourth.path = module.system;
                          });
                        }
                      });
                    }
                  });
                  this.allChildrenMenu.push(
                    ...this.formattingGetPSAppMenuItems(first.children)
                  );
                });
              }
            });
          try {
            this.menuTabsChange(this.menuTabsList[0]);
          } catch (e) {
            this.menuTabsAct = "";
            this.menuList = [];
          }
          return data.result;
        }
      });
  }


  /**
     * 处理group接口返回的数据，开启我的菜单之后返回的数据
     *
     */
  public myMenusGroups: any = [];
  async getGroup() {
    await ApiService.getInstance()
    .getMyMenuGroup()
    .then((res: any) => {
      const {groups} = res.data.result
      this.menuTabsList = [];
      this.myMenusGroups = [];
      if (groups && groups.length) {
        // 获取整体菜单数据
        this.applicationMenu = JSON.parse(JSON.stringify(groups));
        this.applicationMenu.forEach((item: any) => {
          if (item.menus && item.childrenGroups) {
            // 为了一级下得菜单项项可以展示，这里仿照组得形式增加一个菜单组并排序
            item.menus.map((res:any) =>{
              let appMenus: any = {
                groupMenuId: res.menuId,
                groupMenuName: res.myCaption ? res.myCaption : res.caption,
                groupMenuNameEn: res.myCaptionEn ? res.myCaptionEn : res.caption2,
                groupMenuNameLocale: res.myCaptionLocale ? res.myCaptionLocale : res.captionLocale,
                menus: [res],
                orderValue: Number(res.pos),
                resourceId: res.menuId
              };
              item.childrenGroups.push(appMenus)
            })
            //排序
            item.childrenGroups.sort((a:any, b:any) => a.orderValue - b.orderValue);
          }
        });
        
        // 处理初始化数据，用于页面展示等
        this.applicationMenu.forEach((res: any) => {
          this.menuTabsList.push({
            caption: res.groupMenuNameLocale,
            resourceId: res.groupMenuId
          });
          this.groupLoop(res)
        });
        
      }
      try {
        // 默认值
        this.menuTabsChange(this.applicationMenu[0]?.childrenGroups[0]);
      } catch (e) {
        this.menuTabsAct = "";
        this.menuList = [];
      }
    });
  }

  /**
  * 初始化group，递归
  */
  groupLoop(data:any){
    data.menus?.map((item:any) => {
      item.resourceId = item.menuId
      this.allChildrenMenu.push(item)
      const children = [
        {
          ...item
        }
      ];
      this.menuTabsObject[item.resourceId] = {
        ...item,
        children
      };
    })
    data.childrenGroups?.map((child:any) => {
      this.groupLoop(child)
    })
  }

  // 所有子菜单
  allChildrenMenu: any[] = [];
  get allChildrenMenuObj() {
    let obj: any = {};
    this.allChildrenMenu.forEach((item: any) => {
      if (item.resourceId) {
        let remarkName = item.myCaption ? item.myCaption : item.caption;
        let remarkNameEn = item.myCaptionEn ? item.myCaptionEn : item.caption2;
        if (this.$i18n.locale === "zh-CN") {
          obj[item.resourceId + remarkName] = { ...item };
        } else if (this.$i18n.locale === "en-US") {
          obj[item.resourceId + remarkNameEn] = { ...item };
        }
      }
    });
    return obj;
  }

  /**
   * 菜单文字较多时看不清楚，点住拉伸的效果解决此问题
   */
   stretchBack(e:any){
    let init = e.clientX;
    let parent:any = document.getElementById("silderLeft");
    let initWidth = parent.offsetWidth;
      document.onmousemove = function(e) {
        let end = e.clientX;
        let newWidth = end - init + initWidth;
        parent.style.width = newWidth + "px";
      };
      document.onmouseup = function() {
      document.onmousemove = document.onmouseup = null;
    };
  }

  /**
   * ---------- tabs操作
   */

  public menuTabsAct = "";
  public menuTabsList: MenuItem[] = [];
  public menuTabsObject: { [key: string]: any } = {};

  menuTabsChange(item: MenuItem) {
    if (this.isOpen) {
      this.menuList = []
      this.menuTabsAct = item.groupMenuId;
      this.dragLoop(item)
    } else {
      this.menuTabsAct = item.resourceId;
      const children = JSON.parse(
        JSON.stringify(this.menuTabsObject[item.resourceId].children || [])
      );
      this.menuList = this.formattingGetPSAppMenuItems(children);

      let obj: any = {};
      this.menuList
        .map(item => {
          return {
            label: item.caption,
            color: item.color,
            resourceId: item.resourceId,
            icon: item.iconClass
          };
        })
        .forEach(item => {
          obj[item.resourceId] = {
            label: item.label,
            color: item.color,
            icon: item.icon
          };
        });
    }
  }

  /**
  * 点击之后，递归
  */
  dragLoop(res:any){
    res.menus.map((item:any) => {
      this.menuList.push(item)
    })
    if (res.childrenGroups) {
      res.childrenGroups.map((child:any) => {
        this.dragLoop(child)
      })
    }
  }

  /**
   * ---------- 菜单操作
   */

  // tabs所选的右侧菜单
  public menuList: any[] = [];

  /**
   * 计算右侧菜单的选中状态
   */
  get menuListComputed(): any[] {
    return this.menuList.map(item => {
      return {
        ...item,
        isChecked: !!this.selectedObj[item.resourceId]
      };
    });
  }

  /**
   * 计算所有子菜单的选中状态
   */
  get allChildrenMenuComputed() {
    return this.allChildrenMenu.map(item => {
      return {
        ...item,
        isChecked: !!this.selectedObj[item.resourceId]
      };
    });
  }

  public selected: any = [];

  selectedWatch() {
    this.$emit("selected-watch", JSON.parse(JSON.stringify(this.selected)));
  }

  /**
   * 选中菜单的对象形式
   */
  get selectedObj() {
    let obj: any = {};
    this.selected.forEach(
      (item: any, index: number) =>
        (obj[item.resourceId] = { ...item, dttIndex: index })
    );
    return obj;
  }

  /**
   * 添加选中
   */
  addSelected(item: any) {
    console.log(item,'item')
    if (item.resourceId in this.selectedObj) {
      return;
    }
    this.selected.push(item);
  }

  /**
   * 删除选中
   */
  removeSelected(resourceId: any) {
    if (resourceId in this.selectedObj) {
      this.selected.splice(this.selectedObj[resourceId].dttIndex, 1);
    }
  }

  /**
   * 点击菜单跳转
   */
  openMenu(item: MenuItem) {
    this.openMenuMixin({
      apptag: item.apptag,
      funtag: item.funcTag,
      key: item.tag,
      path: "/" + item.path,
      title: item.caption,
      resourceId: item.resourceId
    });
  }

  /**
   * ---------- 检索操作
   */

  get restaurants(): any {
    return this.allChildrenMenuComputed.map(item => {
      return {
        ...item,
        type: "增值税"
      };
    });
  }

  public searchAllValue = "";

  /**
   * 检索栏过滤方法
   */
  public querySearch(queryString: any, cb: any) {
    var restaurants = this.restaurants;
    var results = queryString
      ? restaurants.filter(
          (restaurant: any) =>
            restaurant.caption
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) >= 0
        )
      : restaurants;
    cb(results);
  }

  public handleSelect(item: any) {
    if (item.isChecked) {
      this.removeSelected(item.resourceId);
    } else {
      this.addSelected(item);
    }
  }

  /**
   * 过滤条件加上标识样式
   */
  public searchValueAddTag(value: string, search: string) {
    if (search) {
      return value.replace(
        new RegExp(search, "g"),
        `<span class="tag">${search}</span>`
      );
    } else {
      return value;
    }
  }

  /**
   * js生成随即字符串，用于赋值给id
   */
  public uuid() {
    return Math.random().toString(36).substring(3, 15)
  }

  /**
   * ---------- 弹框操作
   */

  public dialogVisible = false;

  /**
   * 设置弹窗状态
   */
  public setDialogVisible(value: boolean) {
    this.dialogVisible = value;
  }

  /**
   * 获取弹窗状态
   */
  public getDialogVisible() {
    return this.dialogVisible;
  }

  /**
   * 弹窗关闭按钮
   */
  public close() {
    this.dialogVisible = false;
  }

  /**
   * 弹窗确认按钮
   */
  async confirm() {
    await this.setFavorite();
    this.close();
    this.$emit("load-watch", true);
  }

  /**
   * 获取国际化标题
   */
   public getLocaleCaption(menu: any, type:any) {
    if (type === 'menus') {
      switch (this.$i18n.locale) {
        case "zh-CN":
          return menu.myCaptionLocale ? menu.myCaptionLocale : menu.captionLocale ? menu.captionLocale : menu.caption;
        case "en-US":
          return menu.myCaptionLocale ? menu.myCaptionLocale : menu.captionLocale ? menu.captionLocale : menu.caption2;
        case "zh-TW":
        case "zh-HK":
          return menu.caption3 ? menu.caption3 : menu.caption;
        default:
          return menu.caption;
      }
    } else if (type === 'group') {
      switch (this.$i18n.locale) {
        case "zh-CN":
          return menu.groupMenuNameLocale ? menu.groupMenuNameLocale : menu.groupMenuName;
        case "en-US":
          return menu.groupMenuNameLocale ? menu.groupMenuNameLocale : menu.groupMenuNameEn;
        case "zh-TW":
        case "zh-HK":
          return menu.caption3 ? menu.caption3 : menu.caption;
        default:
          return menu.caption;
      }
    }
    
  }
}
