/**
 * 历史项
 *
 * @author chitanda
 * @date 2023-02-22 15:02:31
 * @export
 * @class LocationItem
 */
export class LocationItem {
  readonly hash: string;
  readonly host: string;
  readonly hostname: string;
  readonly href: string;
  readonly origin: string;
  readonly pathname: string;
  readonly port: string;
  readonly protocol: string;
  readonly search: string;
  readonly fullPath: string;

  /**
   * 使用全路径作为唯一标识
   *
   * @author chitanda
   * @date 2023-02-22 15:02:05
   * @readonly
   */
  get id() {
    return this.fullPath;
  }

  constructor(loc: Location) {
    this.hash = loc.hash;
    this.host = loc.host;
    this.hostname = loc.hostname;
    this.href = loc.href;
    this.origin = loc.origin;
    this.pathname = loc.pathname;
    this.port = loc.port;
    this.protocol = loc.protocol;
    this.search = loc.search;
    this.fullPath = loc.href;
  }
}