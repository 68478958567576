




















import { Vue, Component } from "vue-property-decorator";

interface User {
  username: string;
  avatarUrl: string;
  hireDate: string;
}

@Component({
  filters: {
    timeSpan(hireDate: string) {
      if (!(hireDate && hireDate != "Invalid Date")) {
        return "";
      }
      const now = new Date().getTime();
      const hire = new Date(hireDate).getTime();
      if (hire > now) {
        console.log("异常：入职日期大于当前日期");
        return "";
      }
      return Math.floor((now - hire) / (1 * 24 * 60 * 60 * 1000));
    },
  },
})
export default class BaseHomeUser extends Vue {
  mounted() {
    this.getUser();
  }

  user: Partial<User> = {};

  async getUser() {
    this.user = {
      avatarUrl: "",
      hireDate: "2022-07-01",
      username: "admin",
    };
  }
}
