export default {
    baseHome: {
        message: '消息',
        loadmore:'加载更多...',
        nomore:'没有更多了',
        start:'启用',
        openIndex:'显示  默认首页',
        defaultIndex:'隐藏默认首页后，必须配置一个一级菜单项作为默认首页显示',
        more: '更多',
        myCommon: '我的常用',
        recentlyUsed: '最近使用',
        operationalData: '运营数据',
        myMenu:'我的菜单',
        childMenu:'子菜单',
        calendar: '日历',
        relevantPolicy: '相关政策',
        policyGuideline:'政策指引',
        internalCases:'内部案例',
        days: '天',
        pendingAudit: '待审',
        pendingRead: '待阅',
        toDo: '待办',
        done: '已办',
        read: '已阅',
        unread: '待阅',
        myApplication: '我的申请',
        todaysEvents: '今日事项',
        unlimited: '不限',
        cashTaxPayments: '实缴税金',
        period: '期间',
        mainBody: '主体',
        financialContributionAchievementRate: '财力贡献的达成率',
        reportProgress: '申报进度',
        state: '国家',
        region: '地区',
        company: '公司',
        thousand: '千户公司',
        month: '月',
        unfinished: '未完成',
        finished: '完成',
        goodMorning: '上午好',
        nyyoppogtcz: '您已与TTC共同成长',
        selected: '已选',
        dragIconToSort: '拖动图标可排序',
        allApplication: '全部应用',
        common: {
            add: '添加',
            remove: '删除',
            remove2: '移除',
            confirm: '保存',
            cancel: '取消',
            openUp: '展开',
            foldUp: '收起',
            edit:'编辑'
        },
        exportMenu:"导出原生菜单",
        exportMyMenu:"导出自定义菜单",
        selectExportMenu:"请选择导出菜单",
        importMenu:'导入菜单',
        prompt:'提示',
        icon: "图标：",
        dialogTitle: "标题(中)：",
        dialogTitleEn: "标题(英)：",
        dialogUrl: "外链地址：",
        pleaseEnterIcon: "请输入图标",
        pleaseEnterDialogTitle: "请输入标题(中文)",
        pleaseEnterDialogTitleEn: "请输入标题(英文)",
        pleaseEnterDialogUrl: "请输入外链地址",
        PleaseEnterKeyword: "请输入关键字搜索",
        noData: "无数据",
        noIndex: "请开启显示默认首页或配置系统内一级菜单项作为首页",
        noResult: "没有找到",
        isSaveMenu: "请先保存已经编辑的菜单",
        viewDetail: "查看详情",
        download: "直接下载",
        clearCompleted: "清除已完成",
        clearFailed: "清除已失败",
        clearAll:"清除全部",
        Confirm:"确认",
        Progressing: "进行中",
        Completed: "已完成",
        Failed: "已失败",
        ErrorMessage:"失败信息",
        Confirm1:"确定",
        ImportResult: "导入结果",
        ImportData: "导入数据",
        message1:"信息",
        Pathcannotbeempty: "传入路径不能为空",
        TaskStatus: "任务进度",
        TaskManagementCenter: "任务管理中心",
        Autoinvoicing: "自动开票",
        ErrorCode: "返回值错误"
    }
}