import { Http } from "@/net";


/**
* 菜单服务基类 
*/
export class menuServiceBase {

  /**
  *  http单例对象 
  */
  public http: Http = Http.getInstance()

  /**
   * 单例变量声明
   *
   * @private
   * @static
   * @type {ApiService}
   * @memberof ApiService
   */
  private static menuServiceBase: menuServiceBase;

  /**
   * 获取 menuServiceBase 单例对象
   *
   * @static
   * @returns {menuServiceBase}
   * @memberof menuServiceBase
   */
  public static getInstance(): menuServiceBase {
    if (!menuServiceBase.menuServiceBase) {
      menuServiceBase.menuServiceBase = new menuServiceBase();
    }
    return this.menuServiceBase;
  }

  constructor() { 
    // todo
    // this.reqIsCustommMenu()
  }

  /**
  * 请求是否开启自定义菜单 
  * @returns 
  */
  public async getisOpenMymenus() {
    return await Http.getInstance().get(`/portal/menu/openmymenu`)
  }

  /**
  * 是否开启自定义菜单
  */
  public isCustommMenu:boolean = false

  /**
  * 请求远端获取是否开启自定义菜单
  */
  public async reqIsCustommMenu(){
    this.getisOpenMymenus().then((res)=>{
      this.setIsCustommMenu(Boolean(res?.data?.result)) 
    })
  }

  /**
  * 设置是否开启自定义菜单 
  * @param isCustommMenu 
  */
  public setIsCustommMenu(isCustommMenu:boolean){
    this.isCustommMenu = isCustommMenu
  }

  /**
  * 获取是否开启自定义菜单
  * @returns 
  */
  public getIsCustommMenu(){
    return this.isCustommMenu
  }
  
}