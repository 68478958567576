













import { Component, Vue, Watch } from "vue-property-decorator";
import { apps } from "@/micro";
import store from "@/shared/store";
import shared from "@/shared";
import { AppService } from "@/service/app-service";
@Component({})

export default class appBreadcrumb extends Vue {
    /**
   * 取消订阅对象
   */
   public unsubscribe: any;

  /**
   * 当前激活 tab 面板的 key
   */
  public activeKey = "";
  public disabledTooltip = false;

  /**
   * 当前所有面板集合
   */
  public panes: any = [];

  /**
   * 面包屑集合
   */
  public activeMenuPath:any = Vue.observable([])

  public getBreadcrumbItems () {
    // 获取每个面包屑元素的宽度
    var breadcrumbItems = document.getElementsByClassName("elbreadcrumbclass");
    var itemWidths = Array.from(breadcrumbItems).map(function(item) {
      return (item as HTMLDivElement).offsetWidth;
    });
    var widthAll = 0
    for(var i = 0 ; i < itemWidths.length ; i++) {
      widthAll += itemWidths[i]
    }
    return {
      itemWidths: itemWidths,
      itemWidthsTotal: widthAll
    }
  }

  public getBreadcrumbs (itemWidthsOld: any) {
    // 获取面包屑元素
    var breadcrumbs = document.getElementsByClassName("menu-breadcrumb")[0];
    var breadcrumbItems = document.getElementsByClassName("elbreadcrumbclass");
    // 获取面包屑容器的宽度
    var containerWidth = (breadcrumbs as HTMLDivElement).offsetWidth;
    var itemWidths
    if (itemWidthsOld) {
      itemWidths = itemWidthsOld
    } else {
      itemWidths = this.getBreadcrumbItems().itemWidths
      itemWidths.reverse()
      // 计算面包屑总宽度
      var widthAll = 0
      var showFigWidth = -1
      for(var i = 0 ; i < itemWidths.length ; i++) {
        widthAll += itemWidths[i]
        if (widthAll > containerWidth) {
          showFigWidth = i
        }
      }
      // 确定哪些需要变成...
      if (showFigWidth != -1) {
        for(var k = 0 ; k < itemWidths.length ; k++) {
          if (k >= showFigWidth - 1) {
            itemWidths[k] = -1
          }
        }
      }
      itemWidths.reverse()
    }
    for(var j = 0 ; j < itemWidths.length ; j++) {
      if (itemWidths[j] == -1) {
        (breadcrumbItems[j] as HTMLDivElement).innerHTML =  "<span onclick='breadcrumbClickBegin(" + JSON.stringify(this.activeMenuPath[j]) + "," + false + "," + j + ")'>...<span style='margin: 0px 9px;font-weight: 700;color: #C0C4CC;'>/</span></span>";
      } else {
        if (j == itemWidths.length - 1) {
          (breadcrumbItems[j] as HTMLDivElement).innerHTML =  "<span onclick='breadcrumbClickBegin(" + JSON.stringify(this.activeMenuPath[j]) + "," + true + "," + j + ")'>" + this.activeMenuPath[j].title +"</span>";
        } else {
          (breadcrumbItems[j] as HTMLDivElement).innerHTML =  "<span onclick='breadcrumbClickBegin(" + JSON.stringify(this.activeMenuPath[j]) + "," + false + "," + j + ")'>"+ this.activeMenuPath[j].title +"<span style='margin: 0px 9px;font-weight: 700;color: #C0C4CC;'>/</span></span>";
        }
      }
    }
    if (this.getBreadcrumbItems().itemWidthsTotal > containerWidth) {
      for(var p = 0 ; p < itemWidths.length ; p++) {
        if (itemWidths[p] != -1) {
          itemWidths[p] = -1
          break
        }
      }
      this.getBreadcrumbs(itemWidths)
    }
  }

  @Watch("activeMenuPath")
  onActiveMenuPathChange() {
    this.$nextTick(()=> {
      this.getBreadcrumbs('')
    })
  }
  mounted() {
    (window as any).breadcrumbClickBegin = (a:any,b:any,c:any) => {
      this.breadcrumbClick(a,b,c)
    }
  }
  created() {
      this.unsubscribe = store.subscribe(() => {
        //获取面包屑数据，刷新页面的时候取缓存数据
        const activeCrumbList = shared.getActiveCrumbList()
        if (activeCrumbList && activeCrumbList.length > 0) {
          this.activeMenuPath = activeCrumbList
        } else {
          let array: any = sessionStorage.getItem('breadcrumbList');
          if(array !== null && array !== 'undefined' && array !== ''){
            this.activeMenuPath = JSON.parse(array);
          }
        }
      });
  }

  public tooltipActiveMenuPath () {
    if (this.activeMenuPath.length === 0) {
      this.disabledTooltip = true
      return
    }
    this.disabledTooltip = false
    var backName = this.activeMenuPath.map((obj:any) => obj.title).slice(0, this.activeMenuPath.length - 1).join('  /  ')
     + '  /  ' + this.activeMenuPath[this.activeMenuPath.length - 1].title;
    return backName
  }

  /**
   * 菜单面包屑点击,重新加载路由
   * 
   */
  public breadcrumbClick(item: any, flag: any, index: any) {
      let _this: any = this;
      if (flag) {//如果是最后一个面包屑，会刷新页面，并触发左侧菜单
        this.activeKey = item.breadpath;
        let pages:any = store.getState().pages;
        const activePage = pages.find((page: any) => {
          return page.microAppName + "__" + page.fullPath === this.activeKey;
        });

        // 未激活应用
        const activeApp = apps.find((app: any) => {
          return app.name === activePage.microAppName;
        });
        if (activeApp) {
          // 实现面包屑点击功能，这里将触发菜单的computeMenukeys方法
          _this.$baseMenu.computeMenukeys(
             activeApp.activeRule.slice((window as any).Environment.baseDeployUrl.length) + '#' + activePage.fullPath
          );

          //如果是最后一个面包屑，并且是下钻页面，新建或者编辑等，更新子应用需要codeName
          if (item.codeName){
            activePage.codeName = item.codeName
          }

          // 这个需要更改模板，加一个RELOAD行为，然后再基座中触发就会刷新子应用
          AppService.getInstance().noticeMicroApp({
            tag: activePage.microAppName,
            action: "RELOAD",
            data: activePage
          });
        }  
      } else {//如果是第一第二级得数据，就只打开左侧菜单
        let array:any = [];
        (this.activeMenuPath || []).map((item: any , ind: any) => {
          if (ind <= index) {
            array.push(item.key)
          }
        })
        //触发左侧菜单得数据，改变
        _this.$baseMenu.changeSelectKey(array);
        
      }
  }


  /**
   * 销毁
   */
  public destroyed() {
    if (this.unsubscribe) {
      this.unsubscribe();
      shared.setActiveCrumbPathAll([])
    }
  }

    /**
   * 获取标题
   */
  public getCaption(page: any): string {
    let caption: string = page.title;
    if (page.info && !Object.is(page.info, "")) {
      caption = `${caption}-${page.info}`;
    }
    return caption;
  }

}
