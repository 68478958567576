<template>
  <!-- 消息弹出框绘制开始 -->
  <div>
    <el-badge :is-dot="showIsDot">
      <el-popover placement="bottom" trigger="click" width="440" @show="initTabCnt(1)" @hide="initTabCnt(2)">
        <el-tabs :value="default_tab_pane" :stretch="true">
          <el-tab-pane :label="`${$t('baseHome.toDo')}` + (myTasks.length == 0 ? '' : '(' + myTasks.length + ')')" name="first"
            style="height: 300px; overflow: auto" :draggable="false">
            <template>
              <div class="msgDiv dtt-scrollbar">
                <div v-for="(item, index) in myTasks" :key="index" class="msgRow1">
                  <div class="divClassCol1">
                    <el-col class="msgCol1" :span="24">
                      <el-tooltip class="item" effect="light" :content="showFig(item.content)" placement="top">
                        <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ showFig(item.content)
                        }}
                        </p>
                      </el-tooltip>
                    </el-col>
                  </div>
                  <div class="divClassCol2">
                    <el-col class="msgCol2" :span="6">
                      <p :title="item.create" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                        {{ item.create }}</p>
                    </el-col>
                  </div>
                </div>
              </div>
            </template>
            <template>
              <div class='show-more' @click="showMore('taskShowCnt')">
                <label v-if="taskShowCnt < myTasks.length">{{ $t('baseHome.loadmore') }}</label>
                <label v-else>{{ $t('baseHome.nomore') }}</label>
              </div>
            </template>
          </el-tab-pane>
          <!-- 消息 -->
          <el-tab-pane class="msgClass" :label="`${$t('baseHome.message')}` + (myMsgs.length == 0 ? '' : '(' + myMsgs.length + ')')"
            name="second" :style="{ height: '300px', overflow: 'auto' }" :draggable="false">
            <template v-if="myMsgs.length > 0">
              <div class="msgDiv dtt-scrollbar">
                <div v-for="(item, index) in myMsgs" :key="index" class="msgRow1">
                  <div @click="toUrl(item)" class="divClassCol1">
                    <el-col class="msgCol1" :span="24" :title="item.tasktitle">{{ item.tasktitle }}</el-col>
                  </div>
                  <el-dropdown class="divClassCol2" @command="handleCommand($event, item)" trigger="click">
                  <span class="el-dropdown-link">
                    {{showStateFun(item.taskstatus, 1)}}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="app-message-popover-dropdown">
                    <el-dropdown-item command='detail'>{{ $t('baseHome.viewDetail') }}</el-dropdown-item>
                    <el-dropdown-item 
                    command='download'
                    v-if="item.taskstatus === '10' && item.tasktype === '70'"> 
                    {{ $t('baseHome.download') }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                  </el-dropdown>
                  <!-- <div class="divClassCol2" @click="toDialog(item)">
                    <el-col class="msgCol2" :span="6">{{ showStateFun(item.taskstatus, 1) }}</el-col>
                  </div> -->
                  <el-progress :percentage="item.taskprogress" :color="showStateFun(item.taskstatus, 2)"></el-progress>
                </div>
              </div>
              <el-row class="msgRow2">
                <el-button class="msgButton" @click="clearContext(1)">{{ $t('baseHome.clearCompleted') }}</el-button>
                <el-button class="msgButton" @click="clearContext(2)">{{ $t('baseHome.clearFailed') }}</el-button>
                <el-button class="msgButton" @click="clearContext(3)">{{ $t('baseHome.clearAll') }}</el-button>
              </el-row>
            </template>
            <template v-else>
              <div class='show-more'>
                <label>{{ $t('baseHome.nomore') }}</label>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
        <i id="bellBtn" size="20" slot="reference" class='el-icon-bell'></i>
      </el-popover>
    </el-badge>
    <!-- 弹窗 -->
    <el-dialog :append-to-body="true" :close-on-click-modal="false"
      :class="dialogClass ? 'messagePopover messagePopoverIframe' : 'messagePopover'" :close-on-press-escape="false"
      :title="titleDialog" :visible.sync="dialogVisible" :before-close="handleClose">
      <component :is="showUrl" :journalBox="journalBox" :journalBox2="journalBox2" :iconClass="iconClass"
        :percentage="percentage" :typeTotal="typeTotal" :responseResult="responseResult" :activeName="activeName" :srcUrl="srcUrl"
       ></component>
      <div class="showButton">
        <el-button @click="handleClose" size="mini">{{ $t('baseHome.Confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import messageDialog1 from './components/messageDialog1.vue'
import messageDialog2 from './components/messageDialog2.vue'
import messageDialog3 from './components/messageDialog3.vue'
import messageDialog4 from './components/messageDialog4.vue'
import messageDialog5 from './components/messageDialog5.vue'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    messageDialog1,
    messageDialog2,
    messageDialog3,
    messageDialog4,
    messageDialog5
  },
  data() {
    return {
      showUrl: '',
      // 是否显示小圆点
      showIsDot: false,
      responseResult: '',
      // 默认显示的tab页
      default_tab_pane: "first",
      titleDialog: this.$t("baseHome.TaskStatus"),
      typeTotal: this.$t("baseHome.ImportResult"),
      activeName: "first",
      // 待办列表
      myTasks: [],
      dialogVisible: false,
      dialogClass: false,
      divHtml: '',
      showtimer: null,
      //  待办面板显示条数
      taskShowCnt: 0,
      // 消息列表
      myMsgs: [],
      // 环境配置对象
      environment: '1111',
      srcUrl: '',
      dmShow: null,
      misEitShow: null,
      /**
       * 图标样式
       */
      iconClass: 'funnel',
      /**
       * 进度条百分比
       */
      percentage: 0,
      journalBox: '',
      journalBox2: '',
      downloadUrl:'/ibizutil/download/'
    }
  },
  methods: {
    /**
 * 获取待办列表
 */
    getMyTasks() {
      let url = '/portal/message/1/10000';
      axios.get(url).then((response) => {
        console.log(response)
        if (response && response.status == 200) {
          const data = response.data.result;
          if (data && data.length > 0) {
            this.myTasks = data;
            this.showIsDot = true;
          } else {
            this.myTasks = [];
          }
          // 获取消息列表
          this.getMyMsgs();
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    handleClose() {
      this.dialogVisible = false
      clearInterval(this.dmShow);
      this.dmShow = null;
      clearInterval(this.misEitShow)
      this.misEitShow = null;
    },
    /**
    * 状态下拉触发指令 
    */
    handleCommand(command,item){
    if(command=='detail'){
      if(item.tasktype === '70'){
        this.dialogClass = true
          this.titleDialog = this.$t("baseHome.TaskManagementCenter")
          var url = `${window.location.origin}/base/frontpage.html#/frontpage/frmbgtasks/views/gridview?srffullscreen=true`;
          if (url.indexOf("?") !== -1) {
            url = `${url}&srftoken=${localStorage.getItem('ibzuaa-token')}&srfiframe=true&iframemode=model`;
          } else {
            url = `${url}?srftoken=${localStorage.getItem('ibzuaa-token')}&srfiframe=true&iframemode=model`;
          }
          this.srcUrl = this.handleOpenIframeUrl(url);
          this.showUrl = 'messageDialog4'
          this.dialogVisible = true
          return
      }else{
        this.toDialog(item)
      }
    }
    if(command === 'download'){
      const fileinfojson = item.fileinfojson
      if(fileinfojson){
        const fileinfo = JSON.parse(fileinfojson)
        const {id,name} = fileinfo
        this.DownloadFile(`${this.downloadUrl}/${id}`,name)
      }
    }
    },
  /**
  * @description 下载文件
  * @param {string} url
  * @param {*} file
  * @memberof AppPQGrid
  */
  DownloadFile(url,filename) {
    // 发送get请求
    axios({
        method: 'get',
        url: url,
        responseType: 'blob'
    }).then((response) => {
        if (!response || response.status != 200) {
            this.$throw(this.$t('components.appfileupload.downloaderror'));
            return;
        }
        // 请求成功，后台返回的是一个文件流
        if (response.data && filename) {
            // 获取文件名
            const ext = '.' + filename.split('.').pop();
            let filetype = this.calcFilemime(ext);
            // 用blob对象获取文件流
            let blob = new Blob([response.data], {type: filetype});
            // 通过文件流创建下载链接
            var href = URL.createObjectURL(blob);
            // 创建一个a元素并设置相关属性
            let a = document.createElement('a');
            a.href = href;
            a.download = filename;
            // 添加a元素到当前网页
            document.body.appendChild(a);
            // 触发a元素的点击事件，实现下载
            a.click();
            // 从当前网页移除a元素
            document.body.removeChild(a);
            // 释放blob对象
            URL.revokeObjectURL(href);
        } else {
            this.$throw(this.$t('components.appfileupload.downloaderror'));
        }
    }).catch((error) => {
        console.error(error);
    });
  },

  /**
  * @description 计算文件mime类型
  * @param {string} filetype
  * @return {*}  {string}
  * @memberof AppPQGrid
  */
  calcFilemime(filetype) {
    let mime = "image/png";
    switch(filetype) {
        case ".wps":
        mime = "application/kswps";
        break;
        case ".doc":
        mime = "application/msword";
        break;
        case ".docx":
        mime = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
        case ".txt":
        mime = "text/plain";
        break;
        case ".zip":
        mime = "application/zip";
        break;
        case ".png":
        mime = "imgage/png";
        break;
        case ".gif":
        mime = "image/gif";
        break;
        case ".jpeg":
        mime = "image/jpeg";
        break;
        case ".jpg":
        mime = "image/jpeg";
        break;
        case ".rtf":
        mime = "application/rtf";
        break;
        case ".avi": 
        mime = "video/x-msvideo";
        break;
        case ".gz": 
        mime = "application/x-gzip";
        break;
        case ".tar": 
        mime = "application/x-tar";
        break;
    }
    return mime; 
  },
    /**
     * 消息获取
     */
    async getMessage() {
      let params = {
        srfmenuname: "menuitem97",
        srfinsttag: "__srfstdinst__",
        size: 100,
        page: 0,
        sort: "timestamp_version,DESC",
        n_enable_flag_eq: '1',
        n_task_category_like: ''
      }
      let urlShow = '/frmbgtasks/fetchcurusertask?srfmenuname=menuitem97'
      let res = await axios({
        method: 'post',
        url: urlShow,
        data: {
          ...params
        }
      });
      if (res && res.status == 200) {
        this.myMsgs = res.data
      }
      // axios.getInstance().post('/frmbgtasks/fetchcurusertask', params).then((response) => {
      //   if (response && response.status == 200) {
      //     this.myMsgs = response.data
      //   }
      // }).catch((error) => {
      //   LogUtil.warn(this.$t('components.appmessagepopover.error'));
      // })
    },
    /**
     * 清除消息
     */
    async clearContext(val) {
      let params = ''
      if (val == 1) {
        this.myMsgs.forEach((item) => {
          if (item.taskstatus == '10') {
            params = params + item.frmbgtaskuuid + ','
          }
        })
      } else if (val == 2) {
        this.myMsgs.forEach((item) => {
          if (item.taskstatus == '99' || item.taskstatus == '80' || item.taskstatus == '90' || item.taskstatus == '9') {
            params = params + item.frmbgtaskuuid + ','
          }
        })
      } else {
        this.myMsgs.forEach((item) => {
          if (item.taskstatus == '99' || item.taskstatus == '10' || item.taskstatus == '80' || item.taskstatus == '90' || item.taskstatus ==
            '9') {
            params = params + item.frmbgtaskuuid + ','
          }
        })
      }
      console.log(params)
      if (params == '') {
        return
      }
      let urlShow = '/frmbgtasks/disabletask'
      await axios({
        method: 'post',
        url: urlShow,
        data: {
          taskIds: params
        }
      }).then((res) => {
        console.log(res)
        this.getMessage()
      }).catch((error) => {
        console.log(error)
        this.getMessage()
      });
    },

    /**
     * 获取消息列表
     */
    getMyMsgs() {
      // TODO:接口获取消息列表，这里用的待办数据
      // this.myMsgs = this.myTasks;
      if (this.myMsgs.length > 0 && this.myTasks.length == 0) {
        // 显示小圆点
        this.showIsDot = true;
      }
    },
    showFig(val) {
      return val.substring(val.indexOf('>') + 1, val.indexOf('</a>'))
    },
    handleOpenIframeUrl(url) {
      let targetUrl = '';
      if (!url) {
        throw new Error(this.$t("baseHome.Pathcannotbeempty"));
      }
      targetUrl = url.replaceAll('%3B', ';').replaceAll('%3D', '=').replaceAll('%28', '(').replaceAll('%29', ')').replaceAll('%2a', '*').replaceAll('%3A', ':');
      targetUrl = new URL(targetUrl).href;
      return targetUrl;
    },
    toDialog(val) {
      this.dialogClass = false
      this.titleDialog = this.$t("baseHome.TaskStatus")
      if (val.taskcategory === "EIT" && val.taskfunctionname === 'EIT数据集市上传') {
        this.responseResult = this.handleImportInfo(JSON.parse(val.resultjson).data.importDto.result);
        this.showUrl = 'messageDialog2'
        this.dialogVisible = true
      } else if (val.taskcategory === "MIS" || val.taskcategory === "EIT") {
        if (val.taskfunctionname === '印花税申报数据获取' || val.taskfunctionname === '印花税计提数据获取' || val.taskfunctionname === '房土税申报数据获取' || val.taskfunctionname === '房土税计提数据获取' || this.splitfunctionname(val.taskfunctionname) === 'EIT二叉图数据获取') {
          this.iconClass = 'funnel'
          this.activeName = 'first'
          if (val.taskprogress === 100 && val.taskstatus === '10') {
            this.percentage = val.taskprogress
            this.iconClass = 'succes'
            this.journalBox = JSON.parse(JSON.parse(val.resultjson).messageInfo).dataMsg;
            this.journalBox2 = JSON.parse(JSON.parse(val.resultjson).messageInfo).reportMsg;
          } else if (val.taskstatus === '99') {
            let showError = Object.prototype.hasOwnProperty.call(val, 'resultjson') ? JSON.parse(val.resultjson).messageInfo : '系统异常，请联系管理员。'
            this.$alert(showError, this.$t("baseHome.ErrorMessage"), {
              type: 'error',
              confirmButtonText: this.$t("baseHome.Confirm1")
            });
            return
          } else {
            this.getContextMisEit(val)
            this.misEitShow = setInterval(() => {
              this.getContextMisEit(val)
            }, 1000);
          }
          this.showUrl = 'messageDialog3'
          this.dialogVisible = true;
        } else {
          if(val.taskstatus === "00") {
            let showError = Object.prototype.hasOwnProperty.call(val, 'resultjson') ? JSON.parse(val.resultjson).messageInfo : '任务正在执行中。'
            this.$alert(showError, this.$t("baseHome.message1"), {
              type: 'info',
              confirmButtonText: this.$t("baseHome.Confirm1")
            });
            return
          }else {
            let showError = Object.prototype.hasOwnProperty.call(val, 'resultjson') ? JSON.parse(val.resultjson).messageInfo : '系统异常，请联系管理员。'
            this.$alert(showError, this.$t("baseHome.message1"), {
              type: 'info',
              confirmButtonText: this.$t("baseHome.Confirm1")
            });
            return
          }
        }
      } else if (val.taskfunctionname == 'DM数据集市上传') {
        this.responseResult = this.handleImportInfo(JSON.parse(val.resultjson).data.importDto.result);
        this.showUrl = 'messageDialog2'
        this.dialogVisible = true
      } else if (val.taskcategory === "VATFP") {
        if (val.taskfunctionname.indexOf("-导出") !== -1) {
          this.dialogClass = true
          this.titleDialog = this.$t("baseHome.TaskManagementCenter")
          var url = `${window.location.origin}/base/frontpage.html#/frontpage/frmbgtasks/views/gridview?srffullscreen=true`;
          if (url.indexOf("?") !== -1) {
            url = `${url}&srftoken=${localStorage.getItem('ibzuaa-token')}&srfiframe=true&iframemode=model`;
          } else {
            url = `${url}?srftoken=${localStorage.getItem('ibzuaa-token')}&srfiframe=true&iframemode=model`;
          }
          this.srcUrl = this.handleOpenIframeUrl(url);
          this.showUrl = 'messageDialog4'
          this.dialogVisible = true
          return
        }
        this.titleDialog = this.$t("baseHome.ImportData");
        this.typeTotal = this.$t("baseHome.ImportResult");
        if (val.taskfunctionname === 'VAT-销项发票-开票交易池-自动开票') {
          this.typeTotal = this.$t("baseHome.Autoinvoicing");
          this.titleDialog = this.$t("baseHome.Autoinvoicing");
          this.responseResult = this.handleImportInfoVat(JSON.parse(val.resultjson).data.result);
        }else if(val.taskfunctionname==='VATFP-进项发票-批量查验'){
          this.responseResult = this.handleImportInfoVat(JSON.parse(val.resultjson).data.importDto.result);
        } else {
          this.responseResult = this.handleImportInfoVat(JSON.parse(val.resultjson).data.result);
        }    
        this.showUrl = 'messageDialog5'
        this.dialogVisible = true
      } else if (val.taskcategory === "VATRPT") {
        if (val.taskfunctionname === 'VATRPT上传交易明细') {
          this.titleDialog = this.$t("baseHome.ImportData");
          this.typeTotal = this.$t("baseHome.ImportResult");
          this.responseResult = this.handleImportInfo(JSON.parse(val.resultjson).data.importDto.result);
          this.showUrl = 'messageDialog5'
          this.dialogVisible = true
        } else if (val.taskfunctionname === 'VATRPT二叉图数据获取-纳税申报' || val.taskfunctionname === 'VATRPT二叉图数据获取-税金计提') {
          this.iconClass = 'funnel'
          this.activeName = 'first'
          if (val.taskprogress === 100 && val.taskstatus === '10') {
            this.percentage = val.taskprogress
            this.iconClass = 'succes'
            this.journalBox = JSON.parse(JSON.parse(val.resultjson).messageInfo).dataMsg;
            this.journalBox2 = JSON.parse(JSON.parse(val.resultjson).messageInfo).reportMsg;
          } else if (val.taskstatus === '99') {
            let showError = Object.prototype.hasOwnProperty.call(val, 'resultjson') ? JSON.parse(val.resultjson).messageInfo : '系统异常，请联系管理员。'
            this.$alert(showError, this.$t("baseHome.ErrorMessage"), {
              type: 'error',
              confirmButtonText: this.$t("baseHome.Confirm1")
            });
            return
          } else {
            this.getContextMisVatrpt(val)
            this.misEitShow = setInterval(() => {
              this.getContextMisVatrpt(val)
            }, 1000);
          }
          this.showUrl = 'messageDialog3'
          this.dialogVisible = true;
        } else {
          let showError = Object.prototype.hasOwnProperty.call(val, 'resultjson') ? JSON.parse(val.resultjson).messageInfo : '系统异常，请联系管理员。'
          this.$alert(showError, this.$t("baseHome.ErrorMessage"), {
            type: 'error',
            confirmButtonText: this.$t("baseHome.Confirm1")
          });
          return
        }
      } else {
        this.getContext(val)
        this.showUrl = 'messageDialog1'
        this.journalBox = ''
        this.percentage = 0
      }
    },
    async getContext(oldVal) {
      let urlShow = "/frmbgtasks/" + oldVal.frmbgtaskuuid + "/gettaskinfo";
      await axios({
        method: "get",
        url: urlShow,
      }).then((val) => {
        try {
          this.journalBox = val.data.remark2.replace('</SPAN><BR>', '</SPAN>').replace(/\n/g, '');
          console.log(this.journalBox)
        } catch (error) {
          this.$alert(error, this.$t("baseHome.ErrorCode"), {
            type: 'error',
            confirmButtonText: this.$t("baseHome.Confirm1")
          });
          return
        }
        this.dialogVisible = true;
        clearInterval(this.dmShow)
        this.dmShow = null;
        this.dmShow = setInterval(() => {
          this.getContext(oldVal)
        }, 1000);
        this.iconClass = 'funnel'
        this.percentage = val.data.taskprogress
        if (val.data.taskprogress == 100) {
          this.iconClass = 'succes'
          clearInterval(this.dmShow)
          this.dmShow = null;
        }
      })
    },
    async getContextMisVatrpt(val) {
      let urlShow = "/tmsvatretwphs/" + val.remark + "/getetlstatus";
      await axios({
        method: "get",
        url: urlShow,
      }).then((val) => {
        this.percentage = val.data.etlstatus.etlProgress
        this.journalBox = val.data.etlstatus.msg.dataMsg;
        this.journalBox2 = val.data.etlstatus.msg.reportMsg;
        if (this.journalBox.indexOf("数据获取完成") != -1) {
          this.activeName = 'second'
        }
        if (val.data.etlstatus.etlProgress == 100) {
          this.iconClass = 'succes'
          clearInterval(this.misEitShow)
          this.misEitShow = null;
        }
      })
    },
    async getContextMisEit(val) {
      let urlShow = '';
      if (val.taskfunctionname.indexOf("印花税") !== -1) {
        urlShow = "/tmsmissdrhs/" + JSON.parse(val.remark).filingId + "/getetlstatus";
      } else if (val.taskfunctionname.indexOf("房土税") !== -1) {
        urlShow = "/tmsmisprorhs/" + JSON.parse(val.remark).filingId + "/getetlstatus";
      } else if (val.taskfunctionname.indexOf(val.taskfunctionname.indexOf("汇算清缴") !== -1 || val.taskfunctionname.indexOf("预缴申报") !== -1)) {
        urlShow = "/eitfilings/" + val.remark + "/getetlstatus"
      } else if (val.taskfunctionname.indexOf("关联申报") !== -1) {
        urlShow = "/eitrptfilings/" + val.remark + "/getetlstatus"
      } else if (val.taskfunctionname.indexOf("所得税费用") !== -1) {
        urlShow = "/eitdtamgts/" + val.remark + "/getetlstatus"
      }
      await axios({
        method: "get",
        url: urlShow,
      }).then((val) => {
        this.percentage = val.data.etlstatus.etlProgress
        this.journalBox = val.data.etlstatus.msg.dataMsg;
        this.journalBox2 = val.data.etlstatus.msg.reportMsg;
        if (this.journalBox.indexOf("数据获取完成") != -1) {
          this.activeName = 'second'
        }
        if (val.data.etlstatus.etlProgress == 100) {
          this.iconClass = 'succes'
          clearInterval(this.misEitShow)
          this.misEitShow = null;
        }
      })
    },
    /**
     * 消息跳转
     */
    toUrl() {
      // toUrl(val) {
      // var urlNow = "";
      // var urlTo = "";
      // var params = ""
      // Http.getInstance()
      //   .get("/uaa/cua")
      //   .then((res) => {
      //     switch (val.taskfunctionname) {
      //       case "DM数据集市上传":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/dmfileimpinfhs/views/gridview?srfmenuname%3Dmenuitem31%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_FILE_IMP_INF_H";
      //         break;
      //       case "DM-数据传送-申报数据上传-批量上传":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/dmtransferhtaxes/views/tabexpview?srfmenuname%3Dmenuitem14%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_TAXREPORTUPLOAD";
      //         break;
      //       case "DM-数据传送-完税凭证上传-批量上传":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/dmtransferhtaxes/views/tcpsgridview?srfmenuname%3Dmenuitem32%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_TAXCERTIFICATEUPLOAD";
      //         break;
      //       case "DM-数据传送-进项发票上传-上传发票":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/tmsdmcrinvpolhs/views/gridview?srfmenuname%3Dmenuitem20%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_CRINVUPLOAD";
      //         break;
      //       case "DM-数据传送-进项发票上传-上传抵扣清单":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/tmsdmcrinvpolhs/views/gridview?srfmenuname%3Dmenuitem20%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_CRINVUPLOAD";
      //         break;
      //       case "DM-数据传送-销项发票上传-上传发票":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/tmsvatdrinvpolhs/views/gridview?srfmenuname%3Dmenuitem30%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_DRINVUPLOAD_NEW";
      //         break;
      //       case "DM-数据传送-模板数据上传-上传（本级）":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/dmtransferhtaxes/views/etlgridview?srfmenuname%3Dmenuitem17%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_DATA_SEARCH";
      //         break;
      //       case "DM-ETL模型-数据格式化-数据同步":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/dmetldatasources/views/gridview?srfmenuname%3Dmenuitem10%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_FILTER";
      //         break;
      //       case "DM-ETL模型-数据过滤器-数据同步":
      //         urlNow = "DM";
      //         urlTo =
      //           "/dmindex/dmdatafilterdefhs/views/gridview?srfmenuname%3Dmenuitem11%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DDM_DATA_FILTER_DEF";
      //         break;
      //       case "TDA-税务机关报送报表-脱敏凭证查询-手动获取":
      //         urlNow = "TDA";
      //         urlTo =
      //           "/indextda/tdafilingaccsubs/views/gridview?srfmenuname%3Dmenuitem117%3Bsrfmenubaropen%3Dtrue";
      //         break;
      //       case "TDA-报表定义器-报表数据定义-同步（增量）":
      //         urlNow = "TDA";
      //         urlTo =
      //           "/indextda/tdadatasourcedefs/views/gridview?srfmenuname%3Dmenuitem55%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DTDA_DATA_DEFIN";
      //         break;
      //       case "TDA-报表定义器-报表数据定义-同步":
      //         urlNow = "TDA";
      //         urlTo =
      //           "/indextda/tdadatasourcedefs/views/gridview?srfmenuname%3Dmenuitem55%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DTDA_DATA_DEFIN";
      //         break;
      //       case "EIT数据集市上传":
      //         urlNow = "EIT";
      //         urlTo =
      //           "/homepage/eitfileimpinfhs/views/gridview?srfmenuname%3Dmenuitem63%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DEIT_FILE_IMP_INF_H";
      //         break;
      //       case "EIT数据集市数据分发":
      //         urlNow = "EIT";
      //         urlTo =
      //           "/homepage/eitfileimpinfhs/views/gridview?srfmenuname%3Dmenuitem63%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DEIT_FILE_IMP_INF_H";
      //         break;
      //       case "EIT二叉图数据获取-预缴申报":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/prepaymentview?eit_filingid=${val.remark}`;
      //         break;
      //       case "EIT二叉图数据获取-汇算清缴":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/premaincustom?eit_filingid=${val.remark}`;
      //         break;
      //       case "EIT二叉图数据获取-关联申报":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitrptfilings/${val.remark}/views/customview?eit_rpt_filingid=${val.remark}`;
      //         break;
      //       case "EIT二叉图数据获取-所得税费用":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitdtamgts/${val.remark}/views/incometaxdeclaration?eit_dta_mgtid=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-余额表":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewtb?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-关联申报-余额表":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitrptfilings/${val.remark}/views/filingtbeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-所得税费用-余额表":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitdtamgts/${val.remark}/views/dtatbeditview?dtaMgtId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-明细账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/eitfilingeditviewsub?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-关联申报-明细账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitrptfilings/${val.remark}/views/filingsubeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-所得税费用-明细账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitdtamgts/${val.remark}/views/dtatbsubeditview?dtaMgtId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-科目余额表核对":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewtbcheck?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-资产台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewbkfa?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-视同销售台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewbkdeemedsales?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-捐赠支出台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewbkdonation?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-佣金手续费台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewbkcommision?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-租金收入台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/rentseditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-利息收入台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/interestseditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-利息支出台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/interestsexeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-股息红利优惠台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/dividendeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-固定资产损失台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/lossoffaeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-资产损失台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewlossonallasset?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-股权":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewbkstock?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-融通资金台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewfinancing?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-关联申报-关联交易台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitrptfilings/${val.remark}/views/transactioneditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-关联申报-对外支付台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitrptfilings/${val.remark}/views/paymenteditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-被投资主体计税成本台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/investmentinfoeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-关联申报-权益性投资台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitrptfilings/${val.remark}/views/bkinvestmenteditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-股权激励台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/equityincentiveeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-存货报废盘亏台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/inventorylosseditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-残疾人工资台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewhandicappedsalary?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-无合理发票台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewnofapiao?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-财务报表":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/bkfinancialreporteditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-所得税费用-财务报表":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitdtamgts/${val.remark}/views/dtafinancialreporteditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-境外费用支付台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewpaymentoverseas?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-应收坏账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/bkarwriteoffeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-投资收益台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/mdinvestmentprofiteditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-所得税费用-所得税费用审计调整":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitdtamgts/${val.remark}/views/dtaadjeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-所得税费用-所得税费用台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitdtamgts/${val.remark}/views/dtaredteditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-政府补助台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/publicsubsidyeditview?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "EIT数据获取-纳税申报-免税农产品财务数据台账":
      //         urlNow = "EIT";
      //         urlTo =
      //           `/homepage/eitfilings/${val.remark}/views/editviewfinance?filingId=${val.remark}&srfkey=${val.remark}`;
      //         break;
      //       case "进项发票台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = `/homepage/tmsmisfapiaobks/views/gridviewcr?readonly=0&filingId=${JSON.parse(val.remark).filingId}`;
      //         break;
      //       case "销项发票台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = `/homepage/tmsmisfapiaobks/views/gridviewdr?readonly=0&filingId=${JSON.parse(val.remark).filingId}`;
      //         break;
      //       case "印花税申报数据获取":
      //         urlNow = "MIS";
      //         params = `tms_mis_sd_rhid=${JSON.parse(val.remark).filingId};base_tax_payername=${encodeURIComponent(this.context.srforgname)};srfparentdename=tmsmissdrh;srfparentkey=${JSON.parse(val.remark).filingId};srfkeys=${JSON.parse(val.remark).filingId};srfmenuname=menuitem133;srfisolation=MIS_SD_WB`;
      //         urlTo = `/homepage/tmsmissdrhs/${JSON.parse(val.remark).filingId}/views/listview?${encodeURIComponent(params)}`;
      //         break;
      //       case "印花税计提数据获取":
      //         urlNow = "MIS";
      //         params = `tms_mis_sd_rhid=${JSON.parse(val.remark).filingId};base_tax_payername=${encodeURIComponent(this.context.srforgname)};srfparentdename=tmsmissdrh;srfparentkey=${JSON.parse(val.remark).filingId};srfkeys=${JSON.parse(val.remark).filingId};srfmenuname=menuitem133;srfisolation=MIS_SD_PRV`;
      //         urlTo = `/homepage/tmsmissdrhs/${JSON.parse(val.remark).filingId}/views/taxaccrualdeclareview?${encodeURIComponent(params)}`;
      //         break;
      //       case "房土税申报数据获取":
      //         urlNow = "MIS";
      //         params = `tms_mis_pro_rhid=${JSON.parse(val.remark).filingId};base_tax_payername=${encodeURIComponent(this.context.srforgname)};srfparentdename=tmsmisprorh;srfparentkey=${JSON.parse(val.remark).filingId};srfkeys=${JSON.parse(val.remark).filingId};srfmenuname=menuitem133;srfisolation=MIS_PRO_DEC_CLAIM`;
      //         urlTo = `/homepage/tmsmisprorhs/${JSON.parse(val.remark).filingId}/views/listview?${encodeURIComponent(params)}`;
      //         break;
      //       case "房土税计提数据获取":
      //         urlNow = "MIS";
      //         params = `tms_mis_pro_rhid=${JSON.parse(val.remark).filingId};base_tax_payername=${encodeURIComponent(this.context.srforgname)};srfparentdename=tmsmisprorh;srfparentkey=${JSON.parse(val.remark).filingId};srfkeys=${JSON.parse(val.remark).filingId};srfmenuname=menuitem133;srfisolation=MIS_PRO_PRV`;
      //         urlTo = `/homepage/tmsmisprorhs/${JSON.parse(val.remark).filingId}/views/pretaxview?${encodeURIComponent(params)}`;
      //         break;
      //       case "采购订单台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = `/homepage/tmsmispotmps/views/bkgridview?readonly=0&filingId=${JSON.parse(val.remark).filingId}`
      //         break;
      //       case "会计科目台账数据源获取":
      //         urlNow = "MIS";
      //         urlTo = "/homepage/missdacctmps/views/gridview?srfmenuname%3Dmenuitem128%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DMIS_SD_TB_BOOK"
      //         break;
      //       case "会计科目台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = `/homepage/tmsmissdtbbooks/views/bkgridview?readonly=0&filingId=${JSON.parse(val.remark).filingId}`
      //         break;
      //       case "资源税台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = "/homepage/tmsmisresources/views/resourcebookgridview?srfmenuname%3Dmenuitem20181129%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DMIS_RESOURCE_BOOK"
      //         break;
      //       case "通用税费台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = "/homepage/tmsmisgeneralinfos/views/gridview?srfmenuname%3Dmenuitem53%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DMIS_GENERAL_INFO"
      //         break;
      //       case "销售清单台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = "/homepage/tmsmisconspsales/views/gridview?srfmenuname%3Dmenuitem43%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DMIS_CONSP_SALES"
      //         break;
      //       case "视同销售台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = "/homepage/tmsmisdeemedsales/views/gridview?srfmenuname%3Dmenuitem60%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DMIS_DEEMED_SALES"
      //         break;
      //       case "车船税台账数据获取":
      //         urlNow = "MIS";
      //         urlTo = "/homepage/vehicleinfoledgers/views/gridview?srfmenuname%3Dmenuitem47%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DMIS_VEHICLE_TB"
      //         break;
      //       case "VATRPT二叉图数据获取-纳税申报":
      //         urlNow = "VATRPT";
      //         urlTo = `/dashboard/tmsvatretwphs/${val.remark}/views/frnewformeditview?tms_vat_ret_wp_hid=${val.remark}&taxdecbtntype=tax`;
      //         break;
      //       case "VATRPT二叉图数据获取-税金计提":
      //         urlNow = "VATRPT";
      //         urlTo = `/dashboard/tmsvatretwphs/${val.remark}/views/forerchatucustomview?tms_vat_ret_wp_hid=${val.remark}&taxdecbtntype=accrual`;
      //         break;
      //       case "VATRPT分发数据-财务数据":
      //         urlNow = "VATRPT";
      //         urlTo = `/dashboard/vatfileimpinfhs/views/tabexpview?srfmenuname%3Dmenuitem4%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATRPT_DATA_IMP`;
      //         break;
      //       case "VATRPT获取交易池-数据集市":
      //         urlNow = "VATRPT";
      //         urlTo = `/dashboard/vatfileimpinfhs/views/tabexpview?srfmenuname%3Dmenuitem4%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATRPT_DATA_IMP`;
      //         break;
      //       case "VATRPT自动核销-发票核销":
      //         urlNow = "VATRPT";
      //         urlTo = `/dashboard/tmsvatoutinvoicels/views/dashboardview?srfmenuname%3Dmenuitem30%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATRPT_W_INVOICE`;
      //         break;
      //       case "VATRPT上传交易明细":
      //         urlNow = "VATRPT";
      //         urlTo = `/dashboard/tmsretdrvattrxpools/views/gridview?srfmenuname%3Dmenuitem24%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATRPT_TRX_POOL`;
      //         break;
      //       case "VAT-交易认定-原始交易查询-数据导入":
      //         urlNow = "VATFP";
      //         urlTo = "/dashboard/tmsdrtrxpoolinfs/views/oritrxinfoinquiregridview?srfmenuname%3Dmenuitem51%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATFP_AFFIRM_INF"
      //         break;
      //       case "VAT-销项发票-开票交易池-自动开票":
      //         urlNow = "VATFP";
      //         urlTo = "/dashboard/tmsdrvattrxpoolalls/views/tabexpview?srfmenuname%3Dmenuitem13%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATFP_TPOOL_VAT"
      //         break;
      //       case "VAT-销项发票流水统计-导出":
      //         urlNow = "VATFP";
      //         urlTo = "/dashboard/tmsvatdrinvpolls/views/lstjcustomview?srfmenuname%3Dmenuitem66%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATFP_TAX_RECON_LSTJ"
      //         break;
      //       case "VAT-销项发票行明细统计-导出":
      //         urlNow = "VATFP";
      //         urlTo = "/dashboard/tmsvatdrinvpolls/views/hmxtjcustomview?srfmenuname%3Dmenuitem73%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATFP_TAX_RECON_MXTJ"
      //         break;
      //       case "VAT-进项发票全票面信息-导出":
      //         urlNow = "VATFP";
      //         urlTo = "/dashboard/tmsvatcrinvpolls/views/gridviewforreport?srfmenuname%3Dmenuitem4%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATFP_CRPOLEASYUI"
      //         break;
      //       case "Excel导出-VAT-交易开票信息统计-导出":
      //         urlNow = "VATFP";
      //         urlTo = "/dashboard/tmsvatcrinvpolls/views/gridviewforreport?srfmenuname%3Dmenuitem4%3Bsrfmenubaropen%3Dtrue%3Bsrfisolation%3DVATFP_CRPOLEASYUI"
      //         break;
      //     }
      //     let urls = res.data.content || [];
      //     urls.forEach((s) => {
      //       if (s.sub_sys_code == urlNow) {
      //         let url = s.fullurl;
      //         if (url) {
      //           // 用rouer.push方法打开页面
      //           this.$store.commit('setActiveViewCache', null)
      //           this.$router.push({ path: urlTo });
      //         }
      //       }
      //     });
      //   });
    },
    /**
     * 消息展示
     */
    showStateFun(val, state) {
      var returnData = ''
      if (val == '00') {
        if (state == 1) {
          returnData = this.$t("baseHome.Progressing")
        } else {
          returnData = '#08C29E'
        }
      } else if (val == '10') {
        if (state == 1) {
          returnData = this.$t("baseHome.Completed")
        } else {
          returnData = '#03C1FD'
        }
      } else {
        if (state == 1) {
          returnData = this.$t("baseHome.Failed")
        } else {
          returnData = '#F52743'
        }
      }
      return returnData
    },
    /**
     * 处理taskfunctionname 截取第一部分
     */
    splitfunctionname(val) {
        return val.split("-")[0]
    },
    /**
     * 点击标签事件
     */
    handleTag(data) {
      // if (!data) return this.$throw(this.$t('components.appmessagepopover.geterror'), 'handleTag');
      // 拼接要打开的窗口地址
      const baseUrl = this.environment.BaseUrl;
      const openUrl = baseUrl +
        `/wfcore/mytasks/${data.processDefinitionKey}/web/${data.processInstanceBusinessKey}/usertasks/${data.taskDefinitionKey}`;
      // 打开新窗口
      window.open(openUrl, '_blank');
    },

    /**
     * 销毁之前
     */
    beforeDestroy() {
      // 清空数据
      this.showIsDot = false;
      this.myTasks = [];
      this.myMsgs = [];
    },

    /**
     * 时间格式转换
     */
    formatDate(date, format) {
      if (date && format) {
        return moment(date).format(format);
      }
      return date;
    },

    /**
     * 加载更多
     */
    showMore(cnt) {
      if (Object.is('taskShowCnt', cnt)) {
        this.taskShowCnt + 10 < this.myTasks.length ? this.taskShowCnt += 10 : this.taskShowCnt += this.myTasks
          .length - this.taskShowCnt;
      }
    },

    /**
     * 弹出框 显示/隐藏 时显示条数初始化
     */
    initTabCnt(val) {
      if (val == 1) {
        this.getMessage()
        this.showtimer = setInterval(() => {
          this.getMessage();
        }, 5000);
        this.getMyTasks()
      } else {
        clearInterval(this.showtimer);
      }
    },
    /**
  * 处理导出信息
  * @param result 响应数据
  * @param multipleSheet 是否为多sheet
  * @memberof AppDataUploadView
  */
    handleImportInfo(data) {
      let result = {}
      if (data.errorinfo) {
        const errors = Object.keys(data.errorinfo).map((key) => { return { info: data.errorinfo[key].errorInfo, index: key } });
        Object.assign(result, { errorInfos: errors });
      }
      if (data.errorfile) {
        Object.assign(result, { errorFile: data.errorfile });
      }
      if (data.importinfo) {
        Object.assign(result, { total: data.importinfo.total, success: data.importinfo.success, error: data.importinfo.error, warn: data.importinfo.warn });
      }
      return result
    },
    handleImportInfoVat(data) {
      let result = {}
      if (data.errorfile) {
        Object.assign(result, { errorFile: data.errorfile });
      }
      if (data.errorinfo) {
        const errors = Object.keys(data.errorinfo).map((key) => { return { info: data.errorinfo[key].errorInfo, index: key } });
        Object.assign(result, { errorInfos: errors });
      }
      if (data.loginfo) {
        const logs = Object.keys(data.loginfo).map((key) => { return { info: data.loginfo[key].log, index: key } });
        Object.assign(result, { logInfos: logs });
      }
      if (data.warninfo) {
        const warns = Object.keys(data.warninfo).map((key) => { return { info: data.warninfo[key].warn, index: key } });
        Object.assign(result, { warnInfos: warns });
      }
      if (data.importinfo) {
        Object.assign(result, { total: data.importinfo.total, success: data.importinfo.success, error: data.importinfo.error, warn: data.importinfo.warn, log: data.importinfo.log });
      }
      return result
    }
  }
}
</script>
<style lang='less' scoped>
@import "app-message-popover.less";
</style>
<style lang='less'>
.el-message-box__btns {
  button:hover {
    background: rgba(0, 104, 148, 1) !important;
    background-color: rgba(0, 104, 148, 1) !important;
    border-color: rgba(0, 104, 148, 1) !important;
  }
}
::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  background-color: #d6d6d6;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}
</style>