




























import { Component, Vue , Prop } from "vue-property-decorator";
import appUser from "@/components/app-user/app-user.vue";
import appLang from "@/components/app-lang/app-lang.vue";
import  appBreadcrumb  from "@/components/app-breadcrumb/app-breadcrumb.vue";
import { ApiService } from "@/api";
import { AppConfig } from "@/config/config";
import  appMessagePopover  from "./app-message-popover/app-message-popover.vue";
import { eventBus } from "@/service/micro-action-service";


@Component({
  components: {
    appUser,
    appLang,
    appBreadcrumb,
    appMessagePopover,
  },
})
export default class UserInfo extends Vue {
  loginbackgrdId:any = "";
  loginlogoId:any = "";
  loginsysnameId:any = "";
  loginCompanyLogo:any =""
  companylogoshort:any = ''
  created() {
    ApiService.getInstance()
      .getImages()
      .then((res) => {        
        res.data.result.loginbackgrd = res.data.result.loginbackgrd?JSON.parse(res.data.result.loginbackgrd):null;
        res.data.result.loginlogo = res.data.result.loginlogo?JSON.parse(res.data.result.loginlogo):null;
        res.data.result.loginsysname = res.data.result.loginsysname?JSON.parse(res.data.result.loginsysname):null;
        res.data.result.companyLogo = res.data.result.companyLogo?JSON.parse(res.data.result.companyLogo):null
        const context:any = localStorage.getItem('microAppData')
        if(context){
          const companylogoshort = JSON.parse(context).context.param.companylogoshort
          if(companylogoshort){
            this.companylogoshort = `${AppConfig.baseUrl}/ibizutil/download/${JSON.parse(companylogoshort)[0].id}`
          }else{
            this.companylogoshort = require(`/public/img/parameter-img/companylogoshort.png`)
          }
          this.handleHighrResolution(context)
        }
        this.loginbackgrdId = res.data.result.loginbackgrd?`${AppConfig.baseUrl}/ibizutil/download/${res.data.result.loginbackgrd[0].id}`: null;
        this.loginlogoId = res.data.result.loginlogo?`${AppConfig.baseUrl}/ibizutil/download/${res.data.result.loginlogo[0].id}`:require(`/public/img/parameter-img/t-suite.png`);
        this.loginsysnameId = res.data.result.loginsysname?`${AppConfig.baseUrl}/ibizutil/download/${res.data.result.loginsysname[0].id}`: null;
        this.loginCompanyLogo=res.data.result.companyLogo?`${AppConfig.baseUrl}/ibizutil/download/${res.data.result.companyLogo[0].id}`:require(`/public/img/parameter-img/companylogo.png`);
        
        /**
         * ---------- banner
         */
        res.data.result.portalBasePic = res.data.result.portalBasePic?JSON.parse(res.data.result.portalBasePic):null;
        const bannerLink = res.data.result.portalBasePic?`${AppConfig.baseUrl}/ibizutil/download/${res.data.result.portalBasePic[0].id}`: require(`/public/img/parameter-img/homelogo.png`);
        eventBus.$emit('bannerLink', bannerLink);
      });
      
  }

        /**
      * 判断是否开启高分辨率
      */
      handleHighrResolution(context:any){
        let _context = JSON.parse(context).context
        const sysstylemodel = _context.param.sysstylemodel
        let  isHighrResolution:boolean = false
        if(sysstylemodel){
            const _sysstylemodel = JSON.parse(sysstylemodel)
            isHighrResolution =_sysstylemodel?.cssValue?.showHighResolution || false
        }
        if(!isHighrResolution){
            this.delMediaQuery();
            (document as any).querySelector(':root').style.setProperty('--primary-font-size', '12px');
            (document as any).querySelector(':root').style.setProperty('--btn-font-size', '12px');
            (document as any).querySelector(':root').style.setProperty('--text-input-font-size', '12px');
        }
      }
    
      /**
      * 获取所有媒体查询,根据min-width: 1600px这个规则,删除掉不适用的媒体查询
      */
      public delMediaQuery () {
        this.$nextTick(() => {
          // 获取文档中的所有样式表
          let styleSheets = document.styleSheets;
          // 遍历样式表
          for (let i = 0; i < styleSheets.length; i++) {
            let styleSheet = styleSheets[i];
            // 获取样式表中的媒体查询规则
            try {
              let rules =  (styleSheet as CSSStyleSheet).cssRules
              // 遍历媒体查询规则
              for (let j = 0; j < rules.length; j++) {
                let rule = rules[j];
                // 判断规则是否为媒体查询规则
                if (rule instanceof CSSMediaRule) {
                  // 删除超过1600分辨率的媒体查询规则
                  if (rule.conditionText.indexOf('min-width: 1600px') != -1) {
                    (styleSheet as CSSStyleSheet).deleteRule(j);
                    // 删除规则后，需要将索引减1，否则会跳过一个规则
                    j--;
                  }
                }
              }
            } catch (e) {
              console.warn(e);
            }
          }
        })
      }
  

  @Prop() collapsed:boolean = false

  get headerStyle(){
    return {
      width: this.collapsed ? "80px" : "200px"
    }
  }

  get headerLogin(){
    return this.collapsed ? this.companylogoshort : this.loginCompanyLogo
  }
}

