import { apps } from "@/micro";
import { AppService } from "@/service/app-service";
import store from "./store";

class Shared {
  /**
   * 获取全部页面
   */
  public getAllPage(): Array<any> {
    const state = store.getState();
    return state.pages || [];
  }

  /**
   * 获取历史路径列表
   */
  public getHistoryPathList(): Array<string> {
    const state = store.getState();
    return state.historyPathList || [];
  }

  /**
   * 添加页面
   */
  public addPage(payload: any): void {
    const allPages = this.getAllPage();
    //基座应用判重
    if(!payload.microAppName){
      const activeIndex = allPages.findIndex((page:any) =>{
        return page.fullPath === payload.fullPath;
      })
      if(activeIndex !== -1){
        return;
      }
    }
    store.dispatch({
      type: "ADD_PAGE",
      payload
    });
  }

  /**
   * 更新页面
   * @param payload 
   */
  public updatePage(payload: any): void {
    const allPages = this.getAllPage();
    if (allPages && allPages.length > 0) {
      const index = allPages.findIndex((page: any) => {
        return page.microAppName + page.fullPath === payload.microAppName + payload.fullPath;
      });
      if (index > -1) {
        store.dispatch({
          type: "UPDATE_PAGE",
          payload: { index, data: payload }
        });
      }
    }
  }

  /**
   * 删除页面
   */
  public removePage(payload: any): void {
    const allPages = this.getAllPage();
    if (allPages && allPages.length > 0) {
      const index = allPages.findIndex((page: any) => {
        return page.microAppName + page.fullPath === payload.microAppName + payload.fullPath;
      });
      if (index > -1) {
        store.dispatch({
          type: "REMOVE_PAGE",
          payload: index
        });
      }
      //判断数据中是否有isMicro这个参数，如果有说明是子应用里点“完成”按钮得时候需要关掉视图并且通知基座关掉当前页签，走到这里得时候已经将页签关闭了，不需要再一次关闭了
      if (payload.isMicro) return;
      //如果是基座里关闭页签走这里
      if(payload.microAppName){
        AppService.getInstance().noticeMicroApp({ tag: payload.microAppName, action: 'REMOVE_PAGE', data: payload });
      }
    }
  }

  /**
   * 删除所有页面
   */
  public removeAllPage(): void {
    store.dispatch({
      type: "REMOVE_ALL"
    });
    AppService.getInstance().noticeAllMicroApp('REMOVE_ALL');
  }

  /**
   * 删除其他页面
   */
  public removeOther(payload: any): void {
    store.dispatch({
      type: "REMOVE_OTHER",
      payload
    });
    if (apps && apps.length > 0) {
      apps.forEach((app: any) => {
        if (Object.is(payload.microAppName, app.name)) {
          AppService.getInstance().noticeMicroApp({ tag: app.name, action: 'REMOVE_OTHER', data: payload });
        } else {
          AppService.getInstance().noticeMicroApp({ tag: app.name, action: 'REMOVE_ALL', data: payload });
        }
      })
    }
  }

  /**
   * 添加历史记录
   */
  public addHistoryPaths(payload: any): void {
    store.dispatch({
      type: "ADD_HISTORY_PATHS",
      payload
    });
  }

  /**
  * 获取菜单数据
  */
  public getmenus():Array<any>{
    const state = store.getState();
    return state.menus || []
  }

  /**
  * 设置菜单数据
  */
 public setmenus(payload:any){
  store.dispatch({
    type: "ADD_MENU_MAP",
    payload
  });
 }

  /**
  * 设置面包屑所有数据
  */
  public setActiveCrumbPathAll(payload:any){
    store.dispatch({
      type: "ADD_ACTIVEMENUPATHCOPY",
      payload
    });
  }

  /**
  * 设置面包屑当前数据
  */
  public setActiveCrumbPath(payload:any){
    store.dispatch({
      type: "ADD_ACTIVEMENUPATH",
      payload
    });
  }


  /**
  * 获取需要展示在面包屑上的数据
  */
  public getActiveCrumbList():Array<any>{
    const state = store.getState();
    return state.activeCrumbList || []
  }

  /**
  * 获取面包屑所有数据
  */
   public getActiveCrumbPathAll():Array<any>{
    const state = store.getState();
    return state.activeCrumbPathAll || []
  }
  /**
  * 更新面包屑上的数据
  */
  public updateActiveCrumbList(payload: any): void {
    store.dispatch({
      type: "UPDATE_ACTIVEMENUPATH",
      payload
    });
  }

  public sessionPage(payload:any):void{
    store.dispatch({
      type: "SESSION_PAGE",
      payload
    });
  }
  
  /**
  * 清空页签及面包屑 
  * @param payload 
  */
  public clearCache(payload:any):void{
    store.dispatch({
      type: "CLEAR_CACHE",
      payload
    });
  }

  /**
  * base系统参数配置--存
  */
   public setBaseConfig(payload:any){
    store.dispatch({
      type: "ADD_BASE_CONFIG",
      payload
    });
  }

  /**
  * base系统参数配置--取
  */
  public getBaseConfig(): object{
    const state = store.getState();
    return state.baseConfig || {};
  }

  /**
  * 设置是否开启自定义菜单
  */
  public setAppMemu(payload:any){
    store.dispatch({
      type: "SET_APP_MENU",
      payload
    });
  }

    /**
  * 获取是否开启自定义菜单
  */
  public getAppMemu():boolean{
    const state = store.getState();
    return state.isAppMenu || true
  }
}

const shared = new Shared();
export default shared;
