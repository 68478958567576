import { Component, Vue } from 'vue-property-decorator';
import { AppService } from "@/service/app-service";
import shared from "@/shared";
import { Http } from "@/net";

@Component
export class OpenMenu extends Vue {
    /**
     * 从components/menu/index.vue中复制出来
     * @param item 
     */
    openMenuMixin(item: any) {
        if (item.resourceId) {
            this.setHistory(item.resourceId)
        }
        if (item && item.path) {
            this.$router.push(item.path);
            // 微应用
            if (item.apptag && item.funtag) {
                AppService.getInstance().noticeMicroApp({
                    tag: item.apptag,
                    action: "MENUCLICK",
                    data: item,
                });
            } else {
                //基层应用
                setTimeout(() => {
                    const tempRoute = {};
                    if(item.apptag === '首页' || item.apptag === 'Home'){
                        Object.assign(tempRoute, {
                            fullPath: item.path,
                            caption: this.$i18n.locale === 'zh-CN' ? "首页" : 'Home',
                            ...this.$route.meta,
                        });
                    }else{
                        Object.assign(tempRoute, {
                            fullPath: this.$route.fullPath,
                            ...this.$route.meta,
                        });
                    }
                    shared.addPage(tempRoute);
                }, 0);
            }
        }
    }


    /**
     * 统计最近使用
     */
    async setHistory(resourceId: string) {
        Http.getInstance().post("/portal/menu/history", { resourceId })
    }



    /**
     * 从菜单中处理出子菜单
     * 返回叶子菜单
     */
    formattingGetPSAppMenuItems(children: any) {
        let arr: any = [];
        let getLeaf = (children: any) => {
            for (let index = 0; index < children.length; index++) {
                const node = children[index];
                if (node.children?.length) {
                    getLeaf(node.children);
                } else {
                    arr.push(this.resChildrenMenuItem(node));
                }
            }
        };
        getLeaf(children);
        return arr;
    }

    /**
     * 返回子菜单内容
     */
    resChildrenMenuItem(item: any) {
        const o = {
            ...item,
            caption: item.caption,
            caption2: item.caption2,
            src: require("../../../assets/img/icon-import.svg"),
            iconClass:
                this.iconMap[item.resourceId] && this.iconMap[item.resourceId].icon,
            color:
                (this.iconMap[item.resourceId] && this.iconMap[item.resourceId].color) ?
                    this.iconMap[item.resourceId].color :
                    this.randomColor(),
        };
        return o;
    }

    /**
     * 获取国际化标题
     */
    private getCaption(item: any) {
        switch (this.$i18n.locale) {
            case 'zh-CN':
                return item.caption;
            case 'en-US':
                return item.caption2 ? item.caption2 : item.caption;
            case 'zh-TW':
            case 'zh-HK':
                return item.caption3 ? item.caption3 : item.caption;
            default:
                return item.caption;
        }
    }

    /**
     * 生成随机颜色
     */
    randomColor() {
        const r = Math.floor(Math.random() * 200);
        const g = Math.floor(Math.random() * 200);
        const b = Math.floor(Math.random() * 200);
        return "rgba(" + r + "," + g + "," + b + ",1)";
    }


    /**
     * ---------- 菜单icon映射
     */

    /**
     * label 只作为代码阅读使用，没有在界面展示
     */
    iconMap: any = {
        "BASE_BUS_USER": {
            label: "用户管理",
            color: "rgba(86,128,119,1)",
            icon: "las la-user-cog"
        },
        "BASE_AUTH_USER_AUTH": {
            label: "角色清单",
            color: "rgba(99,55,68,1)",
            icon: "las la-user-alt"
        },
        "BASE_BUS_ORG": {
            label: "组织管理",
            color: "rgba(39,151,113,1)",
            icon: "las la-sitemap"
        },
        "BASE_EXCHANGE": {
            label: "汇率信息",
            color: "rgba(38,2,8,1)",
            icon: "las la-dollar-sign"
        },
        "BASE_INVOICE_MGT": {
            label: "用户发票权限",
            color: "rgba(68,100,61,1)",
            icon: "las la-chalkboard-teacher"
        },
        "BASE_TAXPAYER_INFO": {
            label: "纳税主体信息维护",
            color: "rgba(4,63,72,1)",
            icon: "las la-archive"
        },
        "VATFP_TPOOL_VAT": {
            label: "增值税开票交易",
            color: "rgba(142,100,65,1)",
            icon: "las la-balance-scale"
        },
        "VATFP_TRX_POOL_INF": {
            label: "原始交易查询",
            color: "rgba(104,1,115,1)",
            icon: "las la-balance-scale-left"
        },
        "VATFP_BATCH_REQ_H1": {
            label: "批量开票",
            color: "rgba(27,184,29,1)",
            icon: "las la-angle-double-right"
        },
        "VATFP_PRINT": {
            label: "纸质发票池",
            color: "rgba(39,86,102,1)",
            icon: "las la-chalkboard"
        },
        "VATFP_SALE_GTFAPIAO": {
            label: "金税发票核销",
            color: "rgba(28,134,123,1)",
            icon: "las la-th-list"
        },
        "VATFP_CREDITVOID_NAV": {
            label: "发起红冲作废",
            color: "rgba(92,48,121,1)",
            icon: "las la-undo-alt"
        },
        "VATFP_AFFIRM_APPROVAL": {
            label: "审批红冲作废",
            color: "rgba(2,76,95,1)",
            icon: "las la-redo-alt"
        },
        "VATFP_INV_REV_H": {
            label: "红字信息表",
            color: "rgba(81,59,69,1)",
            icon: "las la-columns"
        },
        "VATFP_SAL_CUSTOMER": {
            label: "客户主信息",
            color: "rgba(91,86,86,1)",
            icon: "las la-user-tie"
        },
        "VATFP_MATERIAL_REFLECT": {
            label: "四级分类映射表",
            color: "rgba(90,6,174,1)",
            icon: "las la-stream"
        },
        "VATFP_MATERIAL_ALL": {
            label: "物料主数据表",
            color: "rgba(59,38,65,1)",
            icon: "las la-percentage"
        },
        "VATFP_MATERIAL_DATA": {
            label: "物料拓展表",
            color: "rgba(82,187,160,1)",
            icon: "las la-tasks"
        },
        "VATFP_SKU_DATA": {
            label: "SKU拓展表",
            color: "rgba(15,194,59,1)",
            icon: "las la-folder"
        },
        "VATFP_ALL_ENT_POOL": {
            label: "发票全票面查询",
            color: "rgba(36,89,137,1)",
            icon: "las la-search"
        },
        "VATFP_INP_CUSPOOL": {
            label: "海关缴款书查询",
            color: "rgba(143,45,188,1)",
            icon: "las la-book"
        },
        "VATFP_INP_INVDEDU": {
            label: "发票勾选",
            color: "rgba(32,158,156,1)",
            icon: "las la-external-link-square-alt"
        },
        "VATFP_INP_INV_STAT": {
            label: "发票统计确认",
            color: "rgba(122,179,123,1)",
            icon: "las la-paste"
        },
        "VATFP_INVENTORY_ITM": {
            label: "商品和服务分类编码定义",
            color: "rgba(81,58,122,1)",
            icon: "las la-archive"
        },
        "VATFP_RULE_MAP": {
            label: "发票规则引擎设置",
            color: "rgba(70,159,68,1)",
            icon: "las la-cog"
        },
        "VATFP_LEGAL_ENTITY": {
            label: "开票限额设置",
            color: "rgba(66,18,133,1)",
            icon: "las la-reply-all"
        },
        "VATFP_EQUIPMENT_INFO": {
            label: "打印设备定义",
            color: "rgba(176,166,82,1)",
            icon: "las la-box"
        },
        "VAT_OUTLET_INVOICE": {
            label: "外销发票明细",
            color: "rgba(179,26,23,1)",
            icon: "las la-file-invoice"
        },
        "VAT_OUTLET_REPORT": {
            label: "出口退税报表",
            color: "rgba(16,186,180,1)",
            icon: "las la-money-check"
        },
        BASE_CALEND_RULE: {
            label: "税局征期规则",
            color: "rgba(16,186,180,1)",
            icon: "las la-calendar-check",
        },
        BASE_CALEND_REMIND: {
            label: "税务日历提醒规则",
            color: "rgba(179,26,23,1)",

            icon: "las la-calendar",
        },
        BASE_CALEND_OUTCOME: {
            label: "报税提醒结果表",
            color: "rgba(70,159,68,1)",
            icon: "las la-equals",
        },
        BASE_BUS_DEPT: {
            label: "部门管理",
            color: "rgba(33,146,180,1)",
            icon: "las la-share-alt",
        },
        BASE_BUS_ACCSET: {
            label: "账套管理",
            color: "rgba(122,86,180,1)",
            icon: "las la-file-alt",
        },
        BASE_ACCSUB: {
            label: "会计科目管理",
            color: "rgba(39,95,114,1)",
            icon: "las la-file",
        },
        BASE_INDUSTRY_LEVEL: {
            label: "行业信息",
            color: "rgba(131,186,120,1)",
            icon: "las la-info",
        },
        BASE_PERIOD: {
            label: "申报期间管理",
            color: "rgba(122,179,123,1)",
            icon: "lab la-spotify",
        },
        BASE_CTM_CT: {
            label: "代码表",
            color: "rgba(15,194,59,1)",
            icon: "las la-file-code",
        },
        BASE_DFLTAXPAY_AUTHORITY: {
            label: "纳税主体信息自助",
            color: "rgba(16,186,180,1)",
            icon: "las la-archive",
        },
        BASE_TAXRULE: {
            label: "税目规则管理",
            color: "rgba(166,116,33,1)",
            icon: "las la-sticky-note",
        },
        BASE_TAXFREE: {
            label: "减免税规则管理",
            color: "",
            icon: "las la-tasks",
        },
        BASE_NATIONFINDISTRIBUT: {
            label: "中央财政分配",
            color: "",
            icon: "las la-landmark",
        },
        BASE_PROVINCEFINDIS: {
            label: "省级财政分配",
            color: "",
            icon: "las la-university",
        },
        BASE_BUS_EDITUSER: {
            label: "修改用户信息",
            color: "",
            icon: "las la-user-edit",
        },
        BASE_MODIFY_LOGINACC: {
            label: "修改登录用户信息",
            color: "",
            icon: "las la-user-tag",
        },
        BASE_USER_ORG_ROLE: {
            label: "用户组织/角色权限",
            color: "",
            icon: "las la-users",
        },
        BASE_BUS_ORG_RE: {
            label: "组织管理-房地产",
            color: "",
            icon: "las la-project-diagram",
        },
        BASE_FRAMEWORK: {
            label: "组织扩展架构",
            color: "",
            icon: "las la-bezier-curve",
        },
        BASE_REGION_CODE: {
            label: "地区管理",
            color: "",
            icon: "las la-map",
        },
        BASE_FILING_RULE_SET: {
            label: "参数规则设置",
            color: "",
            icon: "las la-poll",
        },
        BASE_PARAM_HIS: {
            label: "全局参数设置",
            color: "",
            icon: "las la-poll-h",
        },
        BASE_LAND_DEDUCE_RULE: {
            label: "土地成本抵减规则设置",
            color: "",
            icon: "las la-calculator",
        },
        BASE_ORG_BU_MAPPING: {
            label: "组织事业部关系映射",
            color: "",
            icon: "las la-code-branch",
        },
        BASE_TAXPAY_AUTHORITY_RE: {
            label: "纳税主体信息自助-房地产",
            color: "",
            icon: "las la-archive",
        },
        BASE_PROJECTREPORT: {
            label: "申报项目",
            color: "",
            icon: "las la-file-alt",
        },
        BASE_PROJECT: {
            label: "项目信息",
            color: "",
            icon: "las la-project-diagram",
        },
        BASE_STAGE: {
            label: "分期",
            color: "",
            icon: "las la-balance-scale-left",
        },
        BASE_BUILDING: {
            label: "楼栋",
            color: "",
            icon: "las la-building",
        },
        BASE_HOUSE: {
            label: "房间",
            color: "",
            icon: "las la-home",
        },
        BASE_FORMAT_DETAIL: {
            label: "明细业态",
            color: "",
            icon: "las la-chart-bar",
        },
        BASE_VOUCHER_CONFIG: {
            label: "凭证配置",
            color: "",
            icon: "las la-certificate",
        },
        BASE_TAX_PMT: {
            label: "实缴税金登记",
            color: "",
            icon: "las la-marker",
        },
        BASE_RET_WFCONIG: {
            label: "工作流配置",
            color: "",
            icon: "las la-stream",
        },
        BASE_MTM_MTC: {
            label: "消息模板配置",
            color: "",
            icon: "las la-info",
        },
        BASE_MTM_RCSTC: {
            label: "消息模板与业务模块关系配置",
            color: "",
            icon: "las la-info-circle",
        },
        BASE_TABLEAU_TEMPLATE: {
            label: "TABLEAU模板管理",
            color: "",
            icon: "las la-folder-open",
        },
        BASE_TAXPAYER_GROUP: {
            label: "纳税主体组管理",
            color: "",
            icon: "las la-briefcase",
        },
        BASE_RPT_DEF: {
            label: "报表模板管理-纳税主体版",
            color: "",
            icon: "las la-business-time",
        },
        BASE_FUNC_REPORT: {
            label: "报表模板管理",
            color: "",
            icon: "las la-paste",
        },
        BASE_AUTH_SYS_ENTI: {
            label: "实体权限",
            color: "",
            icon: "las la-tasks",
        },
        BASE_NCM: {
            label: "序列号配置管理",
            color: "",
            icon: "las la-thumbtack",
        },
        BASE_AUTH_SYS_DATA: {
            label: "数据对象能力",
            color: "",
            icon: "las la-database",
        },
        BASE_INI_SINGVISIT: {
            label: "实体属性访问",
            color: "",
            icon: "las la-grip-vertical",
        },
        BASE_AUTH_SUBSYS: {
            label: "子系统设置",
            color: "",
            icon: "las la-grip-horizontal",
        },
        BASE_AUTH_SUB_NOTE: {
            label: "子系统节点管理",
            color: "",
            icon: "las la-grip-vertical",
        },
        BASE_FUNC_SSO: {
            label: "单点登录管理",
            color: "",
            icon: "las la-check-square",
        },
        BASE_FRM_CONFIG: {
            label: "配置中心管理",
            color: "",
            icon: "las la-clipboard-list",
        },
        BASE_FRM_TASK: {
            label: "任务管理中心",
            color: "",
            icon: "las la-clipboard-list",
        },
        BASE_FRM_TABLE_EXT_DEF: {
            label: "扩展列配置",
            color: "",
            icon: "las la-chart-bar",
        },
        BASE_BIZ_RULE_DATASTR: {
            label: "匹配规则定义",
            color: "",
            icon: "las la-clipboard-list",
        },
        BASE_LM_ALM: {
            label: "审计日志管理",
            color: "",
            icon: "las la-book",
        },
        BASE_DATA_CHG: {
            label: "数据变更日志",
            color: "",
            icon: "las la-briefcase",
        },
        BASE_LM_LUM: {
            label: "登录用户日志管理",
            color: "",
            icon: "las la-calendar",
        },
        BASE_FRM_AUDIT_LOG: {
            label: "操作审计日志",
            color: "",
            icon: "las la-expand",
        },
        BASE_FRM_REQUEST_LOG: {
            label: "请求访问审计日志",
            color: "",
            icon: "las la-mail-bulk",
        },
        BASE_FRM_MAIL: {
            label: "邮件发送日志",
            color: "",
            icon: "las la-mail-bulk",
        },
        VATFP_VAT_DR_INV: {
            "label": "电子票发票池",
            "color": "rgba(180,140,46,1)",
            icon: 'las la-tablet-alt'
        },
        VATFP_IPT_INV_VOU: {
            "label": "发票入账管理",
            "color": "rgba(84,35,4,1)",
            icon: 'las la-external-link-square-alt'
        },
        VATFP_IPTOUT_REVERSE: {
            "label": "红字信息管理",
            "color": "rgba(171,112,146,1)",
            icon: 'las la-arrows-alt'
        },
        VATFP_INVENTORY_CAT: {
            "label": "商品和服务大类",
            "color": "rgba(128,63,198,1)",
            icon: 'las la-clipboard'
        },
        VATFP_EMAIL_TEMPLATE: {
            "label": "邮件发送模板",
            "color": "rgba(81,70,173,1)",
            icon: 'las la-mail-bulk'
        },
        VATFP_SYST_DATA: {
            "label": "数据字典",
            "color": "rgba(91,122,20,1)",
            icon: 'las la-database'
        },

        "DM_FILE_IMP_INF_H": {
            color: "rgba(93,97,3,1)",
            icon: "las la-address-book",
            label: "财务数据上传"

    },
"DM_TAXREPORTUPLOAD": {
            color: "rgba(68,31,111,1)",
            icon: "las la-address-card",
            label: "申报数据上传"

    },
"DM_TAXCERTIFICATEUPLOAD": {
            color: "rgba(47,143,97,1)",
            icon: "las la-archive",
            label: "完税凭证上传"

    },
"DM_CRINVUPLOAD": {
            color: "rgba(19,162,38,1)",
            icon: "las la-balance-scale",
            label: "进项发票上传"

    },
"DM_DRINVUPLOAD": {
            color: "rgba(46,133,121,1)",
            icon: "las la-balance-scale-left",
            label: "销项发票上传"

    },
"DM_TMS_MIS_CONSP_SALES": {
            color: "rgba(105,77,1,1)",
            icon: "las la-balance-scale-right",
            label: "销售数据上传"

    },
"DM_DATA_SEARCH": {
            color: "rgba(97,10,170,1)",
            icon: "las la-birthday-cake",
            label: "模板数据上传"

    },
"DM_FILTER": {
            color: "rgba(153,60,138,1)",
            icon: "las la-book",
            label: "数据格式化"

    },
"DM_ITE_FILTER": {
            color: "rgba(189,17,182,1)",
            icon: "las la-briefcase",
            label: "税企直连数据格式化"

    },
"DM_DATA_FILTER_DEF": {
            color: "rgba(144,139,127,1)",
            icon: "las la-building",
            label: "数据过滤器"

    },
"DM_DATA_ITEM": {
            color: "rgba(91,158,34,1)",
            icon: "las la-bullhorn",
            label: "数据项管理"

    },
"DM_INTERFACE_LOG": {
            color: "rgba(60,102,18,1)",
            icon: "las la-bullseye",
            label: "接口日志"

    },
"DM_INTERFACE_ADMIN": {
            color: "rgba(39,79,182,1)",
            icon: "las la-business-time",
            label: "接口日志-系统管理员"

    },
"DM_INTER_ACCTSCOPE": {
            color: "rgba(67,141,21,1)",
            icon: "las la-calculator",
            label: "会计科目范围"

    },
"DM_INTER_MAP": {
            color: "rgba(89,158,54,1)",
            icon: "las la-calendar",
            label: "接口映射配置"

    },
"DM_INTER_STAND": {
            color: "rgba(88,116,114,1)",
            icon: "las la-calendar-alt",
            label: "接口规范管理"

    },
"DM_IMPORT_MAP": {
            color: "rgba(89,111,43,1)",
            icon: "las la-certificate",
            label: "导入映射配置"

    },
"DM_DATATRANS_TEMPLATE": {
            color: "rgba(185,120,138,1)",
            icon: "las la-chart-area",
            label: "报送模板维护"

    },
"DM_TEMPLATE_RULE": {
            color: "rgba(10,31,134,1)",
            icon: "las la-chart-bar",
            label: "报送规则维护"

    },
"DM_VALID_MGMT": {
            color: "rgba(35,109,78,1)",
            icon: "las la-chart-line",
            label: "申报表校验规则维护"

    },
"DM_DORIS_DATA": {
            color: "rgba(143,44,101,1)",
            icon: "las la-chart-pie",
            label: "申报数据源管理"

    },
"DM_EBSPZMX": {
            color: "rgba(40,172,91,1)",
            icon: "las la-city",
            label: "凭证查询"

    },
"RESCODE2": {
            color: "rgba(76,74,192,1)",
            icon: "las la-clipboard",
            label: "全文检索"

    },
"RESCODE4": {
            color: "rgba(192,28,129,1)",
            icon: "las la-coffee",
            label: "记账凭证"

    },
"RESCODE5": {
            color: "rgba(170,32,37,1)",
            icon: "las la-columns",
            label: "增值税发票"

    },
"RESCODE7": {
            color: "rgba(2,45,161,1)",
            icon: "las la-compass",
            label: "合同信息"

    },
"RESCODE6": {
            color: "rgba(7,182,79,1)",
            icon: "las la-copy",
            label: "付款申请单"

    },
   
"EVR_MD_CDF": {
            color: "rgba(25,55,51,1)",
            icon: "las la-envelope",
            label: "报关单台账"

    },
"EVR_MD_DELIVERY": {
            color: "rgba(41,102,163,1)",
            icon: "las la-envelope-open",
            label: "销售/出货明细台账"

    },
"EVR_MD_PURCHASE": {
            color: "rgba(171,4,158,1)",
            icon: "las la-envelope-square",
            label: "采购明细台账"

    },
"EVR_MD_BILLING": {
            color: "rgba(142,137,186,1)",
            icon: "las la-eraser",
            label: "外销账单台账"

    },
"EVR_MD_FAPIAO_MAP": {
            color: "rgba(92,32,182,1)",
            icon: "las la-fax",
            label: "金税发票匹配清单"

    },
"EVR_MD_FAPIAO": {
            color: "rgba(34,177,27,1)",
            icon: "las la-file",
            label: "金税发票清单"

    },
"EVR_MD_IN_INV": {
            color: "rgba(138,84,180,1)",
            icon: "las la-file-alt",
            label: "进项发票台账"

    },
"EVR_MD_VERIFICATION": {
            color: "rgba(159,48,81,1)",
            icon: "las la-folder",
            label: "收汇核销台账"

    },
"EVR_MD_TB": {
            color: "rgba(73,66,69,1)",
            icon: "las la-folder-minus",
            label: "科目余额表"

    },
"EVR_MD_MAPPING_EXTRA": {
            color: "rgba(57,170,137,1)",
            icon: "las la-folder-open",
            label: "出货单匹配表"

    },
"EVR_CDF_BILL_RECON": {
            color: "rgba(155,174,17,1)",
            icon: "las la-folder-plus",
            label: "报关单与账单核对"

    },
"EVR_FILING_MAPPING": {
            color: "rgba(112,139,2,1)",
            icon: "las la-glasses",
            label: "报关单与进项发票核销"

    },
"EVR_FILING_MAPPINGRE": {
            color: "rgba(11,95,149,1)",
            icon: "las la-globe",
            label: "报关单与进项发票核销结果"

    },
"EVR_FILING_FILING": {
            color: "rgba(198,125,153,1)",
            icon: "las la-highlighter",
            label: "退税申报管理-生产型"

    },
"EVR_FILING_T": {
            color: "rgba(93,84,134,1)",
            icon: "las la-industry",
            label: "退税申报管理-贸易型"

    },
"EVR_TP_ADJ": {
            color: "rgba(29,136,109,1)",
            icon: "las la-landmark",
            label: "转让定价调整"

    },
"EVR_RISK_VERI": {
            color: "rgba(25,64,136,1)",
            icon: "las la-marker",
            label: "外汇收款报告"

    },
"EVR_RISK_NCDF": {
            color: "rgba(190,135,6,1)",
            icon: "las la-paperclip",
            label: "未申报关单提醒"

    },
"EVR_RISK_INDEX": {
            color: "rgba(31,143,199,1)",
            icon: "las la-paste",
            label: "风险指标"

    },
"EVR_ANNUAL_VERI": {
            color: "rgba(133,175,185,1)",
            icon: "las la-pen",
            label: "年度收汇核销"

    },
"EVR_ANNUAL_NCDF": {
            color: "rgba(2,6,88,1)",
            icon: "las la-pen-alt",
            label: "年度未申报关单"

    },
"EVR_ANNUAL_INCOME": {
            color: "rgba(85,14,187,1)",
            icon: "las la-pen-fancy",
            label: "年度收入跟踪"

    },
"EVR_ANNUAL_RPT_SUMMARY": {
            color: "rgba(171,189,198,1)",
            icon: "las la-pen-nib",
            label: "出口收入统计"

    },
"EVR_ANNUAL_RPT_SUMMARY_YTD_GAP": {
            color: "rgba(190,169,135,1)",
            icon: "las la-pen-square",
            label: "出口收入差异统计-YTD"

    },
"EVR_ANNUAL_RPT_INVOICE_MAP": {
            color: "rgba(167,87,133,1)",
            icon: "las la-pencil-alt",
            label: "发票汇总-按关单"

    },
"EVR_ANNUAL_RPT_INVOICE_MAP_BILL": {
            color: "rgba(20,3,66,1)",
            icon: "las la-percent",
            label: "发票汇总-按账单"

    },
"EVR_RULE_CURRENCY": {
            color: "rgba(161,21,96,1)",
            icon: "las la-phone",
            label: "汇率维护"

    },
"EVR_RULE_CURRENCY_VIEW": {
            color: "rgba(70,123,133,1)",
            icon: "las la-phone-alt",
            label: "汇率查询"

    },
"EVR_RULE_MAPPING": {
            color: "rgba(19,34,20,1)",
            icon: "las la-phone-slash",
            label: "物料映射规则"

    },
"EVR_RULE_DEEMED": {
            color: "rgba(175,147,161,1)",
            icon: "las la-phone-square",
            label: "业务类型规则"

    },
"EVR_RULE_SOLUTION": {
            color: "rgba(48,48,120,1)",
            icon: "las la-phone-square-alt",
            label: "税务处理规则"

    },
"EVR_RULE_TBMAPPING": {
            color: "rgba(87,84,161,1)",
            icon: "las la-phone-volume",
            label: "科目余额表匹配规则"

    },
"EVR_RECON_RULE": {
            color: "rgba(38,50,131,1)",
            icon: "las la-print",
            label: "报关单与账单匹配规则"

    },
"EVR_RULE_FILE": {
            color: "rgba(193,43,71,1)",
            icon: "las la-project-diagram",
            label: "备查文档规则配置"

    },
"MIS_SD_CONTRACT": {
            color: "rgba(168,10,30,1)",
            icon: "las la-registered",
            label: "合同台账"

    },
"MIS_SD_TB_BOOK": {
            color: "rgba(79,25,129,1)",
            icon: "las la-save",
            label: "会计科目台账"

    },
"MIS_SD_PO": {
            color: "rgba(75,197,196,1)",
            icon: "las la-sitemap",
            label: "采购订单台账"

    },
"MIS_SD_CRFAPIAO": {
            color: "rgba(187,152,175,1)",
            icon: "las la-socks",
            label: "进项发票台账"

    },
"MIS_SD_DRFAPIAO": {
            color: "rgba(34,117,109,1)",
            icon: "las la-sticky-note",
            label: "销项发票台账"

    },
"MIS_SD_PRV": {
            color: "rgba(105,51,70,1)",
            icon: "las la-stream",
            label: "印花税计提"

    },
"MIS_SD_WB": {
            color: "rgba(104,74,152,1)",
            icon: "las la-table",
            label: "印花税申报"

    },
"MIS_ASSET_MAP": {
            color: "rgba(166,109,142,1)",
            icon: "las la-tag",
            label: "资产与税源映射关系"

    },
"MIS_ASSET_INFO": {
            color: "rgba(193,30,179,1)",
            icon: "las la-tags",
            label: "资产卡片信息台账"

    },
"MIS_GENERAL_PRO_INFO": {
            color: "rgba(158,158,121,1)",
            icon: "las la-tasks",
            label: "房产信息台账"

    },
"MIS_PROP_CHANGE": {
            color: "rgba(131,179,180,1)",
            icon: "las la-thumbtack",
            label: "房产信息变更台账"

    },
"MIS_LAND_GENERAL_USE": {
            color: "rgba(31,86,85,1)",
            icon: "las la-trademark",
            label: "土地信息台账"

    },
"MIS_PRO_PRV": {
            color: "rgba(193,87,33,1)",
            icon: "las la-wallet",
            label: "房土税计提"

    },
"MIS_PRO_DEC_CLAIM": {
            color: "rgba(174,107,106,1)",
            icon: "las la-address-book",
            label: "房产土地合并申报"

    },
"MIS_CONSP_CAR_LIST": {
            color: "rgba(67,54,126,1)",
            icon: "las la-address-card",
            label: "应税项目清单（乘用车）"

    },
"MIS_CAL_DRA_ITEM": {
            color: "rgba(199,68,161,1)",
            icon: "las la-archive",
            label: "应税项目清单"

    },
"TMS_VERIFI_RUSULT": {
            color: "rgba(150,150,20,1)",
            icon: "las la-balance-scale",
            label: "税局核定表"

    },
"MIS_CONSP_SALES": {
            color: "rgba(9,187,28,1)",
            icon: "las la-balance-scale-left",
            label: "销售清单台账"

    },
"MIS_AVERAGE_PRICE": {
            color: "rgba(121,194,185,1)",
            icon: "las la-balance-scale-right",
            label: "均价台账"

    },
"MIS_DEEMED_SALES": {
            color: "rgba(127,23,129,1)",
            icon: "las la-birthday-cake",
            label: "视同销售台账"

    },
"TMS_MIS_CONSTAX_PRD": {
            color: "rgba(110,50,91,1)",
            icon: "las la-book",
            label: "代扣代缴消费税台账"

    },
"MIS_CONSP_WB_VERIFY": {
            color: "rgba(96,71,143,1)",
            icon: "las la-briefcase",
            label: "消费税核定报表"

    },
"MIS_CONSP_CAR_PRE": {
            color: "rgba(61,101,87,1)",
            icon: "las la-building",
            label: "消费税预缴"

    },
"MIS_CONSP_CAR_WP": {
            color: "rgba(57,70,133,1)",
            icon: "las la-bullhorn",
            label: "消费税申报"

    },
"MIS_VEHICLE_TB": {
            color: "rgba(21,176,61,1)",
            icon: "las la-bullseye",
            label: "车船税台账"

    },
"MIS_VEHICLE_RH": {
            color: "rgba(19,190,180,1)",
            icon: "las la-business-time",
            label: "车船税申报"

    },
"MIS_EPT_LEDGER": {
            color: "rgba(187,187,76,1)",
            icon: "las la-calculator",
            label: "环保税台账"

    },
"MIS_EPT_LEDGER_GAS": {
            color: "rgba(94,57,114,1)",
            icon: "las la-calendar",
            label: "大气污染登记台账"

    },
"MIS_EPT_LEDGER_WATER": {
            color: "rgba(194,51,156,1)",
            icon: "las la-calendar-alt",
            label: "水污染物登记台账"

    },
"MIS_EPT_LEDGER_SOLID": {
            color: "rgba(170,132,77,1)",
            icon: "las la-certificate",
            label: "固体污染物登记台账"

    },
"MIS_EPT_LEDGER_NOISE": {
            color: "rgba(36,122,24,1)",
            icon: "las la-chart-area",
            label: "噪声污染登记台账"

    },
"MIS_EPT_H": {
            color: "rgba(168,129,195,1)",
            icon: "las la-chart-bar",
            label: "环保税申报"

    },
"MIS_EPT_CONFIG": {
            color: "rgba(42,87,18,1)",
            icon: "las la-chart-line",
            label: "环保税设置"

    },
"MIS_EPT_CONFIG_GAS": {
            color: "rgba(139,149,50,1)",
            icon: "las la-chart-pie",
            label: "大气水污染基础信息表"

    },
"MIS_EPT_CONFIG_SOLID": {
            color: "rgba(101,110,6,1)",
            icon: "las la-city",
            label: "固体废弃物基础信息表"

    },
"MIS_EPT_CONFIG_NOISE": {
            color: "rgba(30,115,12,1)",
            icon: "las la-clipboard",
            label: "噪声基础信息表"

    },
"MIS_RESOURCE_BOOK": {
            color: "rgba(33,16,90,1)",
            icon: "las la-coffee",
            label: "资源税台账"

    },
"MIS_RESOURCE_REPORT": {
            color: "rgba(99,146,95,1)",
            icon: "las la-columns",
            label: "资源税申报"

    },
"MIS_WATER_RESOURCE": {
            color: "rgba(11,82,90,1)",
            icon: "las la-compass",
            label: "水资源税申报"

    },
"MIS_WATER_RESOURCE_CONFIG": {
            color: "rgba(133,116,5,1)",
            icon: "las la-copy",
            label: "水资源基础信息"

    },
"MIS_RESOURCE_CONFIG": {
            color: "rgba(95,6,139,1)",
            icon: "las la-copyright",
            label: "资源税税目匹配"

    },
"MIS_GENERAL_INFO": {
            color: "rgba(111,59,85,1)",
            icon: "las la-cut",
            label: "通用税费台账"

    },
"MIS_GENERAL_FILING": {
            color: "rgba(103,199,5,1)",
            icon: "las la-edit",
            label: "通用税费申报"

    },
"MIS_LVINCREMENT_RECORD": {
            color: "rgba(36,88,16,1)",
            icon: "las la-envelope",
            label: "实缴土地增值税登记"

    },
"MIS_LVINCREMENT_FILING": {
            color: "rgba(195,36,110,1)",
            icon: "las la-envelope-open",
            label: "土地增值税申报"

    },
"MIS_LAT_RE_PROVISIONAL": {
            color: "rgba(149,47,5,1)",
            icon: "las la-envelope-square",
            label: "房地产土地增值税预缴"

    },
"MIS_LAT_RE_LIQUIDATIONAL": {
            color: "rgba(124,188,123,1)",
            icon: "las la-eraser",
            label: "房地产土地增值税清算"

    },
"MIS_DEEDTAX_ACCOUNT": {
            color: "rgba(32,87,73,1)",
            icon: "las la-fax",
            label: "契税登记"

    },
"MIS_FARM_OCC_WB": {
            color: "rgba(76,63,3,1)",
            icon: "las la-file",
            label: "耕地占用税登记"

    },
"MIS_RESIDUAL_INPUT": {
            color: "rgba(177,116,57,1)",
            icon: "las la-file-alt",
            label: "残保金登记"

    },
"MIS_VPT_REPORT": {
            color: "rgba(145,170,89,1)",
            icon: "las la-folder",
            label: "车辆购置税登记"

    },
"MIS_IIT_REPORT": {
            color: "rgba(117,107,69,1)",
            icon: "las la-folder-minus",
            label: "个税登记"

    },
"MIS_SOC_REPORT": {
            color: "rgba(95,44,192,1)",
            icon: "las la-folder-open",
            label: "社保登记"

    },
"MIS_TARIFF_REPORT": {
            color: "rgba(148,23,110,1)",
            icon: "las la-folder-plus",
            label: "关税登记"

    },
"MIS_IIT_SURVEY": {
            color: "rgba(53,73,21,1)",
            icon: "las la-glasses",
            label: "调查问卷"

    },
"MIS_IIT_BASICINFO": {
            color: "rgba(75,173,51,1)",
            icon: "las la-globe",
            label: "中国个人基本信息表"

    },
"MIS_IIT_PREPAID": {
            color: "rgba(12,100,150,1)",
            icon: "las la-highlighter",
            label: "个税预扣预缴登记"

    },
"MIS_IIT_MKT": {
            color: "rgba(197,22,139,1)",
            icon: "las la-industry",
            label: "营销费用代扣代缴个人所得税"

    },
"MIS_SYSTEM_CONFIG": {
            color: "rgba(199,104,169,1)",
            icon: "las la-landmark",
            label: "计提频率配置"

    },
"MIS_RESOURCE_UNIT": {
            color: "rgba(131,135,4,1)",
            icon: "las la-marker",
            label: "单位配置表"

    },
"MIS_CODELIST": {
            color: "rgba(177,24,42,1)",
            icon: "las la-paperclip",
            label: "代码表"

    },
"ATF_BUCONS_CONPRE": {
            color: "rgba(16,78,186,1)",
            icon: "las la-paste",
            label: "异地预征配置表"

    },
"ATF_BUCONS_BASPRO": {
            color: "rgba(155,184,127,1)",
            icon: "las la-pen",
            label: "建筑工程项目"

    },
"ATF_BUCONS_TRAN_OUT": {
            color: "rgba(50,21,117,1)",
            icon: "las la-pen-alt",
            label: "建筑分包工程项目台账"

    },
// "ATF_BUCONS_CONPRE": {
//             color: "rgba(160,44,109,1)",
//             icon: "las la-pen-fancy",
//             label: "建筑工程预缴台账"

//     },
"ATF_FILING_PRV_ONE_STEP": {
            color: "rgba(78,24,6,1)",
            icon: "las la-pen-nib",
            label: "一站式计提"

    },
"ATF_FILING_ONE_STEP": {
            color: "rgba(36,17,147,1)",
            icon: "las la-pen-square",
            label: "一站式申报"

    },
"ATF_FILING_SUM": {
            color: "rgba(22,195,179,1)",
            icon: "las la-pencil-alt",
            label: "税金汇总表"

    },
"ATF_FILING_PRVDETAIL": {
            color: "rgba(136,60,145,1)",
            icon: "las la-percent",
            label: "计提税金明细"

    },
"ATF_FILING_DECDETAIL": {
            color: "rgba(144,43,72,1)",
            icon: "las la-phone",
            label: "申报税金明细"

    },
"ATF_FILING_LEDGER": {
            color: "rgba(132,178,128,1)",
            icon: "las la-phone-alt",
            label: "税金台账"

    },
"ATF_RISK_INDEX_CONFIG": {
            color: "rgba(150,182,94,1)",
            icon: "las la-phone-slash",
            label: "业务风险指标配置"

    },
"NTPF_BASIC_TAXPAYER": {
            color: "rgba(157,147,118,1)",
            icon: "las la-phone-square",
            label: "扣缴义务人基本信息"

    },
"NTPF_BASIC_VENDOR": {
            color: "rgba(18,118,79,1)",
            icon: "las la-phone-square-alt",
            label: "非贸付汇供应商管理"

    },
"NTPF_CONTRACT": {
            color: "rgba(132,34,180,1)",
            icon: "las la-phone-volume",
            label: "非贸付汇合同管理"

    },
"NTPF_PAYMENT_PROFORMAINVOICE": {
            color: "rgba(46,134,162,1)",
            icon: "las la-print",
            label: "形式发票台账"

    },
"NTPF_PAYMENT": {
            color: "rgba(147,23,14,1)",
            icon: "las la-project-diagram",
            label: "非贸付汇支付登记"

    },
"NTPF_TAX_PAID": {
            color: "rgba(87,40,62,1)",
            icon: "las la-registered",
            label: "税金实缴登记"

    },
"NTPF_PAYMENT_PAID": {
            color: "rgba(113,48,48,1)",
            icon: "las la-save",
            label: "对外付汇实付登记"

    },
"NTPF_PAYMENT_RECON": {
            color: "rgba(0,124,78,1)",
            icon: "las la-sitemap",
            label: "对外支付核对"

    },
"NTPF_ANA_PROFORMAINVOICE": {
            color: "rgba(88,136,62,1)",
            icon: "las la-socks",
            label: "对外支付汇总报表"

    },
"NTPF_ANA_PAYMENT": {
            color: "rgba(197,120,141,1)",
            icon: "las la-sticky-note",
            label: "非贸付汇实缴明细"

    },
"NTPF_EIT_RATE_RULE": {
            color: "rgba(179,178,76,1)",
            icon: "las la-stream",
            label: "所得税协定税率规则"

    },
"TOM_CASE_LIST_H": {
            color: "rgba(103,51,63,1)",
            icon: "las la-table",
            label: "项目一户式看板"

    },
"TOM_CASE_LIST_H2": {
            color: "rgba(188,104,170,1)",
            icon: "las la-tag",
            label: "项目一户式"

    },
"TOM_RE_EVENT_TMP": {
            color: "rgba(185,130,144,1)",
            icon: "las la-tags",
            label: "全景计划节点查询"

    },
"TOM_RE_PLAN_NODE": {
            color: "rgba(4,95,72,1)",
            icon: "las la-tasks",
            label: "计划系统节点明细"

    },
"TOM_EVENT_INIT": {
            color: "rgba(71,118,74,1)",
            icon: "las la-thumbtack",
            label: "税务管控节点设置"

    },
"TOM_RE_EVENT_SET": {
            color: "rgba(195,59,188,1)",
            icon: "las la-trademark",
            label: "税务管控事项设置"

    },
"TOM_PROJECT_EVENT": {
            color: "rgba(52,159,122,1)",
            icon: "las la-wallet",
            label: "项目全流程管控"

    },
"TOM_PROJECT_EVENT_MATTERLIST": {
            color: "rgba(48,198,7,1)",
            icon: "las la-address-book",
            label: "项目全流程管控查询"

    },
"TOM_TAX_REPORT_LIST": {
            color: "rgba(24,119,22,1)",
            icon: "las la-address-card",
            label: "税筹报告查询"

    },
"TOM_CASE_TAX_REFUND": {
            color: "rgba(174,88,185,1)",
            icon: "las la-archive",
            label: "留抵退税"

    },
"TOM_CASE_NLAT_CLEAR": {
            color: "rgba(137,146,152,1)",
            icon: "las la-balance-scale",
            label: "土增清算"

    },
"TOM_CASE_LOSSPLAN": {
            color: "rgba(83,10,23,1)",
            icon: "las la-balance-scale-left",
            label: "弥补亏损"

    },
"TOM_CASE_SELFCHECK": {
            color: "rgba(163,70,97,1)",
            icon: "las la-balance-scale-right",
            label: "溢价消化"

    },
"TOM_CASE_CHECK": {
            color: "rgba(38,50,90,1)",
            icon: "las la-birthday-cake",
            label: "风险应对"

    },
"TOM_CASE_CANCEL": {
            color: "rgba(117,91,80,1)",
            icon: "las la-book",
            label: "公司注销"

    },
"TOM_CASE_DAILYPLAN": {
            color: "rgba(139,82,110,1)",
            icon: "las la-briefcase",
            label: "自定义事件"

    },
"TOM_CASE_FILE": {
            color: "rgba(37,80,96,1)",
            icon: "las la-building",
            label: "税务专项查询"

    },
"TOM_TAX_OPTIMIZATION": {
            color: "rgba(167,63,35,1)",
            icon: "las la-bullhorn",
            label: "税务资源数据上报"

    },
"TOM_TAX_OPTIMIZATION_MGT": {
            color: "rgba(27,22,113,1)",
            icon: "las la-bullseye",
            label: "税务资源任务管理"

    },
"TOM_TASK_FINAL_CHECK": {
            color: "rgba(17,176,51,1)",
            icon: "las la-business-time",
            label: "风险应对"

    },
"TOM_TASK_FILE_COLLECT": {
            color: "rgba(85,124,198,1)",
            icon: "las la-calculator",
            label: "附件收集"

    },
"TOM_LAT_LIQUIDATION_P": {
            color: "rgba(9,98,155,1)",
            icon: "las la-calendar",
            label: "土增清算"

    },
"TOM_MAKE_UP_LOSSES": {
            color: "rgba(180,149,137,1)",
            icon: "las la-calendar-alt",
            label: "税务亏损"

    },
"TOM_PREMIUM_DIGESTION": {
            color: "rgba(188,166,194,1)",
            icon: "las la-certificate",
            label: "股权溢价"

    },
"TOM_COMPANY_CANCEL": {
            color: "rgba(42,85,137,1)",
            icon: "las la-chart-area",
            label: "公司注销"

    },
"TOM_OPTIMIZATION_RULE": {
            color: "rgba(23,55,33,1)",
            icon: "las la-chart-bar",
            label: "税务资源筛选规则配置"

    },
"TOM_SCORE_EVENTSUMMARY": {
            color: "rgba(93,64,104,1)",
            icon: "las la-chart-line",
            label: "事项评分汇总"

    },
"TOM_SCORE_COMSUMMARY": {
            color: "rgba(84,159,80,1)",
            icon: "las la-chart-pie",
            label: "综合评分汇总"

    },
"TOM_FULLPERIOD_TAXMONITOR": {
            color: "rgba(1,142,35,1)",
            icon: "las la-city",
            label: "税负监控"

    },
"TOM_NLAT_PLANDETAILS": {
            color: "rgba(18,134,30,1)",
            icon: "las la-clipboard",
            label: "土增税规划明细"

    },
"TOM_NLAT_PLANDSUMMARY": {
            color: "rgba(44,112,167,1)",
            icon: "las la-coffee",
            label: "土增税规划汇总"

    },
"TOM_NLAT_FORMIMPORT": {
            color: "rgba(177,188,96,1)",
            icon: "las la-columns",
            label: "土增税申报表导入"

    },
"TOM_VAT_PLANDETAILS": {
            color: "rgba(77,44,0,1)",
            icon: "las la-compass",
            label: "增值税规划明细"

    },
"TOM_VAT_PLANDSUMMARY": {
            color: "rgba(37,163,36,1)",
            icon: "las la-copy",
            label: "增值税规划汇总"

    },
"TOM_EIT_PLANDETAILS": {
            color: "rgba(174,191,196,1)",
            icon: "las la-copyright",
            label: "企业所得税规划明细"

    },
"TOM_EIT_PLANDSUMMARY": {
            color: "rgba(117,115,71,1)",
            icon: "las la-cut",
            label: "企业所得税规划汇总"

    },
"TOM_CITIC_EXPRESS": {
            color: "rgba(19,80,55,1)",
            icon: "las la-edit",
            label: "威科先行法规速递"

    },
"TOM_LEGAL_CASE_MYINTERPRET": {
            color: "rgba(93,52,28,1)",
            icon: "las la-envelope",
            label: "我的法规解读"

    },
"TOM_LEGAL_CASE_MAINTAIN": {
            color: "rgba(13,121,51,1)",
            icon: "las la-envelope-open",
            label: "法规解读维护"

    },
"TOM_LEGAL_CASE_SEARCH": {
            color: "rgba(28,179,196,1)",
            icon: "las la-envelope-square",
            label: "法规查询"

    },
"TOM_LEGAL_MAINTAIN": {
            color: "rgba(1,144,167,1)",
            icon: "las la-eraser",
            label: "内部案例维护"

    },
"TOM_LEGAL_SEARCH": {
            color: "rgba(57,147,13,1)",
            icon: "las la-fax",
            label: "内部案例查询"

    },
"TOM_DTT_LAW": {
            color: "rgba(81,145,73,1)",
            icon: "las la-file",
            label: "德勤研修"

    },
"TOM_LAW_CASE_COLLECT": {
            color: "rgba(185,50,100,1)",
            icon: "las la-file-alt",
            label: "我的收藏"

    },
"TOM_FILE_SEARCH": {
            color: "rgba(108,118,56,1)",
            icon: "las la-folder",
            label: "档案查询"

    },
"TOM_FILE_MAINTAIN": {
            color: "rgba(56,128,137,1)",
            icon: "las la-folder-minus",
            label: "档案上传"

    },
"TOM_RE_FINANCE_REPORT": {
            color: "rgba(118,125,48,1)",
            icon: "las la-folder-open",
            label: "财务报表"

    },
    
"TOM_REPORT_OPTIMIZATION": {
            color: "rgba(108,88,65,1)",
            icon: "las la-glasses",
            label: "资源及进度统计表"

    },
"TOM_REPORT_OPTIMIZATION_LAT": {
            color: "rgba(21,90,106,1)",
            icon: "las la-globe",
            label: "土增清算安排和进度统计表"

    },
"TOM_REPORT_OPTIMIZATION_MAKEUPLOSSES": {
            color: "rgba(112,139,35,1)",
            icon: "las la-highlighter",
            label: "税务亏损安排和进度统计表"

    },
"TOM_REPORT_OPTIMIZATION_PREMIUMDIGESTION": {
            color: "rgba(185,132,24,1)",
            icon: "las la-industry",
            label: "股权溢价安排和进度统计表"

    },
"TOM_REPORT_OPTIMIZATION_TAXFINAL": {
            color: "rgba(111,101,19,1)",
            icon: "las la-landmark",
            label: "公司注销安排和进度统计表"

    },
"TOM_CASE_LIST_RULE_SET": {
            color: "rgba(96,112,169,1)",
            icon: "las la-marker",
            label: "事件管理规则设置"

    },
"TOM_APPRO_RULE_SET": {
            color: "rgba(86,59,136,1)",
            icon: "las la-paperclip",
            label: "事件审批规则配置"

    },
"TOM_TAX_NODE_MAP": {
            color: "rgba(154,56,41,1)",
            icon: "las la-paste",
            label: "税务节点映射"

    },
"TOM_LEGAL_CASERIGHT": {
            color: "rgba(50,189,23,1)",
            icon: "las la-pen",
            label: "案例权限"

    },
"TOM_RULE_AUTHORITY_CODELIST": {
            color: "rgba(4,192,74,1)",
            icon: "las la-pen-alt",
            label: "代码表"

    },
"TOM_RULE_RULE_CONFIG": {
            color: "rgba(136,13,140,1)",
            icon: "las la-pen-fancy",
            label: "评分/奖励规则配置"

    },
"TOM_RULE_VIEW_RECORD": {
            color: "rgba(139,118,97,1)",
            icon: "las la-pen-nib",
            label: "访客记录"

    },
"RND_SCREEEN_REG": {
            color: "rgba(4,7,157,1)",
            icon: "las la-pen-square",
            label: "项目信息登记1"

    },
"RND_SCREEN_LIST": {
            color: "rgba(157,175,129,1)",
            icon: "las la-pencil-alt",
            label: "研发项目清单复核"

    },
"RND_HNTE_PROJ_REV": {
            color: "rgba(27,56,199,1)",
            icon: "las la-percent",
            label: "高新项目清单复核"

    },
"RND_AP_MN": {
            color: "rgba(88,141,135,1)",
            icon: "las la-phone",
            label: "申报项目管理"

    },
"RND_SCREEN_MA_AP": {
            color: "rgba(58,63,24,1)",
            icon: "las la-phone-alt",
            label: "研发指定申报项目"

    },
"RND_SCREEN_MA_HIS": {
            color: "rgba(106,193,41,1)",
            icon: "las la-phone-slash",
            label: "研发申报项目历史查询"

    },
"RND_HNTE_PACK": {
            color: "rgba(1,36,101,1)",
            icon: "las la-phone-square",
            label: "高新指定申报项目"

    },
"RND_HNTE_PACK_HIS": {
            color: "rgba(46,139,130,1)",
            icon: "las la-phone-square-alt",
            label: "高新申报项目历史查询"

    },
"RND_SCREEEN_CON_MGT": {
            color: "rgba(34,118,189,1)",
            icon: "las la-phone-volume",
            label: "委托合同信息"

    },
"RND_SCREEN_CONTRACT": {
            color: "rgba(183,146,116,1)",
            icon: "las la-print",
            label: "项目合同信息登记"

    },
"RND_HTC_IP": {
            color: "rgba(101,13,139,1)",
            icon: "las la-project-diagram",
            label: "知识产权管理"

    },
"RND_SCREEN_RESEARCH": {
            color: "rgba(197,149,82,1)",
            icon: "las la-registered",
            label: "项目研发成果登记"

    },
"RND_SCREEN_REMA": {
            color: "rgba(154,98,77,1)",
            icon: "las la-save",
            label: "研发阶段管理"

    },
"RND_CC_IMPORT": {
            color: "rgba(144,42,166,1)",
            icon: "las la-sitemap",
            label: "明细账导入"

    },
"RND_CC_SAL": {
            color: "rgba(2,47,96,1)",
            icon: "las la-socks",
            label: "研发人员费用"

    },
"RND_CC_SAL_VDT": {
            color: "rgba(126,25,22,1)",
            icon: "las la-sticky-note",
            label: "人员费用科目明细"

    },
"RND_CC_SAL_RES": {
            color: "rgba(37,175,100,1)",
            icon: "las la-stream",
            label: "分摊结果"

    },
"RND_RDP_CHECK": {
            color: "rgba(14,195,148,1)",
            icon: "las la-table",
            label: "研发费用复核"

    },
"RND_HTC_CC": {
            color: "rgba(138,145,155,1)",
            icon: "las la-tag",
            label: "高新费用复核"

    },
"RND_CC_M": {
            color: "rgba(127,43,129,1)",
            icon: "las la-tags",
            label: "工时管理"

    },
"RND_CC_PROJ_TOTAL": {
            color: "rgba(29,35,199,1)",
            icon: "las la-tasks",
            label: "项目工时和考勤总工时"

    },
"RND_CC_WFILL": {
            color: "rgba(151,156,193,1)",
            icon: "las la-thumbtack",
            label: "工时填写"

    },
"RND_CC_WIMPORT": {
            color: "rgba(115,49,94,1)",
            icon: "las la-trademark",
            label: "工时记录明细"

    },
"RND_PROJ_HOUR": {
            color: "rgba(2,189,37,1)",
            icon: "las la-wallet",
            label: "工时比例"

    },
"RND_CC_EA": {
            color: "rgba(184,130,76,1)",
            icon: "las la-address-book",
            label: "费用分摊"

    },
"RND_CC_AUA_ALL": {
            color: "rgba(191,79,121,1)",
            icon: "las la-address-card",
            label: "待分摊费用"

    },
"RND_CC_EA_CONF": {
            color: "rgba(43,82,179,1)",
            icon: "las la-archive",
            label: "确认待分摊集合"

    },
"RND_CC_EA_UP": {
            color: "rgba(12,116,132,1)",
            icon: "las la-balance-scale",
            label: "上传分摊因子"

    },
"RND_CC_EA_CALCU": {
            color: "rgba(110,97,139,1)",
            icon: "las la-balance-scale-left",
            label: "分摊计算"

    },
"RND_CC_EA_CONR": {
            color: "rgba(57,137,34,1)",
            icon: "las la-balance-scale-right",
            label: "确认分摊结果"

    },
"RND_RDP_DOCU_REMA": {
            color: "rgba(95,42,113,1)",
            icon: "las la-birthday-cake",
            label: "研发文档责任分配"

    },
"RND_RDP_DOCU_UP": {
            color: "rgba(27,42,191,1)",
            icon: "las la-book",
            label: "研发项目文档上传"

    },
"RND_RDP_DOCU_CHECK": {
            color: "rgba(115,142,57,1)",
            icon: "las la-briefcase",
            label: "研发项目文档复核"

    },
"RND_HTC_DM_DPM": {
            color: "rgba(79,76,171,1)",
            icon: "las la-building",
            label: "高新文档责任分配"

    },
"RND_HTC_DM_UP": {
            color: "rgba(98,97,137,1)",
            icon: "las la-bullhorn",
            label: "高新文档上传"

    },
"RND_HTC_DM_CHE": {
            color: "rgba(77,39,27,1)",
            icon: "las la-bullseye",
            label: "高新文档复核"

    },
"RND_RDP_ACM": {
            color: "rgba(164,103,102,1)",
            icon: "las la-business-time",
            label: "研发辅助账管理"

    },
"RND_RND_LED_PRO": {
            color: "rgba(15,68,69,1)",
            icon: "las la-calculator",
            label: "辅助账明细台账"

    },
"RND_RDP_ACM_CREAT": {
            color: "rgba(126,72,108,1)",
            icon: "las la-calendar",
            label: "辅助账创建"

    },
"RND_RDP_ACM_ADJ": {
            color: "rgba(183,86,68,1)",
            icon: "las la-calendar-alt",
            label: "加计扣除金额调整"

    },
"RND_CC_AAR_AR": {
            color: "rgba(30,177,195,1)",
            icon: "las la-certificate",
            label: "无形资产登记"

    },
"RND_CC_AAR_AAR": {
            color: "rgba(33,29,27,1)",
            icon: "las la-chart-area",
            label: "无形资产摊销登记"

    },
"RND_RDP_ACM_Q": {
            color: "rgba(110,184,94,1)",
            icon: "las la-chart-bar",
            label: "辅助账查询"

    },
"RND_HTC_ACM": {
            color: "rgba(88,52,44,1)",
            icon: "las la-chart-line",
            label: "高新辅助账管理"

    },
"RND_HNTE_LED_PRO": {
            color: "rgba(152,33,120,1)",
            icon: "las la-chart-pie",
            label: "辅助账明细台账"

    },
"RND_HTC_ACM_C": {
            color: "rgba(109,67,116,1)",
            icon: "las la-city",
            label: "辅助账创建"

    },
"RND_HTC_YEAR_RH": {
            color: "rgba(71,25,73,1)",
            icon: "las la-clipboard",
            label: "辅助账查询"

    },
"RND_HTC_PRODUCT": {
            color: "rgba(84,17,75,1)",
            icon: "las la-coffee",
            label: "产品管理"

    },
"RND_HTC_INCOME": {
            color: "rgba(160,123,113,1)",
            icon: "las la-columns",
            label: "收入管理"

    },
"RND_HTC_ACM_AUX_TYPE": {
            color: "rgba(29,16,187,1)",
            icon: "las la-compass",
            label: "人力资源月明细表"

    },
"RND_HTC_ACM_AUX_MON": {
            color: "rgba(63,129,115,1)",
            icon: "las la-copy",
            label: "人力资源月末汇总表"

    },
"RND_RDP_FA": {
            color: "rgba(176,142,28,1)",
            icon: "las la-copyright",
            label: "研发申报管理"

    },
"RND_HTC_FAM": {
            color: "rgba(190,77,177,1)",
            icon: "las la-cut",
            label: "高新申报管理"

    },
"RND_RDP_DMA": {
            color: "rgba(63,19,142,1)",
            icon: "las la-edit",
            label: "研发档案管理"

    },
"RND_HTC_DMA": {
            color: "rgba(95,20,107,1)",
            icon: "las la-envelope",
            label: "高新档案管理"

    },
"RND_RDP_PROCESS": {
            color: "rgba(77,133,138,1)",
            icon: "las la-envelope-open",
            label: "申报进度监控报表"

    },
"RND_RDP_ANALY_M": {
            color: "rgba(76,36,183,1)",
            icon: "las la-envelope-square",
            label: "研发加计监控"

    },
"RND_RDP_ANALY_M_T": {
            color: "rgba(43,54,138,1)",
            icon: "las la-eraser",
            label: "税务合规性分析"

    },
"RND_RDP_ANALY_M_MA_R": {
            color: "rgba(29,71,98,1)",
            icon: "las la-fax",
            label: "录入报表数据"

    },
"RND_RDP_ANALY_M_D_P": {
            color: "rgba(32,109,149,1)",
            icon: "las la-file",
            label: "人工调整项目可加计状态"

    },
"RND_AUA_ADJ": {
            color: "rgba(71,67,145,1)",
            icon: "las la-file-alt",
            label: "人工调整明细账加计扣除状态"

    },
"RND_RDP_ANALY_M_HM": {
            color: "rgba(100,181,198,1)",
            icon: "las la-folder",
            label: "高新认定监控"

    },
"RND_HNTE_DA": {
            color: "rgba(88,168,131,1)",
            icon: "las la-folder-minus",
            label: "高新指标分析"

    },
"RND_HNTE_INPUTDA": {
            color: "rgba(173,152,91,1)",
            icon: "las la-folder-open",
            label: "录入报表数据"

    },
"RND_RDP_ANALY_COST": {
            color: "rgba(64,45,73,1)",
            icon: "las la-folder-plus",
            label: "费用核对"

    },
"RND_RDP_ANALY_VCR": {
            color: "rgba(119,15,4,1)",
            icon: "las la-glasses",
            label: "明细账公司核对"

    },
"RND_RDP_ANALY_VPR": {
            color: "rgba(35,151,98,1)",
            icon: "las la-globe",
            label: "明细账项目核对"

    },
"RND_RDP_ANALY_SDAR": {
            color: "rgba(153,29,60,1)",
            icon: "las la-highlighter",
            label: "研发辅助账试算"

    },
"RND_RDP_ANALY_HNTEAR": {
            color: "rgba(52,103,11,1)",
            icon: "las la-industry",
            label: "高企辅助账试算"

    },
"RND_RDP_SDFILAR": {
            color: "rgba(191,84,150,1)",
            icon: "las la-landmark",
            label: "研发申报前试算"

    },
"RND_RDP_HNTEFILAR": {
            color: "rgba(50,172,42,1)",
            icon: "las la-marker",
            label: "高企申报前试算"

    },
"RND_RDP_RNDSART": {
            color: "rgba(4,107,68,1)",
            icon: "las la-paperclip",
            label: "研发费用占比试算"

    },
"RND_RDP_HNTESART": {
            color: "rgba(135,25,136,1)",
            icon: "las la-paste",
            label: "高企费用占比试算"

    },
"RND_RDP_AUABNA_COM": {
            color: "rgba(40,48,179,1)",
            icon: "las la-pen",
            label: "加计处理前后数据核对"

    },
"RND_RDP_HNTEBNA_ACC": {
            color: "rgba(171,15,130,1)",
            icon: "las la-pen-alt",
            label: "高企处理前后科目比对"

    },
"RND_RDP_HNTEBNA_COM": {
            color: "rgba(27,28,112,1)",
            icon: "las la-pen-fancy",
            label: "高企处理前后数据核对"

    },
"RND_DOCU_RULES": {
            color: "rgba(78,128,155,1)",
            icon: "las la-pen-nib",
            label: "文档管理规则"

    },
"RND_RND_DOCU_RULES": {
            color: "rgba(167,182,86,1)",
            icon: "las la-pen-square",
            label: "研发加计文档规则"

    },
"RND_HNTE_DOCU_RULES": {
            color: "rgba(20,184,117,1)",
            icon: "las la-pencil-alt",
            label: "高新企业文档规则"

    },
"RND_FEE_RULE": {
            color: "rgba(165,22,199,1)",
            icon: "las la-percent",
            label: "费用分摊规则"

    },
"RND_PAY_RULE": {
            color: "rgba(92,127,181,1)",
            icon: "las la-phone",
            label: "薪资类型科目映射规则"

    },
"RND_DATA_INCACC": {
            color: "rgba(53,161,93,1)",
            icon: "las la-phone-alt",
            label: "会计科目收入类型匹配"

    },
"RND_CONFIG": {
            color: "rgba(128,27,187,1)",
            icon: "las la-phone-slash",
            label: "系统参数设置"

    },
"RND_DEVE_DEFI": {
            color: "rgba(124,130,3,1)",
            icon: "las la-phone-square",
            label: "研发形式定义"

    },
"RND_LEDGER_FEE_CAT": {
            color: "rgba(151,15,182,1)",
            icon: "las la-phone-square-alt",
            label: "辅助账费用类别"

    },
"RND_DATA_LIST_SET": {
            color: "rgba(63,164,108,1)",
            icon: "las la-phone-volume",
            label: "代码表设置"

    },
"RND_SYS_HNTE_SCOPE": {
            color: "rgba(34,19,117,1)",
            icon: "las la-print",
            label: "国家重点支持的高新技术领域"

    },
"RND_SYS_WDATE": {
            color: "rgba(95,182,121,1)",
            icon: "las la-project-diagram",
            label: "工作日设置"

    },
"RND_ACC_SUB_DEFI": {
            color: "rgba(172,168,158,1)",
            icon: "las la-registered",
            label: "会计科目定义"

    },
"TRA_RISK_RESULT_DEMO": {
            color: "rgba(120,165,169,1)",
            icon: "las la-save",
            label: "风险结果展示"

    },
"TRA_BY_RISK_SCENE": {
            color: "rgba(115,55,7,1)",
            icon: "las la-sitemap",
            label: "按风险场景展示"

    },
"TRA_BY_INDEX": {
            color: "rgba(195,101,58,1)",
            icon: "las la-socks",
            label: "按指标展示"

    },
"TRA_RISK_ANLS": {
            color: "rgba(125,130,121,1)",
            icon: "las la-sticky-note",
            label: "风险分析报告"

    },
"TRA_RISK_SCE_DEM": {
            color: "rgba(78,41,166,1)",
            icon: "las la-stream",
            label: "风险下发及审核"

    },
"TRA_RISK_SCE_RES": {
            color: "rgba(103,141,158,1)",
            icon: "las la-table",
            label: "应对反馈及处理"

    },
"TRA_OVERDUE_ISSUE": {
            color: "rgba(44,60,46,1)",
            icon: "las la-tag",
            label: "应对逾期查询"

    },
"TRA_WHITE_LIST": {
            color: "rgba(123,195,180,1)",
            icon: "las la-tags",
            label: "扫描白名单管理"

    },
"TRA_MON_SUB_MAIN": {
            color: "rgba(183,174,4,1)",
            icon: "las la-tasks",
            label: "被监控主体定义"

    },
"TRA_RISK_SCE_MAIN": {
            color: "rgba(164,90,7,1)",
            icon: "las la-thumbtack",
            label: "风险场景定义"

    },
"TRA_RISKSCESORT_MAIN": {
            color: "rgba(42,38,93,1)",
            icon: "las la-trademark",
            label: "风险场景分类定义"

    },
"TRA_INDEX_MAIN": {
            color: "rgba(184,18,168,1)",
            icon: "las la-wallet",
            label: "指标与阈值定义"

    },
"TRA_INDEX_FORM_DEF": {
            color: "rgba(151,4,50,1)",
            icon: "las la-address-book",
            label: "指标公式定义"

    },
"TRA_CONF_MON_SUB": {
            color: "rgba(17,167,107,1)",
            icon: "las la-address-card",
            label: "监控方案配置"

    },
"TRA_CONF_RISK_MON": {
            color: "rgba(163,120,22,1)",
            icon: "las la-archive",
            label: "监控方案运行"

    },
"TRA_CONF_MON_LOGS": {
            color: "rgba(192,22,86,1)",
            icon: "las la-balance-scale",
            label: "监控方案日志"

    },
"TRA_SYS_CONFIG": {
            color: "rgba(80,69,2,1)",
            icon: "las la-balance-scale-left",
            label: "系统参数"

    },
"TRA_INDEX_DRILL_DOWN": {
            color: "rgba(163,146,124,1)",
            icon: "las la-balance-scale-right",
            label: "指标穿透管理"

    },
"TRA_RISK_CODE_LIST": {
            color: "rgba(33,55,48,1)",
            icon: "las la-birthday-cake",
            label: "风险配置代码表"

    },
"TRA_MO_RE_CASE_REL": {
            color: "rgba(39,87,163,1)",
            icon: "las la-book",
            label: "风险事件关联表"

    },
"TRA_SCE_CASE_SET": {
            color: "rgba(142,98,197,1)",
            icon: "las la-briefcase",
            label: "风险事件规则设置"

    },
"TRA_DATA_DICT": {
            color: "rgba(137,28,36,1)",
            icon: "las la-bullhorn",
            label: "数据字典"

    },
"TDA_MGMT_COCKPIT": {
            color: "rgba(188,58,143,1)",
            icon: "las la-bullseye",
            label: "管理驾驶舱"

    },
"TDA_GROUP_COCKPIT": {
            color: "rgba(115,39,22,1)",
            icon: "las la-business-time",
            label: "集团管理驾驶舱"

    },
"TDA_MEMBER_COCKPIT": {
            color: "rgba(78,143,112,1)",
            icon: "las la-calculator",
            label: "单体公司管理驾驶舱"

    },
"TDA_DASHBOARD_DYNAMIC": {
            color: "rgba(60,166,49,1)",
            icon: "las la-calendar",
            label: "发票管理驾驶舱"

    },
"TDA_DASHBOARD_DYNAMIC_LANDSCAPE": {
            color: "rgba(33,57,73,1)",
            icon: "las la-calendar-alt",
            label: "管理驾驶舱"

    },
// "TDA_DASHBOARD_CONFIG": {
//             color: "rgba(196,60,26,1)",
//             icon: "las la-certificate",
//             label: "管理驾驶舱定义"

//     },
"TDA_CHART_MGT": {
            color: "rgba(113,148,38,1)",
            icon: "las la-chart-area",
            label: "驾驶舱图库管理"

    },
"TDA_TAX_PERF_REPORT": {
            color: "rgba(80,109,125,1)",
            icon: "las la-chart-bar",
            label: "宏观统计与对标分析"

    },
"TDA_ENT_TAX": {
            color: "rgba(175,69,106,1)",
            icon: "las la-chart-line",
            label: "集团内部财税表现比较"

    },
"TDA_COMP_TAX": {
            color: "rgba(89,144,150,1)",
            icon: "las la-chart-pie",
            label: "与行业对标企业税务表现比较"

    },
"TDA_TAX_REPORT": {
            color: "rgba(130,85,186,1)",
            icon: "las la-city",
            label: "纳税统计报表"

    },
"TDA_TAX_DELAY_SUM": {
            color: "rgba(2,57,26,1)",
            icon: "las la-clipboard",
            label: "缓缴明细表"

    },
"TDA_TAX_EXEC_MONITOR": {
            color: "rgba(166,0,7,1)",
            icon: "las la-coffee",
            label: "税金执行监控表"

    },
"TDA_MM_TAX": {
            color: "rgba(40,42,46,1)",
            icon: "las la-columns",
            label: "分月纳税情况统计"

    },
"TDA_TAX_TYP": {
            color: "rgba(86,96,117,1)",
            icon: "las la-compass",
            label: "分税种变动分析"

    },
"TDA_TAX_SITU": {
            color: "rgba(175,146,158,1)",
            icon: "las la-copy",
            label: "纳税情况明细表"

    },
"TDA_TAX_DRIVER": {
            color: "rgba(92,180,143,1)",
            icon: "las la-copyright",
            label: "税费动因分析"

    },
"TDA_VAT_ANLS": {
            color: "rgba(76,143,79,1)",
            icon: "las la-cut",
            label: "增值税分析表"

    },
"TDA_EIT_ANLS": {
            color: "rgba(4,11,28,1)",
            icon: "las la-edit",
            label: "企业所得税分析表"

    },
"TDA_STAMP_TAX_ANLS": {
            color: "rgba(140,116,2,1)",
            icon: "las la-envelope",
            label: "印花税分析表"

    },
"TDA_PROPERTY_TAX_ANLS": {
            color: "rgba(133,45,140,1)",
            icon: "las la-envelope-open",
            label: "房产税分析表"

    },
"TDA_VAT_DIFFERENTIAL": {
            color: "rgba(58,84,177,1)",
            icon: "las la-envelope-square",
            label: "增值税税差分析"

    },
"TDA_EIT_RETURN_DIFFERENTIAL": {
            color: "rgba(142,149,76,1)",
            icon: "las la-eraser",
            label: "企业所得税税差分析（汇缴）"

    },
"TDA_EIT_DIFFERENTIAL": {
            color: "rgba(139,173,121,1)",
            icon: "las la-fax",
            label: "企业所得税税差分析（预缴）"

    },
"TDA_TAX_MONITER": {
            color: "rgba(182,110,62,1)",
            icon: "las la-file",
            label: "税务管理及筹划"

    },
"TDA_FINANCIAL_RETURN_MONITOR": {
            color: "rgba(190,79,75,1)",
            icon: "las la-file-alt",
            label: "财政返还监控表"

    },
"TDA_LOSS_REPORT": {
            color: "rgba(75,68,159,1)",
            icon: "las la-folder",
            label: "可结转弥补亏损监控表"

    },
"TDA_REF_VAT_CREDIT": {
            color: "rgba(36,157,7,1)",
            icon: "las la-folder-minus",
            label: "增值税留抵退税监控表"

    },
"TDA_INVOICE_UNDEDUCTED": {
            color: "rgba(173,173,166,1)",
            icon: "las la-folder-open",
            label: "未抵扣发票账龄分析"

    },
"TDA_TAX_PROF": {
            color: "rgba(48,138,140,1)",
            icon: "las la-folder-plus",
            label: "税收优惠享受统计表"

    },
"TDA_FIELD_PROV": {
            color: "rgba(21,56,22,1)",
            icon: "las la-glasses",
            label: "地方财政贡献统计表"

    },
"TDA_RND": {
            color: "rgba(38,47,149,1)",
            icon: "las la-globe",
            label: "研发加计享受情况"

    },
"TDA_VAT_TRANSOUT": {
            color: "rgba(172,37,186,1)",
            icon: "las la-highlighter",
            label: "进项转出监控"

    },
"TDA_INVOICE_REPORT": {
            color: "rgba(129,169,102,1)",
            icon: "las la-industry",
            label: "发票主题报表"

    },
"TDA_CORP_CR": {
            color: "rgba(176,55,90,1)",
            icon: "las la-landmark",
            label: "进项发票企业流"

    },
"TDA_CORP_DR": {
            color: "rgba(132,102,185,1)",
            icon: "las la-marker",
            label: "销项发票企业流"

    },
"TDA_GOODS_CR": {
            color: "rgba(161,11,182,1)",
            icon: "las la-paperclip",
            label: "进项发票货物流"

    },
"TDA_GOODS_DR": {
            color: "rgba(179,12,143,1)",
            icon: "las la-paste",
            label: "销项发票货物流"

    },
"TDA_INV_CR_ANLS": {
            color: "rgba(76,91,132,1)",
            icon: "las la-pen",
            label: "进项发票来源地"

    },
"TDA_INV_DR_ANLS": {
            color: "rgba(105,17,195,1)",
            icon: "las la-pen-alt",
            label: "销项发票流向地"

    },
"TDA_FILING_UPLOAD": {
            color: "rgba(40,29,42,1)",
            icon: "las la-pen-fancy",
            label: "申报上传情况查询"

    },
"TDA_DECL_STATUS": {
            color: "rgba(107,14,19,1)",
            icon: "las la-pen-nib",
            label: "纳税主体申报状态监控"

    },
"TDA_FILING_UPLOAD_PERIOD": {
            color: "rgba(90,194,90,1)",
            icon: "las la-pen-square",
            label: "全部上传情况查询"

    },
"TDA_UPLOAD_STATUS": {
            color: "rgba(157,121,72,1)",
            icon: "las la-pencil-alt",
            label: "上传进度查询"

    },
"TDA_FILING_NOTUPLOAD": {
            color: "rgba(113,55,59,1)",
            icon: "las la-percent",
            label: "未上传情况查询"

    },
"TDA_TAX_REPORT_WF": {
            color: "rgba(77,90,181,1)",
            icon: "las la-phone",
            label: "税务机关报送报表"

    },
"TDA_TAX_REPORT_WF_QH": {
            color: "rgba(110,120,196,1)",
            icon: "las la-phone-alt",
            label: "千户集团报送报表"

    },
"TRA_CLEAR_ACC_SUB": {
            color: "rgba(72,191,48,1)",
            icon: "las la-phone-slash",
            label: "脱敏凭证查询"

    },
"TDA_QH_ACC_TB": {
            color: "rgba(9,37,100,1)",
            icon: "las la-phone-square",
            label: "期初余额查询"

    },
"TDA_QH_TAX_RULE": {
            color: "rgba(55,159,71,1)",
            icon: "las la-phone-square-alt",
            label: "税务规则定义"

    },
"TDA_DATA_DEFIN": {
            color: "rgba(164,26,47,1)",
            icon: "las la-phone-volume",
            label: "报表数据定义"

    },
"TDA_GRID_DEF": {
            color: "rgba(157,113,181,1)",
            icon: "las la-print",
            label: "报表结构定义"

    },
"TDA_PARAMETER_DEF": {
            color: "rgba(79,115,148,1)",
            icon: "las la-project-diagram",
            label: "应用功能管理"

    },
"TDA_MAPPING_LIST": {
            color: "rgba(182,21,49,1)",
            icon: "las la-registered",
            label: "报表排序管理"

    },
"TDA_STANDARD_ENTER": {
            color: "rgba(42,72,155,1)",
            icon: "las la-save",
            label: "对标企业管理"

    },
"TDA_ITEM_CODE_GROUP": {
            color: "rgba(195,44,21,1)",
            icon: "las la-sitemap",
            label: "数据项分组"

    },
"TDA_REPORT_DRILL": {
            color: "rgba(7,96,120,1)",
            icon: "las la-socks",
            label: "报表穿透管理"

    },
"TDA_DASHBOARD_CONFIG": {
            color: "rgba(17,47,91,1)",
            icon: "las la-sticky-note",
            label: "管理驾驶舱参数设置"

    },
"VATFP_AFFIRM_INF": {
            color: "rgba(28,171,105,1)",
            icon: "las la-stream",
            label: "原始交易池"

    },
"VATFP_AFFIRM": {
            color: "rgba(68,95,92,1)",
            icon: "las la-table",
            label: "交易认定"

    },
"VATFP_AFFIRM_RESULT": {
            color: "rgba(144,162,98,1)",
            icon: "las la-tag",
            label: "交易认定结果查询"

    },
"VATFP_AFFIRM_ERROR": {
            color: "rgba(130,35,180,1)",
            icon: "las la-tags",
            label: "交易认定异常处理"

    },
"VATFP_INVREQ": {
            color: "rgba(116,140,196,1)",
            icon: "las la-thumbtack",
            label: "开票申请管理"

    },
"VATFP_BATCH_REQ_H3": {
            color: "rgba(52,95,13,1)",
            icon: "las la-wallet",
            label: "手工开票"

    },
"VATFP_BATCH_REQ_H5": {
            color: "rgba(110,25,118,1)",
            icon: "las la-address-book",
            label: "机动车开票"

    },
"VATFP_REQ_H_SCHCAR": {
            color: "rgba(60,113,32,1)",
            icon: "las la-address-card",
            label: "二手车开票"

    },
"VATFP_PRINTFP": {
            color: "rgba(108,54,134,1)",
            icon: "las la-archive",
            label: "发票开具打印"

    },
"VATFP_VEH_PRINT": {
            color: "rgba(117,3,79,1)",
            icon: "las la-balance-scale-right",
            label: "机动车发票池"

    },
"VATFP_VAT_DR_SCHCAR": {
            color: "rgba(41,119,35,1)",
            icon: "las la-birthday-cake",
            label: "二手车发票池"

    },
"VATFP_PRINT_OIL": {
            color: "rgba(35,56,142,1)",
            icon: "las la-book",
            label: "成品油发票池"

    },
"VATFP_VAT_DR_FORM": {
            color: "rgba(61,78,50,1)",
            icon: "las la-briefcase",
            label: "形式发票池"

    },
"VATFP_SAL_EXPC": {
            color: "rgba(92,174,27,1)",
            icon: "las la-bullhorn",
            label: "发票例外管理"

    },
"VATFP_FORREVCONFIRM": {
            color: "rgba(22,91,108,1)",
            icon: "las la-bullseye",
            label: "红字发票信息确认单"

    },
"VATFP_LINE_OF_CREDIT_MANAGEMENT": {
            color: "rgba(0,33,29,1)",
            icon: "las la-calendar-alt",
            label: "授信额度查询"

    },
"VATFP_INV_MGMT": {
            color: "rgba(193,14,21,1)",
            icon: "las la-certificate",
            label: "发票库存管理"

    },
"VATFP_INV_SPBU": {
            color: "rgba(51,142,4,1)",
            icon: "las la-chart-area",
            label: "特定业务信息"

    },
"VATFP_INV_REALESTATEINFO": {
            color: "rgba(143,20,99,1)",
            icon: "las la-chart-bar",
            label: "不动产经营租赁服务"

    },
"VATFP_INV_BUILDING": {
            color: "rgba(175,15,40,1)",
            icon: "las la-chart-line",
            label: "建筑服务"

    },
"VATFP_INV_CARGOTRSP": {
            color: "rgba(34,54,89,1)",
            icon: "las la-chart-pie",
            label: "货物运输服务"

    },
"VATFP_ELECTRONIC_INV_SEND_HISTORY": {
            color: "rgba(150,35,198,1)",
            icon: "las la-city",
            label: "电票发送历史"

    },
"VATFP_RULE_LOG": {
            color: "rgba(18,109,118,1)",
            icon: "las la-clipboard",
            label: "规则引擎日志"

    },
"VATFP_INV_POOL": {
            color: "rgba(91,25,44,1)",
            icon: "las la-coffee",
            label: "票据数据池"

    },
"VATFP_TAXPAYMENT_POOL": {
            color: "rgba(2,98,19,1)",
            icon: "las la-copy",
            label: "代扣代缴完税凭证"

    },
"VATFP_MAIL": {
            color: "rgba(101,70,172,1)",
            icon: "las la-copyright",
            label: "邮件登记"

    },
"VATFP_INV_CHECK": {
            color: "rgba(56,127,81,1)",
            icon: "las la-cut",
            label: "发票登记"

    },
"VATFP_INPUT_FP_MAIL": {
            color: "rgba(13,153,55,1)",
            icon: "las la-edit",
            label: "供应商发票"

    },
"VATFP_INP_EXPENSE": {
            color: "rgba(90,11,172,1)",
            icon: "las la-envelope",
            label: "报账发票"

    },
"VATFP_DEDU_MGT": {
            color: "rgba(166,122,9,1)",
            icon: "las la-envelope-square",
            label: "发票认证"

    },
"VATFP_INPUT_CREDIT": {
            color: "rgba(140,150,153,1)",
            icon: "las la-file",
            label: "发票例外管理"

    },
"VATFP_CR_FORREVCONFIRM": {
            color: "rgba(182,28,121,1)",
            icon: "las la-file-alt",
            label: "红字发票信息确认"

    },
"VATFP_ARCHIVE_OUTPUT": {
            color: "rgba(119,130,52,1)",
            icon: "las la-folder-minus",
            label: "销项电票归档"

    },
"VATFP_ARCHIVE_INPUT": {
            color: "rgba(10,170,174,1)",
            icon: "las la-folder-open",
            label: "进项电票归档"

    },
"VATFP_CRRPT": {
            color: "rgba(148,163,117,1)",
            icon: "las la-folder-plus",
            label: "销项统计分析"

    },
"VATFP_TAX_RECON_LSTJ": {
            color: "rgba(86,136,100,1)",
            icon: "las la-glasses",
            label: "销项发票流水统计"

    },
"VATFP_TAX_RECON_MXTJ": {
            color: "rgba(118,82,44,1)",
            icon: "las la-globe",
            label: "销项发票行明细统计"

    },
"VATFP_INV_INVENTC": {
            color: "rgba(129,16,34,1)",
            icon: "las la-highlighter",
            label: "销项发票分商编"

    },
"VATFP_INV_INVENTR": {
            color: "rgba(129,65,67,1)",
            icon: "las la-industry",
            label: "销项发票分税率"

    },
"VATFP_BILLING_INVOICING_SUMMARY_BY_CUSTOMER": {
            color: "rgba(46,76,118,1)",
            icon: "las la-landmark",
            label: "客户交易开票情况统计表"

    },
"VATFP_SALES_RECON_BILLINGINFO": {
            color: "rgba(26,168,69,1)",
            icon: "las la-marker",
            label: "交易开票信息统计表"

    },
"VATFP_INV_OUTRECON": {
            color: "rgba(131,116,120,1)",
            icon: "las la-paperclip",
            label: "销项税会差异明细表"

    },
"VATFP_DRRPT": {
            color: "rgba(56,8,12,1)",
            icon: "las la-paste",
            label: "进项统计分析"

    },
"VATFP_INP_INV_UPDATE": {
            color: "rgba(118,80,189,1)",
            icon: "las la-pen",
            label: "发票状态变更日志"

    },
"VATFP_INV_ABNOEASYUI": {
            color: "rgba(118,165,168,1)",
            icon: "las la-pen-alt",
            label: "发票状态异常统计"

    },
"VATFP_CRPOLEASYUI": {
            color: "rgba(24,11,190,1)",
            icon: "las la-pen-fancy",
            label: "发票全票面信息"

    },
"VATFP_INV_INPUTRECON": {
            color: "rgba(101,123,32,1)",
            icon: "las la-pen-nib",
            label: "进项税会差异"

    },
"VATFP_REIMBURSEMENTINV_STATISTICS": {
            color: "rgba(139,0,97,1)",
            icon: "las la-pen-square",
            label: "报账发票统计"

    },
"VATFP_SAL_CUSTOMER_M": {
            color: "rgba(26,2,132,1)",
            icon: "las la-pencil-alt",
            label: "销项主数据"

    },
"VATFP_VEH_INFO": {
            color: "rgba(53,124,36,1)",
            icon: "las la-phone",
            label: "车辆信息维护"

    },
"VATFP_MASTER_DATA_MAINTAIN_INPUT": {
            color: "rgba(27,165,175,1)",
            icon: "las la-phone-alt",
            label: "进项主数据"

    },
"VATFP_TAXRULE_IN_RELATEPARTY": {
            color: "rgba(30,26,181,1)",
            icon: "las la-phone-slash",
            label: "关联方认定"

    },
"VATFP_INPUT_VENDOR": {
            color: "rgba(59,151,163,1)",
            icon: "las la-phone-square",
            label: "供应商主数据"

    },
"VATFP_VAT_RULES": {
            color: "rgba(184,150,189,1)",
            icon: "las la-phone-square-alt",
            label: "销项规则配置"

    },
"VATFP_SHUIWU_SH": {
            color: "rgba(195,170,41,1)",
            icon: "las la-project-diagram",
            label: "交易认定规则配置"

    },
"VATFP_ADDITIONAL_ELEMENTS": {
            color: "rgba(114,32,44,1)",
            icon: "las la-save",
            label: "全电发票附加要素"

    },
"VATFP_ELEMENTS_DEFINE": {
            color: "rgba(62,193,38,1)",
            icon: "las la-sitemap",
            label: "附加要素池"

    },
"VATFP_ELE_TEMPLATE_POL_H": {
            color: "rgba(9,180,188,1)",
            icon: "las la-socks",
            label: "附加要素模板"

    },
"VATFP_ELE_CUST_TEMPLATE": {
            color: "rgba(129,114,178,1)",
            icon: "las la-sticky-note",
            label: "附加要素模板关联管理"

    },
"VATFP_SYST_PARA": {
            color: "rgba(110,54,113,1)",
            icon: "las la-tags",
            label: "系统参数"

    },
"VATFP_RULE_IPT": {
            color: "rgba(0,17,36,1)",
            icon: "las la-tasks",
            label: "进项规则配置"

    },
"VATFP_RULE_OCR": {
            color: "rgba(132,121,23,1)",
            icon: "las la-thumbtack",
            label: "发票读取规则"

    },
"VATFP_RULE_REGEX": {
            color: "rgba(167,73,42,1)",
            icon: "las la-trademark",
            label: "正则表达式"

    },
"VATFP_EQUIPMENT": {
            color: "rgba(94,6,101,1)",
            icon: "las la-wallet",
            label: "电票平台设置"

    },
"VATRPT_DATA_IMP": {
            color: "rgba(105,135,32,1)",
            icon: "las la-address-book",
            label: "数据集市汇总"

    },
"VATRPT_ACC_DATA": {
            color: "rgba(132,10,116,1)",
            icon: "las la-address-card",
            label: "核算数据"

    },
"VATRPT_VAT_CLIENT_TB": {
            color: "rgba(127,32,29,1)",
            icon: "las la-archive",
            label: "科目余额表"

    },
"VATRPT_ACC_JOURNAL": {
            color: "rgba(126,148,35,1)",
            icon: "las la-balance-scale",
            label: "科目明细账"

    },
"VATRPT_FAPIAO_DATA": {
            color: "rgba(195,152,82,1)",
            icon: "las la-balance-scale-left",
            label: "发票数据"

    },
"VATRPT_GTS_DATA": {
            color: "rgba(180,117,103,1)",
            icon: "las la-balance-scale-right",
            label: "销项发票"

    },
"VATRPT_INPUT_DATA": {
            color: "rgba(18,153,109,1)",
            icon: "las la-birthday-cake",
            label: "进项发票"

    },
"VATRPT_TOLL": {
            color: "rgba(171,20,107,1)",
            icon: "las la-book",
            label: "电子通行费"

    },
"VATRPT_CUSTOM_DATA": {
            color: "rgba(144,153,2,1)",
            icon: "las la-briefcase",
            label: "海关缴款书"

    },
"VATRPT_WITHHOLD_DATA": {
            color: "rgba(153,40,7,1)",
            icon: "las la-building",
            label: "代扣代缴完税凭证"

    },
"VATRPT_BUSINESS_DATA": {
            color: "rgba(76,176,1,1)",
            icon: "las la-bullhorn",
            label: "业务数据"

    },
"VATRPT_TRX_POOL": {
            color: "rgba(58,69,82,1)",
            icon: "las la-bullseye",
            label: "交易明细"

    },
"VATRPT_REV_H": {
            color: "rgba(176,130,179,1)",
            icon: "las la-business-time",
            label: "未开票收入"

    },
"VATRPT_SALES_DATA": {
            color: "rgba(131,93,180,1)",
            icon: "las la-calculator",
            label: "视同销售"

    },
"VATRPT_ASSET_DIS": {
            color: "rgba(29,92,2,1)",
            icon: "las la-calendar",
            label: "资产处置"

    },
"VATRPT_FIN_COMMODITY": {
            color: "rgba(12,104,110,1)",
            icon: "las la-calendar-alt",
            label: "金融商品"

    },
"VATRPT_XINTAIZHANG": {
            color: "rgba(182,72,68,1)",
            icon: "las la-certificate",
            label: "即征即退收入"

    },
"VATRPT_MAT_COST": {
            color: "rgba(114,28,75,1)",
            icon: "las la-chart-area",
            label: "嵌软物料收入"

    },
"TMS_VAT_SALE_REDBAT": {
            color: "rgba(20,89,74,1)",
            icon: "las la-chart-bar",
            label: "销售返利明细"

    },
"VATRPT_TRANSCON_OUT": {
            color: "rgba(161,199,116,1)",
            icon: "las la-chart-line",
            label: "建筑分包工程"

    },
"VATRPT_PRE_CON": {
            color: "rgba(59,3,45,1)",
            icon: "las la-chart-pie",
            label: "建筑预缴"

    },
"VATRPT_WITH_TAX": {
            color: "rgba(158,11,197,1)",
            icon: "las la-city",
            label: "代扣代缴"

    },
"VATRPT_PASS_TRANS": {
            color: "rgba(172,67,41,1)",
            icon: "las la-clipboard",
            label: "旅客运输发票"

    },
"VATRPT_AGRI_FP_DATA": {
            color: "rgba(176,156,192,1)",
            icon: "las la-coffee",
            label: "农产品发票"

    },
"VATRPT_AGRI_PDT_STOCK": {
            color: "rgba(143,132,142,1)",
            icon: "las la-columns",
            label: "农产品核定扣除"

    },
"VATRPT_MD_AGRI_ORDER": {
            color: "rgba(154,58,119,1)",
            icon: "las la-compass",
            label: "换算65度白酒订单"

    },
"VATRPT_MD_WINE_ACCOUNT": {
            color: "rgba(148,127,182,1)",
            icon: "las la-copy",
            label: "产成品领购台账"

    },
"VATRPT_TURN_OUT": {
            color: "rgba(144,134,93,1)",
            icon: "las la-copyright",
            label: "进项转出"

    },
"VATRPT_FREE_TAX": {
            color: "rgba(77,182,105,1)",
            icon: "las la-cut",
            label: "免税备案合同"

    },
"VATRPT_BASIC_DATA": {
            color: "rgba(116,83,89,1)",
            icon: "las la-edit",
            label: "基础数据"

    },
"VATRPT_FIN_BASE": {
            color: "rgba(120,109,60,1)",
            icon: "las la-envelope",
            label: "金融商品"

    },
"VATRPT_JZJT_DEFINE": {
            color: "rgba(24,62,88,1)",
            icon: "las la-envelope-open",
            label: "即征即退项目定义"

    },
"VATRPT_SW_CRT_LIST": {
            color: "rgba(136,81,125,1)",
            icon: "las la-envelope-square",
            label: "嵌入式软件登记证书"

    },
"VATRPT_SW_CRT_MAP": {
            color: "rgba(199,175,196,1)",
            icon: "las la-eraser",
            label: "软件与物料映射"

    },
"VATRPT_CON_RULE": {
            color: "rgba(104,0,64,1)",
            icon: "las la-fax",
            label: "建筑分包工程项目"

    },
"VATRPT_AGRI_PDT_INFO": {
            color: "rgba(52,156,64,1)",
            icon: "las la-file",
            label: "农产品及物料信息"

    },
"VATRPT_SOLDIER_INFO": {
            color: "rgba(160,132,29,1)",
            icon: "las la-file-alt",
            label: "退役士兵基础信息"

    },
"VATRPT_AGRI_DGR_CMP": {
            color: "rgba(42,33,145,1)",
            icon: "las la-folder",
            label: "各品类白酒酒精度对照表"

    },
"VATRPT_AGRI_DGR_CR": {
            color: "rgba(88,135,67,1)",
            icon: "las la-folder-minus",
            label: "65度白酒折换系数表"

    },
"VATRPT_W_INVOICE": {
            color: "rgba(35,114,120,1)",
            icon: "las la-folder-open",
            label: "销项发票核销"

    },
"VATRPT_W_RES": {
            color: "rgba(74,148,109,1)",
            icon: "las la-folder-plus",
            label: "销项核销结果"

    },
"VATRPT_W_PMHIS": {
            color: "rgba(171,180,174,1)",
            icon: "las la-glasses",
            label: "核销参数"

    },
"VATRPT_SYST_PARA": {
            color: "rgba(172,183,144,1)",
            icon: "las la-globe",
            label: "核销维度设置"

    },
"VATRPT_RPT_VATBASE": {
            color: "rgba(68,114,144,1)",
            icon: "las la-highlighter",
            label: "增值税核对表"

    },
"VATRPT_RPT_VAT_BURDEN": {
            color: "rgba(163,181,31,1)",
            icon: "las la-industry",
            label: "增值税税负统计"

    },
"VATRPT_RPT_VAT_FILING_DETAIL": {
            color: "rgba(126,110,182,1)",
            icon: "las la-landmark",
            label: "增值税申报统计"

    },
"VATRPT_RPT_WF_MEMO": {
            color: "rgba(19,169,71,1)",
            icon: "las la-marker",
            label: "审批记录汇总"

    },
"VATRPT_RPT_VAT_BASICINFO": {
            color: "rgba(65,73,39,1)",
            icon: "las la-paperclip",
            label: "基础信息统计表"

    },
"VAT_REPORT_RULE": {
            color: "rgba(168,42,109,1)",
            icon: "las la-paste",
            label: "启用/计算规则"

    },
"VATRPT_PRE_DATA": {
            color: "rgba(171,100,140,1)",
            icon: "las la-pen",
            label: "申报数据初始化"

    },
"VATRPT_SBL3": {
            color: "rgba(174,166,149,1)",
            icon: "las la-pen-alt",
            label: "税务科目编码定义"

    },
"VATRPT_OUTPUTMAPPING": {
            color: "rgba(38,103,88,1)",
            icon: "las la-pen-fancy",
            label: "销项发票映射"

    },
"VATRPT_BABCRULE": {
            color: "rgba(24,199,19,1)",
            icon: "las la-pen-nib",
            label: "备案备查规则定义"

    },
"EIT_FILE_IMP_INF_H": {
            color: "rgba(176,197,162,1)",
            icon: "las la-pen-square",
            label: "数据集市汇总"

    },
"EIT_MD_DATA": {
            color: "rgba(130,39,159,1)",
            icon: "las la-pencil-alt",
            label: "核算数据"

    },
"EIT_CLIENT_MD_TB": {
            color: "rgba(49,122,198,1)",
            icon: "las la-percent",
            label: "科目余额表"

    },
"EIT_CLIENT_MD_SUB": {
            color: "rgba(8,113,15,1)",
            icon: "las la-phone",
            label: "科目明细账"

    },
"EIT_FINANCIAL_REPORT": {
            color: "rgba(80,189,153,1)",
            icon: "las la-phone-alt",
            label: "财务报表"

    },
"EIT_MD_MGT": {
            color: "rgba(24,93,18,1)",
            icon: "las la-phone-slash",
            label: "业务数据"

    },
"EIT_MD_FA": {
            color: "rgba(94,185,97,1)",
            icon: "las la-phone-square",
            label: "资产台账"

    },
"EIT_MD_DEEMED_SALES": {
            color: "rgba(56,171,197,1)",
            icon: "las la-phone-square-alt",
            label: "视同销售台账"

    },
"EIT_MD_DONATION_COST": {
            color: "rgba(2,24,44,1)",
            icon: "las la-phone-volume",
            label: "捐赠支出台账"

    },
"EIT_MD_COMMISION": {
            color: "rgba(0,142,84,1)",
            icon: "las la-print",
            label: "佣金手续费台账"

    },
"EIT_MD_RENTS": {
            color: "rgba(100,71,151,1)",
            icon: "las la-project-diagram",
            label: "租金收入台账"

    },
"EIT_MD_INTERESTS": {
            color: "rgba(143,36,91,1)",
            icon: "las la-registered",
            label: "利息收入台账"

    },
"EIT_MD_LOSS_ON_FA": {
            color: "rgba(90,154,47,1)",
            icon: "las la-save",
            label: "固定资产损失台账"

    },
"EIT_MD_DIVIDEND": {
            color: "rgba(148,174,137,1)",
            icon: "las la-sitemap",
            label: "股息红利优惠台账"

    },
"EIT_MD_INTERESTS_EX": {
            color: "rgba(168,88,64,1)",
            icon: "las la-socks",
            label: "利息支出台账"

    },
"EIT_MD_INVEST_INFO": {
            color: "rgba(158,130,126,1)",
            icon: "las la-sticky-note",
            label: "被投资主体计税成本台账"

    },
// "EIT_MD_INVEST_INFO": {
//             color: "rgba(90,182,41,1)",
//             icon: "las la-stream",
//             label: "投资事项管理台账"

//     },
"EIT_MD_EQUITY": {
            color: "rgba(70,21,58,1)",
            icon: "las la-table",
            label: "股权激励台账"

    },
"EIT_MD_INVENT_LOSS": {
            color: "rgba(104,51,138,1)",
            icon: "las la-tag",
            label: "存货报废盘亏台账"

    },
"EIT_MD_HANDICAPPED": {
            color: "rgba(15,199,175,1)",
            icon: "las la-tags",
            label: "残疾人工资台账"

    },
"EIT_MD_BRAN_PAR": {
            color: "rgba(22,19,160,1)",
            icon: "las la-tasks",
            label: "分支机构三因素获取"

    },
"EIT_MD_STOCK": {
            color: "rgba(120,62,75,1)",
            icon: "las la-thumbtack",
            label: "股权"

    },
"EIT_MD_LOSS_ASSETS": {
            color: "rgba(44,68,50,1)",
            icon: "las la-trademark",
            label: "资产损失台账"

    },
"EIT_MD_DEDUCTION": {
            color: "rgba(150,168,43,1)",
            icon: "las la-wallet",
            label: "跨期扣除台账"

    },
"EIT_MD_PAY_OVERSEAS": {
            color: "rgba(18,137,118,1)",
            icon: "las la-address-book",
            label: "境外费用支付台账"

    },
"EIT_MD_PAY_BAD": {
            color: "rgba(16,158,108,1)",
            icon: "las la-address-card",
            label: "应收坏账台账"

    },
"EIT_MD_NO_FAPIAO": {
            color: "rgba(145,27,52,1)",
            icon: "las la-archive",
            label: "无合理发票台账"

    },
"EIT_MD_PUBLICSUBSIDY": {
            color: "rgba(107,39,5,1)",
            icon: "las la-balance-scale",
            label: "政府补助台账"

    },
"EIT_MD_INVEST_PROFIT": {
            color: "rgba(73,82,144,1)",
            icon: "las la-balance-scale-left",
            label: "投资收益台账"

    },
"EIT_MD_HOUSE_CD_INFOR": {
            color: "rgba(184,99,56,1)",
            icon: "las la-birthday-cake",
            label: "所得税结转房间明细"

    },
"EIT_MD_HOUSE_CD_INFOR_FIN": {
            color: "rgba(160,125,173,1)",
            icon: "las la-book",
            label: "所得税结转房间明细（财务）"

    },
"EIT_MD_COST_MAP": {
            color: "rgba(75,49,162,1)",
            icon: "las la-briefcase",
            label: "工程成本合同"

    },
"EIT_MD_AMORTIZATION_COST": {
            color: "rgba(44,196,120,1)",
            icon: "las la-building",
            label: "长期待摊费用台账"

    },
"EIT_MD_SPONSOR_COST": {
            color: "rgba(183,27,145,1)",
            icon: "las la-bullhorn",
            label: "赞助支出台账"

    },
"EIT_MD_UNPAID_SALARY": {
            color: "rgba(0,112,38,1)",
            icon: "las la-bullseye",
            label: "已计提未实发工资台账"

    },
"EIT_MD_EMPLOYEE": {
            color: "rgba(10,112,22,1)",
            icon: "las la-business-time",
            label: "员工人数"

    },
"EIT_MD_REELIEF_EMPLOYEE": {
            color: "rgba(170,199,162,1)",
            icon: "las la-calculator",
            label: "退伍士兵&贫困人口&失业优惠依次扣减台账"

    },
"MD_FOREIGN_PAYMENT": {
            color: "rgba(8,51,54,1)",
            icon: "las la-calendar",
            label: "购汇付款明细台账"

    },
"EIT_MD_FOREIGN_DEDUCTION": {
            color: "rgba(192,5,104,1)",
            icon: "las la-calendar-alt",
            label: "境外抵免台账"

    },
"EIT_ASSET_BOOK": {
            color: "rgba(104,57,14,1)",
            icon: "las la-certificate",
            label: "资产账簿台账"

    },
"EIT_MD_RD": {
            color: "rgba(48,71,12,1)",
            icon: "las la-chart-area",
            label: "研发加计台账"

    },
"EIT_MD": {
            color: "rgba(20,91,64,1)",
            icon: "las la-chart-bar",
            label: "关联申报数据"

    },
"EIT_MD_TRANSACTION": {
            color: "rgba(115,187,63,1)",
            icon: "las la-chart-line",
            label: "关联交易台账"

    },
"EIT_MD_FINANCING": {
            color: "rgba(103,199,112,1)",
            icon: "las la-chart-pie",
            label: "融通资金台账"

    },
"EIT_MD_INVESTMENT": {
            color: "rgba(146,195,121,1)",
            icon: "las la-city",
            label: "权益性投资台账"

    },
"EIT_MD_PAYMENT": {
            color: "rgba(122,175,145,1)",
            icon: "las la-clipboard",
            label: "对外支付台账"

    },
"EIT_MD_DTA": {
            color: "rgba(183,35,84,1)",
            icon: "las la-coffee",
            label: "所得税费用数据"

    },
"EIT_MD_DTA_RE_DT": {
            color: "rgba(13,43,10,1)",
            icon: "las la-columns",
            label: "所得税费用金额台账"

    },
"EIT_MD_DTA_ADJ": {
            color: "rgba(83,115,115,1)",
            icon: "las la-compass",
            label: "所得税费用审计调整台账"

    },
"EIT_MD_FATAX_BOOK": {
            color: "rgba(162,98,82,1)",
            icon: "las la-copy",
            label: "资产税簿"

    },
"EIT_MD_FATAX_BOOK_BA": {
            color: "rgba(5,194,127,1)",
            icon: "las la-copyright",
            label: "资产基本信息"

    },
"EIT_MD_FA_CGE_LOG": {
            color: "rgba(73,0,165,1)",
            icon: "las la-cut",
            label: "资产变更记录台账"

    },
"EIT_MD_FA_DEPRECIAT": {
            color: "rgba(34,195,45,1)",
            icon: "las la-edit",
            label: "资产折旧记录台账"

    },
"EIT_FILING_REPORT": {
            color: "rgba(63,149,52,1)",
            icon: "las la-envelope",
            label: "财务报表报送"

    },
"EIT_FILING_INVEST": {
            color: "rgba(76,20,75,1)",
            icon: "las la-envelope-open",
            label: "投资管理"

    },
"EIT_FILING_QUARTER": {
            color: "rgba(141,6,67,1)",
            icon: "las la-envelope-square",
            label: "预缴申报"

    },
"EIT_FILING_ANNUAL": {
            color: "rgba(59,16,122,1)",
            icon: "las la-eraser",
            label: "汇算清缴"

    },
"EIT_FILING_PAY": {
            color: "rgba(21,193,22,1)",
            icon: "las la-fax",
            label: "查看/登记缴款信息"

    },
"EIT_RPT_FILING": {
            color: "rgba(141,43,197,1)",
            icon: "las la-file",
            label: "关联报告申报"

    },
"EIT_DTA_LIST": {
            color: "rgba(104,148,129,1)",
            icon: "las la-file-alt",
            label: "所得税费用管理"

    },
"EIT_DTA_SETUP_H": {
            color: "rgba(38,13,93,1)",
            icon: "las la-folder",
            label: "所得税费用配置"

    },
"EIT_DTA_TAX_RATE": {
            color: "rgba(139,41,65,1)",
            icon: "las la-folder-minus",
            label: "所得税费用税率"

    },
"EIT_BEICHA_UPLOAD": {
            color: "rgba(45,83,127,1)",
            icon: "las la-folder-open",
            label: "备查文档上传"

    },
"EIT_BEICHA_REVIEW": {
            color: "rgba(150,181,30,1)",
            icon: "las la-folder-plus",
            label: "备查文档复核"

    },
"EIT_BIZ_BEICHA_MGT": {
            color: "rgba(51,101,82,1)",
            icon: "las la-glasses",
            label: "备案备查规则设置"

    },
"EIT_BIZ_RULE_DOCU": {
            color: "rgba(51,44,83,1)",
            icon: "las la-globe",
            label: "文档规则设置"

    },
"EIT_BIZ_DOC_REV": {
            color: "rgba(197,187,100,1)",
            icon: "las la-highlighter",
            label: "文档审批设置"

    },
"EIT_INVEST_COMPANY": {
            color: "rgba(119,122,184,1)",
            icon: "las la-industry",
            label: "被投资企业基本信息"

    },
"EIT_MD_RELATED": {
            color: "rgba(171,118,51,1)",
            icon: "las la-landmark",
            label: "关联方主表"

    },
"EIT_RELATED_TAXPAYER": {
            color: "rgba(20,5,101,1)",
            icon: "las la-marker",
            label: "关联主体信息汇总表"

    },
"EIT_BIZ_DONATE_LIST": {
            color: "rgba(80,140,59,1)",
            icon: "las la-paperclip",
            label: "捐赠支出单位清单"

    },
"EIT_MD_FREETAX_PRO": {
            color: "rgba(150,156,121,1)",
            icon: "las la-paste",
            label: "免税产品认定基础表"

    },
"EIT_INCOME_RELIEF": {
            color: "rgba(69,14,31,1)",
            icon: "las la-pen",
            label: "所得减免项目"

    },
"EIT_NORMAL_CODEMGT": {
            color: "rgba(11,29,26,1)",
            icon: "las la-pen-alt",
            label: "代码表"

    },
"EIT_NORMAL_AUDIT": {
            color: "rgba(193,189,10,1)",
            icon: "las la-pen-fancy",
            label: "数据审计"

    },
"EIT_TAX_A_INDUSTRY": {
            color: "rgba(115,148,170,1)",
            icon: "las la-pen-nib",
            label: "数据集市规则设置"

    },
"EIT_BIZ_RULE_DATASTR": {
            color: "rgba(199,73,97,1)",
            icon: "las la-pen-square",
            label: "匹配规则定义"

    },
"EIT_TAXPAYER_DATA": {
            color: "rgba(9,107,159,1)",
            icon: "las la-pencil-alt",
            label: "纳税主体源数据定义"

    },
"EIT_SYNCDATA_RULE": {
            color: "rgba(136,184,51,1)",
            icon: "las la-percent",
            label: "数据获取规则设置"

    },
"EIT_TEMPLATE": {
            color: "rgba(171,15,62,1)",
            icon: "las la-phone",
            label: "导入模板"

    },
"EIT_TAX_MGT": {
            color: "rgba(130,124,23,1)",
            icon: "las la-phone-alt",
            label: "税务规则配置"

    },
"EIT_TAX_ACCOUNT": {
            color: "rgba(44,104,62,1)",
            icon: "las la-phone-slash",
            label: "税务科目定义"

    },
"EIT_BIZ_ASSET_DEPRE": {
            color: "rgba(177,55,17,1)",
            icon: "las la-phone-square",
            label: "资产税务折旧规则"

    },
"EIT_THRESHOLD": {
            color: "rgba(133,83,127,1)",
            icon: "las la-phone-square-alt",
            label: "系统预警参数设置"

    },
"BASE_INTERFACE_PROMPT_MESSAGE": {
            color: "rgba(124,22,125,1)",
            icon: "las la-address-card",
            label: "消息信息配置"

    },
"BASE_TAX_OTHER": {
            color: "rgba(120,109,62,1)",
            icon: "las la-archive",
            label: "其他税务设置"

    },
"BASE_MTM": {
            color: "rgba(49,17,154,1)",
            icon: "las la-balance-scale",
            label: "消息模板管理"

    },
"BASE_SYS_INI": {
            color: "rgba(192,194,83,1)",
            icon: "las la-balance-scale-left",
            label: "初始化配置"

    },
"BASE_SYS_SUBSYS": {
            color: "rgba(102,62,142,1)",
            icon: "las la-balance-scale-right",
            label: "子系统管理"

    },
"BASE_TAX_ACCOUNT": {
            color: "rgba(11,189,187,1)",
            icon: "las la-birthday-cake",
            label: "税务科目定义"

    },
"FRM_ENTITY_LOG": {
            color: "rgba(108,94,71,1)",
            icon: "las la-book",
            label: "实体变更审计日志"

    }
    }
}