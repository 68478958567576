import { whiteList,IwhiteList } from './request-ticket-manager-lit'
import {Util} from '@/api/util'
import CryptoJS from 'crypto-js'

/**
* 防重复提交管理器
*/
export class RequestTicketManager {
    /**
    * 防重复提交管理器全局唯一实例
    *
    * @private
    * @static
    * @type {AppVersionManager}
    * @memberof AppVersionManager
    */
    private static RequestTicketManager: RequestTicketManager;

    constructor(){

    }

    /**
     * 防重复提交管理器
     *
     * @static
     * @return {*}  {AppVersionManager}
     * @memberof RequestTicketManager
     */
    public static getInstance(environment?: any, args: any = {}): RequestTicketManager {
      if (!this.RequestTicketManager) {
          this.RequestTicketManager = new RequestTicketManager();
      }
      return this.RequestTicketManager;
    }

    /**
    * 设置请求头request-tikcet 
    * @param config 
    * @returns 
    */
    public setRequestTicket(config:any){
      const loginname = localStorage.getItem('loginname')
      if(!loginname){
        return config
      }
      let isWhite:boolean = false
      whiteList.forEach((item:IwhiteList) => {
        if(config.url.includes(item.url)){
          isWhite = true
        }
      })
      if(isWhite){
        config = this.setRequestTicketWihite(config)
      }
      const timestamp = this.createTimestamp()
      const info = `${loginname};${config.url};${timestamp}`
      const key = Util.concatenateString(loginname.toLowerCase())
      const iv = Util.concatenateString(loginname.toLowerCase())
      const value = Util.encryptAES256(info,key,iv)
      const valueBase64 = btoa(value)
      config.headers['request-ticket'] = valueBase64
      return config
    }

    /**
    * 设置防重复提交白名单 
    */
    public setRequestTicketWihite(config:any){
      let requestTicketWihite = ''
      if(config.method === 'post' && config.data){
        requestTicketWihite = CryptoJS.MD5(JSON.stringify(config.data)).toString()
      }else{
        requestTicketWihite = Util.createUUID()
      }
      let params = config.url.lastIndexOf('?')
      if(params === -1){
        config.url = `${config.url}?requestTicketWihite=${requestTicketWihite}`
      }else{
        config.url = `${config.url}&requestTicketWihite=${requestTicketWihite}`
      }
      return config
    }

    /***
    * 时间戳类型满足java.time.LocalDateTime DateTimeFormatter "yyyy-MM-dd'T'HH:mm:ss"
    */
    public createTimestamp(){
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, '0');
      let day = String(currentDate.getDate()).padStart(2, '0');
      let hours = String(currentDate.getHours()).padStart(2, '0');
      let minutes = String(currentDate.getMinutes()).padStart(2, '0');
      let seconds = String(currentDate.getSeconds()).padStart(2, '0');
      let timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return timestamp
    }
}