import { ApiService } from "@/api";
import shared from "@/shared";  
import { Message } from "element-ui";

let Environment:any = []

export function getBaseConfig(){
  let result:any = {}
  handleReqData()
  return new Promise((resolve: any, reject: any) => {
    ApiService.getInstance().getBaseConfig(reqData).then((data:any) => {
      result = handleResData(data)
      shared.setBaseConfig(result);
      // 原始参数由微应用进行处理
      (window as any).___portalConfig___ = data
      clearExpireLocal()
      resolve(true)
    }).catch((err:any) => {
      Message.error('配置中心加载失败,请联系管理员')
      clearExpireLocal()
      reject(false)
    })
    
  })
}

/**
*  获取的配置集合 
*/
let configMap = [
  // gateway地址
  {
    configKey:'app.gateway.url',
    handle:(data:any) => {
      return data['app.gateway.url']
    }
  },
   // 登录加密方式
  {
    configKey:'login.encryption.method',
    defaultValue:'md5',
    handle:(data:any) => {
      return data['login.encryption.method']
    }
  },
  // AES128加密偏移量
  {
    configKey:'AES.iv',
    handle:(data:any) => {
      return data['AES.iv']
    }
  },
  // AES128加密秘钥
  {
    configKey:'AES.key',
    handle:(data:any) => {
      return data['AES.key']
    }
  },
  {
    // 服务路径映射
    configKey:'serviceUrlMap',
    defaultValue:'/v7;/base;*,/uaa;/base;*,/configs;/base;*,/wfcore;/base;*,/tra/;;*',
      handle:(data:any) => {
        return data['environment.serviceUrlMap']
      }
  },
  {
    // 远端动态基础路径
    configKey:'remoteDynaPath.base',
    remoteDynaPath:true,
    defaultValue:'/dynamicmodel/randd',
    handle:(data:any) => {
      if(data['environment.remoteDynaPath.base']){
        return data['environment.remoteDynaPath.base']
      }
      return '/dynamicmodel/randd'
    }
  },
  {
    // 是否启用动态
    configKey:'bDynamic',
    defaultValue:'true',
    handle:(data:any) => {
      if(data['environment.bDynamic']){
        return stringByBoolean(data['environment.bDynamic'])
      }
      return true
    }
  },
  {
    // 是否启用远端模式
    configKey:'enableRemoteMode',
    defaultValue:'true',
    handle:(data:any) => {
      if(data['environment.enableRemoteMode']){
        return stringByBoolean(data['environment.enableRemoteMode'])
      }
      return true
    }
  },
  {
    // 是否为开发模式
    configKey:'devMode',
    defaultValue:'true',
    handle:(data:any) => {
      if(data['environment.devMode']){
        return stringByBoolean(data['environment.devMode'])
      }
      return true
    }
  },
  {
    // 是否为pc端应用
    configKey:'isAppMode',
    defaultValue:'true',
    handle:(data:any) => {
      if(data['environment.isAppMode']){
        return stringByBoolean(data['environment.isAppMode'])
      }
      return true
    }
  },
  {
    // ibz saas模式
    configKey:'SaaSMode',
    defaultValue:'true',
    handle:(data:any) => {
      if(data['environment.SaaSMode']){
        return stringByBoolean(data['environment.SaaSMode'])
      }
      return true
    }
  },
  {
    // 是否开启权限验证
    configKey:'enablePermissionValid',
    defaultValue:'true',
    handle:(data:any) => {
      if(data['environment.enablePermissionValid']){
        return stringByBoolean(data['environment.enablePermissionValid'])
      }
      return true
    }
  },
    {
    // 是否显示版本信息
    configKey:'enableVersion',
    defaultValue:'false',
    handle:(data:any) => {
      if(data['environment.enableVersion']){
        return stringByBoolean(data['environment.enableVersion'])
      }
      return true
    }
  },
  {
    // 是否启用登录验证码
    configKey:'login.captcha.enabled',
    defaultValue:'false',
    handle:(data:any) => {
      if(data['login.captcha.enabled']){
        return stringByBoolean(data['login.captcha.enabled'])
      }
      return false
    }
    },
    {
    // 是否开启多租户
    configKey:'multi.tenant',
    defaultValue:'false',
    handle:(data:any) => {
      if(data['multi.tenant']){
        return stringByBoolean(data['multi.tenant'])
      }
      return false
    }
  },
  {
    // 会话超时参数 
    configKey:'session.timeout.minutes',
    handle:(data:any) => {
      if(data['session.timeout.minutes']){
        return data['session.timeout.minutes']
      }
      return true
    }
  },
  {
    // 是否显示修改密码
    configKey:'enablechangepwd',
    defaultValue:'true',
    handle:(data:any) => {
      if(data['environment.enablechangepwd']){
        return stringByBoolean(data['environment.enablechangepwd'])
      }
      return true
    }
  },
  {
    // 基座我的常用模块
    configKey:'micro-main-common',
    defaultValue:'false',
    handle:(data:any) => {
      if(data['micro-main-common']){
        return stringByBoolean(data['micro-main-common'])
      }
      return true
    }
  },
  // 常用错误HTTP状态码消息文本
  {
    configKey:'responseCodeDefaultMessage',
    defaultValue:'502,错误网关,error;503,服务不可用,error;504,网关超时,error;',
    handle:(data:any) => {
      return data['environment.responseCodeDefaultMessage']
    }
  },
]

/**
 * 字符串转布尔
 * @param string 
 */
function stringByBoolean(string:string){
  if(string === 'false'){
    return false
  }
  return true
}

/**
* 请求参数 
*/
let reqData:any = []

/**
* 忽略environment前缀相关配置 
*/  
let configKeyMap:Array<string> = ['app.gateway.url','login.encryption.method','AES.iv','AES.key','multi.tenant','session.timeout.minutes','micro-main-common','login.captcha.enabled']

/**
 * 处理请求参数
 */
function  handleReqData(){
  configMap.forEach((config:any) => {
    let configKey = ''
    if(configKeyMap.includes(config.configKey)) {
      configKey = config.configKey
    }else{
      configKey = 'environment.' + config.configKey
    }
    const temp = {
      configKey,
      defaultValue:config.defaultValue
    }
    reqData.push(temp)
  })
}

/**
*  处理响应参数 
*/
function handleResData(data:any){
  let result:any = {}
  configMap.forEach((config:any) => {
    if(config.remoteDynaPath){
      result.remoteDynaPath = config.handle(data)
    }else{
      result[config.configKey] = config.handle(data)
    }
  })
  return result
}

/**
* 根据后端设置的token过期时间和最后一次请求时间,如果过期则清除token
*/
const clearExpireLocal = () =>{
  let lastRequestTime = localStorage.getItem('last-request-time')
  if(lastRequestTime){
    let minutes = (Environment as any)['session.timeout.minutes'] || 120;
    // console.log((Environment as any)['session.timeout.minutes'])
    let milliseconds = minutes * 60 * 1000;
    let expiredTime = Number(lastRequestTime) + milliseconds
    let now = Date.now()
    if(now > expiredTime){
      localStorage.removeItem('ibzuaa-token')
      // 清除租户相关信息
      localStorage.removeItem("activeOrgData");
      localStorage.removeItem("srfdynaorgid");
      localStorage.removeItem("dcsystem");
      localStorage.removeItem("orgsData");
    }
  }
}