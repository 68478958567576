



























































import { Vue, Component } from "vue-property-decorator";
import { ApiService } from "@/api";
import axios from "axios";

interface Item {
  handlingTime: number; // 处理耗时
  time: string; // 时间
  title: string; // 事项标题
  handler: string; // 处理人
  status: string; // 事项状态
}

@Component({})
export default class BaseHomeAuditContent extends Vue {
  public mounted() {
    let auditheight:any = document.getElementsByClassName("audit")[0].scrollHeight - 80;
    auditheight = parseFloat(auditheight);
    if(auditheight < 300){
      auditheight = 300;
    }
    this.pageSize = auditheight/68;
    this.pageSize = parseInt(this.pageSize) + 2;
    this.initData();
    this.initApi();
  }

  public tabsValue = "0";
  public pageSize:any = 8;
  public noMore = false;//没有更多数据

  public tabsOption = [
    {
      label: this.$t("baseHome.toDo"),
      name: "0",
    },
    {
      label: this.$t("baseHome.done"),
      name: "1",
    },
    {
      label: this.$t("baseHome.unread"),
      name: "2",
    },
    {
      label: this.$t("baseHome.read"),
      name: "3",
    },
  ];

  data: { [key: string]: Item[] } = {
    // 0: [], // 待办
    // 1: [], // 已办
    // 2: [], // 待阅
    // 3: [], // 已阅
  };

  // 懒加载之  --------  初始化参数
  oldData: { [key: string]: Item[] } = {
    0: [], // 待办
    1: [], // 已办
    2: [], // 待阅
    3: [], // 已阅
  };
  // 懒加载之  --------  初始化参数之当前加载的page页
  public currentPage0 = 1;
  public currentPage1 = 1;
  public currentPage2 = 1;
  public currentPage3 = 1;

  /**
   * 初始化数据
   */
  initData() {
    this.tabsOption.forEach((item) => {
    this.$set(this.oldData, item.name, []);
    });
  }

  /**
   * 初始化接口
   */
  async initApi() {
    const pageSize = this.pageSize;
    this.tabsOption.forEach(async (item: any) => {
      ApiService.getInstance()
        .getMessage(item.name)
        .then((res: any) => {
          const data = res.data || {};
          this.data[item.name] = (data.result || []).map((item: any) => {
            if(item.content.includes("<a")){
                return {
                  title: item.content.split("<a")[0],
                  link:"<a" + item.content.split("<a")[1],
                  time: item.create,
                  id:item.id
                };
            }else{
                return {
                  title: item.content,
                  time: item.create,
                  id:item.id
                };
            }
            
          });
          // 懒加载之  --------  初始化数据
          let dataArray = JSON.parse(JSON.stringify( this.data || {} )) 
          for( let item in dataArray){
            if (dataArray[item] && dataArray[item].length > pageSize) {
              this.oldData[item] = dataArray[item].splice(0, pageSize)
            } else {
              this.oldData[item] = dataArray[item]
            }
          }
          
        });
    });
  }

  // 懒加载之  --------  数据请求完带 把数据给 this.oldData  之后调用  initData  获取当前数据第一次数据， 在滚动时  让currentPage + 1， 之后再 initData
  initializationData () {
    const pageSize = this.pageSize;
    const self = this as any
    let number = Number(this.tabsValue)
    let currentPage:number = Number(self[`currentPage${number}`])
    
    if ( this.data[number].length - (pageSize * currentPage) <= 0 ){
      this.noMore = true
      return false
    } else {
      currentPage += 1
      this.oldData[number] = [...this.oldData[number], ...this.pagination(pageSize, currentPage, this.data[number])]
      self[`currentPage${number}`] = currentPage
    }
  }

  // 懒加载之  --------  数据叠加处理
  pagination (pageSize: any, currentPage: any, arr: string|any[]) {
    var skipNum = (currentPage - 1) * pageSize
    var newArr = (skipNum + pageSize >= arr.length) ? arr.slice(skipNum, arr.length) : arr.slice(skipNum, skipNum + pageSize)
    return newArr
  }

  // 懒加载之  --------  监听页面滚动 refs现在有4个 :ref="`resListRef${index1}`"
  scroll() {
    this.$nextTick(()=>{
        // console.log(this.$refs) //这时的refs是个数组
        const ref = this.$refs as any
        let number = Number(this.tabsValue)
        // 获取滚动的高度--根据不同的tab获取不同的高度值
        let scrollTop = ref[`resListRef${number}`][0].scrollTop
        // 获取可视区的高度--根据不同的tab获取不同的高度值
        const windowHeight = ref[`resListRef${number}`][0].clientHeight
        // 获取滚动条的总高度--根据不同的tab获取不同的高度值
        const scrollHeight = ref[`resListRef${number}`][0].scrollHeight

        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        if (scrollTop + windowHeight + 1 >= scrollHeight ) {
          console.log('到底啦')
          // 调用函数
          this.initializationData()
        }
    })
  }
  //切换tab
  handleClick() {
    this.noMore = false;
  }

  // 点击待办等信息在基座中打开
  msgclick(item:any){
    this.$router.push(item);
  }
  public msgclose(item:any,index:number){
    //只有待阅的时候调
    if(index!=2){
     
        return;
    }
    axios({
            method: 'post',
            url: "../../../gateway/base/msgintnotifications/"+item.id+"/msgclose",
            data: {
                
            },

    }).then(() =>{
        this.initData();
        this.initApi();
    }).catch((err:any) =>{
        console.log(err)
    });
  }
}
