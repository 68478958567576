<template>
  <div class="messageDialog1">
    <div class="dttProcessTitle">
      <img :src="iconClass === 'funnel' ? 'img/funnel.png' : 'img/success.svg'" :class="iconClass" />
      <span class="imgSpanClass">{{ iconClass === 'funnel' ? '任务进行中...' : '任务已完成' }}</span>
    </div>
    <div class="dttProcess">
      <el-progress :percentage="percentage" :stroke-width="12"></el-progress>
    </div>
    <div class="dttProcessJournalBox">
      <div class="dttProcessJournalBoxDiv" v-html="journalBox ? journalBox : ''"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'journalBox',
    'iconClass',
    'percentage'
  ],
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped></style>
