import { AppState } from "@/interface";
import shared from "@/shared";
import router from "@/routes";
import { AppService } from "./app-service";
import { apps } from "@/micro";
import { Message } from "element-ui";
import { ApiService } from "@/api";
import NProgress from "nprogress";
import Vue from 'vue';

/**
 * 微应用行为处理服务
 *
 * @export
 * @class MicroActionService
 */
export class MicroActionService {
  /**
   * 唯一实例
   *
   * @private
   * @static
   * @memberof MicroActionService
   */
  private static readonly instance = new MicroActionService();

  /**
   * 获取唯一实例
   *
   * @static
   * @return {*}  {MicroActionService}
   * @memberof MicroActionService
   */
  public static getInstance(): MicroActionService {
    return MicroActionService.instance;
  }

  /**
   * 处理微应用抛出行为
   *
   * @param {AppState} state
   * @memberof MicroActionService
   */
  public handleAction(state: AppState) {
    const { action, data } = state;
    switch (action) {
      case "ADD_PAGE": {
        const loadContainer = document.getElementById(
          "child-container-loading"
        );
        if (loadContainer) {
          loadContainer.style.cssText = "display: none;";
        }
        shared.addPage(data);
        break;
      }
      case "ADD_MENUSPATH": {
        shared.updateActiveCrumbList(data);
        break;
      }
      case "UPDATE_PAGE":
        shared.updatePage(data);
        break;
      case "REMOVE_PAGE":
        this.handleRemovePage(data);
        break;
      case "LOGOUT":
        this.handleLogout();
        break;
      case "ADD_MENUS":
        this.handleMenus(data);
        break;
      case "OPEN_MYMENUS":
        this.handleOpenMenus(data);
        break;
      default:
        console.warn(`基座应用${action}暂未支持`);
        break;
    }
  }

  /**
   * 处理关闭页面(由微应用触发)
   *
   * @param {*} data
   * @memberof MicroActionService
   */
  public handleRemovePage(data: any) {
    const allPages = JSON.parse(JSON.stringify(shared.getAllPage()));
    shared.removePage(data);
    const activeMicroApp = AppService.getInstance().getMountedMicroApp();
    // 无页面，跳转当前激活应用默认页面
    if (!allPages || allPages.length === 0) {
      const activeRule = activeMicroApp?.activeRule;
      if (activeRule) {
        // router.push(activeRule);
        // router.push(activeRule.slice((window as any).Environment.baseDeployUrl.length));
        router.push("/");
      }
    } else {
      const existAppIndex = allPages.findIndex((page: any) => {
        return page.microAppName === activeMicroApp.name;
      });
      // 当前激活应用无激活页面，应跳转其他应用
      if (existAppIndex === -1) {
        const lastPage = allPages[allPages.length - 1];
        if (lastPage.microAppName) {
          const activeApp = apps.find((app: any) => {
            return app.name === lastPage.microAppName;
          });
          if (activeApp) {
            // router.push(activeApp.activeRule);
            router.push(
              activeApp.activeRule.slice(
                (window as any).Environment.baseDeployUrl.length
              )
            );
          }
          AppService.getInstance().noticeMicroApp({
            tag: lastPage.microAppName,
            action: "TABCLICK",
            data: lastPage,
          });
        }
      } else {
        const currentFullPath =
          (window as any).Environment.baseDeployUrl +
          router.currentRoute.fullPath;
        const removeFullPath = `${activeMicroApp.activeRule}#${data.fullPath}`;
        if (
          currentFullPath === removeFullPath &&
          shared.getHistoryPathList().length > 0
        ) {
          const lastHistoryPath =
            shared.getHistoryPathList()[shared.getHistoryPathList().length - 1];
          // 和当前关闭页面是同一个应用，则由基座应用跳转
          if (lastHistoryPath.startsWith(`/${activeMicroApp.activeRule}`)) {
            const lastPage = allPages.find((page: any) => {
              return (
                page.fullPath ===
                lastHistoryPath.slice(lastHistoryPath.indexOf("#") + 1)
              );
            });
            if (lastPage && lastPage.microAppName) {
              AppService.getInstance().noticeMicroApp({
                tag: lastPage.microAppName,
                action: "TABCLICK",
                data: lastPage,
              });
            }
          } else {
            // 和当前关闭页面不是同一个应用，则由基座应用跳转
            const lastPage = allPages.find((page: any) => {
              return (
                page.fullPath ===
                lastHistoryPath.slice(lastHistoryPath.indexOf("#") + 1)
              );
            });
            if (lastPage && lastPage.microAppName) {
              const activeApp = apps.find((app: any) => {
                return app.name === lastPage.microAppName;
              });
              if (activeApp) {
                router.push(
                  activeApp.activeRule.slice(
                    (window as any).Environment.baseDeployUrl.length
                  )
                );
              }
              AppService.getInstance().noticeMicroApp({
                tag: lastPage.microAppName,
                action: "TABCLICK",
                data: lastPage,
              });
            }
          }
        }
      }
    }
  }

  /**
   * 处理登出
   *
   * @memberof MicroActionService
   */
  public handleLogout(isMessage: boolean = true) {
    AppService.getInstance().clearAppData();
    shared.removeAllPage();
    shared.setActiveCrumbPathAll([])
    shared.setActiveCrumbPath([])
    if (localStorage.getItem('ssoToUrl')) {
      const post: Promise<any> = ApiService.getInstance().LoginSSO({
        emailDomainName: localStorage.getItem('ssoToUrl')
      });
      post.then((res: any) => {
        localStorage.setItem('ssoUrlOld', window.location.href.substring(window.location.href.indexOf("portal/") + 6));
        window.location.href = res.data.redirectUrl
      }).catch((err: any) => {
        Message.error((err as any)?.response?.data?.message);
      })
    } else {
      if(isMessage){
        Message({ message: "登录状态已过期，请重新登陆!", type: "error" });
      }
      router.push({ name: 'Login'});
      const loadContainer = document.getElementById(
        "child-container-loading"
      );
      if (loadContainer) {
        loadContainer.style.cssText = "display: none;";
      }
      NProgress.done()
    }
  }
  /**
   * SSO处理登出
   *
   * @memberof MicroActionService
   */
  public handleLogoutSSO() {
    AppService.getInstance().clearAppData();
    shared.removeAllPage();
  }
  /**
   * 自定义菜单中全部应用的菜单项触发基座打开菜单
   *
   * @memberof MicroActionService
   */
  public handleMenus(data:any) {
    // 在需要触发其他页面函数的地方
    eventBus.$emit('customMenuMessage', data);
  }

  /**
   * base系统参数中打开我的菜单通知基座打开
   *
   */
  public handleOpenMenus(data:any) {
    // 通知app-user打开弹窗
    eventBus.$emit('opemMenus', data.open);
  }
  

}
export const eventBus = new Vue();
