





import { Vue, Component } from 'vue-property-decorator';
import { ApiService } from "@/api";
import { AppService } from "@/service/app-service";
import { Message } from "element-ui";
@Component({
  components: {},
})
export default class Login extends Vue {
  public created() {
    var formState = this.$route.query
    AppService.getInstance().clearAppData();
    const post: Promise<any> = ApiService.getInstance().LoginSaml(formState);
    post.then((res: any) => {
      const { data } = res;
      if (data && data['token']) {
        localStorage.setItem("ibzuaa-token", data['token']);
        if (data && data['user']) {
          localStorage.setItem("ibzuaa-user", JSON.stringify(data['user']));
        }
        if (data && data.expirdate) {
          localStorage.setItem(
            "ibzuaa-expired",
            new Date(data.expirdate).getTime().toString()
          );
        }
        localStorage.setItem('loginToUser', 'login');
        localStorage.removeItem('ssoToUrl');
        localStorage.removeItem('ssoUrlOld');
        this.$router.push('/');
      }
    }).catch(() => {
      setTimeout(() => {
      const el = document.getElementById("app-root-loading-x");
      if (el) {
        el.style.display = "none";
        Message.error('用户错误')
      }
    }, 1000);
    })
  }
}
