import { menuServiceBase } from './menu-service-base'
import { IrootMenus,IMenuItem,IfuzzrySerach } from '@/interface'
import { AppConfig } from "@/config/config";
import { AppService } from "@/service/app-service";

export class IBIZServiceBase extends menuServiceBase {
    /**
   * 单例变量声明
   *
   * @private
   * @static
   * @type {ApiService}
   * @memberof ApiService
   */
    private static IBIZServiceBase: IBIZServiceBase;

  /**
  * 获取 IBIZServiceBase 单例对象
  *
  * @static
  * @returns {IBIZServiceBase}
  * @memberof IBIZServiceBase
  */
  public static getInstance(): IBIZServiceBase {
    if (!IBIZServiceBase.IBIZServiceBase) {
      IBIZServiceBase.IBIZServiceBase = new IBIZServiceBase();
    }     
    return this.IBIZServiceBase;
  }

  private constructor(){
    super();
  }

  /**
  * 子系统菜单缓存 
  */
  public menuCache:any = new Map();

  /**
  * 一级菜单缓存 
  */
  public rootMenusCache: IrootMenus[] = [];

  /**
  * 菜单收缩状态
  */
  public collapsed:boolean = false

  /**
  * 获取一级菜单 
  * @returns 
  */
  public async getallrootmenus() {
    return await this.http.get("/uaa/allrootmenus");
  }
  
  /**
  * 获取子系统菜单 
  * @returns 
  */
  public async getMenus(system:string,app:string,menutag:string) {
    return await this.http.get(`/uaa/menus/${system}/${app}/${menutag}`);
  }

  /**
  * 一级菜单加载 
  * @param 菜单层级
  */
  public rootMenusLoad(){
    return new Promise((resolve, reject) => {
      this.getallrootmenus().then((res)=>{
        this.rootMenusCache = this.handleRootMenus(res.data)
        resolve(this.rootMenusCache)
      }).catch((err:any) => {
        reject(err)
      })
    })
  }

  /**
  * 子系统菜单加载 
  */
  public menusLoad(menu:any){
    return new Promise((resolve, reject) => {
      const {system,app,tag} = menu
      const title = this.rootMenusCache.find((item:any) => item.system === system)?.title
      if(!title){
        reject('无匹配的一级菜单项')
      }
      let result:any = []
      if(this.menuCache.get(system)){
        result = this.menuCache.get(system)
        resolve(this.addFirstMenu(result,menu))
        return
      }
      this.getMenus(system,app,tag).then((res)=>{
        const data = this.handleMenu(res.data,menu)
        result = AppService.getInstance().initMenuUrl(data,title)
        this.setMenuCache(system,result)
        resolve(this.addFirstMenu(result,menu))
      }).catch((err:any) => {
        reject(err)
      })
    })
  }

  /**
  * 菜单收缩时将一级菜单添加到二级 
  * @param menu 
  */
  public addFirstMenu(result:any,menu:any){
    let menuGroup:any = {}
    if(result[0] && result[0].menuGroup){
      result.shift()
    }
    if(this.collapsed){
      const keys = Object.keys(menu)
      keys.forEach((key:any) => {
          if(key !== 'children'){
              menuGroup[key] = menu[key]
          }
          if(key == 'key'){
            menuGroup[key] = `${menu[key]}-group`
          }
      })
      menuGroup.menuGroup = true
      result.unshift(menuGroup)
    }
    return result
  }

  /**
  * 设置子系统菜单缓存 
  * @param menu   
  */
  public setMenuCache(key:any,vale:any){
    if(!this.menuCache.get(key)){
      this.menuCache.set(key,vale)
    }
  }

  /**
  * 设置菜单收缩状态
  * @param collapsed 
  */
  public setCollapsed(collapsed:boolean){
    this.collapsed = collapsed
  }

  /**
  *  清空子系统菜单缓存 
  */
  public clearMenuCache(){
    this.menuCache = new Map()
  }

  /**
  * 处理子系统菜单
  * @param menus 
  */
  public handleMenu(menus:Array<any>,args:any,target:Array<any> = []){
    const {app,path} = args
    menus.forEach((menu:any,index:any) => {
      const targetMenuItem:IMenuItem = {
        key: `${app}-${menu.tag}`,
        title: this.getLocaleCaption(menu),
        path: path,
        funtag: menu.funcTag,
        apptag: app,
        iconClass: menu?.iconClass ?? 'las la-plus-square',
        resourceId: menu.resourceId,
      };
      target.push(targetMenuItem)
      if(menu.children && menu.children.length){
        target[index].children = this.handleMenu(menu.children,args)
      }
    })
    return target
  }

  /**
  * 处理一级菜单
  * @param menus 
  */
  public handleRootMenus(menus:Array<any>){
    const data:IrootMenus[] = [
      {
        system: 'portal',
        title: '首页',
        path: `/`,
        icon: 'home',
        tag:'index',
        app:'index',
        key:'index',
      }
    ];
    if (menus && menus.length) {
      menus.forEach((menu: any) => {
        if (menu && menu.system && AppConfig.mountedSystems.indexOf(menu.system) !== -1) {
          let appMenus: IrootMenus = {
            system: menu.system,
            title: this.getLocaleCaption(menu),
            path: `/${menu.system}`,
            icon: menu.icon ?? 'default',
            tag:menu.tag,
            app:menu.app,
            key:menu.system,
            children:[]
          };
          data.push(appMenus);
        }
      });
    }
    return data
  }

  /**
  * 处理模糊搜索数据 
  * @param menus 
  */
  public handleFuzzySearch(menus:Array<any>):IfuzzrySerach[]{
    let data:IfuzzrySerach[] = []
    menus.forEach((menu:any,index:any)=>{
      const app = this.rootMenusCache.find((item:any)=>item.system === menu.configEx)?.app || ''
      const item:IfuzzrySerach = {
        key: `${app}-${menu.tag}`,
        title: this.getLocaleCaption(menu),
        path: menu.configEx,
        funtag: menu.funcTag,
        apptag: app,
        iconClass: menu?.iconClass ?? 'las la-plus-square',
        resourceId: menu.resourceId,
        system:menu.configEx,
        tag:menu.tag,
      }
      data.push(item)
    })
    return data
  }

  /**
  * 远端模糊搜索 
  */
  public async getFuzzySearch(value:string){
    return await this.http.get(`/uaa/queryleafmenus?name=${value}`)
  }

  /**
  * 模糊搜索 
  */
  public fuzzySearch(value:string){
    return new Promise((resolve, reject) => {
      this.getFuzzySearch(value).then((res)=>{
        const data = this.handleFuzzySearch(res.data)
        resolve(data)
      }).catch((err:any) => {
        reject(err)
      })
    })
  }


  /**
  * 获取国际化标题
  */
  private getLocaleCaption(menu: any) {
    return menu.myCaptionLocale ? menu.myCaptionLocale : menu.captionLocale
  }
}
