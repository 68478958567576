import Vue from 'vue';
import VueI18n from 'vue-i18n';

const vueApp: any = Vue;

vueApp.use(VueI18n);
import zhCN from './lang/zh-cn';
import enUS from './lang/en-us';

const messages = {
    'zh-CN': zhCN(),
    'en-US': enUS()
}

const navLang = localStorage.getItem('local') || navigator.language;
const localLang = (navLang === 'zh-CN' || (navLang === 'en-US')) ? navLang : false;
let lang: string = localLang || 'zh-CN';

vueApp.config.lang = lang

// vue-i18n 6.x+写法
vueApp.locale = () => { };

const i18n = new VueI18n({
    locale: lang,
    messages,
    silentTranslationWarn:true
});

export default i18n;
