import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhBaseHome from './zh-base-home'

function getAppLocale() {
    const data = {
        micro: {
            tablimittooltip: '最多只能开启 {count} 个标签页，请关闭现有标签页后再开启新的标签页'
        },  
        common: {
            microapp: '微应用基座应用',
            more: '更多'
        },
        language: {
            EN: '英文',
            CN: '中文简体'
        },
        components: {
            apppageexp: {
                closeall: '关闭所有',
                closeother: '关闭其他'
            },
            appuser: {
                info: '用户信息',
                logout: '退出登录',
                clearCache:'清除缓存',
                myMenus:'我的菜单',
                sureclearCache:'是否要清除缓存?',
                updatePassWord:'修改密码',
                versiondetail:'版本信息',
                versionBuildTime:'构建时间',
                currentVersion:'当前版本',
                serveCoreVersion:'core包版本',
                serveIBIZVersion:'ibiz框架包版本',
                model:'使用模板'
            },
            login: {
                loginbutton: '登录',
                loginerror: '登录失败！',
                placeholder: {
                    user: '请输入用户名',
                    password: '请输入密码'
                }
            },
            appupdatepassword: {
                oldpwd: '原密码',
                newpwd: '新密码',
                confirmpwd: '确认密码',
                sure: '确认修改',
                oldpwderr: '原密码不能为空！',
                newpwderr: '新密码不能为空！',
                newpwderr2:'密码不能小于8位且必须包含大小写数字和特殊字符',
                newpwderr3:'新密码不能为旧密码相同!',
                confirmewderr: '两次输入密码不一致！',
                success:'修改密码成功！'
            },
            tisHome: {
                RiskScanResults: '风险扫描结果',
                LastMonth: '上月度',
                ThisQuarter: '本季度',
                ThisYear: '本年度',
                LowRisk: '低风险',
                HighRisk: '高风险',
                Announcement: '公告',
                ViewAll: '查看全部',
                DataUploaded: '待上传数据',
                IndividualReport: '单体报告',
                GroupReport: '集团报告',
                ClickToView: '点击查看',
                ConsultationAnswers: '咨询与解答',
                FAQ: '常见问题',
                PolicyInterpretation: '政策解读',
                TaxConsultation: '税务咨讯',
                AdvisorOnline: '顾问在线',
                Homepage: '首页配置',
                BackgroundColor: '背景色',
                NoMore: '没有更多数据了',
                Sanduserpermissions: '此处展示所有具备统一资源码及用户权限得菜单应用',
                UserRequired: '用户名不能为空',
                PwdRequired: '密码不能为空',
                VerificationCode: '验证码',
                Validate: '验证',
                Enterverificationcode: '请输入邮箱验证码',
                EmailVerificationCode: '邮箱验证码',
                EnterSMSverificationcode: '请输入短信验证码',
                SMSVerificationCode: '短信验证码',
                SSO: '单点登录',
                Maildomainname: '请输入邮箱域名',
                EmitRequired: '邮箱域名不能为空',
                GoTo: '前往',
                ICPPNo: '沪ICP备19022806号-2',
                PreparationNo: '沪公安网备31010102006864号',
                TaxManagement: '税务管理系统平台门户',
            },
            operationGuide: {
                operationGuideTitle: "操作指南",
                userOperationGuide: "用户操作指南",
            },
        },
        el: {
            colorpicker: {
                clear: '清空',
                confirm: '确认'
            }
        },
        // ↑ el与elemen提供的同名，会被覆盖
        ...zhLocale,
        ...zhBaseHome
    }
    return data;
}
export default getAppLocale;