






















import { Vue, Component, Prop, Watch } from "vue-property-decorator";
// import { getCookie } from "qx-util";

@Component({})
export default class BaseHomeChartsContent extends Vue {
  @Prop({
    type: String,
    default: () => null,
  })
  value!: string;

  @Watch("value", { immediate: true })
  valueWatch() {
    this.company = this.value;
  }

  mounted() {
    this.getDeclareOrg();
  }

  company: null | string = null;
  options: any[] = [];

  getDeclareOrg() {
    this.getOrgData();
    this.options = [
      { orgid: null, orgname: this.$t('baseHome.unlimited') },
      ...(this.selectedOrgArray || []),
    ];
  }

  change() {
    this.$emit("input", this.company);
    this.$emit("change", this.company);
  }

  /**
   * ---------- 从 app-orgsector.vue 中复制过来 start
   */

  /**
   * 选中组织部门id
   *
   * @type {string}
   * @memberof AppOrgSector
   */
  public selectedOrgId: string = "";

  /**
   * 选中组织部门名称
   *
   * @type {string}
   * @memberof AppOrgSector
   */
  public selectedOrgName: string = "";

  /**
   * 组织部门名称数组
   *
   * @type {Array<any>}
   * @memberof AppOrgSector
   */
  public selectedOrgArray: Array<any> = [];

  /**
   * 获取数据
   *
   * @memberof AppOrgSector
   */
  public getOrgData() {
    // if (getCookie("activeOrgData")) {
    //   this.selectedOrgName = JSON.parse(
    //     getCookie("activeOrgData") as string
    //   )?.orgname;
    //   this.selectedOrgId = JSON.parse(
    //     getCookie("activeOrgData") as string
    //   )?.orgid;
    // }
    // if (getCookie("orgsData")) {
    //   this.selectedOrgArray = JSON.parse(getCookie("orgsData") as string);
    //   if (this.selectedOrgArray?.length > 0) {
    //     this.selectedOrgArray.forEach((item: any) => {
    //       Object.assign(item, {
    //         srforgsectorid: item.orgid,
    //         srforgsectorname: item.orgname,
    //       });
    //     });
    //   }
    // }
  }

  /**
   * ---------- 从 app-orgsector.vue 中复制过来 end
   */
}
