import { AppConfig } from "@/config/config";
import { Http } from "@/net";
import { AppService } from "@/service/app-service";
import store from "@/shared/store";
import { notification } from "ant-design-vue";
import qs from "qs";
import { ApiService } from "@/api";
import { Message } from "element-ui";
import Vue from "vue";

export class AuthGuard {

    /**
     * 权限校验
     *
     * @static
     * @memberof AuthGuard
     */
    public static async checkAuth(router: any): Promise<boolean> {
        return new Promise((resolve: any, reject: any) => {
            const url = '/appdata';
            if (AppConfig && AppConfig.SaaSMode) {
                if (localStorage.getItem('activeOrgData')) {
                    this.getAppData(url, router).then((result: any) => {
                        result ? resolve(true) : reject(false);
                    });
                } else {
                    this.getOrgsByDcsystem(router).then((result: boolean) => {
                        if (!result) {
                            reject(false);
                        } else {
                            this.getAppData(url, router).then((result: any) => {
                                result ? resolve(true) : reject(false);
                            });
                        }
                    });
                }
            } else {
                this.getAppData(url, router).then((result: any) => {
                    result ? resolve(true) : reject(false);
                });
            }
        });
    }

    /**
     * 通过租户获取组织数据
     *
     * @memberof AuthGuard
     */
    private static getOrgsByDcsystem(router: any): Promise<boolean> {
        return new Promise((resolve: any) => {
            let tempViewParam = this.hanldeViewParam(window.location.href);
            if (!tempViewParam.srfdcsystem) {
                if (!tempViewParam.redirect) {
                    if (localStorage.getItem('dcsystem')) {
                        tempViewParam = JSON.parse(localStorage.getItem('dcsystem') as string);
                    }
                } else {
                    tempViewParam = this.hanldeViewParam(tempViewParam.redirect);
                }
            }
            if (!tempViewParam.srfdcsystem && AppConfig.mockDcSystemId) {
                Object.assign(tempViewParam, { srfdcsystem: AppConfig.mockDcSystemId });
            }
            if (tempViewParam.srfdcsystem) {
                localStorage.setItem('dcsystem', JSON.stringify(tempViewParam));
                // let requestUrl: string = `/uaa/getbydcsystem/${tempViewParam.srfdcsystem}`;
                let requestUrl: string = `/userCtrl/myOrgList.dtt?includeMyManager=true`;
                const get: Promise<any> = Http.getInstance().get(requestUrl);
                get.then((response: any) => {
                    if (response && response.status === 200) {
                        let { data }: { data: any } = response;
                        if(data?.result && data?.extResult){
                            const _orgData=this.ObjectToLowerCase(data.result,tempViewParam.srfdcsystem)
                            const _activeOrgData=this.ObjectToLowerCase(data.extResult,tempViewParam.srfdcsystem)
                            localStorage.setItem('orgsData',JSON.stringify(_orgData))
                            localStorage.setItem('activeOrgData',JSON.stringify(_activeOrgData))
                        }else{
                            // 无组织时填充systemid
                            const systemid={systemid:tempViewParam.srfdcsystem,orgid:''}
                            localStorage.setItem('activeOrgData',JSON.stringify(systemid))
                        }
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch((error:any) => {
                    const {message} = error.response;
                    this.goLogin(router, message ? error.response :"登录失败，请联系管理员: "+error.response.data.message).then((result:any) =>{
                        resolve(result);
                    })

                });
            } else {
                this.goLogin(router, "登录失败，请联系管理员").then((result:any) =>{
                    resolve(result);
                })

            }
        });
    }

    /**
     * 获取应用数据
     *
     * @param {string} url url 请求路径
     * @param {*} [router] 路由对象
     * @param {*} [params={}] 请求参数
     * @returns {Promise<boolean>} 是否通过
     * @memberof AuthGuard
     */
    private static getAppData(url: string, router: any, _params: any = {}): Promise<boolean> {
        return new Promise((resolve: any) => {
            const get: Promise<any> = Http.getInstance().get(url);
            get.then((response: any) => {
                if (response && response.status === 200) {
                    let { data }: { data: any } = response;
                    if(data?.context?.srflocale){
                        const i18nLocaleMap = (key:any) => {
                            switch (key) {
                                case 'ZH-CN':
                                    return 'zh-CN';
                                case 'EN':
                                    return 'en-US';
                                default:
                                    return 'zh-CN';
                            }
                        }
                        if(!localStorage.getItem('local')){
                            localStorage.setItem("local", i18nLocaleMap(data.context.srflocale));
                            if(Vue.prototype && Vue.prototype.$baseMenu){
                                Vue.prototype.$baseMenu.setI18nLocale(i18nLocaleMap(data.context.srflocale))
                            }
                        }else{
                            if(Vue.prototype && Vue.prototype.$baseMenu){
                                Vue.prototype.$baseMenu.setI18nLocale(localStorage.getItem('local'))
                            }
                        }
                    }
                    if (data && Object.keys(data).length > 0) {
                        AppService.getInstance().setAppData(data);
                        localStorage.setItem('microAppData', JSON.stringify(data));
                        this.initIcon(data)
                        this.initTheme(data.context);//动态主题颜色
                    }
                }
                resolve(true)
            }).catch(() => {
                this.goLogin(router, "登录失败，请联系管理员").then((result:any) =>{
                    resolve(result);
                })
            });
        });
    }

    /**
    *  初始化图标
    */
    private static initIcon(data:any) {
        const context = data.context
        if(context && context.param && context.param.icon){
            const link: any = document.querySelector("link[rel*='icon'") || document.querySelector("link");
            link.href =context.param.icon;
            document.getElementsByClassName("head")[0]?.appendChild(link);
        }
    }

    /**
     * 处理路径数据
     *
     * @param {*} [urlStr] 路径
     *
     * @memberof AuthGuard
     */
    private static hanldeViewParam(urlStr: string): any {
        let tempViewParam: any = {};
        const tempViewparam: any = urlStr.slice(urlStr.indexOf('?') + 1);
        const viewparamArray: Array<string> = decodeURIComponent(tempViewparam).split(';');
        if (viewparamArray.length > 0) {
            viewparamArray.forEach((item: any) => {
                Object.assign(tempViewParam, qs.parse(item));
            });
        }
        return tempViewParam;
    }

    /**
     * 跳转登录
     *
     * @static
     * @memberof AuthGuard
     */
    private static async goLogin(router: any, message: string) {
        AppService.getInstance().clearAppData();
        if (Object.is(router.currentRoute.name, 'Login')) {
            Message.error(message);
            return;
        }
        if (localStorage.getItem('ssoToUrl')) {
          localStorage.setItem('ssoUrlOld', window.location.href.substring(window.location.href.indexOf("portal/") + 6));
          const post: Promise<any> = ApiService.getInstance().LoginSSO({
            emailDomainName: localStorage.getItem('ssoToUrl')
          });
          post.then((res: any) => {
            window.location.href = res.data.redirectUrl
          }).catch((err: any) => {
            Message.error((err as any)?.response?.data?.message);
          })
        } else {
          const isAtEnd = window.location.href.endsWith("/portal");
          let redirect = '/'
          if(!isAtEnd){
            redirect = window.location.href.substring(window.location.href.indexOf("portal/") + 6)
          }
          router.push({ name: 'Login', query: { redirect: redirect }}); 
        }
        return false;
    }
    /**
     * 组织返回值属性名小写转换
     */
    public static ObjectToLowerCase(obj:any,srfdcsystem:any){
        if(Array.isArray(obj)){
            obj.map((item:any) => {
                item = this.ObjectToLowerCase(item,srfdcsystem)
                if(item.childorgset.length > 0){
                    this.ObjectToLowerCase(item.childorgset,srfdcsystem)
                }
            })
        }else{
            for (var key in obj){
                obj[key.toLowerCase()] = obj[key];
                obj.systemid = srfdcsystem
                delete(obj[key]);
            }
        }
        return obj;
    }

    /**
     * 初始化主题
     *
     * @static
     * @memberof AuthGuard
     */
     private static initTheme(context: any) {
        try {
            if (context.theme) {
                const options: any = JSON.parse(context.theme);
                if (options.selectTheme) {
                    localStorage.setItem("theme-class", `${options.selectTheme}__micro`);
                    document.getElementsByTagName('html')[0].className = `${options.selectTheme}__micro`;
                }
                if (options.fontFamily) {
                    localStorage.setItem("font-family", options.fontFamily);
                    if (store) {
                        // store.commit("setCurrentSelectFont", options.fontFamily);
                    }
                }
                if (options.cssValue) {
                    let content = "";
                    // 这里是新增颜色，用于菜单搜索颜色
                    options.cssValue['--menusSearch-font-color'] = options.cssValue['--leftsidebar-font-color'] + '10';
                    for (const key of Object.keys(options.cssValue)) {
                        if (key && options.cssValue[key]) {
                            content += key + ": " + options.cssValue[key] + ";";
                        }
                    }
                    const cssText = `.${options.selectTheme}__micro:root { ${content} }`;
                    let dom: any = null;
                    for (let i = document.head.childNodes.length - 1; i >= 0; i--) {
                        const children: any = document.head.childNodes[i];
                        if (
                            children.nodeName == "STYLE" &&
                            children.getAttribute("title") &&
                            children.getAttribute("title") == "custom-theme-css"
                        ) {
                            dom = children;
                        }
                    }
                    if (dom) {
                        dom.innerText = cssText;
                    } else {
                        const styleDom = document.createElement("style");
                        styleDom.type = "text/css";
                        styleDom.setAttribute("title", "custom-theme-css");
                        styleDom.innerText = cssText;
                        document.head.appendChild(styleDom);
                    }
                }
            } else {
                // 默认主题
                localStorage.setItem("theme-class", 'app-theme-default__micro');
            }
        } catch (error) {
            // 默认主题
            if (context.theme) {
                localStorage.setItem("theme-class", context.theme);
            } else {
                localStorage.setItem("theme-class", 'app-theme-default__micro');
            }
        }
    }
}