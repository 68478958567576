import { AuthGuard } from "@/auth";
import Home from "@/pages/home/index.vue";
import Login from "@/pages/login/index.vue";
import loginChangeTrains from "@/pages/ssoLogin/loginChangeTrains.vue";
import loginChangeTrainsUser from "@/pages/ssoLogin/loginChangeTrainsUser.vue";
import loginChangeTrainsNoPassword from "@/pages/ssoLogin/loginChangeTrainsNoPassword.vue";
import loginChangeTrainsUserSaml from "@/pages/ssoLogin/loginChangeTrainsUserSaml.vue";
import dingding from "@/pages/ssoLogin/dingding.vue";
import VueRouter from "vue-router";
const routes = [
  {
    /**
     * path: 路径为 / 时触发该路由规则
     * name: 路由的 name 为 Home
     * component: 触发路由时加载 `Home` 组件
     */
    path: "/",
    name: "Home",
    component: Home,
    // beforeEnter: (to: any, from: any, next: any) => {
    //   const auth: Promise<boolean> = AuthGuard.checkAuth(router);
    //   auth.then((result) => {
    //     next(result ? true : false);
    //     })
    //     .catch(() => {
    //       next(false);
    //     });
    // },
    meta: {
      caption: "首页",
    },
  },
  {
    /**
     * path: 路径为 / 时触发该路由规则
     * name: 路由的 name 为 Login
     * component: 触发路由时加载 `Login` 组件
     */
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/loginChangeTrains",
    name: "loginChangeTrains",
    component: loginChangeTrains,
  },
  {
    path: "/loginChangeTrainsUser",
    name: "loginChangeTrainsUser",
    component: loginChangeTrainsUser,
  },
  {
    path: "/loginChangeTrainsUserSaml",
    name: "loginChangeTrainsUserSaml",
    component: loginChangeTrainsUserSaml,
  },
  {
    path: "/dingding",
    name: "dingding",
    component: dingding,
  },
  // {
  //   path: "/loginChangeTrainsNoPassword",
  //   name: "loginChangeTrainsNoPassword",
  //   component: loginChangeTrainsNoPassword,
  // }
];

/**
 * 注册路由实例
 * 即将开始监听 location 变化，触发路由规则
 */
const router = new VueRouter({
  mode: "history",
  base: (window as any).Environment.baseDeployUrl.slice(1),
  routes,
});

/**
 * 全局拦截器
 */
let i = 0;
router.beforeEach((to: any, from: any, next: any) => {
  if (to.name === "Login") {
    i = 0;
    next(true);
  } else if (to.name === "loginChangeTrains") {
    next()
  } else if (to.name === "loginChangeTrainsUser") {
    next()
  } else if (to.name === "loginChangeTrainsNoPassword") {
    // next()
  } else if (to.name === "loginChangeTrainsUserSaml") {
    next()
  } else if (to.name === "dingding") {
    next()
  } else if (to.path.indexOf('sso') != -1) {
    if (to.path.indexOf('password_based') != -1) {
      next({
        name: 'loginChangeTrainsUser',
        query: {
          ...to.params,
          ...to.query
        }
      })
    } else if (to.path.indexOf('no_password') != -1) {
      // next({
      //   name: 'loginChangeTrainsNoPassword',
      //   query: {
      //     ...to.params,
      //     ...to.query
      //   }
      // })
    } else if (to.path.indexOf('/sso/saml') != -1) {
      next({
        name: 'loginChangeTrainsUserSaml',
        query: {
          ...to.params,
          ...to.query
        }
      })
    } else {
      let listUrl = to.path.split('/')
      next({
        path: '/loginChangeTrains',
        query: {
          ...to.query,
          sso_protocol: listUrl[2],
          tenant_info: listUrl[3]
        }
      })
    }
  } else {
    if (i++ === 0) {
      const auth: Promise<boolean> = AuthGuard.checkAuth(router);
      auth
        .then((result) => {
          next(result ? true : false);
        })
        .catch(() => {
          next(false);
        });
    } else {
      next(true);
    }
  }
});

export default router;
