





import { Vue, Component } from 'vue-property-decorator';
import { ApiService } from "@/api";
@Component({
  components: {},
})
export default class Login extends Vue {
  public created() {
    const ssoGetContext: Promise<any> = ApiService.getInstance().ssoGetContext({
      ...this.$route.query
    });
    ssoGetContext.then((res: any) => {
      const { data } = res;
      if (data && data.token) {
        localStorage.removeItem('loginToUser');
        localStorage.setItem("ibzuaa-token", data.token);
        if (data && data.user) {
          localStorage.setItem("ibzuaa-user", JSON.stringify(data.user));
        }
        if (data && data.expirdate) {
          localStorage.setItem(
            "ibzuaa-expired",
            new Date(data.expirdate).getTime().toString()
          );
        }
        const url: any = localStorage.getItem('ssoUrlOld') ? localStorage.getItem('ssoUrlOld') : '/';
        this.$router.push({ path: url });
      }
    }).catch((err: any) => {
      console.log(err)
    })
  }
}
