export default {
    baseHome: {
        message: 'Message',
        loadmore:'Load More',
        nomore:'No More',
        start:'Start',
        openIndex:'Show Default Index',
        defaultIndex:'After hiding the default homepage, a first level menu item must be configured to display as the default homepage',
        more: 'More',
        myCommon: 'My Common',
        recentlyUsed: 'Recently Used',
        operationalData: 'Operational Data',
        myMenu:' My Menu',
        childMenu:' Child Menu',
        calendar: 'Calendar',
        relevantPolicy: 'Relevant Policy',
        policyGuideline: 'Policy Guideline',
        internalCases: 'Internal Cases',
        days: 'Days',
        pendingAudit: 'Pending Audit',
        pendingRead: 'Pending Read',
        toDo: 'Todo',
        done: 'Done',
        read: 'Read',
        unread: 'Unread',
        myApplication: 'My Application',
        todaysEvents: "Today's Events",
        unlimited: 'Unlimited',
        cashTaxPayments: 'Cash Tax Payments',
        period: 'Period',
        mainBody: 'Main Body',
        financialContributionAchievementRate: 'Financial Contribution Achievement Rate',
        reportProgress: 'Report Progress',
        state: 'State',
        region: 'Region',
        company: 'Company',
        thousand: 'Thousand',
        month: 'Month',
        unfinished: 'Unfinished',
        finished: 'Finished',
        goodMorning: 'Good Morning',
        nyyoppogtcz: 'You And TTC Grow Together For',
        selected: 'Selected',
        dragIconToSort: 'Drag Icon To Sort',
        allApplication: 'All Application',
        common: {
            add: 'Add',
            remove: 'Remove',
            remove2: 'Remove',
            confirm: 'Save',
            cancel: 'Cancel',
            openUp: 'Open Up',
            foldUp: 'Fold USp',
            edit:'Edit',
        },
        exportMenu:"Export Native Menu",
        exportMyMenu:"Export The Custom Menu",
        selectExportMenu:"Select The Export Menu",
        importMenu:'Import Menu',
        prompt:'Prompt',
        icon: "Icon：",
        dialogTitle: "Title(CN)：",
        dialogTitleEn: "Title(EN)：",
        dialogUrl: "External Link Address:",
        pleaseEnter: "Please Enter",
        pleaseEnterIcon: "Please Enter Icon",
        pleaseEnterDialogTitle: "Please Enter Title(Chinese)",
        pleaseEnterDialogTitleEn: "Please Enter Title(English)",
        pleaseEnterDialogUrl: "Please Enter External Link Address",
        PleaseEnterKeyword: "Search Keywords",
        noData: "No Data",
        noIndex: "Please enable the display of the default homepage or configure the first level menu item within the system as the homepage",
        noResult: "No Result",
        isSaveMenu: "Please Save The Changes",
        viewDetail: "Details",
        download: "Download",
        clearCompleted: "Clear Completed",
        clearFailed: "Clear Failed",
        clearAll:"Clear All",
        Confirm:"Confirm",
        Progressing: "Progressing",
        Completed: "Completed",
        Failed: "Failed",
        ErrorMessage:"Error Message",
        Confirm1:"Confirm",
        ImportResult: "Import Result",
        ImportData: "Import Data",
        message1:"Message",
        Pathcannotbeempty: "Path cannot be empty.",
        TaskStatus: "Task Status",
        TaskManagementCenter: "Task Management Center",
        Autoinvoicing: "Auto Invoicing",
        ErrorCode: "Error Code"
    }
}