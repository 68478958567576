import { Http } from "@/net";
import axios from 'axios';
import {Util} from './util'
import {AESKeyIVGenerator} from '@/utils/encryption'
import qs from "qs";

interface IMenuCollection {
  menuid: string;
  pos: string;
}
[];
export class ApiService {
  /**
   * 单例变量声明
   *
   * @private
   * @static
   * @type {ApiService}
   * @memberof ApiService
   */
  private static ApiService: ApiService;

  /**
   * 获取 ApiService 单例对象
   *
   * @static
   * @returns {ApiService}
   * @memberof ApiService
   */
  public static getInstance(): ApiService {
    if (!ApiService.ApiService) {
      ApiService.ApiService = new ApiService();
    }
    return this.ApiService;
  }

  /**
   * 登录
   *
   * @memberof ApiService
   */
  public async Login(formState: any) {
    return await Http.getInstance().post("/v7/login", formState);
  }
  /**
   * 用户名登录
   *
   * @memberof ApiService
   */
  public async LoginNoPassword(formState: any) {
    return await Http.getInstance().get("/sso/oauth2/loginByUsername", formState);
  }
  /**
   * SSO登录
   *
   * @memberof ApiService
   */
  public async LoginSSO(formState: any) {
    return await Http.getInstance().get("/sso/redirect", formState);
  }
    /**
   * SSO获取数据
   *
   * @memberof ApiService
   */
    public async ssoGetContext(formState: any) {
      return await Http.getInstance().get(`/sso/${formState.sso_protocol}/code`, formState);
    }
     /**
   * SSO获取数据
   *
   * @memberof ApiService
   */
     public async LoginSaml(formState: any) {
      return await Http.getInstance().post2(`/sso/saml/code?tenant_info=${formState.tenant_info}`, qs.stringify(formState));
    }
      /**
   * SSO登出
   *
   * @memberof ApiService
   */
      public async ssoLogout() {
        return await Http.getInstance().get("/sso/logout");
      }

  /**
   * 登出
   *
   * @memberof ApiService
   */
  public async Logout() {
    return await Http.getInstance().get("/v7/logout");
  }

  /**
   * 获取菜单模型
   *
   * @memberof ApiService
   */
  public async getMenus() {
    return await Http.getInstance().get("/uaa/allmenus");
  }

  // return {
  //     status:200,
  //     data: [
  //         {
  //             app: "EHRApp",
  //             caption: "Ehr 微应用",
  //             system: 'EHR',
  //             menuItems: [
  //                 {
  //                     tag: "menuitem39",
  //                     caption: "发起申请",
  //                     funcTag: "AppFunc26"
  //                 },
  //                 {
  //                     tag: "menuitem32",
  //                     caption: "事项办理",
  //                     funcTag: null,
  //                     children: [
  //                         {
  //                             tag: "menuitem33",
  //                             caption: "我的草稿",
  //                             funcTag: "AppFunc20"
  //                         },
  //                         {
  //                             tag: "menuitem34",
  //                             caption: "我的已建",
  //                             funcTag: "AppFunc21"
  //                         },
  //                         {
  //                             tag: "menuitem35",
  //                             caption: "待办事项",
  //                             funcTag: "AppFunc22"
  //                         },
  //                         {
  //                             tag: "menuitem36",
  //                             caption: "已办事项",
  //                             funcTag: "AppFunc23"
  //                         },
  //                     ]
  //                 },
  //                 {
  //                     tag: "menuitem100",
  //                     caption: "事项查询",
  //                     funcTag: "AppFunc64"
  //                 }
  //             ]
  //         },
  //         {
  //             caption: "Self 微应用",
  //             path: "/self",
  //             app: "EHRSelf",
  //             system: 'EHRAPI',
  //             menuItems: [
  //                 {
  //                     tag: "menuitem2",
  //                     caption: "我的查询",
  //                     funcTag: null,
  //                     children: [
  //                         {
  //                             tag: "menuitem15",
  //                             caption: "分配信息",
  //                             funcTag: "AppFunc12"
  //                         },
  //                         {
  //                             tag: "menuitem16",
  //                             caption: "档案信息",
  //                             funcTag: "AppFunc13"
  //                         },
  //                         {
  //                             tag: "menuitem18",
  //                             caption: "合同信息",
  //                             funcTag: "AppFunc15"
  //                         },
  //                         {
  //                             tag: "menuitem17",
  //                             caption: "社保信息",
  //                             funcTag: "AppFunc47"
  //                         },
  //                         {
  //                             tag: "menuitem19",
  //                             caption: "薪酬信息",
  //                             funcTag: "AppFunc16"
  //                         }
  //                     ]
  //                 }
  //             ]
  //         }
  //     ]
  // }

  /**
   * 查询税务日历
   */
  public async getCalendar() {
    return await Http.getInstance().get("/portal/calendar");
  }

  /**
   * 查询待办已办待阅已阅
   * @param type 待办："0" 已办："1" 待阅："2" 已阅："3"
   * @param count 查询数据条数
   * @returns
   */
  public async getMessage(type: string, count: string = "10000") {
    return await Http.getInstance().get(`/portal/message/${type}/${count}`);
  }

  /**
   * 查询政策/内部案例
   * @param type 政策指引："0" 内部案例："1"
   * @returns
   */
  public async getNews(type: string) {
    return await Http.getInstance().get(`/portal/news/${type}`);
  }

  /**
   * 查询菜单收藏
   * @returns
   */
  public async getFavorite() {
    return await Http.getInstance().get(`/portal/menu/favorite`);
  }

  /**
   * 保存菜单收藏
   * @param params [{"menuid":"menuid0001", "pos":"1"},{"menuid":"menuid0002", "pos":"2"}]
   * @returns
   */
  public async postFavorite(params: { menuid: string; pos: string }[]) {
    return await Http.getInstance().post(`/portal/menu/favorite`);
  }

  /**
   * 查询历史记录
   * @returns
   */
  public async getHistory() {
    return await Http.getInstance().get(`/portal/menu/history`);
  }

  /**
   * 保存历史记录
   * @param params 参数结构保证查询历史记录能使用即可
   * @returns
   */
  public async postHistory(params: { menuid: string; pos: string }[]) {
    return await Http.getInstance().post(`/portal/menu/history`);
  }
  /**
   * 查询图片信息
   * @returns
   */
  public async getImages() {
    return await Http.getInstance().get(`/portal/images`);
  }
  public async downImage() {
    return await Http.getInstance().post(`ibizutil/download`);
  }
  // 保存菜单收藏 /portal/menu/favorite
  public async postMenuCollection(parmars: IMenuCollection) {
    return await Http.getInstance().post(`/portal/menu/favorite`, parmars);
  }
  // 获取菜单收藏
  public async getMenuCollection() {
    return await Http.getInstance().get(`/portal/menu/favorite`);
  }
  // 获取组织下拉
  public async getOrganizationDrop() {
    return await Http.getInstance().get(`/userCtrl/myOrgList.dtt?includeMyManager=true`)
  }
  public async updateSwitchOrg(orgid: any) {
    return await Http.getInstance().post(`/userCtrl/switchOrg.dtt?newOrgId=${orgid}`);
  }
  // 获取我的菜单
  public async getMyMenuGroup() {
    return await Http.getInstance().get(`/portal/menu/group`);
  }
  // 获取自定义菜单一级菜单
  public async getRootGroup() {
    return await Http.getInstance().get(`/portal/menu/rootgroup`);
  }
  // 获取自定义菜单下级菜单
  public async getGroupChild(groupMenuId: any) {
    return await Http.getInstance().get(`/portal/menu/group/${groupMenuId}`);
  }
  // 获取自定义菜单模糊搜索
  public async getMenubymenuname(name: any) {
    return await Http.getInstance().get(`/portal/menu/menubymenuname?name=${name}`);
  }  
  // 获取我的菜单-弹窗数据
  public async getMyMenuAllGroup() {
    return await Http.getInstance().get(`/portal/menu/allgroup`);
  }
  // 保存我的菜单
  public async postMymenuGroup(parmars: any) {
    return await Http.getInstance().post(`/portal/menu/group`, parmars);
  }
  // 是否会启用我的菜单
  public async isOpenMyMenus(openmymenuflag: any) {
    return await Http.getInstance().get(`/portal/menu/${openmymenuflag}/openmymenu`);
  }
  // 获取是否启用我的菜单
  public async getisOpenMymenus() {
    return await Http.getInstance().get(`/portal/menu/openmymenu`)
  }
  // 删除我的菜单
  public async deleteMyMenus(parmars: any) {
    return await Http.getInstance().post(`/portal/menu/group/del`, parmars);
  }

  // 当前用户是否为菜单管理员
  public async getIsSuperUser() {
    return await Http.getInstance().get(`/portal/ismenuadmin`);
  }

  // 查询是否开启菜单首页接口
  public async getOpenhomepagelink() {
    return await Http.getInstance().get(`/portal/menu/openhomepagelink`);
  }

  // 保存是否开启菜单首页接口
  public async saveHomepagelink(openhomepagelink:any) {
    return await Http.getInstance().get(`/portal/menu/${openhomepagelink}/openhomepagelink`);
  }

  // 初始化图标
  public async getIco() {
    return await Http.getInstance().get(`/uaa/ico`);
  }

  // tis首页接口
  public async getTisHomePage(context:any) {
    return await Http.getInstance().post(`/tra/tramonsubdefs/${context.methodName}/tishomepage`, context );
  }

  /**
   * 导出原生菜单
   * @returns 
   */
  public async getExportMenu() {
    return new Promise((resolve: any, reject: any) => {
      axios
          .get('/portal/menu/exportAllMenus', { responseType: 'blob', })
          .then((response: any) => {
              resolve(response);
          })
          .catch((response: any) => {
              reject(response);
          });
  });
  }

  /**
 * 导出自定义菜单
 * @returns 
 */
  public async getExportMyMenu() {
    return new Promise((resolve: any, reject: any) => {
      axios
          .get('/portal/menu/export', { responseType: 'blob', })
          .then((response: any) => {
              resolve(response);
          })
          .catch((response: any) => {
              reject(response);
          });
    });
  }

  /**
   * 导入菜单
   * @param file 导入文件
   * @returns 
   */
  public async importMenu(file:any) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    return await Http.getInstance().post(`/portal/menu/import`,file,headers);
  }

  /**
* 获取base配置中心参数
* @param key 
*/
  public async getBaseConfig(data:any){
    const configpath =  (window as any).Environment.baseServiceUrl.replace("base", "utility/configServiceCtrl/property.dtt")
    const apitokenUrl = (window as any).Environment.baseServiceUrl.replace("base", "utility/configServiceCtrl/apiToken.dtt")
    return new Promise  ((resolve,reject) => {
      Http.getInstance().get(apitokenUrl).then(res => {
        const apitoken = res.data.result
        const headers = {
          'Request-Apitoken': `${apitoken}`,
        }
        Http.getInstance().post(configpath,data,headers).then(res => {
          let _data = ''
          const {data} = res
          if(typeof data === 'string'){
            const generator = AESKeyIVGenerator.getInstance()
            const dttApikey = {
                key:generator.getKey(),
                iv:generator.getIV()
            }
            const decry = Util.decryptAES256(data, dttApikey.key, dttApikey.iv)
            _data = JSON.parse(decry)
          }else{
            _data = data
          }
          console.log(_data)
          resolve(_data)
        }).catch(err => {
          reject('配置中心获取失败' + err)
        })
      }).catch(err => {
        reject('apitoken获取失败'+ err)
      })
    })
  }

  /**
 * 修改密码
 *
 * @param context
 * @param data
 * @param headers
 */
  public async changPassword(context: any = {}, data: any = {}, headers: any = {}): Promise<any> {
      return Http.getInstance().post(`/v7/changepwd`, data, headers);
  }
  
  /**
 * 获取版本
 *
 * @param context
 * @param data
 * @param headers
 */
  public async getVersion(context: any = '/portal/', data: any = {}, headers: any = {}): Promise<any> {
    headers['Cache-Control'] = 'no-store';
    return Http.getInstance().get(`${context}/build_time.json`, data, headers);
  }

    /**
 * 获取core
 *
 * @param context
 * @param data
 * @param headers
 */
  public async getServeCore(context: any = '/base/', data: any = {}, headers: any = {}): Promise<any> {
    headers['Cache-Control'] = 'no-store';
    headers['Request-Apitoken'] = `${(window as any).apitoken}`
    return Http.getInstance().get(`${context}defaultCtrl/deployInfo.dtt`, data, headers);
  }
  
 /**
 * 获取当前应用版本版本
 *
 * @param context
 * @param data
 * @param headers
 */
  public async getAppVersion(context: any = '/portal/', data: any = {}, headers: any = {}): Promise<any> {
    headers['Cache-Control'] = 'no-store';
    return Http.getInstance().get(`${context}assets/json/app-version.json`, data, headers);
  }

    /**
   * 获取MFA类型
   *
   * @memberof AppAuthService
   */
  public async getMfaType(loginname:any) {
      const post: any = await Http.getInstance().get("/captchaCtrl/" + loginname + "/getMfaType.dtt", {});
      const { status, data } = post;
      return post;
  }


  /**
   * 邮件方式发送MFA验证码
   *
   * @memberof AppAuthService
   */
  public async sendMailMfa(loginname:any) {
      const post: any = await Http.getInstance().get("/captchaCtrl/" + loginname + "/sendMailMfa.dtt", {});
      const { status, data } = post;
      return post;
  }

  /**
   * 手机短信方式发送MFA验证码
   *
   * @memberof AppAuthService
   */
  public async sendSmsMfa(loginname:any) {
      const post: any = await Http.getInstance().get("/captchaCtrl/" + loginname + "/sendSmsMfa.dtt", {});
      const { status, data } = post;
      return post;
  }

  /**
   * 验证邮件验证码
   *
   * @memberof AppAuthService
   */
  public async validateMailMfa(loginname:any,mfacode:any) {
      const post: any = await Http.getInstance().get("/captchaCtrl/" + loginname + "/" + mfacode + "/validateMailMfa.dtt", {});
      const { status, data } = post;
      return post;
  }

  /**
   * 验证手机短信验证码
   *
   * @memberof AppAuthService
   */
  public async validateSmsMfa(loginname:any,mfacode:any) {
      const post: any = await Http.getInstance().get("/captchaCtrl/" + loginname+"/" + mfacode + "/validateSmsMfa.dtt", {});
      const { status, data } = post;
      return post;
  }

  /**
   * 校验验证码
   *
   * @memberof AppAuthService
   */
  public async checkvalistr(loginForm: any,timestamp:any) {
      const post: any = await Http.getInstance().post("/captchaCtrl/" + timestamp + "/validate.dtt", loginForm.valistr);
      const { status, data } = post;
      return post;
  }

  /**
   * 切换语言
   * @param loginForm 
   * @param timestamp 
   * @returns 
   */
  public async switchlang(key: any) {
      const url = `/uaa/switchlang/${key}`
      const post: any = await Http.getInstance().get(url)
      return post;
  }
  
}