import { IAppConfig } from "@/interface";

export const AppConfig: IAppConfig = {
  baseUrl: (window as any).Environment.baseServiceUrl,
  SaaSMode: true,
  mockDcSystemId: "base",
  mountedSystems: [
    "vat",
    "vatrpt",
    "eit",
    "mis",
    "atf",
    "tda",
    "tra",
    "retom",
    "rnd",
    "evr",
    "ntpf",
    "efiling",
    "base",
    "dm",
    "ovatfp",
    "otp",
    "leqi"
  ],
  baseStaticImg:
    "http://default.digital-tsuite.com.cn/gateway/base/assets/micro-main/",
  // 挂载微应用配置
  apps: [
    /**
     * name: 微应用名称 - 具有唯一性，对应子应用应用codename
     * entry: 微应用入口 - 通过该地址加载微应用，这里我们使用 config 配置，nginx二级目录名称
     * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
     * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
     */
    // {
    //   name: "mis",
    //   // entry: "//localhost:9997",
    //   entry: "/child/mis/index.html",
    //   container: "#mis",
    //   activeRule: (window as any).Environment.baseDeployUrl + "/mis",
    // },
    // {
    //   name: "randd",
    //   // entry: "//localhost:9998",
    //   entry: "/child/base/frontpage.html",
    //   container: "#randd",
    //   activeRule: (window as any).Environment.baseDeployUrl + "/base",
    // }
    
    {
      name: "otp",
      entry: "/oversea/#/",
      container: "#otp",
      activeRule: (window as any).Environment.baseDeployUrl + "/otp",
    },
    {
      name: "randd",
      entry: (window as any).Environment.baseDeployUrl + "/baseweb/",
      // entry: "http://localhost:8080/frontpage.html",
      container: "#randd",
      activeRule: (window as any).Environment.baseDeployUrl + "/base",
    },

    {
      name: "dm",
      entry: (window as any).Environment.baseDeployUrl + "/dmweb/",
      container: "#dm",
      activeRule: (window as any).Environment.baseDeployUrl + "/dm",
    },

    {
      name: "vat",
      // entry: "http://localhost:8080",
      entry: (window as any).Environment.baseDeployUrl + "/vatfpweb/",
      container: "#vat",
      activeRule: (window as any).Environment.baseDeployUrl + "/vat",
    },

    {
      name: "tom",
      entry: (window as any).Environment.baseDeployUrl + "/tomweb/",
      container: "#tom",
      activeRule: (window as any).Environment.baseDeployUrl + "/retom",
    },

    {
      name: "mis",
      entry: (window as any).Environment.baseDeployUrl + "/misweb/",
      container: "#mis",
      activeRule: (window as any).Environment.baseDeployUrl + "/mis",
    },

    {
      name: "neweit",
      entry: (window as any).Environment.baseDeployUrl + "/eitweb/",
      container: "#neweit",
      activeRule: (window as any).Environment.baseDeployUrl + "/eit",
    },

    {
      name: "tda",
      entry: (window as any).Environment.baseDeployUrl + "/tdaweb/",
      container: "#tda",
      activeRule: (window as any).Environment.baseDeployUrl + "/tda",
    },

    {
      name: "tra",
      entry: (window as any).Environment.baseDeployUrl + "/traweb/",
      container: "#tra",
      activeRule: (window as any).Environment.baseDeployUrl + "/tra",
    },

    {
      name: "vatrpt",
      entry: (window as any).Environment.baseDeployUrl + "/vatrptweb/",
      // entry: "http://localhost:8080",
      container: "#vatrpt",
      activeRule: (window as any).Environment.baseDeployUrl + "/vatrpt",
    },

    {
      name: "atf",
      entry: (window as any).Environment.baseDeployUrl + "/atfweb/",
      container: "#atf",
      activeRule: (window as any).Environment.baseDeployUrl + "/atf",
    },
    {
      name: "rnd",
      entry: (window as any).Environment.baseDeployUrl + "/rndweb/",
      container: "#rnd",
      activeRule: (window as any).Environment.baseDeployUrl + "/rnd",
    },
    {
      name: "efiling",
      entry: (window as any).Environment.baseDeployUrl + "/efilingweb/",
      container: "#efiling",
      activeRule: (window as any).Environment.baseDeployUrl + "/efiling",
    },
    {
      name: "evr",
      entry: (window as any).Environment.baseDeployUrl + "/evrweb/",
      container: "#evr",
      activeRule: (window as any).Environment.baseDeployUrl + "/evr",
    },
    {
      name: "ntpf",
      entry: (window as any).Environment.baseDeployUrl + "/ntpfweb/",
      container: "#ntpf",
      activeRule: (window as any).Environment.baseDeployUrl + "/ntpf",
    },
    {
      name: "ovatfp",
      entry: (window as any).Environment.baseDeployUrl + "/ovatfpweb/",
      container: "#ovatfp",
      activeRule: (window as any).Environment.baseDeployUrl + "/ovatfp",
    },
    {
      name: "leqi",
      entry: (window as any).Environment.baseDeployUrl + "/leqiweb/",
      container: "#leqi",
      activeRule: (window as any).Environment.baseDeployUrl + "/leqi",
    },
  ],
};
