import { AppConfig } from '@/config/config';
import { AppService } from '@/service/app-service';
import { AxiosRequestConfig } from 'axios';
import { Http } from './http';
import { Message } from "element-ui";
import { ApiService } from "@/api";
import i18n from '@/locale';
import {AESKeyIVGenerator} from '@/utils/encryption'
import {Util} from '@/api/util'
import { RequestTicketManager } from '@/api/request-ticket-manager/request-ticket-manager'
/**
 * 拦截器
 *
 * @export
 * @class Interceptors
 */
export class Interceptors {

    /**
     * 路由对象
     *
     * @private
     * @type {any}
     * @memberof Interceptors
     */
    private router: any;

    /**
     *  单列对象
     *
     * @private
     * @static
     * @type { Interceptors }
     * @memberof Interceptors
     */
    private static readonly instance: Interceptors = new Interceptors();

    /**
     * Creates an instance of Interceptors.
     * 私有构造，拒绝通过 new 创建对象
     * 
     * @memberof Interceptors
     */
    private constructor() {
        if (Interceptors.instance) {
            return Interceptors.instance;
        } else {
            this.intercept();
        }
    }

    /**
     * 初始化
     *
     * @static
     * @returns {Interceptors}
     * @memberof Interceptors
     */
    public static init(router: any): Interceptors {
        this.instance.router = router;
        return this.instance;
    }

    /**
     * 拦截器实现接口
     *
     * @private
     * @memberof Interceptors
     */
    private intercept(): void {
        Http.getHttp().interceptors.request.use((config: AxiosRequestConfig) => {
            const appdata = AppService.getInstance().getAppData();
            if (appdata && appdata.context) {
                config.headers['srforgsectorid'] = appdata.context.srforgsectorid;
            }
            if (AppConfig.SaaSMode) {
                let activeOrgData: any = localStorage.getItem('activeOrgData') ? JSON.parse(localStorage.getItem('activeOrgData') as string) : {};
                config.headers['srforgid'] = activeOrgData?.orgid;
                config.headers['srfsystemid'] = activeOrgData?.systemid;
            }
            if (localStorage.getItem('ibzuaa-token')) {
                config.headers['Authorization'] = `Bearer ${localStorage.getItem('ibzuaa-token')}`;
            }
            if (config.url == '/tpgateway1/base-tax/userMenu/getUserMenu') {
                config.headers['Authorization'] = localStorage.getItem('ibzuaa-token');
            // 孟鹏飞 2023/08/23 禅道37294
            // DM弹窗2 3 下载拦截, 地址换成dm/mis/eit/vatrpt的地址
            } else if (config.url?.indexOf('/ibizutil/download/') != -1) {
                config.url = AppConfig.baseUrl.replace('base', 'dm') + config.url;
            } else if (config.url?.indexOf('/tmsmissdrhs/') != -1 || config.url?.indexOf('/tmsmisprorhs/') != -1) {
                config.url = AppConfig.baseUrl.replace('base', 'mis') + config.url;
            } else if (config.url?.indexOf('/eitfilings/') != -1 || config.url?.indexOf('/eitrptfilings/') != -1 || config.url?.indexOf('/eitdtamgts/') != -1) {
                config.url = AppConfig.baseUrl.replace('base', 'eit') + config.url;
            } else if (config.url?.indexOf('/tmsvatretwphs/') != -1) {
              config.url = AppConfig.baseUrl.replace('base', 'vatrpt') + config.url;
            } else if(config.url?.indexOf('utility/configServiceCtrl/property.dtt') != -1){
                const generator = AESKeyIVGenerator.getInstance()
                const dttApikey = {
                    key:generator.getKey(),
                    iv:generator.getIV()
                }
                delete config.headers['Authorization'];
                const base64 = btoa(JSON.stringify(dttApikey))
                config.headers['dtt-apikey'] = base64;
            } else if(config.url.indexOf('build_time.json') != -1 || config.url.indexOf('app-version.json') != -1 ){
                config.url = AppConfig.baseUrl.replace('/gateway/base', '') + config.url
                // config.url = '/api'+ config.url
            } else if(config.url.indexOf('/defaultCtrl/deployInfo.dtt') != -1){
                config.url = AppConfig.baseUrl.replace('/base', '') + config.url
            } else if(config.url?.indexOf('utility/configServiceCtrl/apiToken.dtt') != -1){
                const generator = AESKeyIVGenerator.getInstance()
                const dttApikey = {
                    key:generator.getKey(),
                    iv:generator.getIV()
                }
                delete config.headers['Authorization'];
                const base64 = btoa(JSON.stringify(dttApikey))
                config.headers['dtt-apikey'] = base64;
                // 时间戳类型满足java.time.LocalDateTime DateTimeFormatter "yyyy-MM-dd'T'HH:mm:ss"
                let currentDate = new Date();
                let year = currentDate.getFullYear();
                let month = String(currentDate.getMonth() + 1).padStart(2, '0');
                let day = String(currentDate.getDate()).padStart(2, '0');
                let hours = String(currentDate.getHours()).padStart(2, '0');
                let minutes = String(currentDate.getMinutes()).padStart(2, '0');
                let seconds = String(currentDate.getSeconds()).padStart(2, '0');
                let timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
                const dttApivalue = Util.encryptAES256(timestamp,dttApikey.key,dttApikey.iv)
                const valueBase64 = btoa(dttApivalue)
                config.headers['dtt-apivalue'] = valueBase64;
            } else if (config.url?.indexOf('/tra/tramonsubdefs') !==  -1) {
                config.url = AppConfig.baseUrl.slice(0, -5) + config.url;
            } else {
                config.url = AppConfig.baseUrl + config.url;
            } 
            if(config.url.search('/v7/login') != -1){
                delete config.headers['Authorization'];
            }
            // 6222 必须具备防重复提交机制（提交两次;在处理页面刷新;或使用工具进行请求拦截修改重放）
            RequestTicketManager.getInstance().setRequestTicket(config)
            config.headers['Accept-Language'] = i18n.locale;
            return config;
        }, (error: any) => {
            return Promise.reject(error);
        });

        Http.getHttp().interceptors.response.use((response: any) => {
            console.log(response)
            if (response.status === 401) {
                this.doNoLogin(response, response.data);
            }
            return response;
        }, (error: any) => {
            return Promise.reject(error);
        });
    }

    /**
     * 处理未登录异常情况
     *
     * @private
     * @param {*} [data={}]
     * @memberof Interceptors
     */
    private doNoLogin(response: any, data: any = {}): void {
        // 交由路由守卫自行处理
        if (response && response.config && response.config.url && Object.is(response.config.url, '/appdata')) {
            return;
        }
        AppService.getInstance().clearAppData();
        if (Object.is(this.router.currentRoute.name, 'login')) {
            return;
        }
        if (localStorage.getItem('ssoToUrl')) {
          const post: Promise<any> = ApiService.getInstance().LoginSSO({
            emailDomainName: localStorage.getItem('ssoToUrl')
          });
          post.then((res: any) => {
            localStorage.setItem('ssoUrlOld', window.location.href.substring(window.location.href.indexOf("portal/") + 6));
            window.location.href = res.data.redirectUrl
          }).catch((err: any) => {
            Message.error((err as any)?.response?.data?.message);
          })
        } else {
          this.router.push({ name: "Login", query: { redirect: window.location.href.substring(window.location.href.indexOf("portal/") + 6) } });
        }
    }
}