import {Vue } from "vue-property-decorator";
import { IBIZServiceBase } from '@/api'
import { loadingService } from '@/api'

/**
* 菜单基类
*/
export default class AppMenuBase extends Vue {

  /**
  * 菜单服务对象 
  */
  public service: IBIZServiceBase = IBIZServiceBase.getInstance()

  /**
  * 加载服务对象 
  */
  public loadingService:loadingService = loadingService.getInstance()


}