





































import { Vue, Component, Watch } from "vue-property-decorator";
import { ApiService } from "@/api";
import moment from "moment";

interface Item {
  taxType: string;
  ruleDeadline: any;
  baseTaxPayername: string;
}

@Component
export default class BaseHomeCalendarContent extends Vue {
  created() {
    this.getCalendar();
  }

  calendar = new Date();
  todoList: Item[] = [];
  public dateList: Item[] = [];
  @Watch("calendar")
  calendarWatch() {
    // this.getCalendar();
    const date = this.formatDate(this.calendar);
    this.todoList =[];
    (this.dateList ||[]).forEach((res: any) => {
      res.ruleDeadline = this.dateFormat(res.ruleDeadline);
      if (res.ruleDeadline === date) {
        this.todoList.push(res);
      }
    });
  }

  async getCalendar() {
    const date = this.dateFormat(this.calendar);
    ApiService.getInstance()
      .getCalendar()
      .then((res: any) => {
        this.dateList = res.data.result?res.data.result.portalCalendarDetails:[];
        (this.dateList ||[]).forEach( (res:any)=> {
          res.ruleDeadline = this.dateFormat(res.ruleDeadline)
          if (res.ruleDeadline === date) {
            this.todoList.push(res)
          }
        } )
      });
  }

  formatDate(date: Date) {
    let y = date.getFullYear();
    let m: any = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d: any = date.getDate();
    d = d < 10 ? "0" + d : d;
    return y + "-" + m + "-" + d;
  }
  public dateFormat(date: any): string {
    return moment(date).format("YYYY-MM-DD");
  }

  // 日历如果当前日期有数据，那么给一个浅色标记
  changeColor(data: any){
      let string = JSON.stringify(this.dateList);
      if(string && string.indexOf(data.day.toString()) > -1){
        return 'default-selected'
      }
  }

}
