































import { Vue, Component } from "vue-property-decorator";
import { ApiService } from "@/api";
import { AppService } from "@/service/app-service";
import { apps } from "@/micro";

interface Item {
  name: string;
  date: string;
  location?: string;
}

@Component({})
export default class BaseHomePolicyContent extends Vue {
  /* eslint-disable */

  get appService() {
    return AppService.getInstance();
  }
  
  /**
  *  声明周期 
  */
  public created() {
    this.getPolicyList('0');
    this.$nextTick(() => {
      const element = document.getElementById(`policy-content-0`)
      this.bindClick(element)
    })
  }

  /**
  * 绑定展示区a标签click
  */
  public bindClick(element:HTMLElement|null){
      if(element){
        element.addEventListener("click", (event:any) => {
          if (event.target.tagName === 'A') {
            event.preventDefault();
            console.log("Link clicked: " + event.target);
            const domain = event.target.href.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/img);
            const url = domain[0]
            const route = event.target.href.replace(url,'')
            const startIndex = route.indexOf("/") + 1;
            const endIndex = route.indexOf("/", startIndex);
            const contentBetweenSlashes = route.substring(startIndex, endIndex);
            const activeApp = apps.find((app: any) => {
                return app.name === contentBetweenSlashes;
            });
            if((window as any).Environment.baseServiceUrl.indexOf(url) !== -1 && activeApp){
              const activeRoute = `/${activeApp.activeRule.slice((window as any).Environment.baseDeployUrl.length)}` + route.replace(`/${contentBetweenSlashes}/`,'')
              this.$router.push(activeRoute)
            }else{
              window.open(event.target.href)
            }
          }
        })
      }
  }

  /**
  * tabs数据 
  */
  public tabs:any[] = [
    {
      name: 'policyGuideline',
      title: this.$t('baseHome.policyGuideline'),
      type: '0',
      icon:'las la-compass'
    },
    {
      name: 'internalCases',
      title: this.$t('baseHome.internalCases'),
      type: '1',
      icon:'la la-book'
    }
  ]
  
  /**
  * 双向绑定值 
  */
  public tabValue:any = this.tabs[0].name


  policyList: Item[] = [];

  timestampToTime(timestamp: any) {
    let date = new Date(timestamp || null); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + "-";
    let M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    let h =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    let m =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    let s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  }

  /**
  * 数据获取 
  * @param value 
  */
  async getPolicyList(value:any) {
    ApiService.getInstance()
      .getNews(value)
      .then((res: any) => {
        const data = res.data || {};
        const list = JSON.parse(data.result?.list || null);
        console.log(list)
        if (list) {
          this.policyList = list.map((item: any) => {
            let hrefurl = ''
            let businessServiceUrl = (window as any).Environment.baseServiceUrl
            let serverUrl = businessServiceUrl.replace("/gateway/base", "/tom/#/");
            let httpPath = window.location.protocol.replace(':', '')
            if(item.linkType == '20' || item.linkType == '30'){
                let username = JSON.parse((localStorage.getItem("ibzuaa-user") as any)).personname;
                hrefurl = `${serverUrl}index/tomtaxcases/${item.tomTaxCaseId}/views/usr2customview?id=${JSON.parse(item.attachment)[0].id}&name=${encodeURIComponent(username)}`
            }else{
                if(item.urlName.replace(/\"/g,"").indexOf('http') != -1){
                    hrefurl = `${item.urlName.replace(/\"/g,"")}`;  
                }else{
                    hrefurl = `${httpPath}://${item.urlName.replace(/\"/g,"")}`;  
                }
            }
            return {
              date: this.timestampToTime(item.createDate),
              name: item.caseContent,
              location:hrefurl,
            };
          });
        }    
      });
  }

  /**
  * tab切换
  * @param type 子系统url
  *
  */
  public switchtab(tab:any, event:any){
    const { name } = tab
    const value = this.tabs.find(item => item.name === name)?.type
    this.getPolicyList(value)
    this.$nextTick(() =>{
      const element = document.getElementById(`policy-content-${value}`)
      this.bindClick(element)
    })
  }
}
