



























import { apps } from "@/micro";
import { AppService } from "@/service/app-service";
import { eventBus, MicroActionService } from "@/service/micro-action-service";
import shared from "@/shared";
import store from "@/shared/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import Sortable from "sortablejs";
import qs from "qs";

@Component
export default class AppPageExp extends Vue {
  /**
   * 取消订阅对象
   */
  public unsubscribe: any;

  /**
   * 当前激活 tab 面板的 key
   */
  public activeKey = "";

  /**
   * 当前所有面板集合
   */
  public panes: any = [];

  /**
   * 是否是tis租户
   */
  public isTis:any = false;
  

  /**
   * 监听路由变化
   */
  @Watch("$route")
  public onRouteChange() {
    const route:any = this.$route;
    const { fullPath } = route;
    this.handleMenuUrl(route)
    if (fullPath && fullPath.indexOf("views") !== -1) {
      if (!fullPath.startsWith((window as any).Environment.baseDeployUrl)) {
        shared.addHistoryPaths((window as any).Environment.baseDeployUrl + fullPath);
      } else {
        // 加protal做的改动
        shared.addHistoryPaths(fullPath);
      }
    }
    // tis租户监听当前路由地址是不是首页
    if (this.isTis) {
      if ((route.meta.caption === '首页' && route.path === '/') && !fullPath.includes("isOutsideLinks")) {
        eventBus.$emit('tabPaneChange', true);
      } else {
        eventBus.$emit('tabPaneChange', false);
      }
    }
    if (route.hash.includes("/base#/frontpage/baseuserguides/views/customview")) {
        return
      }
    
    this.$nextTick(()=> {
      this.computeActiveKey(route);

    })

  }

  /**
  * 菜单Map
  */
  public menuMap: Map<string, any> = new Map();

  get appService() {
    return AppService.getInstance();
  }

  /**
  * 处理路由路径改变缓存中菜单路径
  * @param data
  */
  public handleMenuUrl(data: any) {
    const params: any = {};
    const _params: any = data.fullPath.slice(data.fullPath.indexOf("?") + 1);
    const paramArray: Array<string> = decodeURIComponent(_params).split(";");
    if (paramArray.length > 0) {
      paramArray.forEach((item: any) => {
        Object.assign(params, qs.parse(item));
      });
    }
    if (params.srfmenuname) {
      const menu = this.menuMap.get(params.srfmenuname)
      if (menu?.menuUrl) {
        localStorage.setItem('mainMenuUrl', JSON.stringify(menu.menuUrl))
      }
    }
  }

  /**
   * 菜单数据转map
   * @param menus 菜单数据
   */
  public handleMenuMap(menus: Array<string>) {
    if (menus.length > 0) {
      menus.forEach((item: any) => {
        this.menuMap.set(item.key, item)
        if (item.children) {
          this.handleMenuMap(item.children)
        }
      })
    }
  }

  /**
  * 初始化时会话缓存面板I18n 
  */
  public sessionPageI18n(){
    const isAppMenu = sessionStorage.getItem('isAppMenu') === 'true' ? true : false
    const Zh_CN_fild = isAppMenu ? 'caption' : 'titleZHCN'
    const EN_fild = isAppMenu ? 'caption2' : 'titleEN'
    this.panes.map((pane:any,index:any) => {
      const _page = pane
      _page.myCaptionLocale = this.$i18n.locale == 'zh-CN' ? _page[Zh_CN_fild] : _page[EN_fild]
      this.$set(this.panes, index, _page);
    })
  }

  /**
   * 初始化
   */
  public created() {
    const context:any = localStorage.getItem('microAppData')
    if(context){
      const code = JSON.parse(context).context.tenantcode
      if(code === 'TIS'){
        this.isTis = true
      } else {
        this.isTis = false
      }
    }
    let sessionPage = sessionStorage.getItem('sessionPage')
    if(sessionPage !== null && sessionPage !== "undefined" ){
      sessionPage = JSON.parse(sessionPage)
      shared.sessionPage(sessionPage)
    }
    this.unsubscribe = store.subscribe(() => {
      const pages = store.getState().pages;
      sessionStorage.setItem('sessionPage',JSON.stringify(pages))
      if (pages && pages.length > 0) {
        if (this.panes) {
          this.panes.length = 0;
        }
        pages.forEach((page: any, index: number) => {
          this.$set(this.panes, index, page);
        });
        this.computeActiveKey(this.$route);
      } else {
        this.panes.length = 0;
        this.$forceUpdate();
      }
      const menus = shared.getmenus()
      if (menus.length > 0) {
        this.handleMenuMap(menus)
      }
      
    });
  }

  public beforeDestroy() {
    // 取消订阅事件
    eventBus.$off('remove', this.remove);
  }

  /**
   * Vue生命周期 --- mounted
   */
  public mounted() {
    // 在子应用中监听事件
    eventBus.$on('remove', (route:any) => {
      this.remove(route)
    });
  
    const el = this.$el.querySelector(
      ".app-page-exp .cns-tabs .cns-tabs-nav-container .cns-tabs-nav > div"
    );

    const that: any = this;
    Sortable.create(el, {//页签拖拽，原始逻辑是交换位置，改为不交换位置
      onEnd: ({ newIndex, oldIndex }: any) => {
        const curTag = that.panes.splice(oldIndex, 1)[0];
        that.panes.splice(newIndex, 0, curTag);
        const pages = store.getState().pages;
        if (pages && pages.length === this.panes.length) {
          const curPage = pages.splice(oldIndex, 1)[0];
          pages.splice(newIndex, 0, curPage);
        }
      }
    });
    // 默认添加首页
    let data:any = localStorage.getItem('defaultIndex');
    if (data === null || data.includes('OtherCustom')) {

      this.panes.push({ caption: '首页' ,caption2:'Home', fullPath: "/" });
      // this.handleTabClick('undefined__/')
      // 默认添加首页
      let indexRoute = {}
      Object.assign(indexRoute, { caption: '首页' ,caption2:'Home', path: "/", fullPath: '/' })
      shared.addPage(indexRoute);

    }
    // 如果关闭首页配置，这里删除默认首页
    eventBus.$on('isOpenIndex', (flag:any) => {
      this.$nextTick(()=> {
          if ((!flag && (data && data.includes('OtherCustom')))) {
            let indexRoute = {}
            Object.assign(indexRoute, { caption: '首页' ,caption2:'Home', path: "/", fullPath: '/' })
            shared.removePage(indexRoute);
          } 
      })
    }); 
    // 关闭首页
    eventBus.$on('closeIndex', (flag:any) => {
          if (flag) {
            let indexRoute = {}
            Object.assign(indexRoute, { caption: '首页' ,caption2:'Home', path: "/", fullPath: '/' })
            shared.removePage(indexRoute);
          } 
    }); 
    if (!this.$route.fullPath.includes("isOutsideLinks")) {
      this.sessionPageI18n()
    }
  }

  /**
   * 计算激活项
   */
  public computeActiveKey(route: any) {
    if (this.panes && this.panes.length > 0 && route) {
      let activePage: any;
      // 微应用
      if (route.hash) {
        activePage = this.panes.find((page: any) => {
          return Object.is(route.hash, `#${page.fullPath}`);
        });
       if (!(route.hash.includes("/frontpage/baseuserguides/views/customview") || route.hash.includes("/index2/tramonsubdefs/views/grouphomepagethird") || (route.hash.includes("/index2/tramonsubdefs/views/drilldownview")))) {
          this.customMenuSetCapion(route)
        } 
      } else {
        // 基座应用
        activePage = this.panes.find((page: any) => {
          return Object.is(route.fullPath, page.fullPath);
        });
      }
      if (activePage) {
        this.activeKey = `${activePage.microAppName}__${activePage.fullPath}`;
      }
    }
  }

  /**
  *  自定义菜单由于逻辑混乱无法梳理，取面包屑数据用于更新tab页标题
 
  */
  public customMenuSetCapion(route: any){
    let isappmenu:any = sessionStorage.getItem('isAppMenu')
    isappmenu = isappmenu === 'true' ? true : false
    if(!isappmenu){
      if (route.hash.includes("isOutsideLinks")) {
        return
      }
      const  activeIndex = this.panes.findIndex((page: any) => {
        return Object.is(route.hash, `#${page.fullPath}`);
      });
      setTimeout(() => {
        const bread = shared.getActiveCrumbList()
        const listBread = bread[bread.length - 1]
        if(listBread && listBread.title && activeIndex != -1){
          if(!listBread.menuInfo){
              this.$set(this.panes[activeIndex], 'myCaptionLocale',listBread.title)
          }
        }
      }, 0);
    }
  }


  /**
   * 销毁
   */
  public destroyed() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  /**
   * 处理按钮下拉事件
   */
  public handleMenuClick(event: any) {
    let _this: any = this;
    const { key } = event;
    switch (key) {
      case "REMOVEALL": {
        shared.removeAllPage();
        // 跳转首页
        let data:any = localStorage.getItem('defaultIndex');
        if (data !== null) {
          if (JSON.parse(data).menuId.includes('OtherCustom')) {
            // 默认添加首页
            this.panes.push({ caption: '首页' ,caption2:'Home', fullPath: "/" });
            this.handleTabClick('undefined__/')
            // 默认添加首页
            let indexRoute = {}
            Object.assign(indexRoute, { caption: '首页' ,caption2:'Home', path: "/", fullPath: '/' })
            shared.addPage(indexRoute);
          } else {
            _this.$baseMenu.changeMenu(JSON.parse(data));
          }
        } else {
          this.panes.push({ caption: '首页' ,caption2:'Home', fullPath: "/" });
          this.handleTabClick('undefined__/')
          // 默认添加首页
          let indexRoute = {}
          Object.assign(indexRoute, { caption: '首页' ,caption2:'Home', path: "/", fullPath: '/' })
          shared.addPage(indexRoute);
        }
        break;
      }
      case "REMOVEOTHER": {
        const activeIndex: number = this.panes.findIndex((page: any) => {
          return page.microAppName + "__" + page.fullPath === this.activeKey;
        });
        shared.removeOther(this.panes[activeIndex]);
        break;
      }
      default: {
        console.log(`${key}暂未实现`);
        break;
      }
    }
  }

  /**
   * 处理tab被点击事件
   */
  public handleTabClick(key: any) {
    this.activeKey = key;
    const activePage = this.panes.find((page: any) => {
      return page.microAppName + "__" + page.fullPath === key;
    });
    let activeOrgData: any = localStorage.getItem('activeOrgData') ? JSON.parse(localStorage.getItem('activeOrgData') as string) : {};
    if (!activePage) return;
    // 微应用
    if (activePage.microAppName) {
      // 当前激活应用
      if (
        this.appService.getMountedMicroApp() &&
        Object.is(
          this.appService.getMountedMicroApp().name,
          activePage.microAppName
        )
      ) {
        this.appService.noticeMicroApp({
          tag: activePage.microAppName,
          action: "TABCLICK",
          data: activePage
        });
        
      } else {
        // 未激活应用
        const activeApp = apps.find((app: any) => {
          return app.name === activePage.microAppName;
        });
        if (activeApp) {
          // 加protal做的改动
          this.$router.push(activeApp.activeRule.slice((window as any).Environment.baseDeployUrl.length));
          // this.$router.push(activeApp.activeRule);
        }
        this.appService.noticeMicroApp({
          tag: activePage.microAppName,
          action: "TABCLICK",
          data: activePage
        });
      }
      let orgid = activeOrgData?.orgid;
        if(activePage.orgid&&activePage.orgid!=orgid){
          this.appService.noticeMicroApp({
            tag: activePage.microAppName,
            action: "RELOAD",
            data: activePage
          });
          let pages:any = store.getState().pages;
          for(let count = 0;count < pages.length;count++){
            //当前tab
              if(pages[count].microAppName + "__" + pages[count].fullPath === key){
                pages[count].orgid = orgid;
              }
          }
        }
    } else {
      // 基座应用
      this.$router.push(activePage.fullPath);
    }
  }

  /**
   * 新增和删除页签的回调
   */
  public handleEdit(key: string, action: string) {
    key != 'undefined__/' ? (this as any)[action](key) : "";
  }

  // 点击tab的时候面包屑也跟着变化
  public tabChange(activePage: any, flag: any) {
    let _this: any = this;
    if (activePage === 'undefined__/') {
      // 清空面包屑
      shared.setActiveCrumbPath([]);
      //触发左侧菜单得数据，改变
      _this.$baseMenu.changeSelectKey('Home');
    } else if ( this.$route.hash.includes("/frontpage/baseuserguides/views/customview") ) {
      // 清空面包屑
      shared.setActiveCrumbPath([]);
    } else {
      // 获取本地存储数据
      let breadMenusCopy = JSON.parse(JSON.stringify(store.getState().activeCrumbPathAll ? store.getState().activeCrumbPathAll : JSON.parse(sessionStorage.getItem('breadcrumbListCopy') || '')))
      // 获取本地menus数据
      if (flag) {
        breadMenusCopy.map((page: any, index: any) => {
          if (page.fullPathChildren[0].breadpath === activePage) {
            breadMenusCopy.splice(index, 1)
          }
        });
        // 存储面包屑点击过的全部数据
        shared.setActiveCrumbPathAll(breadMenusCopy)
      } else {
        breadMenusCopy.map((page: any) => {
          // 切换页签得时候如果如果外层breadpath匹配上，说明点击得是菜单数据，
          // 如果外层breadpath没有匹配上就过滤fullPathChildren中是否有符合得path，有就说明是下钻页面
          if (decodeURIComponent(activePage).includes(decodeURIComponent(page.breadpath))) {
            // 存储面包屑数据
            shared.setActiveCrumbPath(page.fullPathChildren)
          } 
        });
      }
    }
  }

  /**
   * 处理删除事件
   */
  public remove(route: string) {
    const activePage = this.panes.find((page: any) => {
      if (route.includes("isOutsideLinks")) {
        return route.includes(page.fullPath) && page.fullPath !== '/';
      } else {
        return page.microAppName + "__" + decodeURIComponent(page.fullPath) === decodeURIComponent(route);
      }
    });
    const activeIndex: number = this.panes.findIndex((page: any) => {
      if (route.includes("isOutsideLinks")) {
        return route.includes(page.fullPath) && page.fullPath !== '/';
      } else {
        return page.microAppName + "__" + decodeURIComponent(page.fullPath) === decodeURIComponent(route);
      }
    });
    if ((activePage.caption === '首页' || activePage.caption === 'Home') && !route.includes("isOutsideLinks")) {
      const index = this.panes.findIndex((page: any) => {
        return page.caption === activePage.caption
      })
      shared.removePage(activePage);
      if (index > 0) {
        const url = `${this.panes[index - 1].microAppName}__${this.panes[index - 1].fullPath}`
        this.handleTabClick(url)
      }
    } else {
      if (activeIndex > 0) {
        MicroActionService.getInstance().handleRemovePage(activePage);
        const url = `${this.panes[activeIndex - 1].microAppName}__${this.panes[activeIndex - 1].fullPath}`
        this.handleTabClick(url)
        // 获取存储得点击所有数据
        let breadMenus: any = store.getState().activeCrumbPathAll ? store.getState().activeCrumbPathAll : JSON.parse(sessionStorage.getItem('breadcrumbListCopy') || '')
        breadMenus.map((page: any,index:any) => {
          // 删除得页签，面包屑也要删除
          if (decodeURIComponent(route).includes(decodeURIComponent(page.breadpath)) && page.breadpath !== '/') {
            breadMenus.splice(index, 1); 
          }
          // 删除页签之后触发得页签，需要触发下一个面包屑
          if (decodeURIComponent(url).includes(decodeURIComponent(page.breadpath)) && 
              page.breadpath !== '/') {
            shared.setActiveCrumbPath(page.fullPathChildren)
            // 存储面包屑点击过的全部数据
            shared.setActiveCrumbPathAll(breadMenus)
            sessionStorage.setItem('breadcrumbListCopy', JSON.stringify(breadMenus));
          }
        });
      } else {
        MicroActionService.getInstance().handleRemovePage(activePage);
        // 跳转首页
        this.panes.push({ caption: '首页' ,caption2:'Home', fullPath: "/" });
        this.handleTabClick('undefined__/')
        // 默认添加首页
        let indexRoute = {}
        Object.assign(indexRoute, { caption: '首页' ,caption2:'Home', path: "/", fullPath: '/' })
        shared.addPage(indexRoute);
      }
    }
  }

  /**
   * 获取标题
   */
  public getCaption(page: any): string {
    let caption: string = '';
    switch (this.$i18n.locale) {
      case "zh-CN":
        caption = page.myCaptionLocale ? page.myCaptionLocale : page.caption;
        break;
      case "en-US":
        caption = page.myCaptionLocale ? page.myCaptionLocale : page.caption2 ? page.caption2 : page.caption;
        // caption = page.caption2 ? page.caption2 : page.myCaptionLocale
        break;
      default:
        caption =page.caption
        break
    }
    if (page.info && !Object.is(page.info, "")) {
      caption = `${caption}-${page.info}`;
    }
    if (page.caption === '用户操作指南' && this.$i18n.locale === 'en-US') {
      let data:any = this.$t("components.operationGuide.userOperationGuide");
      caption = data;
    }
    return caption;
  }
}
