












import { Vue, Component } from 'vue-property-decorator';
import { ApiService } from "@/api";
@Component({
  components: {},
})
export default class Login extends Vue {
  windowurl: any = "";
  /**
   * vue生命周期 -- mounted
   *
   */
  public mounted() {
    setTimeout(() => {
      const el = document.getElementById("app-root-loading-x");
      if (el) {
        el.style.display = "none";
      }
    }, 1000);
  }
  public created() {
    this.windowurl = (window as any).location.href;
  }
}
