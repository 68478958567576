<template>
  <div :id="'echartsDiv' + id" style="height: 100%;width: 100%;">
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  components: {
  },
  data() {
    return {
      myChart: null
    }
  },
  props: {
    id: {
      type: Number
    },
    titleNow: {
      type: Object
    },
    showColor: {
      type: Boolean
    }
  },
  mounted() {
    setTimeout(() => {
      this.showEchrats()
    }, 1000);
  },
  watch: {
    "showColor": {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        this.showEchrats()
      }
    }
  },
  methods: {
    showEchrats() {
      const element = document.getElementById('echartsDiv' + this.id);
      this.myChart = echarts.init(element);
      let option = {
        title: {
          text: this.titleNow.title,
          textStyle: {
            color: this.showColor ? '#FFFFFF' : '#000000',
            fontSize: 16
          },
          bottom: '5%',
          left: 'center'
        },
        series: [
          {
            type: 'gauge',
            radius: '25%',
            center: ['50%', '40%'],
            startAngle: 360,
            endAngle: 0,
            min: 0,
            max: 60,
            splitNumber: 12,
            itemStyle: {
              color: '#f37215',
            },
            progress: {
              show: false
            },
            pointer: {
              show: false
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              length: 10,
              lineStyle: {
                width: 2,
                color: !this.showColor ? '#e5e5e5' : 'rgba(58, 63, 94, 1)'
              }
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            anchor: {
              show: false
            },

            detail: {
              valueAnimation: true,
              width: '60%',
              lineHeight: 40,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 15,
              fontWeight: 'bolder',
              formatter: '{value} / ' + this.titleNow.valueAll,
              color: this.showColor ? '#FFFFFF' : '#000000'
            },
            data: [
              {
                value: this.titleNow.value
              }
            ]
          },
          {
            type: 'gauge',
            radius: '50%',
            center: ['50%', '40%'],
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: this.titleNow.valueAll,
            // 蓝色条
            progress: {
              show: true,
              width: 5,
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0.8,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: this.titleNow.color[1] // 0% 处的颜色
                    }, {
                      offset: 1,
                      color: this.titleNow.color[0] // 100% 处的颜色
                    }
                  ],
                  global: false
                }
              }
            },
            pointer: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 5,
                color: !this.showColor ? [[1, '#e5e5e5']] : [[1, 'rgba(38, 43, 90, 1)']]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value: this.titleNow.value
              }
            ]
          }
        ]
      }
      this.myChart.setOption(option);
    }
  }
}
</script>
<style lang='less' scoped></style>