import axios from 'axios';
import qs from 'qs';
/**
 * Http net 对象
 * 调用 getInstance() 获取实例
 *
 * @class Http
 */
export class Http {
    /**
     * 单例变量声明
     *
     * @private
     * @static
     * @type {Http}
     * @memberof Http
     */
    private static Http: Http;

    /**
     * 获取 Http 单例对象
     *
     * @static
     * @returns {Http}
     * @memberof Http
     */
    public static getInstance(): Http {
        if (!Http.Http) {
            Http.Http = new Http();
        }
        return this.Http;
    }

    /**
     * Creates an instance of Http.
     * 私有构造，拒绝通过 new 创建对象
     *
     * @memberof Http
     */
    private constructor() { }

    /**
     * 网络请求对象
     *
     * @memberof Http
     */
    public static getHttp() {
        return axios;
    }

    /**
     * post请求
     *
     * @param {string} url
     * @param {*} [params={}]
     * @returns {Promise<any>}
     * @memberof Http
     */
    public post(url: string, params: any = {}, headers: any = {}): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            axios({
                method: 'post',
                url: url,
                data: params,
                headers: Object.assign({ 'Content-Type': 'application/json;charset=UTF-8', Accept: 'application/json' }, headers),
            })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((response: any) => {
                    reject(response.response);
                });
        });
    }

    /**
     * post请求
     *
     * @param {string} url
     * @param {*} [params={}]
     * @returns {Promise<any>}
     * @memberof Http
     */
    public post2(url: string, params: any = {}, headers: any = {}): Promise<any> {
      return new Promise((resolve: any, reject: any) => {
          axios({
              method: 'post',
              url: url,
              data: params,
              headers: Object.assign({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', Accept: 'application/json' }, headers),
          })
              .then((response: any) => {
                  resolve(response);
              })
              .catch((response: any) => {
                  reject(response.response);
              });
      });
  }

    /**
     * get请求
     *
     * @param {string} url
     * @param {*} [params={}]
     * @param {number} [serialnumber]
     * @returns {Promise<any>}
     * @memberof Http
     */
    public get(url: string, params: any = {}, headers: any = {}): Promise<any> {
        if (Object.keys(params).length > 0) {
            let tempParam: any = {};
            Object.keys(params).forEach((item: any) => {
                if (params[item] || Object.is(params[item], 0)) {
                    tempParam[item] = params[item];
                }
            });
            if (Object.keys(tempParam).length > 0) {
                url += `?${qs.stringify(tempParam)}`;
            }
        }
        return new Promise((resolve: any, reject: any) => {
            axios
                .get(url, { headers: headers })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

}
