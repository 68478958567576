<template>
  <div class="messageDialog3">
    <el-tabs v-model="activeNameShow">
      <el-tab-pane label="数据准备" name="first"></el-tab-pane>
      <el-tab-pane label="底稿申报表" name="second"></el-tab-pane>
    </el-tabs>
    <div class="dttProcessTitle">
      <img :src="iconClass === 'funnel' ? 'img/funnel.png' : 'img/success.svg'" :class="iconClass" />
      <span class="imgSpanClass">{{ iconClass === 'funnel' ? '任务进行中...' : '任务已完成' }}</span>
    </div>
    <div class="dttProcess">
      <el-progress :percentage="percentage" :stroke-width="12"></el-progress>
    </div>
    <div class="dttProcessJournalBox">
      <div class="dttProcessJournalBoxDiv" v-html="showHtml()"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'percentage',
    'iconClass',
    'journalBox',
    'journalBox2',
    'activeName'
  ],
  data() {
    return {
      activeNameShow: 'first'
    }
  },
  watch: {
    activeName(newValue) {
      this.activeNameShow = newValue
    }
  },
  methods: {
    showHtml() {
      if (this.activeNameShow == 'first') {
        const str = "[查看详情]<br/>";
        const link = `<a  id="${this.UUID}">查看详情</a><br/>`;
        const isViewDetails = this.journalBox.includes(str);
        if (isViewDetails) {
          this.journalBox = this.journalBox.replace(str, link);
          setTimeout(() => {
            const viewDetails = document.getElementById(this.UUID);
            if (viewDetails) {
              viewDetails.addEventListener("click", () => {
                this.$router.push(
                  "/homepage/tmsmisassetinfos/views/gridview"
                );
              }),
                false;
            }
          }, 1000);
        }
        return this.journalBox ? this.journalBox : ''
      } else {
        return this.journalBox2 ? this.journalBox2 : ''
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
