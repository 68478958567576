<template>
  <div class="messageDialog2">
    <div class="app-data-upload-view" element-loading-background="rgba(57, 57, 57, 0.2)">
      <div class="main-content">
        <div class="data-info-container">
          <div class="message-container">
            <div class="result-list" v-if="Object.keys(responseResult || {}).length > 0">
              <ul>
                <li class="title"><span>{{ typeTotal }}</span></li>
                <li>
                  <span>
                    {{ typeTotal }} {{ responseResult.total }}
                    条，错误[{{ responseResult.error }}]，成功[{{ responseResult.success }}]，警告[{{ responseResult.warn ?
                      responseResult.warn : 0 }}]
                    <span v-if="responseResult.log">，规则引擎进度[{{ responseResult.log ? this.responseResult.log : 0
                    }}]</span>
                  </span>
                </li>
              </ul>
              <div
                v-if="responseResult.error !== 0 && responseResult.errorInfos && responseResult.errorInfos.length !== 0">
                <ul>
                  <li class="title"><span>错误信息</span></li>
                </ul>
                <ul>
                  <li v-for="(itemLi, indexLi) in responseResult.errorInfos.slice(0, 10)" :key="indexLi" class="error-item">
                    <span>第{{ itemLi.index }}行：</span><span>{{ itemLi.info }}</span>
                  </li>
                </ul>
              </div>
              <div
                v-if="responseResult.warn !== 0 && responseResult.warnInfos && responseResult.warnInfos.length !== 0">
                <ul>
                  <li class="title"><span>警告信息</span></li>
                </ul>
                <ul>
                  <li v-for="(itemLi, indexLi) in responseResult.warnInfos.slice(0, 10)" :key="indexLi" class="warn-item">
                    <span>第{{ itemLi.index }}行：</span><span>{{ itemLi.info }}</span>
                  </li>
                </ul>
              </div>
              <div v-if="responseResult.log !== 0 && responseResult.logInfos && responseResult.logInfos.length !== 0">
                <ul>
                  <li class="title"><span>规则引擎进度</span></li>
                </ul>
                <ul>
                  <li v-for="(itemLi, indexLi) in responseResult.logInfos.slice(0, 10)" :key="indexLi">
                    {{ itemLi.index }}.<span>{{ itemLi.info }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <el-row class="second-content">
          <div class="over-info" v-if="Object.keys(responseResult.errorFile || {}).length !== 0">
            ...具体请下载反馈信息查看
          </div>
          <div class="downDivClass"
            v-if="responseResult.error + responseResult.warn !== 0 && Object.keys(responseResult.errorFile || {}).length !== 0"
            @click="downloadImportMessage">
            下载反馈信息
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  props: [
    'responseResult',
    'typeTotal'
  ],
  data() {
    return {
    }
  },
  methods: {
    /**
       * 下载导入反馈信息
       *
       * @memberof AppDataUploadView
       */
    downloadImportMessage() {
      let downloadUrl = '/ibizutil/download/' + this.responseResult.errorFile.fileid;
      const headers = {};
      axios({
        method: 'get',
        url: downloadUrl,
        headers: headers,
        responseType: 'blob'
      }).then((response) => {
        if (!response || response.status != 200) {
          this.$message.error('图片下载失败！');
          return;
        }
        // 请求成功，后台返回的是一个文件流
        if (response.data) {
          // 获取文件名
          const filename = this.responseResult.errorFile.filename;
          const ext = this.responseResult.errorFile.ext ? this.responseResult.errorFile.ext : '.' + filename.split('.').pop();
          let filetype = this.calcFilemime(ext);
          // 用blob对象获取文件流
          let blob = new Blob([response.data], { type: filetype });
          // 通过文件流创建下载链接
          var href = URL.createObjectURL(blob);
          // 创建一个a元素并设置相关属性
          let a = document.createElement('a');
          a.href = href;
          a.download = filename;
          // 添加a元素到当前网页
          document.body.appendChild(a);
          // 触发a元素的点击事件，实现下载
          a.click();
          // 从当前网页移除a元素
          document.body.removeChild(a);
          // 释放blob对象
          URL.revokeObjectURL(href);
        } else {
          this.$message.error('图片下载失败！');
        }
      }).catch((error) => {
        console.error(error);
      });
    },

    /**
       * 计算文件mime类型
       *
       * @param filetype 文件后缀
       * @memberof DiskFileUpload
       */
    calcFilemime(filetype) {
      let mime = "application/vnd.ms-excel";
      switch (filetype) {
        case ".xlsx":
          mime = "application/vnd.ms-excel";
          break;
        case ".wps":
          mime = "application/kswps";
          break;
        case ".doc":
          mime = "application/msword";
          break;
        case ".docx":
          mime = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case ".txt":
          mime = "text/plain";
          break;
        case ".zip":
          mime = "application/zip";
          break;
        case ".png":
          mime = "image/png";
          break;
        case ".gif":
          mime = "image/gif";
          break;
        case ".jpeg":
          mime = "image/jpeg";
          break;
        case ".jpg":
          mime = "image/jpeg";
          break;
        case ".rtf":
          mime = "application/rtf";
          break;
        case ".avi":
          mime = "video/x-msvideo";
          break;
        case ".gz":
          mime = "application/x-gzip";
          break;
        case ".tar":
          mime = "application/x-tar";
          break;
      }
      return mime;
    }
  }
}
</script>
<style lang="scss" scoped></style>
