



















import { ApiService, ActionLogService } from "@/api";
import { micro } from "@/micro";
import { AppService } from "@/service/app-service";
import shared from "@/shared";
import { Menu } from "ant-design-vue";//菜单递归
import qs from "qs";
import { Vue, Component, Watch, Mixins, Prop } from "vue-property-decorator";
import AppMenuItem from "./app-menu-item.vue";
import AppMenuSearch from "./app-menu-search.vue";
import { eventBus } from '@/service/micro-action-service';
import { OpenMenu } from "../base-home/mixin/open-menu";

//菜单递归方法
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
        <p slot="title">
          <app-menu-item :item="menuInfo" :itemLevel="itemLevel"/>
        </p>
        <template v-for="res in menuInfo.children">
          <a-menu-item v-if="!res.children" :key="res.key" @click="$baseMenu.changeMenu(res)" >
            <app-menu-item :item="res" :itemLevel="2"/>
          </a-menu-item>
          <sub-menu v-else :key="res.key" :menu-info="res" :itemLevel="2"/>
        </template>
      </a-sub-menu>
    `,
  name: 'SubMenu',
  isSubMenu: true,// must add isSubMenu: true
  props : {
    ...(Menu.SubMenu as any).props,// Cannot overlap with properties within Menu.SubMenu.props,这里是父级给自己得数据，函数模板在ant2.0以下需要
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
    // 是否收缩菜单--左侧菜单得展开与收缩在App.vue文件中
    collapsed: {
      type: Boolean,
      default: false,
    },

    //层级，用来开启我的菜单时防止递归将二级菜单也给icon
    itemLevel: {
      type: Number,
      default: 0,
    }

  },

  components: {
    AppMenuItem, //引入组件
  },
  
};


type MenuItem = {
  toUrl: any;
  key: string;
  title: string;
  path: string;
  // icon?: string;
  menuUrl?: string;
  linkUrl?: string;
  apptag?: string;
  funtag?: string;
  children?: MenuItem[];
  menuId?:string;
  externalLink?:string;
  myCaptionLocale?:string;
  myCaption?:string;
  myCaptionEn?:string;
};

@Component({
  components: {
    'sub-menu': SubMenu,
    AppMenuItem,
    AppMenuSearch
  }
})
export default class baseMenu extends Mixins(OpenMenu) {
  // [x: string]: any;
  /**
   * 当前选中的菜单项 key 数组
   */
  selectKey: string = "";

  /**
   * 当前展开的 SubMenu 菜单项 key 数组
   */
  openKeys: string[] = [];

  /**
   * 是否收缩菜单--菜单递归
   */
   @Prop({ default: false }) collapsed!: boolean;

  /**
   * 我的菜单导航
   */
  public myMenusGroups: any = [];
  
  /**
  * 菜单数据平铺 
  */
  public flattenMenu:any = []

  created() {
    // 实现面包屑点击功能，这里将整个vue给实例化
    Vue.prototype.$baseMenu = this;
    eventBus.$on('customMenuMessage', this.myEventHandler);
    if(sessionStorage.getItem('isFirst') === 'true'){ 
      this.isFirst = true
    } else if(sessionStorage.getItem('isFirst') === 'false'){
      this.isFirst = false
    }
    if(sessionStorage.getItem('customMenu')){
      this.myMenusGroups = JSON.parse(sessionStorage.getItem('customMenu') || '[]')
      this.computeMenukeys(this.$route.fullPath);
      this.handleMenuI18n(this.myMenusGroups)
      this.initSearchMenu(this.myMenusGroups)
      AppService.getInstance().initMenuUrl(this.myMenusGroups)
    }else{
      /**
       * 自定义菜单第一级菜单group接口
       *
       */
      ApiService.getInstance()
        .getRootGroup()
        .then((res: any) => {
          this.handleGroup(res.data.result);
          this.computeMenukeys(this.$route.fullPath);
          sessionStorage.setItem('customMenu',JSON.stringify(this.myMenusGroups))
          sessionStorage.setItem('isFirst', 'true')
        });
    }
  }

  /**
  * 根据语言切换菜单title 
  */
  handleMenuI18n(menu:any){
    const titleFild = this.$i18n.locale === 'en-US' ? 'titleEN' : 'titleZHCN';
    menu.forEach((item:any) => {
        item.title = (item[titleFild] || item.key === 'Home') ? item[titleFild] : item.title;
        if(item.children){
          this.handleMenuI18n(item.children)
        }
    })
  }

  isFirst: Boolean = true;
  /**
  * 打开菜单回调 
  * @param openKeys 
  */
  openChange(openKeys:string[], search:any){
    const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
    const index  = this.myMenusGroups.findIndex((item:any) => item.key === latestOpenKey)
    if(index !== -1 || search === 'search'){
      if(this.isFirst ){
        // 第一层级
        this.isFirst = false;
        ApiService.getInstance()
        .getMyMenuGroup()
        .then((res: any) => {
            sessionStorage.setItem('isFirst', 'false')
            this.myMenusGroups.map((list:any,num:any) =>{
              res.data.result.groups.map((item:any) =>{
                if (item.groupMenuId === list.groupMenuId && 
                item.groupMenuNameLocale === list.title) {
                  this.filterData( item,list );
                  this.$set(this.myMenusGroups, num, list);
                }
              })
            })
            AppService.getInstance().initMenuUrl(this.myMenusGroups)
            this.initSearchMenu(this.myMenusGroups)
            sessionStorage.setItem('customMenu',JSON.stringify(this.myMenusGroups))
        });
      }
      if (search !== 'search'){
        this.$nextTick(() => {
          this.openKeys = [this.myMenusGroups[index].key]
        })
      }
    } else {
      // 模块内树形菜单手风琴,openKeys[0]始终为打开的一级菜单
      const _index = this.myMenusGroups.findIndex((item:any) => item.key === openKeys[0])
      if(_index !== - 1){
        // 关闭菜单项得时候找到关闭得层级level，展开时latestOpenKey是最后一个新打开得页签，
        // 关闭时latestOpenKey字段是空得，关闭时需要截取关闭之前得key值，
        var indexOld:any = 0;
        let newdata = this.openKeys;
        let newList = openKeys;
        if(latestOpenKey === undefined) {
          indexOld = newdata.findIndex((key,index) => {
            if (openKeys.indexOf(key) === -1)
            return index + 1
          });
        } else {
          indexOld = 0
        }
        const flat = this.flattenTree(this.myMenusGroups[_index]);
        //latestOpenKey === undefined表示关闭，关闭时截取关闭之前得数据，新增得时候取第一级得key就好
        let _openKeys:any = latestOpenKey === undefined ? newList.slice(0, indexOld) : [openKeys[0]];
        const findMenu = (key:any,menu:any[] = []) => {
          let temp = flat.find((menu:any) => menu.key === key)
          if(temp){
            menu.push(temp)
            if(temp.parent){
              findMenu(temp.parent,menu)
            }
          }
          return menu
        }
        const _menu = findMenu(latestOpenKey)
        if(_menu && _menu.length) {
          _menu.forEach((menu:any) => {
            if (!_openKeys.includes(menu.key)) {
              _openKeys.splice(1, 0, menu.key);
            }
          })
        }
        if (search !== 'search'){
          this.$nextTick(() => {
            this.openKeys  = _openKeys
          })
        }
      }
    }  
  }

  /**
   * 树形结构平铺并标明层级返回
   * @param node 
   * @param level 
   * @param flatArray 
   */
  public flattenTree(node: any, level = 0, flatArray: any[] = [],parent:any = null): any[] {
    flatArray.push({ ...node, level,parent });
      if (node.children) {
        node.children.forEach((childNode:any) => {
          this.flattenTree(childNode, level + 1, flatArray,node.key);
        });
      }
    return flatArray;
  } 

  /**
   * vue生命周期 -- mounted
   *
   */
  public mounted() {
    setTimeout(() => {
      const el = document.getElementById("app-root-loading-x");
      if (el) {
        el.style.display = "none";
      }
    }, 1000);
  }

  /**
   * 收起菜单时，把展开菜单都去掉
   */
  @Watch("collapsed")
  onCollapsedChange(newVal: any) {
    if (newVal === true) {
      this.openKeys = [];
    } else {
      this.openKeys = this.getParentMenus();
    }
  }

  /**
  * 获取树形菜单平铺
  * @param data 
  */
  public getTopKeys(data: any) {
    data.forEach((item: any,index:any) => {
      this.flattenMenu[index] = this.flattenTree(item)
    })
    this.flattenMenu = this.flattenMenu.flat(1)
  }

  /**
 * 监听路由变化
 */
  @Watch("$route")
  onPathChange(newVal: any, oldVal: any) {
    if (newVal.path !== oldVal.path) {
      this._initMenus();
    }
    if ((newVal.meta.caption === '首页' || newVal.meta.caption === 'Home') && 
        newVal.fullPath === "/" &&
        !newVal.hash.includes("isOutsideLinks")
      ) {
      this.openKeys = [];
      this.selectKey = 'Home';
      // 存储面包屑
      shared.setActiveCrumbPath([])
    }
    if (newVal.hash.includes("?")) {
      if (newVal.hash.includes("isOutsideLinks")) {
        // 在主应用中发布事件
        eventBus.$emit('remove', newVal.fullPath);
      } else {
        this.breadInit(newVal.hash);
        this.computeMenukeys(newVal.fullPath);
      }
    }
  }

  /**
   * 计算选中的菜单
   */
  public computeMenukeys(path: string) {
    const params: any = {};
    const _params: any = path.slice(path.indexOf("?") + 1);
    const paramArray: Array<string> = decodeURIComponent(_params).split(";");
    if (paramArray.length > 0) {
      paramArray.forEach((item: any) => {
        Object.assign(params, qs.parse(item));
      });
    }
    if (
      Object.prototype.hasOwnProperty.call(params, "srfmenuname") &&
      params.srfmenuname &&
      this.selectKey !== params.srfmenuname
    ) {
      this.selectKey = params.srfmenuname;
    }
    const parentMenus = this.getParentMenus();
    if (parentMenus?.length && !this.collapsed) {
      // 不是收缩状态再去改
      this.openKeys = this.getParentMenus();
    }
    const _activeMenuItem = this.isContains(this.selectKey,this.myMenusGroups)
    if(_activeMenuItem && _activeMenuItem.isContains){
      this.activeMenuItem = _activeMenuItem.item
      this.breadInit(this.$route.hash)
    }
  }

  public isContains(
    key:string,
    arr:Array<any>,
    contains:{isContains:boolean,item:any} = {isContains:false,item:[]}
  ){
    arr.forEach((item:any) => {
      if(item.key === key){
        contains.isContains = true
        contains.item = item
      }
      if(item.children){
        contains = this.isContains(key,item.children,contains)
      }
    })
    return contains
  }

  /**
   * 获取父菜单项
   *
   * @memberof Menu
   */
  public getParentMenu(parentName: string, menus: any[]): string {
    let result: string = "";
    for (const menu of menus) {
      if (menu.key === this.selectKey) {
        result = parentName;
        break;
      }
      if (menu.children && menu.children.length) {
        result = this.getParentMenu(menu.key, menu.children);
        if (result) {
          break;
        }
      }
    }
    return result;
  }

  /**
   * 获取父菜单项 所有父节点
   *
   * @memberof Menu
   */
  public getParentMenus() {
    let temp: string[] = [];
    let forFn = (arr: any, key: string, parent: any = null) => {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        if (item.key === key) {
          if (parent?.key) {
            temp.push(parent.key);
            forFn(this.myMenusGroups, parent.key);
          }
        } else {
          if (item.children) {
            forFn(item.children, key, item);
          }
        }
      }
    };
    forFn(this.myMenusGroups, this.selectKey);
    return temp;
  }

  /**
   * 处理单个应用菜单数据
   *
   */
  public handleSingleAppMenus(source: any, target: any, args: any) {
    if (source && source.length > 0) {
      const { appName, path } = args;
      const childrenMenus: Array<any> = [];
      source.forEach((sourceMenuItem: any) => {
        const targetMenuItem = {
          key: this.uuid(),
          title: this.getLocaleCaption(sourceMenuItem, false),
          titleZHCN:sourceMenuItem.myCaption,
          titleEN:sourceMenuItem.myCaptionEn,
          path: path,
          funtag: sourceMenuItem.funcTag,
          apptag: appName,
          iconClass: sourceMenuItem?.iconClass ?? 'las la-plus-square'
        };
        if (sourceMenuItem && sourceMenuItem.children?.length > 0) {
          this.handleSingleAppMenus(
            sourceMenuItem.children,
            targetMenuItem,
            args
          );
        }
        childrenMenus.push(targetMenuItem);
      });
      target.children = childrenMenus;
    }
  }

  /**
     * 是否开启首页
     */
  public isOpenIndex:any = true;

  /**
   * 首页开关
   */
   async getOpenhomepagelink(item:any) {
      ApiService.getInstance().getOpenhomepagelink().then((res: any) => {
        // 关闭则走配置得菜单项
        if (res.data.result === 0) {
          this.isOpenIndex = false;
          localStorage.setItem('defaultIndex',JSON.stringify(item))
          this.changeMenu(item)
          eventBus.$emit('isOpenIndex', false);
        } else if (res.data.result === 1) {
          // 开启则走默认得首页
          this.isOpenIndex = true;
          localStorage.removeItem('defaultIndex')

          // 针对PTC组织得设置
          const context:any = localStorage.getItem('microAppData')
          if(context){
            const code = JSON.parse(context).context.tenantcode
            if(code !== 'PTC'){
              this.myMenusGroups.unshift({
                key: "Home",
                title: this.$i18n.locale === 'zh-CN' ? "首页" : "Home",
                titleZHCN: '首页' ,
                titleEN:'Home', 
                path: "/",
                iconClass: 'las la-home'
              });
            }
          }
          eventBus.$emit('isOpenIndex', true);
        }
        sessionStorage.setItem('customMenu',JSON.stringify(this.myMenusGroups))
      });
  }

  /**
     * 处理group接口返回的数据，开启我的菜单之后返回的数据
     *
     */
  public copyMenusGroups = [];
  public handleGroup(result: any) {
    this.myMenusGroups = [];
    if (result.groups && result.groups.length > 0) {
      result.groups.forEach((item: any) => {
        if (item) {
          let appMenus: any = {
            groupMenuId: item.groupMenuId,
            isRetrieved: false,
            key: this.uuid(),
            title: this.getLocaleCaption(item, true),
            titleZHCN: item.groupMenuName,
            titleEN:item.groupMenuNameEn,
            path: '',
            orderValue: item.orderValue,
            iconClass: item?.iconClass ?? 'las la-plus-square'
          };
          //有下一级得时候才循环
          appMenus.children = [];
          this.myMenusGroups.push(appMenus);
        }

      });
    }
    if (result.menus && result.menus.length > 0) {
      result.menus.forEach((item: any,index:any) => {
        item.key = this.uuid();
        item.title = this.getLocaleCaption(item, false);
        item.path = `/${item.path}`;
        item.funtag = item.funcTag;
        item.orderValue = Number(item.pos);
        this.myMenusGroups.push(item);
      });
      // 如果一级菜单只有外链菜单，那么正常跳转并且显示无首页页面
      const firstItemBelowTen = result.menus.find((item:any) => !item.menuId.includes('OtherCustom'));
      console.log(firstItemBelowTen,'firstItemBelowTen')
      if (firstItemBelowTen !== undefined) {
          this.getOpenhomepagelink(firstItemBelowTen)
          eventBus.$emit('closeIndex', true);

        } else {
          this.getOpenhomepagelink(result.menus[0])
        }
    } else {
      this.getOpenhomepagelink({})
    }
    this.myMenusGroups.sort((a:any, b:any) => a.orderValue - b.orderValue);
    this.getTopKeys(this.myMenusGroups);
  }


  /**
   * 如果是开启我的菜单--我的菜单现在是多级菜单，这里需要把group得菜单递归下
   */
  public filterData(source: any, target:any) {
    let childrenMenus: any = [];
    // 如果有menus，需要递归下数据
    if (source && source.menus?.length > 0) {
      //命名要求，不重复，横线之前的名称需要和父key保持一致，才能在打开子菜单时向上找到父级，否则会关闭当前展开项
      source.key = this.uuid();
      source.path = `/${source.menus[0].path}`;
      (source.menus || []).forEach((sourceMenuItem: any) => {
        let targetMenuItem:any = {
          key:this.uuid(),
          title: this.getLocaleCaption(sourceMenuItem, false),
          titleZHCN:sourceMenuItem.myCaption,
          titleEN:sourceMenuItem.myCaptionEn,
          path: `/${sourceMenuItem.path}`,
          funtag: sourceMenuItem.funcTag,
          apptag: sourceMenuItem.apptag,
          iconClass: sourceMenuItem?.iconClass ?? 'las la-plus-square',
          orderValue: Number(sourceMenuItem.pos),
          menuId: sourceMenuItem.menuId
        };
        if (sourceMenuItem.externalLink) {
          targetMenuItem.externalLink = sourceMenuItem.externalLink
        }
        childrenMenus.push(targetMenuItem);
      });
    }
    if (source && source.childrenGroups?.length > 0) {
      (source.childrenGroups || []).forEach((sourceMenuItem: any) => {
        let GroupsMenus: any = {
            key: this.uuid(),
            title: this.getLocaleCaption(sourceMenuItem, true),
            titleZHCN: sourceMenuItem.groupMenuName,
            titleEN:sourceMenuItem.groupMenuNameEn,
            path: '',
            iconClass: sourceMenuItem?.iconClass ?? 'las la-plus-square',
            orderValue: sourceMenuItem.orderValue
          };  
          childrenMenus.push(GroupsMenus);
        if (sourceMenuItem.childrenGroups && sourceMenuItem.childrenGroups.length > 0 ||
            sourceMenuItem && sourceMenuItem.menus?.length > 0) {
          GroupsMenus.children = [];
          this.filterData(
            sourceMenuItem,
            GroupsMenus
          );
        }
      });
    }

    //这里如果当前级别即有子应用又有group组，那么就需要合一下数组，如果没有子应用只有group，直接复制给target.children
    if (Array.isArray(target.children)) {
      target.children = [...target.children, ...childrenMenus];
    } else {
      target.children = childrenMenus;
    }
    target.isRetrieved = true;

    //group自定义菜单需要根据导入excel中排序值进行排序
    // 按照排序字段对JSON数组进行排序
    target.children.sort((a:any, b:any) => a.orderValue - b.orderValue);
  }

  //js生成随即字符串
  public uuid() {
    return Math.random().toString(36).substring(3, 15)
  }

  /**
   * 当前触发的菜单项selectKey
   */
  private _initMenus() {
    const currentMenu = this._findCurrentMenu(
      this.myMenusGroups,
      this.selectKey
    ) as MenuItem;
    if (!currentMenu) return;
    const { key } = currentMenu;
    this.selectKey = key;
  }

  private _findCurrentMenu(
    menus: MenuItem[],
    selectKey: string
  ): MenuItem | null {
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      const { key } = menu;
      if (key === selectKey) return menu;

      const currentMenu = this._findCurrentMenu(
        menu.children || [],
        selectKey
      );
      if (currentMenu) return currentMenu;
    }
    return null;
  }

  /**
   * 存储选中得item
   */
  public activeMenuItem: any = {};

  /**
  * 我得菜单触发得菜单
  * 从全部菜单点击过来的数据是否在配置得菜单中
  * 存在：需要触发左侧菜单定位锚点
  * 不存在：直接打开原生菜单即可
  */
  selfChangeMenu (item: any) {
    if (JSON.stringify(this.myMenusGroups).includes(item.menuId)
     && JSON.stringify(this.myMenusGroups).includes(item.apptag)) {
      this.GetGroupItems(this.myMenusGroups,item)
    } else {
      this.openMenuMixin({
          apptag: item.apptag,
          funtag: item.funcTag,
          key: `${item.apptag}-${item.tag}`,
          path: "/" + item.path,
          title: item.myCaptionLocale,
          resourceId: item.resourceId,
          iconClass: item?.iconClass ?? 'las la-plus-square',
      });
    }
  }

  /**
  * 获取我得菜单得数据，因为原生菜单和自定义菜单得key值不一致，需要找到自定义菜单中得对应数据并触发
  */
  GetGroupItems(children: any, item: any) {
    children.map((res: any) => {
      if (res.menuId === item.menuId && 
          res.apptag === item.apptag
      ) {
          this.changeMenu(res)
      } else if (res.children){
          this.GetGroupItems(res.children, item);
      }
    })
  }

  /**
   * 切换菜单
   */
  changeMenu(item: MenuItem) {
    // 如果配置了外链得菜单，直接新窗口打开
    if (item.externalLink) {
      if (item.externalLink !== '') { 
        window.open(item.externalLink, '_blank')
      } 
      return;
    }
    if (micro.limitLoading) {
      micro.noticeLimitLoading();
      return;
    }
    ActionLogService.getInstance().menuAction({ menu: item })
    const { key } = item;
    this.selectKey = key;
    if (item && item.path) {
      if (
        location.pathname.slice(
          (window as any).Environment.baseDeployUrl.length
        ) !== item.path
      ) {
        const loadContainer = document.getElementById(
          "child-container-loading"
        );
        if (loadContainer) {
          loadContainer.style.cssText = "display: block;";
        }
        setTimeout(() => {
          this.$router.push(item.path);
        }, 0);
      }
      // 微应用
      if (item.apptag && item.funtag) {
        AppService.getInstance().noticeMicroApp({
          tag: item.apptag,
          action: "MENUCLICK",
          data: item
        });
      } else {
        //基层应用
        setTimeout(() => {
          const tempRoute = {};
          Object.assign(tempRoute, {
            fullPath: this.$route.fullPath,
            menuUrl: item.menuUrl,
            ...this.$route.meta
          });
          shared.addPage(tempRoute);

        }, 0);
      }
      // 将点击数据存在字段里初始化面包屑数据得时候用
      this.activeMenuItem = JSON.parse(JSON.stringify(item));
      sessionStorage.setItem('activeMenuItem',JSON.stringify(this.activeMenuItem))
      
    }
  }


  /**
   * 面包屑初始化
  */
  public breadInit(route: any) {
    // 路由只有有数据的时候才往下进行，路由数据会延迟，需要确认是完整数据eg: #/frontpage/baseusers/views/gridview?srfmenuname%3Drandd-menuitem2%3Bsrfmenubaropen%3Dtrue
    // 取出路径方便重组面包屑数据
    let menuUrl = (this.activeMenuItem?.menuUrl || '').split('->');
    // 获取存储得点击所有数据
    let data: any = shared.getActiveCrumbPathAll() ? shared.getActiveCrumbPathAll() : JSON.parse(sessionStorage.getItem('breadcrumbListCopy') || '');

    // 定义新对象，将选中得数据给新对象
    let breadcrumbObj = this.activeMenuItem;
    // 定义新数组，存放选中得面包屑数据
    let activeMenuPath: any = [];
    // 当前选中得数据是新数据才能push

    // 重组面包屑数据
    (menuUrl || []).map((items: any) => {
      let Object = {
        title: items,
        key: this.uuid(),//将每一级得key给到面包屑
        menuUrl: this.activeMenuItem.menuUrl,
        breadpath: this.activeMenuItem.apptag + "__" + route.substr(1)
      }
      activeMenuPath.push(Object)
    })
    // 重组之后得数据是一个对象里加数组
    breadcrumbObj.fullPathChildren = activeMenuPath;
    //这里要和点击页签得路经一致
    breadcrumbObj.breadpath = route.substr(1)
    //判断是否是新数据，是新数据
    if (!JSON.stringify(data).includes(this.activeMenuItem.menuUrl || '')) {
      // 新数据直接存储
      data.push(breadcrumbObj)
      // 存储面包屑所有点击的数据
      shared.setActiveCrumbPathAll(data)
      // 存储面包屑
      shared.setActiveCrumbPath(activeMenuPath)
    } else if (JSON.stringify(data).includes(this.activeMenuItem.menuUrl || '')) {
      // 切换页签，不是新数据，只替换展示得面包屑，不存储本地
      if (this.activeMenuItem.menuUrl) {
        // 存储面包屑
        shared.setActiveCrumbPath(activeMenuPath)
      }

    }

    // 清空选中项，否则影响页签得切换功能
    this.activeMenuItem = {}
  }

  /**
 * 点击面包屑得时候改变openKeys得值达到触发左侧菜单得效果
 *
 * @memberof Menu
 */
 public changeSelectKey(value: any) {
    if (value === 'Home') {
      this.selectKey = 'Home';
    } else {
      this.openKeys = value;
    }
  }

  // 自定义菜单触发基座，基座触发eventBus，在这里执行切换菜单方法
  myEventHandler(data:any) {
    // 执行相应的函数
    this.changeMenu(data)
  }
  //===========================================菜单检索开始=========================================
  /**
   * 检索数据只需要应用数据，一级二级不要
  */
  public searchList: any = [];

  /**
   * 检索栏下拉选中方法
   */
  public handleSelect(item: any) {
    this.changeMenu(item);  
  }

  // 点击搜索按钮获取所有数据用于搜索
  handleClick(res: any){
    this.openChange([], 'search')
  }
  /**
   * 将所有得应用都过滤出来
   */
   public initSearchMenu(menus: any) {
    if (menus && menus.length) {
      menus.forEach((menu: any) => {
        if (menu.children) {
          this.initSearchMenu(menu.children)
        } else {
          if(menu.key !== 'Home'){
            this.searchList.push(menu)
          }
        }
      })
    }
  }

  setI18nLocale(locale:any){
    this.$i18n.locale = locale
  }
  //===========================================菜单检索结束=========================================

  /**
   * 获取国际化标题
   * type：true: 代表group组，使用groupMenuNameLocale字段
   * type：false: 代表menus组，使用myCaptionLocale字段
   */
  private getLocaleCaption(menu: any, type:any) {
    return type ? menu.groupMenuNameLocale : menu.myCaptionLocale ? menu.myCaptionLocale : menu.captionLocale;
  }
}
