






















































































import { Spin } from 'ant-design-vue';
import { Mixins, Component } from "vue-property-decorator";
import myCommonDialog from "./components/my-common-dialog.vue";
import BaseHomeAuditContent from "./components/base-home-audit-content.vue";
import BaseHomeUser from "./components/base-home-user.vue";
import BaseHomeChartsContent from "./components/base-home-charts-content.vue";
import BaseHomeCalendarContent from "./components/base-home-calendar-content.vue";
import BaseHomePolicyContent from "./components/base-home-policy-content.vue";
import { OpenMenu } from "./mixin/open-menu";
import { ApiService } from "@/api";
import { AppConfig } from "@/config/config";
import shared from "@/shared";
import { eventBus } from '@/service/micro-action-service';

@Component({
  components: {
    myCommonDialog,
    BaseHomeAuditContent,
    BaseHomeUser,
    BaseHomeChartsContent,
    BaseHomeCalendarContent,
    BaseHomePolicyContent,
    'a-spin': Spin,
  }
})
export default class BaseHomePage extends Mixins(OpenMenu) {
  /**
   * loading触发
   */
  public indicator:boolean = false;
  /**
   * 是配置了我的常用菜单权限
   */
  public isCommonTab:boolean = false;
  public commonTabs = "";// 目前将我的常用和最近使用都隐藏
  public commonList: any = [];
  /**
   * 未配置一级菜单项，但是关闭了默认首页
   */
  public isIndex:boolean = true;

  /**
   * ---------- banner
   */
  bannerInfo: { bannerLink?: any; title?: string; subtitle?: string } = {};

  mounted() {
    // 去除menu显示的遮罩
    setTimeout(() => {
      const loadContainers = document.querySelectorAll(
        "#child-container-loading"
      );
      if (loadContainers?.length) {
        loadContainers.forEach((loadContainer: any) => {
          if (loadContainer?.style) {
            loadContainer.style.cssText = "display: none;";
          }
        });
      }
    }, 0);
    let BaseConfig: any = shared.getBaseConfig();
    if (BaseConfig["micro-main-common"]) {
      this.indicator = true ;
      this.commonTabs = "common";
      this.isCommonTab = true;
    } else {
      this.indicator = false;
      this.commonTabs = "";
      this.isCommonTab = false;
    }

    /**
     * ---------- banner
     */
    eventBus.$on('bannerLink', (value:any) => {
      if (value) {
        this.$set(this.bannerInfo,'bannerLink', value)
      }
    });

    /**
     * ---------- 如果关闭首页配置
     */
    let data:any = localStorage.getItem('defaultIndex');
    console.log(data,'如果关闭首页配置')
    if (data !== null && data !== "undefined") {
      if (JSON.parse(data).menuId.includes('OtherCustom')) {
        this.isIndex = false
      }
    }
    eventBus.$on('isOpenIndex', (flag:any) => {
      if (!flag) {
        this.isIndex = false
      } else {
        this.isIndex = true
      }
    }); 
  }

  async created() {
    this.$forceUpdate();
  }

  public calendar = new Date();

  /**
   * ---------- 我的常用
   */
  commonListChange(value: any) {
    this.commonList = value;
    this.indicator = false;
  }

  // loading开启关闭
  loadChange(value: any) {
    this.indicator = value;
  }

  commonClick(item: any) {
    this.openMenuMixin({
      apptag: item.apptag,
      funtag: item.funcTag,
      key: item.tag,
      path: "/" + item.path,
      title: item.captionLocale,
      resourceId: item.resourceId
    });
  }

  /**
   * 我的常用 更多
   */
  public commonMore() {
    if (this.commonTabs === "common") {
      const myCommonDialog: any = this.$refs.myCommonDialog;
      myCommonDialog.setDialogVisible(true);
    }
  }

  /**
   * 获取国际化标题
   * type：false: 代表group组，使用groupMenuNameLocale字段
   * type：true: 代表menus组，使用myCaptionLocale字段
   */
  public getLocaleCaption(menu: any, type:any) {
    if (type) {
      return menu.myCaptionLocale ? menu.myCaptionLocale : menu.captionLocale;
    } else {
      return menu.groupMenuNameLocale ? menu.groupMenuNameLocale : menu.groupMenuName;
    }
  }
}
