import { AppConfig } from "@/config/config";
import { LocationItem } from "@/custom-router/location-item";
import i18n from "@/locale";
import { AppService } from "@/service/app-service";
import store, { State } from "@/shared/store";
import { message } from "ant-design-vue";
import NProgress from "nprogress";
import { loadMicroApp, MicroApp } from "qiankun";

/**
 * 微应用加载控制服务
 *
 * @author chitanda
 * @date 2023-02-22 17:02:42
 * @export
 * @class MicroService
 */
export class MicroService {
  /**
   * 子应用实例缓存
   *
   * @author chitanda
   * @date 2023-02-22 17:02:15
   * @protected
   * @type {Map<string, MicroApp>}
   */
  protected cache: Map<string, MicroApp> = new Map();

  /**
   * 当前激活路径
   *
   * @author chitanda
   * @date 2023-02-22 17:02:31
   */
  pathName = "";

  /**
   * 是否正在加载子应用
   *
   * @author chitanda
   * @date 2023-02-22 17:02:40
   * @type {boolean}
   */
  isLoadChildApp: boolean = false;

  /**
   * 极限载荷页签数量
   *
   * @author chitanda
   * @date 2023-02-25 12:02:42
   * @type {number}
   */
  limitLoadingCount: number = 20;

  /**
   * 当前已打开标签页数量
   *
   * @author chitanda
   * @date 2023-03-07 14:03:40
   * @readonly
   * @type {number}
   */
   get openedTabs(): number {
    const state = store.getState();
    if (state.pages && state.pages.length) {
      return state.pages.length;
    }
    return 0;
  }

  /**
   * 是否已达极限载荷
   *
   * @author chitanda
   * @date 2023-02-25 12:02:05
   * @readonly
   * @type {boolean}
   */
  get limitLoading(): boolean {
    if (this.openedTabs >= this.limitLoadingCount) {
      return true;
    }
    return false;
  }

  /**
   * 历史路由激活信息
   *
   * @author chitanda
   * @date 2023-02-25 11:02:47
   * @protected
   * @type {(LocationItem | null)}
   */
  protected local: LocationItem | null = null;

  protected get appService() {
    return AppService.getInstance();
  }

  /**
   * 标签页极限荷载后提示
   *
   * @author chitanda
   * @date 2023-02-25 12:02:06
   */
  noticeLimitLoading(): void {
    const msg = i18n.t("micro.tablimittooltip", {
      count: this.limitLoadingCount,
    }) as string;
    message.info({
      content: msg,
    });
  }

  /**
   * 判断子应用是否已存在实例
   *
   * @author chitanda
   * @date 2023-02-23 22:02:34
   * @param {string} name
   * @return {*}  {boolean}
   */
  hash(name: string): boolean {
    return this.cache.has(name);
  }

  /**
   * 根据路径加载子应用
   *
   * @author chitanda
   * @date 2023-02-22 17:02:39
   * @param {string} pathName
   */
  loadApp(pathName: string, local: LocationItem): Promise<void> {
    return new Promise<void>((resolve) => {
      this.local = local;
      this.pathName = pathName;
      const appConfig = AppConfig.apps.find((app) => {
        return this.pathName === app.activeRule;
      });
      if (appConfig) {
        if (!this.cache.has(appConfig.name)) {
          this.isLoadChildApp = true;
          const microApp = loadMicroApp(
            appConfig,
            {},
            {
              // qiankun 生命周期钩子 - 加载前
              beforeLoad: () => {
                const loadContainer = document.getElementById(
                  "child-container-loading"
                );
                if (loadContainer) {
                  loadContainer.style.cssText = "display: block;";
                }
                NProgress.start();
                return Promise.resolve();
              },
              // qiankun 生命周期钩子 - 挂载后
              afterMount: (app: any) => {
                this.isLoadChildApp = false;
                console.log("挂载后----", app);
                const loadContainer = document.getElementById(
                  "child-container-loading"
                );
                if (loadContainer) {
                  loadContainer.style.cssText = "display: none;";
                }
                NProgress.done();
                this.appService.addMountedMicroApp(app);
                this.childAppRoutePush(appConfig.name, local);
                // 子应用挂载完成
                resolve();
                return Promise.resolve();
              },
            }
          );
          this.cache.set(appConfig.name, microApp);
        } else {
          this.appService.addMountedMicroApp(appConfig);
          // 通知现有应用为激活状态
          this.activeApp(appConfig.name);
          this.childAppRoutePush(appConfig.name, local);
          // 切换应用完成
          resolve();
        }
        // 通知所有非激活应用为隐藏状态
        this.cache.forEach((_app, key) => {
          if (appConfig.name !== key) {
            this.hiddenApp(key);
          }
        });
      }else{
        // 当前为基座应用时隐藏其他非激活子应用
        this.cache.forEach((_app, key) => {
          this.hiddenApp(key);
        });
      }
      { // 遍历当前已经打开的所有视图，发现当前缓存的应用在视图中不存在时。自动销毁对应应用
        const state = store.getState();
        const pages = state.pages || [];
        this.cache.forEach((microApp: MicroApp, key: string) => {
          if (appConfig?.name !== key) {
            const i = pages.findIndex((page:any) => page.microAppName === key);
            if (i === -1) {
              microApp.unmount();
              this.cache.delete(key);
            }
          }
        });
      }
    });
  }

  /**
   * 子路由进行 hash 路由跳转
   *
   * @author chitanda
   * @date 2023-02-25 11:02:14
   * @protected
   * @param {string} app
   * @param {LocationItem} local
   */
  protected childAppRoutePush(app: string, local: LocationItem): void {
    const { hash } = local;
    if (hash) {
      this.pushRouteApp(app, hash);
    }
  }

  /**
   * 设置子应用激活对应 hash 路由
   *
   * @author chitanda
   * @date 2023-02-25 11:02:03
   * @param {string} app
   * @param {string} routePath
   */
  pushRouteApp(app: string, routePath: string): void {
    this.appService.noticeMicroApp({ action: 'ACTIVE_ROUTE', tag: app, data: routePath });
  }

  /**
   * 激活 App
   *
   * @author chitanda
   * @date 2023-02-24 16:02:03
   * @param {string} app
   */
  activeApp(app: string): void {
    this.appService.noticeMicroApp({ action: "ACTIVE_APP", tag: app });
  }

  /**
   * 隐藏 App
   *
   * @author chitanda
   * @date 2023-02-24 16:02:20
   * @param {string} app
   */
  hiddenApp(app: string): void {
    this.appService.noticeMicroApp({ action: "HIDDEN_APP", tag: app });
  }

  /**
   * 销毁 App
   *
   * @author chitanda
   * @date 2023-02-23 22:02:14
   * @param {string} app
   */
  destroyApp(app: string): void {
    const micro = this.cache.get(app);
    if (micro) {
      micro.unmount();
    }
  }

  /**
   * 是否为当前已经激活的分页
   *
   * @author chitanda
   * @date 2023-02-27 16:02:34
   * @param {string} appName
   * @param {string} hash
   * @param {State} [state]
   * @return {*}  {boolean}
   */
  isActivePath(appName: string, hash: string, state?: State): boolean {
    if (!state) {
      state = store.getState();
    }
    if (state.historyPathList) {
      const app = AppConfig.apps.find(app => app.name === appName);
      if (app) {
        const fullPath = `${app.activeRule}#${hash}`;
        return state.historyPathList.findIndex(path => path.endsWith(fullPath)) !== -1;
      }
    }
    return false;
  }

  /**
   * 是否允许打开更多视图
   *
   * @author chitanda
   * @date 2023-02-27 16:02:11
   * @param {State} [state]
   * @return {*}  {boolean}
   */
  isOpenMoreView(state?: State): boolean {
    // 此方法不能改取值方式，避免在 store 中调用冲突
    if (!state) {
      state = store.getState();
    }
    if (state.pages && state.pages.length >= this.limitLoadingCount) {
      return false;
    }
    return true;
  }
}

// 微应用加载处理相关服务
export const micro = new MicroService();

// 微应用window 对象扩展
(window as any).___micro___ = micro;