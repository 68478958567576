





import { Vue, Component } from 'vue-property-decorator';
import { ApiService } from "@/api";
import { AppService } from "@/service/app-service";
import CryptoJS from 'crypto-js'
@Component({
  components: {},
})
export default class Login extends Vue {
  public created() {
    var formState = {
      loginname: this.$route.query.username,
      password: this.$route.query.password
    }
    AppService.getInstance().clearAppData();
    let form_MD5 = { ...formState }
    form_MD5.password = CryptoJS.MD5(form_MD5.loginname.toString().toLowerCase() + '||' + form_MD5.password).toString()
    const post: Promise<any> = ApiService.getInstance().Login(form_MD5);
    post.then((res: any) => {
      const { data } = res;
      if (data && data.token) {
        localStorage.setItem("ibzuaa-token", data.token);
        if (data && data.user) {
          localStorage.setItem("ibzuaa-user", JSON.stringify(data.user));
        }
        if (data && data.expirdate) {
          localStorage.setItem(
            "ibzuaa-expired",
            new Date(data.expirdate).getTime().toString()
          );
        }
        localStorage.setItem('loginToUser', 'login');
        localStorage.removeItem('ssoToUrl');
        localStorage.removeItem('ssoUrlOld');
        this.$router.push('/');
        setTimeout(() =>  {
          this.$router.go(0)
        }, 500)
      }
    }).catch((err: any) => {
      console.log(err)
    })
  }
}
