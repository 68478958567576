<template>
  <div class="messageDialog1">
    <iframe style="height: 100%;width: 100%;border: 0px!important;" :src="srcUrl"></iframe>
  </div>
</template>
<script>
export default {
  props: [
    'srcUrl',
  ],
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped></style>
