

















































































import { Vue, Component } from "vue-property-decorator";
import { ApiService } from "@/api";
import {eventBus, MicroActionService } from "@/service/micro-action-service";
import { AppService } from "@/service/app-service";
import shared from "@/shared";  
import AppUpdatePassword from "../app-update-password/app-update-password.vue"
import AppVerion from "../app-version/app-version.vue"
import myMenuDialog from "../base-home/components/my-menu-dialog.vue";

@Component({
  components:{
    AppUpdatePassword,
    AppVerion,
    myMenuDialog
  }
})
export default class AppUser extends Vue {

  /**
   * 用户信息
   *
   * @memberof AppUser
   */
  public user = {
    name: "",
    avatar: require("@/assets/avatar.svg"),
  };

  /**
  * 是否显示修改密码弹窗 
  */
  public isUpdatePassword:boolean = false

  /**
  * 是否显示版本信息弹窗
  */
  public isAppVersion:boolean = false

  /**
  * 是否显示版本信息 
  */
  public enableVersion:boolean = false

  /**
  * 是否显示修改密码 
  */
  public enablechangepwd:boolean = true

  /**
   * vue  生命周期
   *
   * @memberof AppUser
   */
  public mounted() {
    let _user: any = {};
     if (localStorage.getItem("ibzuaa-user")!=null) {
      const user = JSON.parse(localStorage.getItem("ibzuaa-user") as string);
      if(user.username === 'ibzadmin'){
        this.user.name = '系统管理员'
      }else{
        _user.name = user.personname;
      }
    }
    Object.assign(this.user, _user, {
      time: +new Date(),
    });
    const baseConfig:any = shared.getBaseConfig()
    this.enableVersion = baseConfig['enableVersion']
    this.enablechangepwd = baseConfig['enablechangepwd']
    // 监听来自tis页面打开“我得菜单”，通知app-user打开弹窗
    eventBus.$on('opemMenus', (flag:any) => {
      if (flag) {
        this.opemMenus()
      }
    }); 
  }

  created() {
    this.getIsSuperUser();
  }

  public getIsSuperUser() {
    ApiService.getInstance()
      .getIsSuperUser()
      .then((res) => {
        let loginName = localStorage.getItem("loginname")
        if (res.data.result && loginName !== "ibzadmin" && loginName !== "taxsystemadmin") {
          this.isShowMenus = true
          // 发布是否是自定义菜单管理员
          eventBus.$emit('isSuperUser', true);
        } else {
          this.isShowMenus = false
          // 发布是否是自定义菜单管理员
          eventBus.$emit('isSuperUser', false);
        }
      });
  }

  /**
   * 退出登录
   *
   * @memberof AppUser
   */
  public async logout() {
    try {
        const res:any = await ApiService.getInstance().Logout();
        if (res ) {
          AppService.getInstance().noticeAllMicroApp('CLEAR_CODE_LIST');
          if (localStorage.getItem('loginToUser')) {
            console.log('我走login退出')
            localStorage.removeItem('loginToUser');
            MicroActionService.getInstance().handleLogout(false);
          } else {
            console.log('我走SSO退出')
            localStorage.removeItem('loginToUser');
            ApiService.getInstance().ssoLogout().then((data:any) => {
              MicroActionService.getInstance().handleLogoutSSO();
              window.location.href = data.data.redirectUrl
            })
          }
          (document as any).querySelector(':root').removeAttribute('style')
        }
    } catch (error) {
          MicroActionService.getInstance().handleLogout();
          AppService.getInstance().noticeAllMicroApp('CLEAR_CODE_LIST');
    }
  }

  /**
  * 修改密码 
  */
  public updatePassword() {
    this.isUpdatePassword = true
  }

  /**
  *  版本信息弹窗 
  */
  public showVersion(){
    this.isAppVersion = true
    const ref = this.$refs.AppVerion;
    if(ref){
      (ref as any).getVersion()
    }
  }

  /**
  * 清除缓存数据 
  */
  public clearCache(){
        AppService.getInstance().clearAppData();
        localStorage.clear()
        sessionStorage.clear()
        shared.clearCache([])
        if((navigator as any).msClearCache){
            (navigator as any).msClearCache()
        }
        if((window as any).ClearSiteData){
            (window as any).ClearSiteData()
        }
        if((window as any).safari){
            (window as any).safari.clearCache();
        }
        if((window as any).caches){
            window.caches.keys().then(function (cacheNames) {
                console.log(cacheNames)
                cacheNames.forEach(function (cacheName) {
                window.caches.delete(cacheName);
                });
            });
        }
        this.$router.push({ name: "Login", query: { redirect: window.location.href.substring(window.location.href.indexOf("portal/") + 6) } });
  }

  //=====================我的菜单开始========================
  /**
  * 关闭我的菜单
  */
  public isClose:boolean = false;
  /**
  * 是否显示我的菜单
  */
  public isShowMenus:boolean = false

  /**
  * 打开我的菜单
  */
  public opemMenus(){
    const myMenuDialog:any = this.$refs.myMenuDialog;
    myMenuDialog.setDialogVisible(true);
  }

  /**
  * 关闭我的菜单弹窗
  */
  closeChange() {
    this.isClose = false;
  }

  public beforeDestroy() {
    // 取消 点击tis页面设置中得“我得菜单”，通知app-user打开弹窗
    eventBus.$off('opemMenus');
    // 取消 是否是菜单管理员
    eventBus.$off('isSuperUser');
  }

  //=====================我的菜单结束========================

  /**
  * 处理修改密码关闭事件 
  *
  */
  public handleUdatePassWordClose(){
    this.isUpdatePassword = false
    const ref = this.$refs.AppUpdatePassword
    if(ref && (ref as any).clear){
      (ref as any).clear()
    }
  }

  /**关闭事件
  * 处理版本信息关闭事件
  *
  */
  public handleAppVersionClose(){
    this.isAppVersion = false
  }

}
