import { render, staticRenderFns } from "./messageDialog4.vue?vue&type=template&id=28844d33&scoped=true&"
import script from "./messageDialog4.vue?vue&type=script&lang=js&"
export * from "./messageDialog4.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28844d33",
  null
  
)

export default component.exports