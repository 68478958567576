export class loadingService {
  /**
  * 单例变量声明
  *
  * @private
  * @static
  * @type {ApiService}
  * @memberof ApiService
  */
  private static loadingService: loadingService;

  /**
   * 获取 loadingService 单例对象
   *
   * @static
   * @returns {loadingService}
   * @memberof loadingService
   */
  public static getInstance(): loadingService {
    if (!loadingService.loadingService) {
      loadingService.loadingService = new loadingService();
    }
    return this.loadingService;
  }

  /**
  * 关闭loading 
  */
  public closeLoading(){
    const el = document.getElementById("app-root-loading-x");
    if (el) {
      el.style.display = "none";
    }
  }

}