































































import { Component, Vue, Watch } from "vue-property-decorator";
import MainMenu from "@/components/menu/index.vue";
import AppMenu from "@/components/app-menu/app-menu.vue";
import Login from "@/pages/login/index.vue";
import AppHeader from "@/components/app-header/app-header.vue";
import AppPageExp from "./components/app-page-exp/app-page-exp.vue";
import qs from "qs";
import { micro } from "./micro";
import { AppConfig } from "./config/config";
import { LocationItem } from "./custom-router/location-item";
import store from "@/shared/store";
import { ApiService } from "@/api";
import shared from "@/shared";

@Component({
  components: {
    MainMenu,
    Login,
    AppHeader,
    AppPageExp,
    AppMenu
  },
})
export default class App extends Vue {

  /**
  * true为标准菜单,false为自定义菜单 
  */
  appMenu:boolean = true

  /**
   * 是否收缩菜单
   */
  collapsed: boolean = false;

  /**
   * 是否显示侧边栏
   */
  sidebar = true;

  /**
   * 微应用服务
   */
  micro = micro;

  /**
   * 微应用配置
   */
  apps = AppConfig.apps;

  /**
  * 取消订阅对象
  */
  public unsubscribe: any;

  @Watch('$route', { immediate: true })
  watchRouter() {
    this.micro.loadApp(location.pathname, new LocationItem(location));
  }

  /**
   * 菜单容器样式
   */
  get menuStyle() {
    return {
      width: this.collapsed ? "80px" : "200px",
    };
  }

  /**
   * 容器样式
   */
  get frameStyle() {
    return {
      width: this.collapsed ? "calc(100vw - 80px)" : "calc(100vw - 200px)",
      left: this.collapsed ? "80px" : "200px",
    };
  }

  /**
   * 内容在不显示侧边栏时覆盖的样式
   */
  get frameSidebarStyle() {
    if (this.sidebar !== false) {
      return { ...this.frameStyle };
    }
    return {
      top: "0!important",
      left: "0!important",
      width: "100vw!important",
      height: "100vh!important",
    };
  }

  /**
   * 处理菜单收缩
   *
   */
  public toggleCollapsed() {
    this.collapsed = !this.collapsed;
    const childContainerLoading = document.getElementById(
      "child-container-loading"
    );
    if (childContainerLoading) {
      childContainerLoading.style.left = this.collapsed ? "80px" : "200px";
    }
  }

  /**
   * Vue生命周期 --- created
   */
  public created() {
    this.initTheme(); //动态主题颜色
    this.initThridParam();
    if(localStorage.getItem('ibzuaa-token')){
      this.getOpenMymenus()
    }
    if(sessionStorage.getItem('isAppMenu')){
      this.appMenu = sessionStorage.getItem('isAppMenu') === ' true' ? true : false
    }
    this.unsubscribe = store.subscribe(() => {
      if(store.getState().isAppMenu !== undefined){
        this.appMenu = Boolean(store.getState().isAppMenu);
        // this.appMenu = false
      }
    })
  }

  /**
   * 初始化主题
   */
  private initTheme() {
    let selectTheme: string = 'app-theme-default__micro';
    // if (localStorage.getItem('theme-class')) {
    //   selectTheme = localStorage.getItem('theme-class') as string;
    // }
    document.getElementsByTagName("html")[0].className = selectTheme;
  }

  /**
  * 是否开启我的菜单接口
  */
  public getOpenMymenus() {
    ApiService.getInstance()
      .getisOpenMymenus()
      .then((res: any) => {
        shared.setAppMemu(res.data.result == 1 ? false : true)
      }).catch((error: any) => {
        shared.setAppMemu(true)
      });
  } 

  /**
   * 初始化第三方参数
   */
  public initThridParam() {
    if (window.location && window.location.href.indexOf("?") > -1) {
      let tempViewParam: any = {};
      const tempViewparam: any = window.location.href.slice(
        window.location.href.lastIndexOf("?") + 1
      );
      const viewparamArray: Array<string> =
        decodeURIComponent(tempViewparam).split(";");
      if (viewparamArray.length > 0) {
        viewparamArray.forEach((item: any) => {
          Object.assign(tempViewParam, qs.parse(item));
        });
      }
      // 是否隐藏菜单栏
      if (
        tempViewParam.srffullscreen === "true" ||
        tempViewParam.dtt_sidebar === "false"
      ) {
        this.sidebar = false;
      }
    }
  }

  //===========================================拖拽开始=========================================
    /**
     * 拖拽元素状态
     */
     public dragState: any = { endMouseX: 0 }
    // 主要用于只有超过3s才关闭，如果鼠标滑过右侧不超过3s又进入菜单区域，则不触发关闭方法
    public isSrag:any = false
    // 只有点击拖拽之后才能操作其他，否则不触发，避免浪费资源
    public startMove:any = false

    /**
    * 处理鼠标按下事件
    */
    public handleMouseDown(event:any){
      let me = this
      this.startMove = true
      event.stopPropagation(); // 阻止事件冒泡
      (this.$refs.customLeft as any).style.transition = 'all 0s';
      (document as any).getElementById("silderScrollProtal").style.transition = 'all 0s';
      document.onselectstart = () => false;
      document.ondragstart = () => false;
      this.dragState = {
        endMouseX: event.clientX
      };
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);

      // 获取所有class为"view-container"的元素
      var viewContainers =  (document as any).getElementsByClassName("view-container");
      if (viewContainers) {
          // 遍历所有view-container元素
          for (var i = 0; i < viewContainers.length; i++) {
              var viewContainer = viewContainers[i];
              // 检查元素的display样式是否为"none" 
              if (window.getComputedStyle(viewContainer).display !== "none" && viewContainer.id !== '') {
                  // 获取view-container下的iframe元素
                  var iframe = viewContainer.querySelector("iframe");
                  // 为iframe添加鼠标监听事件
                  if (iframe) {
                      iframe.contentWindow.document.body.addEventListener('mousemove', this.handleMouseMove);
                      iframe.contentWindow.document.body.addEventListener('mouseup', this.handleMouseUp);
                      iframe.contentWindow.document.body.addEventListener('click',
                              me.handleLeave)
                  }
              }
          }
      }
    }

    /**
    * 处理鼠标移动事件
    *
    * @param {MouseEvent} 鼠标事件
    */
    public handleMouseMove(event:any){
        const {customLeft} = this.$refs
        const {endMouseX } = this.dragState
        const curretWidthLeft = (customLeft as any).getBoundingClientRect().width
        const distance = Math.abs(
            parseInt(((endMouseX - event.clientX) * 100).toString(), 10) / 100
        );
        const leftMinWidth = 200
        let leftWidth:any;
        if(endMouseX > event.clientX){
            // 向左移动
            leftWidth = curretWidthLeft - distance;
            (customLeft as any).style.width = `${leftWidth}px`;
            (document as any).getElementById("silderScrollProtal").style.left = `${leftWidth - 4}px`;
        }else{
            // 向右移动
            leftWidth = curretWidthLeft + distance;
            (customLeft as any).style.width = `${leftWidth}px`;
            (document as any).getElementById("silderScrollProtal").style.left = `${leftWidth - 4}px`;
        }
        // 最小阈值
        if(leftWidth < leftMinWidth){
            (customLeft as any).style.width = `${leftMinWidth}px`;
            (document as any).getElementById("silderScrollProtal").style.left = `${leftMinWidth - 4}px`;
        }
        // 记录鼠标结束位置
        this.dragState.endMouseX = event.clientX;
    }

     /**
     * 处理鼠标放开事件
     */
    public handleMouseUp () {
      // 移除鼠标移动事件
      document.removeEventListener('mousemove', this.handleMouseMove);
      // 移除鼠标放开事件
      document.removeEventListener('mouseup', this.handleMouseUp);
      // 允许用户选择网页中文字
      document.onselectstart = null;
      // 允许用户拖动元素
      document.ondragstart = null;


      // 获取所有class为"view-container"的元素
      var viewContainers = (document as any).getElementsByClassName("view-container");
      if (viewContainers) {
          // 遍历所有view-container元素
          for (var i = 0; i < viewContainers.length; i++) {
              var viewContainer = viewContainers[i];
              // 检查元素的display样式是否为"none"
              if (window.getComputedStyle(viewContainer).display !== "none"
              && viewContainer.id !== '') {
                  // 获取view-container下的iframe元素
                  var iframe = viewContainer.querySelector("iframe");
                  // 为iframe添加鼠标监听事件
                  if (iframe) {
                      iframe.contentWindow.document.body.removeEventListener('mousemove', this.handleMouseMove);
                      iframe.contentWindow.document.body.removeEventListener('mouseup', this.handleMouseUp);
                      iframe.contentWindow.document.body.removeEventListener('click',
                          function () {}
                      )
                  }
              }
          }
      }
      
    }

     /**
     * 进入左侧菜单
     */
    public  timer:any= null;
    handleMouseEnterLeft() {
      this.isSrag = true
    }

     /**
     * 进入右侧空白部分
     */
    handleMouseEnterRight() {
      this.isSrag = false;
      this.timer = setTimeout(() => {
        if (!this.isSrag) {
          this.handleLeave()
        } 
      }, 3000);

    }
    
    /**
    * 点击空白或者离开时恢复
    */
    public handleLeave () {
      (this.$refs.customLeft as any).style.transition = 'all 0.3s';
      (document as any).getElementById("silderScrollProtal").style.transition = 'all 0.3s';
      (this.$refs.customLeft as any).style.width = '200px';
      (document as any).getElementById("silderScrollProtal").style.left = '196px';
      this.startMove = false
      // 移除鼠标离开事件
      clearTimeout(this.timer);
    }
  //===========================================拖拽结束=========================================

  /**
  * 销毁
  */
  public destroyed() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
